/* eslint-disable prettier/prettier */
import { useEffect } from 'react';
import {
  IActivitiesFilter,
  IActivitiesFilterPayload,
  IActivityCreateData,
  IActivityData,
} from './activitiesInterface';

export const addNewActivityPayload = (
  f_id: string,
  data: IActivityCreateData,
  date?: string
) => {
  const payload: IActivityData = {
    facilityId: f_id,
    startingTime: data?.startingTime,
    startingDate: data?.startingDate,
    endingTime: data?.endingTime,
    endingDate: data?.endingDate,
    description: data?.description,
    name: data?.name,
    type: data?.type,
    location: data?.location,
    repeatable: data?.repeatable,
    weekdaysList: data?.weekdaysList ? data?.weekdaysList : [],
    particularDates: data?.particularDates ? data?.particularDates : [],
    image: data?.image,
    date,
  };

  return payload;
};

export const addActivityManupulator = (
  data: IActivityData,
  imageId: string,
  date?: string
) => {
  const payload = {
    ...data,
    posterImageId: imageId,
    date,
  };
  return payload;
};

export const updateExistingActivityPayload = (
  id: string,
  f_id: string,
  data: IActivityCreateData,
  date?: string
) => {
  const payload: IActivityData = {
    id: id,
    facilityId: f_id,
    startingTime: data?.startingTime,
    startingDate: data?.startingDate,
    endingTime: data?.endingTime,
    endingDate: data?.endingDate,
    description: data.description,
    name: data.name,
    type: data?.type,
    location: data?.location,
    repeatable: data?.repeatable,
    weekdaysList: data?.weekdaysList ? data?.weekdaysList : [],
    particularDates: data?.particularDates ? data?.particularDates : [],
    image: data?.image,
    date,
    repeatOption: data?.repeatOption,
    updateForSpecifiedDate: data.updateForSpecifiedDate,
    posterImageId: data?.posterImageId,
  };

  return payload;
};

export const convert24hourTo12HourFormat = (time: string) => {
  const timePartArray = time?.split(':');
  let time_24 = Number(timePartArray[0]);
  let amPm = 'AM';
  if (time_24 >= 12) {
    amPm = 'PM';
  }
  if (time_24 > 12) {
    time_24 = Number(timePartArray[0]) - 12;
  }
  const formattedTime = time_24 + ':' + timePartArray[1] + ' ' + amPm;
  return formattedTime;
};

export const deleteActivity = (id: string) => {
  const deleteUserPayload = {
    id: id,
    for: 'delete',
  };
  return deleteUserPayload;
};
export const editActivity = (id: string) => {
  const editUserPayload = {
    id: id,
    for: 'edit',
  };
  return editUserPayload;
};
export const filterChange = (
  params: IActivitiesFilter,
  f_id: string,
  date?: string
): IActivitiesFilterPayload => {
  const payload = {
    ...params,
    facilityIds: f_id,
    date,
  };
  return payload;
};

export const deleteActivityPayload = (
  id: string,
  f_id: string,
  date: string,
  repeatOption: string
) => {
  const payload = {
    id: id,
    facilityId: f_id,
    date,
    repeatOption,
  };
  return payload;
};

export const disablePastDate = () => {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const yyyy = today.getFullYear();
  return yyyy + '-' + mm + '-' + dd;
};

type Ref = { current: any };
type EventHandler = (event: Event) => void;
export const useOnClickOutside = (ref: Ref, handler: EventHandler) => {
  useEffect(() => {
    const listener = (event: Event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};
