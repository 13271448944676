export interface IAddRestaurant {
  restaurantName: string;
  startingTime: string;
  endTime: string;
  image?: File[];
  menuImages: any[];
  existingMenuIds: any[];
}
export interface IAddRestaurantPayload {
  restaurantName: string;
  startingTime: string;
  endTime: string;
  facilityId: string;
  image?: File[];
  imageId?: string;
  menuImages?: File[];
  menuIds?: string[];
  // filter: IRestaurantFilter;
}
export interface IRestaurantPaginate {
  totalPages: number;
  totalElements: number;
  numberOfElements: number;
  size: number;
  number: number;
}
export interface IRestaurantFilter {
  sort: string;
  page: number;
  size: number;
}
export interface IMenuFile {
  type: string;
  file: FileList;
}
export interface IMenuFileSrc {
  file: string;
  fileType: string;
  fileName: string;
}

export interface IMenuIds {
  type: string;
  file: string;
}
export interface IRestaurants {
  id: string;
  restaurantName: string;
  startTime: string;
  endTime: string;
  imageId: string;
  menuIds: IMenuFileSrc[];
}
export interface IUpdateRestaurantPayload {
  id: string;
  restaurantName: string;
  startingTime: string;
  endTime: string;
  facilityId: string;
  menuIds?: string[];
  image?: File[];
  imageId?: string;
  menuImages?: File[];
  existingMenuIds?: string[];
}
export interface IGetMealsByDate {
  facilityIds?: string;
  restaurantId: string;
  date: string;
}
export interface IMealsPerDay {
  id: string;
  mealType: string;
  startTime: string;
  endTime: string;
  restaurantId: string;
  date: string;
  weekdays: string[];
  noOfItems: number;
  mealCategory?: {
    id: number;
    categoryName: string;
  };
}
export interface IMealItems {
  id: string;
  title: string;
  description: string;
  price: number;
  mealId: string;
  type: string;
}
export interface IGetMealsByWeeks {
  facilityIds?: string;
  restaurantId: string;
  weeks: string;
}
export interface IMealsByWeekData {
  id: string;
  mealType: string;
  startTime: string;
  endTime: string;
  restaurantId: string;
  date: string;
  weekdays: string[];
  noOfItems: number;
}

export interface IDiningDynamicInput {
  id?: string;
  title: string;
  price: string | number;
  description: string;
  type: string;
}
export interface IAcitveButtons {
  normalButton: boolean;
  exclusiveButton: boolean;
}
export interface IDailiyRepeatConst {
  date: string;
  repeat: string;
}
export interface IItemTypeConst {
  exclusive: string;
  regular: string;
}
export const DailyRepeatConstants: IDailiyRepeatConst = {
  date: 'DATE',
  repeat: 'REPEAT',
};
export const ItemTypeConstants: IItemTypeConst = {
  exclusive: 'EXCLUSIVE',
  regular: 'REGULAR',
};
export interface IActiveItemType {
  type: string;
  id: string;
}

export interface IWeekdaysConstants {
  for: string;
  content: string;
  value: string;
}
export interface IWeekdaysCheckbox {
  id: string;
  value: string;
  checked: boolean;
}
export interface IDiningItemsPayload {
  id?: string;
  title: string;
  description: string;
  price: number | string;
  type: string;
}
export interface IAddNewDiningMealsPayload {
  title: string;
  startTime: string;
  endTime: string;
  restaurantId: string;
  categoryId?: number;
  repeatable?: boolean;
  date?: string;
  weekdaysList?: string[];
  diningItems: IDiningItemsPayload[];
}
export interface IAddNewDiningMealsParams {
  item: IAddNewDiningMealsPayload;
  date: string;
}
export interface IUpdateDailyDiningMealsPayload {
  id: string;
  title: string;
  startTime: string;
  endTime: string;
  categoryId?: number;
  restaurantId: string;
  diningItems: IDiningItemsPayload[];
  date: string;
  applyToTheDate?: boolean;
}
export interface IUpdateRepeatDiningMealsPayload {
  id: string;
  title: string;
  startTime: string;
  endTime: string;
  restaurantId: string;
  categoryId?: number;
  date?: string;
  repeatable: boolean;
  weekdaysList: string[];
  diningItems: IDiningItemsPayload[];
}
export interface IAddToScheduleItem {
  date: string;
  restaurantId: string;
  title: string;
  startTime: string;
  endTime: string;
}
export interface IAddToScheduleData {
  item: IAddToScheduleItem;
  id: string;
}
export interface IDeleteMealsPayload {
  id: string;
  weeks: string[];
  restaurantId: string;
  date: string;
}

export interface ISkipMealsData {
  id: string;
  skipDate: string;
  date: string;
  weeks: string[];
  restaurantId: string;
}
export interface IUpdateRepeatDiningMealsParams {
  item: IUpdateRepeatDiningMealsPayload;
  date: string;
}
export interface IGetMealItems {
  id: string;
  type?: string;
  tag?: string;
}
export interface IRemoveWeeks {
  weekdaysList: string[];
  id: string;
  repeatable: boolean;
  restaurantId: string;
}
export const daysArray: string[] = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];
export interface IRepeatFormSchema {
  name: string;
  startTime: string;
  endTime: string;
  categoryId?: number;
}
export interface IItemDetails {
  id: string;
  title: string;
  price: string;
  description: string;
}
export interface IRestaurantValidation {
  restaurantName: string;
  startingTime: string;
  endTime: string;
}
export interface IDeleteRestaurantPayload {
  id: string;
  facilityId: string;
}
export interface IRestaurantModal {
  id: string;
  for?: string;
}
export interface IDateWeek {
  date: number;
  week: string;
}
export interface IdiningOrderPayload {
  newPosition: IdiningOrderProps[];
  date: string;
}
export interface IdiningOrderProps {
  mealId: string;
  position: number;
}
