import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../pages/dashboard/dashboard.css';

const DashboardNoData: React.FC<{
  name: string;
}> = ({ name }) => {
  const [translation] = useTranslation('common');
  return (
    <div>
      <div className="noDashboardData">
        <img src={process.env.PUBLIC_URL + '/assets/noData.svg'} alt="" />
        <span> {translation('DASHBOARD.NO_DATA')}</span>
      </div>
    </div>
  );
};

export default DashboardNoData;
