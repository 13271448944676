/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '../../app/store';
import {
  getAllRatingsReviews,
  getAvgRatingsReviews,
  getUserDetails,
} from '../../services/ratingsReviewsService';
import { getRatingsRequestParams } from './ratingsParams';
import {
  IRatingsFilter,
  IRatingsPaginate,
  IRatingsReviewsData,
  IRatingsReviewsStats,
} from './ratingsReviewsInterface';

export interface IRatingsReviews {
  isProcessingRatingsReviews: boolean;
  businessRatingsReviewsData: IRatingsReviewsData[];
  isProcessingAvgRatingsReviews: boolean;
  avgRatingsReviewsStats: IRatingsReviewsStats;
  ratingsFilter: IRatingsFilter;
  ratingsPaginate: IRatingsPaginate;
}
const initialState: IRatingsReviews = {
  isProcessingRatingsReviews: false,
  businessRatingsReviewsData: [],
  isProcessingAvgRatingsReviews: false,
  avgRatingsReviewsStats: {} as IRatingsReviewsStats,
  ratingsFilter: {
    sort: 'createdAt,Desc',
    page: 0,
    size: 10,
    id: '',
  },
  ratingsPaginate: {} as IRatingsPaginate,
};

export const ratingsReviewsSlice = createSlice({
  name: 'ratingsReviews',
  initialState,
  reducers: {
    resetRatingsReviewsState: () => {
      return initialState;
    },
    setRatingsPaginate: (state, action) => {
      state.ratingsPaginate = action.payload;
    },
    addRatingsFilterPage: (state, action) => {
      state.ratingsFilter.page = action.payload;
    },
    ratingReviewFilterPageReset: (state) => {
      state.ratingsFilter.page = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRatingsReviews.pending, (state) => {
        state.isProcessingRatingsReviews = true;
      })
      .addCase(
        fetchAllRatingsReviews.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isProcessingRatingsReviews = false;
          state.businessRatingsReviewsData = action.payload?.data[0]?.data;
          state.ratingsPaginate = action.payload?.data[0];
        }
      )
      .addCase(
        fetchAllRatingsReviews.rejected,
        (state, action: PayloadAction<any>) => {
          state.isProcessingRatingsReviews = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(fetchAvgRatingsReviewsStats.pending, (state) => {
        state.isProcessingAvgRatingsReviews = true;
      })
      .addCase(
        fetchAvgRatingsReviewsStats.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isProcessingAvgRatingsReviews = false;
          state.avgRatingsReviewsStats = action.payload?.data[0];
        }
      )
      .addCase(
        fetchAvgRatingsReviewsStats.rejected,
        (state, action: PayloadAction<any>) => {
          state.isProcessingAvgRatingsReviews = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      );
  },
});

export const fetchAllRatingsReviews = createAsyncThunk(
  'ratingsReviews/fetchAllRatingsReviews',
  async (data: IRatingsFilter, { rejectWithValue, dispatch }) => {
    try {
      const params = getRatingsRequestParams(
        data.sort,
        data.page,
        data.size,
        data.id
      );
      const response = await getAllRatingsReviews(params);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchAvgRatingsReviewsStats = createAsyncThunk(
  'ratingsReviews/fetchAvgRatingsReviewsStats',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getAvgRatingsReviews(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchUserDetails = createAsyncThunk(
  'ratingsReviews/fetchUserDetails',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getUserDetails(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const {
  resetRatingsReviewsState,
  setRatingsPaginate,
  addRatingsFilterPage,
  ratingReviewFilterPageReset,
} = ratingsReviewsSlice.actions;
export const selectBookings = (state: RootState) => state.ratingsReviews;
export const ratingsReviewsReducer = ratingsReviewsSlice.reducer;
