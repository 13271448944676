import { IFacilityReviewsFilter } from './facilityReviewsInterface';

export const facilityReviewPayloadSetter = (
  filter: IFacilityReviewsFilter,
  facilityId: string
) => {
  const payload = {
    ...filter,
    id: facilityId,
  };
  return payload;
};
export const deleteReviewPicPayload = (id: string, photoId: string) => {
  const payload = {
    mealTypeId: id,
    restaurantId: photoId,
  };
  return payload;
};
