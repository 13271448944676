import React, { ReactNode } from 'react';
import Slider from 'react-slick';
// import 'slick-carousel/slick/slick-theme.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import '../../../node_modules/slick-carousel/slick/slick.css';

export interface IResponsive {
  breakpoint: number;
  settings: {
    slidesToShow: number;
    slidesToScroll: number;
    infinite: boolean;
    dots: boolean;
  };
}
export interface ISlider {
  dots: boolean;
  infinite: boolean;
  speed: number;
  slidesToShow: number;
  slidesToScroll: number;
  arrows: boolean;
  draggable: boolean;
  responsive: IResponsive[];
}

const SliderComponent: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const settings: ISlider = {
    dots: false,
    infinite: true,
    speed: 800,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    draggable: true,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  return (
    <div className="padding-top-1_5rem padding-bottom-min">
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

export default SliderComponent;
