/* eslint-disable react/react-in-jsx-scope */
import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import { domain_env } from '../config/env';
import { PathConstants } from '../constants/pathConstants';
import { isAuthenticated } from './localStorage';

const PrivateRoute: FC<{
  children: JSX.Element;
  hostName: string;
}> = ({ children, hostName }) => {
  if (isAuthenticated()) {
    return children;
  } else {
    return domain_env.BUSINESS_DOMAINS.includes(hostName) ? (
      <Navigate to={PathConstants.BUSINESS_LOGIN} />
    ) : domain_env.FACILITY_DOMAINS.includes(hostName) ||
      domain_env.LOCALHOST.includes(hostName) ? (
      <Navigate to={PathConstants.LOGIN} />
    ) : null;
  }
};
export default PrivateRoute;
