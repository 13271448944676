export type TBreakPoints = {
  [key in number]: {
    slidesPerView: number;
  };
};
export const diningBreakPoints: TBreakPoints = {
  1440: {
    slidesPerView: 15,
  },
  1240: {
    slidesPerView: 10,
  },
  1024: {
    slidesPerView: 7,
  },
  770: {
    slidesPerView: 5,
  },
  544: {
    slidesPerView: 5,
  },
  0: {
    slidesPerView: 4,
  },
};
export const activityBreakPoints: TBreakPoints = {
  1440: {
    slidesPerView: 20,
  },
  1240: {
    slidesPerView: 15,
  },
  1024: {
    slidesPerView: 12,
  },
  884: {
    slidesPerView: 10,
  },
  634: {
    slidesPerView: 8,
  },
  544: {
    slidesPerView: 6,
  },
  420: {
    slidesPerView: 5,
  },
  330: {
    slidesPerView: 4,
  },
  0: {
    slidesPerView: 3,
  },
};
