import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '../../app/store';
import {
  createPassword,
  // eslint-disable-next-line prettier/prettier
  resetPassword,
} from '../../services/createPasswordService';
import { History } from '../../_helpers/history';
import { setTokens } from '../../_helpers/localStorage';
import { ICreatePassword } from './createPasswordInterface';

export interface ICreate {
  isProcessingCreatePassword: boolean;
  isProcessingResetPassword: boolean;
  isProcessingBusinessResetPassword: boolean;
}

const initialState: ICreate = {
  isProcessingCreatePassword: false,
  isProcessingResetPassword: false,
  isProcessingBusinessResetPassword: false,
};

export const createPasswordSlice = createSlice({
  name: 'createPassword',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(createUsersPassword.pending, (state) => {
        state.isProcessingCreatePassword = true;
      })
      .addCase(
        createUsersPassword.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isProcessingCreatePassword = false;
          setTokens(action.payload.data[0]?.accessToken);

          toast.success(
            `${action.payload?.message
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload?.message.slice(1)}`
          );
        }
      )
      .addCase(
        createUsersPassword.rejected,
        (state, action: PayloadAction<any>) => {
          state.isProcessingCreatePassword = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(resetUsersPassword.pending, (state) => {
        state.isProcessingResetPassword = true;
      })
      .addCase(resetUsersPassword.fulfilled, (state, action) => {
        state.isProcessingResetPassword = false;
        toast.success('Password reset. Please Login with your New password');
        History.navigate('/login');
      })
      .addCase(
        resetUsersPassword.rejected,
        (state, action: PayloadAction<any>) => {
          state.isProcessingResetPassword = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(resetBusinessUsersPassword.pending, (state) => {
        state.isProcessingResetPassword = true;
      })
      .addCase(resetBusinessUsersPassword.fulfilled, (state, action) => {
        state.isProcessingResetPassword = false;
        toast.success('Password reset. Please Login with your New password');
        History.navigate('/business/login');
      })
      .addCase(
        resetBusinessUsersPassword.rejected,
        (state, action: PayloadAction<any>) => {
          state.isProcessingResetPassword = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      );
  },
});

export const createUsersPassword = createAsyncThunk(
  'createPassword/createUserPassword',
  async (createData: ICreatePassword, { rejectWithValue }) => {
    try {
      const response = await createPassword(createData);
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);
export const resetUsersPassword = createAsyncThunk(
  'createPassword/resetUserPassword',
  async (resetData: ICreatePassword, { rejectWithValue }) => {
    try {
      const response = await resetPassword(resetData);
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);
export const resetBusinessUsersPassword = createAsyncThunk(
  'createPassword/resetBusinessUsersPassword',
  async (resetData: ICreatePassword, { rejectWithValue }) => {
    try {
      const response = await resetPassword(resetData);
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const selectCreatePassword = (state: RootState) => state.createPassword;
export const createPasswordReducer = createPasswordSlice.reducer;
