import { IAmenityOrderPayload } from '../pages/amenitiesPage/amenitiesInterface';
import { IProfileUpdatePayload } from '../pages/facilityProfilePage/profileInterface';
import { getImageApi, patch, post } from './apiService';

export const updateFacility = async (data: IProfileUpdatePayload) => {
  return await patch(`/facility/${data?.facilityId}`, data);
};
export const getImageId = async (data: any) => {
  return await post(`/files`, data);
};
export const getImage = async (id: string) => {
  return await getImageApi(`/files/${id}`);
};
export const postOrderChange = async (data: IAmenityOrderPayload) => {
  return await post(`/facility-amenities/position-change`, data);
};
