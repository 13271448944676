import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getLanguageCode } from '../../_helpers/checkerFunctions';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import DateComponent from '../../components/activitiesForm/dateComponent';
import TimeCompWrap from '../../components/activitiesForm/timeCompWrap';
import EventImageComp from '../../components/eventComponent/eventImageComp';
import UsersCard from '../../components/eventComponent/usersCard';
import FullScreenImgComp from '../../components/fullScreenImage/fullScreenImgComp';
import PageLoader from '../../components/pageLoading/pageLoader';
import SectionLoader from '../../components/sectionLoader/sectionLoader';
import {
  fetchEventById,
  fetchEventCheckedInUsers,
} from '../activitiesPage/activiitiesSlice';
import {
  IActivity,
  IGuestUserData,
} from '../activitiesPage/activitiesInterface';
import { getSelectedFacility, translateText } from '../loginPage/authSlice';
import './event.css';

const Event: React.FC = () => {
  const { f_id, eventId } = useParams();
  const { isFetchingGuests, isProcessingEvent } = useAppSelector(
    (state) => state.activities
  );
  const [eventData, setEventData] = useState<IActivity>({} as IActivity);
  const [guestUsers, setGuestUsers] = useState<IGuestUserData[]>([]);
  const [enlargableImage, setEnlargableImage] = useState<string>('');
  const [openEnlage, setOpenEnlarge] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [translation] = useTranslation('common');
  const [t, i18n] = useTranslation('common');
  const language = i18n.language;

  const translatefunction = async (words: string[]) => {
    const resp = await dispatch(
      translateText({
        q: [words],
        target: getLanguageCode(language),
      })
    );

    const responseName = resp?.payload?.data?.translations[0]?.translatedText;
    const responseDescription =
      resp?.payload?.data?.translations[1]?.translatedText;

    const data = {
      ...eventData,
      name: responseName,
      description: responseDescription,
    };
    setEventData(data);
  };

  useEffect(() => {
    if (f_id) {
      dispatch(getSelectedFacility(f_id));
    }
  }, []);
  useEffect(() => {
    getEventFunc();
  }, [eventId]);

  //translation

  const getEventFunc = async () => {
    if (eventId) {
      const response = await dispatch(fetchEventById(eventId));
      const userResponse = await dispatch(fetchEventCheckedInUsers(eventId));
      setEventData(response.payload?.data[0]);
      setGuestUsers(userResponse.payload?.data[0]?.users);
    }
  };

  useEffect(() => {
    if (language !== 'en' && eventData?.description) {
      translatefunction([eventData?.name, eventData?.description]);
    }
  }, [language, eventData?.description]);

  useEffect(() => {
    if (language === 'en') {
      getEventFunc();
    }
  }, [language, eventId]);

  // ----------

  const enlargeImageClick = (image: string) => {
    setEnlargableImage(image);
    setOpenEnlarge(true);
  };
  const closeEnlargeImage = () => {
    setEnlargableImage('');
    setOpenEnlarge(false);
  };
  function sortDays(days: string[]): string[] {
    // Define a custom sorting order based on weekdays
    const weekdaysOrder: { [key: string]: number } = {
      MONDAY: 1,
      TUESDAY: 2,
      WEDNESDAY: 3,
      THURSDAY: 4,
      FRIDAY: 5,
      SATURDAY: 6,
      SUNDAY: 7,
    };

    // Sort the days based on the custom order
    const sortedDays = days
      .slice()
      .sort((a, b) => weekdaysOrder[a] - weekdaysOrder[b]);
    return sortedDays;
  }
  function sortDates(dates: string[] | undefined | null): string[] {
    if (!dates) {
      return []; // Return an empty array if dates is null or undefined
    }

    const copiedDates = [...dates]; // Spread only if dates is defined
    return copiedDates.sort((a, b) => moment(a).diff(moment(b)));
  }
  return (
    <div className="page-background amenities-page">
      <FullScreenImgComp
        image={enlargableImage}
        state={openEnlage}
        closeClass={closeEnlargeImage}
      >
        <EventImageComp
          divClass="enlarge-image-div"
          className="enlarge-image"
          src="/assets/eventIcon.svg"
          imageId={enlargableImage}
          loadingColor="dark"
        />
      </FullScreenImgComp>
      <div className="amenitiesPage-head event-head">
        <h2> {translation('ACTIVITIES.EVENT_DETAILS')}</h2>
      </div>

      <div className="event-details-container">
        {isProcessingEvent ? (
          <PageLoader />
        ) : eventData ? (
          eventData && (
            <>
              <div className="event-left-container">
                <div className="event-icon">
                  <img
                    src={process.env.PUBLIC_URL + '/assets/eventIconBig.svg'}
                    alt=""
                  />
                </div>
                <div className="event-details-div">
                  <div className="event-heading-div">
                    <span className="heading">{eventData?.name}</span>
                    <div className="event-location-div">
                      <img
                        src={
                          process.env.PUBLIC_URL + '/assets/locationIcon.svg'
                        }
                        alt=""
                      />
                      <span className="location">
                        {eventData?.location
                          ? `${eventData?.location}, ${eventData?.facility?.facilityName}`
                          : `${eventData?.facility?.facilityName}`}
                      </span>
                    </div>
                    <div className="event-time-date-div">
                      {eventData?.repeatable &&
                        eventData?.weekdaysList?.length != 0 && (
                          <DateComponent
                            comp={
                              <span>
                                {translation('ACTIVITIES.REPEAT_ON')} &nbsp;
                                {sortDays(
                                  eventData?.weekdaysList as string[]
                                ).map((item: string, index: number) => (
                                  <span key={index}>
                                    {item}
                                    {
                                      <span>
                                        {index + 1 !=
                                          eventData?.weekdaysList?.length &&
                                          ', '}
                                      </span>
                                    }
                                  </span>
                                ))}
                              </span>
                            }
                          />
                        )}
                      {!eventData?.repeatable &&
                        eventData?.particularDates?.length != 0 && (
                          <DateComponent
                            comp={
                              <span>
                                {sortDates(
                                  eventData?.particularDates as string[]
                                )?.map((item: string, index: number) => (
                                  <span key={index}>
                                    {moment(item).format('DD/MM/YYYY')}
                                    {
                                      <span>
                                        {index + 1 !=
                                          eventData?.particularDates?.length &&
                                          ', '}
                                      </span>
                                    }
                                  </span>
                                ))}
                              </span>
                            }
                          />
                        )}
                      {!eventData?.repeatable &&
                        eventData?.startingDate != '' &&
                        eventData?.particularDates?.length == 0 &&
                        eventData?.weekdaysList?.length == 0 && (
                          <DateComponent
                            comp={
                              <span>{`${eventData?.startingDate} to ${eventData?.endingDate}`}</span>
                            }
                          />
                        )}
                      {eventData?.startingTime && eventData?.endingTime && (
                        <TimeCompWrap
                          startTime={eventData?.startingTime}
                          endTime={eventData?.endingTime}
                        />
                      )}
                    </div>
                  </div>

                  <div className="event-details-separator"></div>
                  <div className="about-event-div">
                    <span className="about-event">
                      {' '}
                      {translation('ACTIVITIES.ABOUT_EVENT')} &nbsp;
                    </span>
                    <span className="event-description">
                      {eventData?.description ? (
                        eventData?.description
                      ) : (
                        <span className="no-guests-yet">
                          {translation('ACTIVITIES.NO_DESCRIPTION')}
                        </span>
                      )}
                    </span>
                  </div>
                  <div className="event-attachment-div">
                    <span className="attachment">
                      {translation('ACTIVITIES.ATTACHMENT')}
                    </span>
                    {eventData?.posterImageId ? (
                      <EventImageComp
                        divClass="posterImage-div"
                        className="posterImage"
                        src="/assets/eventIcon.svg"
                        imageId={eventData?.posterImageId}
                        loadingColor="dark"
                        onImageClick={enlargeImageClick}
                      />
                    ) : (
                      <span className="no-guests-yet">
                        {translation('ACTIVITIES.NO_ATTACHMENTS')}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className="event-right-container">
                <div className="users-head-div">
                  <img
                    src={process.env.PUBLIC_URL + '/assets/groupIcon.svg'}
                    alt=""
                  />
                  <span className="guests-interested-heading">
                    {`${
                      eventData?.noOfGuestsInterested
                        ? eventData?.noOfGuestsInterested
                        : '0'
                    } ${
                      eventData?.noOfGuestsInterested > 1
                        ? translation('ACTIVITIES.GUESTS_INTERESTED')
                        : translation('ACTIVITIES.GUEST_INTERESTED')
                    }`}
                  </span>
                </div>
                <div className="user-details-separator"></div>
                {isFetchingGuests ? (
                  <SectionLoader />
                ) : guestUsers.length ? (
                  <div className="guest-card-parent guestUser-parent-card-R">
                    {guestUsers?.map((user: IGuestUserData, index: number) => (
                      <UsersCard key={index} user={user} />
                    ))}
                  </div>
                ) : (
                  <span className="no-guests-yet">
                    {' '}
                    {translation('ACTIVITIES.No_GUESTS_YET')}
                  </span>
                )}
              </div>
            </>
          )
        ) : null}
      </div>
    </div>
  );
};

export default Event;
