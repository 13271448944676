/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';
import { RootState } from '../../app/store';
import { closeModalReducer } from '../../components/modal/modalSlice';
import { getImage } from '../../services/facilityService';
import {
  deleteReviewPic,
  getFacilityReviews,
  getFacilityReviewStats,
  getFaciltyReviewById,
  getUserDetails,
} from '../../services/ratingsReviewsService';
import { getRatingsRequestParams } from '../ratingsReviewsPage/ratingsParams';
import { IUserPaginateData } from '../usersPage/usersInterface';
import {
  deleteReview,
  IFacilityReviewsFilter,
  IFacilityReviewStats,
  IFaciltiyReviewsData,
  LastdeleteReview,
} from './facilityReviewsInterface';
import { facilityReviewPayloadSetter } from './utilityFunctions';

export interface IFacilityReviews {
  isProcessingFacilityReviews: boolean;
  facilityReviewsData: IFaciltiyReviewsData[];
  facilityReviewByIdData: IFaciltiyReviewsData;
  facilityReviewsFilter: IFacilityReviewsFilter;
  facilityReviewsPaginate: IUserPaginateData;
  isProcessingFacilityReviewStats: boolean;
  facilityReviewStats: IFacilityReviewStats;
  isFetchingUserImage: boolean;
  isDeleteReiviewImage: boolean;
  isFetchFacilityReviewById: boolean;
  isProcessingFacilityReviewImageById: boolean;
  processedFacilityReviewImageById: boolean;
  isDeleteLastReiviewImage: boolean;
}
const initialState: IFacilityReviews = {
  isProcessingFacilityReviews: false,
  isDeleteReiviewImage: false,
  isFetchFacilityReviewById: false,
  facilityReviewByIdData: {} as IFaciltiyReviewsData,
  facilityReviewsData: [],
  facilityReviewsFilter: {
    sort: 'createdAt,Desc',
    page: 0,
    size: 10,
    id: '',
  },
  facilityReviewsPaginate: {
    pageNumber: 0,
    pageSize: 0,
    numberOfElements: 0,
    totalPages: 0,
    totalElements: 0,
    first: false,
  },
  isProcessingFacilityReviewStats: false,
  facilityReviewStats: {} as IFacilityReviewStats,
  isFetchingUserImage: false,
  isProcessingFacilityReviewImageById: false,
  processedFacilityReviewImageById: false,
  isDeleteLastReiviewImage: false,
};

export const facilityReviewsSlice = createSlice({
  name: 'facilityReviews',
  initialState,

  reducers: {
    resetRatingsReviewsState: () => {
      return initialState;
    },
    addFilterPage: (state, action) => {
      state.facilityReviewsFilter.page = action.payload;
    },
    facilityReviewPageFilterReset: (state) => {
      state.facilityReviewsFilter.page = 0;
    },
    clearFacilityReviewStats: (state) => {
      state.facilityReviewStats = {
        averageRating: 0,
        ratingCount: 0,
        reviewCount: 0,
      };
    },
    addTranslatedData: (state, action) => {
      state.facilityReviewsData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllFacilityReviews.pending, (state) => {
        state.isProcessingFacilityReviews = true;
      })
      .addCase(
        fetchAllFacilityReviews.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isProcessingFacilityReviews = false;
          state.facilityReviewsData = action.payload?.data[0]?.data;
          state.facilityReviewsPaginate = action.payload?.data[0];
        }
      )
      .addCase(
        fetchAllFacilityReviews.rejected,
        (state, action: PayloadAction<any>) => {
          state.isProcessingFacilityReviews = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(fetchAvgFacilityReviewsStats.pending, (state) => {
        state.isProcessingFacilityReviewStats = true;
      })
      .addCase(
        fetchAvgFacilityReviewsStats.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isProcessingFacilityReviewStats = false;
          state.facilityReviewStats = action.payload?.data[0];
        }
      )
      .addCase(
        fetchAvgFacilityReviewsStats.rejected,
        (state, action: PayloadAction<any>) => {
          state.isProcessingFacilityReviewStats = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(getUserImageById.pending, (state) => {
        state.isFetchingUserImage = true;
      })
      .addCase(getUserImageById.fulfilled, (state) => {
        state.isFetchingUserImage = false;
      })
      .addCase(
        getUserImageById.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingUserImage = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(deleteRatingPhoto.pending, (state) => {
        state.isDeleteReiviewImage = true;
      })
      .addCase(deleteRatingPhoto.fulfilled, (state) => {
        state.isDeleteReiviewImage = false;
      })
      .addCase(
        deleteRatingPhoto.rejected,
        (state, action: PayloadAction<any>) => {
          state.isDeleteReiviewImage = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(deleteLastRatingPhoto.pending, (state) => {
        state.isDeleteLastReiviewImage = true;
      })
      .addCase(deleteLastRatingPhoto.fulfilled, (state) => {
        state.isDeleteLastReiviewImage = false;
      })
      .addCase(
        deleteLastRatingPhoto.rejected,
        (state, action: PayloadAction<any>) => {
          state.isDeleteLastReiviewImage = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(fetchfacilityRatingById.pending, (state) => {
        state.isFetchFacilityReviewById = true;
      })
      .addCase(
        fetchfacilityRatingById.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isFetchFacilityReviewById = false;
          state.facilityReviewByIdData = action.payload?.data[0];
        }
      )
      .addCase(
        fetchfacilityRatingById.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchFacilityReviewById = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )

      .addCase(fetchFacilityReviewImageById.pending, (state) => {
        state.isProcessingFacilityReviewImageById = true;
        state.processedFacilityReviewImageById = false;
      })
      .addCase(fetchFacilityReviewImageById.fulfilled, (state, action) => {
        state.isProcessingFacilityReviewImageById = false;
        state.processedFacilityReviewImageById = true;
      })
      .addCase(
        fetchFacilityReviewImageById.rejected,
        (state, action: PayloadAction<any>) => {
          state.isProcessingFacilityReviewImageById = false;
          state.processedFacilityReviewImageById = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      );
  },
});

export const fetchAllFacilityReviews = createAsyncThunk(
  'facilityReviews/fetchAvgRatingsReviewsStats',
  async (data: IFacilityReviewsFilter, { rejectWithValue }) => {
    try {
      const params = getRatingsRequestParams(
        data.sort,
        data.page,
        data.size,
        data.id
      );
      const response = await getFacilityReviews(params);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchAvgFacilityReviewsStats = createAsyncThunk(
  'facilityReviews/fetchAvgFacilityReviewsStats',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getFacilityReviewStats(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchUserDetails = createAsyncThunk(
  'facilityReviews/fetchUserDetails',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getUserDetails(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteRatingPhoto = createAsyncThunk(
  'facilityReviews/deleteReviewPic',
  async (ids: deleteReview, { rejectWithValue }) => {
    try {
      const response = await deleteReviewPic(ids.reviewId, ids.imageId);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteLastRatingPhoto = createAsyncThunk(
  'facilityReviews/deleteLastReviewPic',
  async (ids: LastdeleteReview, { rejectWithValue, dispatch, getState }) => {
    const { ratingsReviews } = getState() as {
      ratingsReviews: IFacilityReviews;
    };

    try {
      const response = await deleteReviewPic(ids.reviewId, ids.imageId);
      dispatch(
        fetchAllFacilityReviews(facilityReviewPayloadSetter(ids.filter, ids.id))
      );
      dispatch(closeModalReducer());

      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchfacilityRatingById = createAsyncThunk(
  'facilityReviews/fetchImagebyId',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getFaciltyReviewById(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const getUserImageById = createAsyncThunk(
  'facilityProfile/getUserImageById',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getImage(id);
      const fileType = response.headers['content-type'];
      const buffer = Buffer.from(response.data).toString('base64');

      const data = `data:${fileType};base64, ${buffer}`;

      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchFacilityReviewImageById = createAsyncThunk(
  'facilityReviews/fetchFacilityReviewImageById',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getImage(id);
      const fileType = response.headers['content-type'];
      const buffer = Buffer.from(response.data).toString('base64');

      const data = `data:${fileType};base64, ${buffer}`;

      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const {
  resetRatingsReviewsState,
  addFilterPage,
  facilityReviewPageFilterReset,
  clearFacilityReviewStats,
  addTranslatedData,
} = facilityReviewsSlice.actions;
export const selectBookings = (state: RootState) => state.ratingsReviews;
export const facilityReviewsReducer = facilityReviewsSlice.reducer;
