import React from 'react';
import { useTranslation } from 'react-i18next';

export interface IRestaurantOptions {
  tag: string;
  label?: string;
}

const DropdownComp: React.FC<{
  compKey?: string;
  optionClickParent: (data: IRestaurantOptions) => void;
}> = ({ compKey, optionClickParent }) => {
  const [translation] = useTranslation('common');

  const options: IRestaurantOptions[] = [
    {
      tag: 'Edit Restaurant',
      label: translation(`RESTAURANT.EDIT_RESTAURANT`),
    },
    {
      tag: 'Remove',
      label: translation(`RESTAURANT.REMOVE`),
    },
  ];

  const optionClickHandler = (item: IRestaurantOptions) => {
    optionClickParent(item);
  };
  return (
    <div className="restaurantDropdown-wrap">
      <ul className="diningDropdown">
        {options.map((item: IRestaurantOptions, index: number) => (
          <li
            key={index}
            className="pointer"
            onClick={() => optionClickHandler(item)}
          >
            {item.label}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownComp;
