import React from 'react';
import { domain_env } from '../../config/env';

const BusinessLoginWrap: React.FC<{
  children: JSX.Element;
  hostName: string;
}> = ({ children, hostName }) => {
  return domain_env.BUSINESS_DOMAINS.includes(hostName) ||
    domain_env.LOCALHOST.includes(hostName)
    ? children
    : null;
};

export default BusinessLoginWrap;
