import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { domain_env, env } from '../config/env';
import { PathConstants } from '../constants/pathConstants';
import { getHeaderInfo } from '../_helpers/apiHeaderCreator';
import { History } from '../_helpers/history';

const handleResponse = (response: AxiosResponse) => {
  const hostName = window.location.hostname;
  if (response.status === 401) {
    localStorage.clear();
    if (domain_env.BUSINESS_DOMAINS.includes(hostName)) {
      History.push(PathConstants.BUSINESS_LOGIN);
    } else if (
      domain_env.FACILITY_DOMAINS.includes(hostName) ||
      domain_env.LOCALHOST.includes(hostName)
    ) {
      History.navigate(PathConstants.LOGIN);
    }
    return Promise.reject(response.data);
  }
  if (response.data.success) {
    return response.data;
  }
  return Promise.reject(response.data);
};
const handleResponseTranslation = (response: any) => {
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(`Request failed with status code ${response.status}`);
  }
};
export const post = async function <T>(url: string, body: T) {
  const header = await getHeaderInfo();
  try {
    const resp = await axios.post(env.development.BASE_URL + url, body, header);
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const postTranslate = async function <T>(
  url: string,
  body: T,
  config: AxiosRequestConfig
) {
  try {
    const resp = await axios.post(
      'https://translation.googleapis.com/language' + url,
      body,
      config
    );

    return handleResponseTranslation(resp);
  } catch (err: any) {
    return handleResponseTranslation(err.response);
  }
};

export const patch = async function <T>(url: string, body: T) {
  const header = await getHeaderInfo();
  try {
    const resp = await axios.patch(
      env.development.BASE_URL + url,
      body,
      header
    );
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const get = async <T>(url: string, params: any = {}) => {
  const header = await getHeaderInfo();

  try {
    const resp = await axios.get(env.development.BASE_URL + url, {
      ...header,
      params,
    });

    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const deleteApi = async function (url: string) {
  const header = await getHeaderInfo();

  try {
    const resp = await axios.delete(env.development.BASE_URL + url, header);

    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const deleteWithParams = async function (url: string, params: any = {}) {
  const header = await getHeaderInfo();

  try {
    const resp = await axios.delete(env.development.BASE_URL + url, {
      ...header,
      params,
    });

    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const put = async function <T>(url: string, body: T) {
  const header = await getHeaderInfo();

  try {
    const resp = await axios.put(env.development.BASE_URL + url, body, header);

    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};

export const getImageApi = async (url: any, params: any = {}) => {
  const header = await getHeaderInfo();

  try {
    const resp: any = await axios.get(env.development.BASE_URL + url, {
      ...header,
      responseType: 'arraybuffer',
    });

    return resp;
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
