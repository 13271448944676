import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { PathConstants } from '../../constants/pathConstants';
import { CreatePassword } from '../../pages/createPassword';
import { resetPasswordConst } from '../../pages/createPassword/createPasswordConstants';
import { ICreatePasswordFinal } from '../../pages/createPassword/createPasswordInterface';
import {
  resetBusinessUsersPassword,
  // eslint-disable-next-line prettier/prettier
  resetUsersPassword
} from '../../pages/createPassword/createPasswordSlice';

const ResetPasswordWrap: React.FC = () => {
  const { isProcessingResetPassword } = useAppSelector(
    (state) => state.createPassword
  );
  const dispatch = useAppDispatch();
  const clickHandler = (data: ICreatePasswordFinal) => {
    if (window.location.pathname === PathConstants.RESET_PASSWORD) {
      dispatch(resetUsersPassword(data));
    } else if (
      window.location.pathname === PathConstants.BUSINESS_RESET_PASSWORD
    ) {
      dispatch(resetBusinessUsersPassword(data));
    }
  };
  return (
    <CreatePassword
      constantValues={resetPasswordConst}
      onClick={clickHandler}
      processing={isProcessingResetPassword}
    />
  );
};

export default ResetPasswordWrap;
