import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { activeInactiveChecker } from '../../_helpers/checkerFunctions';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { translateArrayList } from '../../components/cloudTranslationCommon/translation';
import DeleteConfirmation from '../../components/deleteConfirmation/deleteConfirmation';
import ImageComp from '../../components/imageComponents/imageComp';
import { ModalComponent } from '../../components/modal';
import {
  // eslint-disable-next-line prettier/prettier
  openAddModalReducer,
} from '../../components/modal/modalSlice';
import NoDataComponent from '../../components/noDataComponent/noDataComponent';
import PageLoader from '../../components/pageLoading/pageLoader';
import Paginate, {
  // eslint-disable-next-line prettier/prettier
  IPaginateClickData,
} from '../../components/paginate/paginate';
import UsersPageForm from '../../components/usersPageForm/usersPageForm';
import { getSelectedFacility, translateText } from '../loginPage/authSlice';
import { IEditUserData, IUsersData } from './usersInterface';
import './usersPage.css';
import {
  addFilterName,
  addFilterPage,
  addNewUser,
  addTranslatedData,
  clearAllFilter,
  clearUserState,
  deleteSelectedUser,
  fetchAllUsers,
  fetchUserById,
  fetchUserImageId_Add,
  fetchUserImageId_Update,
  filterReset,
  resendEmailInvitationAction,
  updateSelectedUser,
  // eslint-disable-next-line prettier/prettier
  userPageFilterReset,
} from './usersSlice';
import {
  addImageData,
  deleteUser,
  deleteUserPayload,
  editUser,
  filterChange,
  updateImageData,
  // eslint-disable-next-line prettier/prettier
  updateUserData,
} from './utilityFunctions';

const UsersPage: React.FC = () => {
  const { f_id } = useParams();
  const { openAddModal, openEditModal, openDeleteModal } = useAppSelector(
    (state) => state.modal
  );
  const {
    user,
    userList,
    filter,
    isAddingNewUser,
    isDeletingUser,
    isUpdatingUser,
    userPaginate,
    isProcessingAllUsers,
    isFetchingImage,
    imageDetails,
    isSendingInvitation,
  } = useAppSelector((state) => state.users);
  const { currentFacility } = useAppSelector((state) => state.authentication);
  const [search, setSearch] = useState<string>('');
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const [t, i18n] = useTranslation('common');

  const [translation] = useTranslation('common');
  const [image, setImage] = useState<any>('');
  const [selectedUserId, setSelectedUserId] = useState<string>('');
  const language = i18n.language;
  // translation
  useEffect(() => {
    if (language !== 'en' && userList?.length > 0) {
      translateArrayList(
        userList,
        language,
        dispatch,
        translateText,
        addTranslatedData,
        ['designation'] // Pass the properties that need to be translated
      );
    }
  }, [language, isProcessingAllUsers]);
  useEffect(() => {
    if (f_id) {
      language === 'en' && dispatch(fetchAllUsers(filterChange(filter, f_id)));
    }
  }, [language]);
  // -----
  useEffect(() => {
    if (language !== 'en') {
      return () => {
        dispatch(addTranslatedData([]));
      };
    }
  }, []);
  useEffect(() => {
    dispatch(userPageFilterReset());
    if (f_id) {
      dispatch(getSelectedFacility(f_id));
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (f_id) {
      dispatch(fetchAllUsers(filterChange(filter, f_id)));
    }
  }, [filter, f_id]);
  const onSearchTextHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setSearchParams({ name });
    if (!name) {
      dispatch(filterReset());
    }
  };
  const searchClickHandler = (e: SyntheticEvent) => {
    dispatch(addFilterName(searchParams.get('name')));
    dispatch(addFilterPage(0));
    if (!searchParams.get('name')) {
      dispatch(addFilterName(null));
    }
    e.preventDefault();
  };
  const clearSearchText = () => {
    setSearch('');
    setSearchParams({ name: '' });
    dispatch(clearAllFilter());
  };
  const addUserData = () => {
    dispatch(clearUserState());
    setSearchParams({ name: '' });
    dispatch(openAddModalReducer());
  };
  const userData = (data: IUsersData, f_id: string) => {
    const payload = {
      facilityId: f_id,
      actor: 'FACILITY_USER',
      ...data,
    };
    return payload;
  };
  const addUserModalHandler = (data: IUsersData) => {
    if (f_id) {
      if (data.image) {
        dispatch(fetchUserImageId_Add(addImageData(data, f_id)));
      } else {
        dispatch(addNewUser(userData(data, f_id)));
      }
    }
  };
  const editUserData = (id: string) => {
    dispatch(fetchUserById(editUser(id)));
  };
  const editUserModalData = (data: IEditUserData) => {
    if (f_id) {
      if (data.image) {
        dispatch(
          fetchUserImageId_Update(updateImageData(data, f_id, user?.id))
        );
      } else {
        dispatch(updateSelectedUser(updateUserData(user?.id, data, f_id)));
      }
    }
  };
  const deleteUserData = (id: string) => {
    dispatch(clearUserState());
    dispatch(fetchUserById(deleteUser(id)));
  };
  const deleteUserModalHandler = (id: string) => {
    if (f_id) {
      dispatch(deleteSelectedUser(deleteUserPayload(id, f_id)));
    }
  };
  const handlePageClick = (data: IPaginateClickData) => {
    if (data.selected !== userPaginate.totalPages) {
      dispatch(addFilterPage(data?.selected));
    }
  };
  const resendInvitataionHandler = (id: string) => {
    setSelectedUserId(id);
    dispatch(resendEmailInvitationAction(id));
  };
  return (
    <div className="page-background user-page">
      <div className="userPage-head">
        <h2>{translation('USERS_PAGE_DATA.PAGE_TITLE')}</h2>
        <div className="userPage-btn-search">
          <Button
            className="addUser-button shadow-none"
            disabled={!activeInactiveChecker(currentFacility?.status)}
            onClick={() => {
              addUserData();
            }}
          >
            {translation('USERS_PAGE_DATA.ADD_USERS_BUTTON')}
          </Button>
          <Form className="userPage-form">
            <FormGroup className="userPage-search-btn">
              <Input
                name="search"
                placeholder={translation('USERS_PAGE_DATA.TYPE_TO_SEARCH')}
                type="search"
                value={searchParams.get('name') || ''}
                className="searchBar-input shadow-none padding-search-facility-user"
                onChange={onSearchTextHandler}
              />
              {searchParams.get('name') && (
                <img
                  className="searchClose-button pointer"
                  src={process.env.PUBLIC_URL + '/assets/searchClose.svg'}
                  alt="close-search"
                  onClick={clearSearchText}
                />
              )}
              <Button
                type="submit"
                className="searchButton shadow-none"
                onClick={searchClickHandler}
              >
                <img
                  src={process.env.PUBLIC_URL + '/assets/searchIcon.svg'}
                  alt="search-icon"
                />
              </Button>
            </FormGroup>
          </Form>
        </div>
      </div>
      {isProcessingAllUsers ? (
        <PageLoader />
      ) : userList.length ? (
        <div className="user-card-div users-parent-card-R">
          {userList.map((user) => (
            <div key={user?.id} className="user-card">
              <ImageComp
                imageId={user?.imageId}
                className="user-image"
                noImageClass="noImageClass"
                noImageDivClass="user-image-div"
                divClass="user-image-div"
                alt={`${user?.userName}-image`}
                src="/assets/userImage.svg"
              />
              <div className="user-details">
                <div className="user-details-wrap">
                  <span className="user-name">
                    {translation(`${user?.userName}`)}
                  </span>
                </div>
                <div className="user-details-wrap">
                  <span className="user-role">
                    {translation(`${user?.designation}`)}
                  </span>
                </div>
                <div className="user-details-wrap">
                  <span className="user-email">
                    {translation(`${user?.email}`)}
                  </span>
                </div>
                <div className="user-details-wrap">
                  <button
                    id={user?.id}
                    className={
                      selectedUserId == user?.id && isSendingInvitation
                        ? 'user-invitation pointer disabled-color'
                        : 'user-invitation pointer'
                    }
                    disabled={isSendingInvitation ? true : false}
                    onClick={() => resendInvitataionHandler(user?.id)}
                  >
                    {translation('USERS_PAGE_DATA.RESEND_INVITATION')}
                  </button>
                </div>
              </div>
              <div className="user-action-buttons">
                <div
                  className="user-editIcon pointer"
                  onClick={
                    activeInactiveChecker(currentFacility?.status)
                      ? () => editUserData(user?.id)
                      : undefined
                  }
                >
                  <img
                    src={process.env.PUBLIC_URL + '/assets/editWhiteIcon.svg'}
                    alt="edit-icon"
                  />
                </div>
                <div className="action-button-border"></div>

                <div
                  className="user-deleteIcon pointer"
                  onClick={
                    activeInactiveChecker(currentFacility?.status)
                      ? () => deleteUserData(user?.id)
                      : undefined
                  }
                >
                  <img
                    src={process.env.PUBLIC_URL + '/assets/deleteWhiteIcon.svg'}
                    alt="delete-icon"
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NoDataComponent constant="NO_USER" />
      )}

      {openAddModal && (
        <ModalComponent
          isOpen={openAddModal}
          modalHeading={'USERS_PAGE_DATA.MODAL_TITLE_CREATE'}
        >
          <UsersPageForm
            onCreate={addUserModalHandler}
            isAdding={isAddingNewUser || isFetchingImage}
            setImage={setImage}
            buttonTitle={translation('BUTTONS.CREATE')}
          />
        </ModalComponent>
      )}
      {user
        ? openEditModal && (
            <ModalComponent
              isOpen={openEditModal}
              modalHeading={'USERS_PAGE_DATA.MODAL_TITLE_EDIT'}
            >
              <UsersPageForm
                content={user}
                imageDetails={imageDetails}
                onCreate={editUserModalData}
                isAdding={isUpdatingUser || isFetchingImage}
                buttonTitle={translation('PROFILE_EDIT.UPDATE_BUTTON')}
              />
            </ModalComponent>
          )
        : null}
      {user
        ? openDeleteModal && (
            <ModalComponent
              isOpen={openDeleteModal}
              modalHeading={'USERS_PAGE_DATA.MODAL_TITLE_DELETE'}
            >
              <DeleteConfirmation
                content={user}
                onDelete={deleteUserModalHandler}
                isDeleting={isDeletingUser}
              />
            </ModalComponent>
          )
        : null}
      {userPaginate?.totalPages > 1 && (
        <Paginate
          handlePageClick={handlePageClick}
          totalPage={userPaginate?.totalPages}
        />
      )}
    </div>
  );
};

export default UsersPage;
