import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '../../app/store';
import { PathConstants } from '../../constants/pathConstants';
import { registerFacility } from '../../services/registration';
import { History } from '../../_helpers/history';
import { IFacilityFinalData } from './registrationTypes';

export interface IRegistration {
  isProcessingRegistration: boolean;
  isSuccess: boolean;
}
const initialState: IRegistration = {
  isProcessingRegistration: false,
  isSuccess: true,
};

export const registrationSlice = createSlice({
  name: 'registration',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createFacility.pending, (state) => {
        state.isProcessingRegistration = true;
        state.isSuccess = false;
      })
      .addCase(
        createFacility.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isProcessingRegistration = false;

          toast.success(
            `${action.payload?.message
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload?.message.slice(1)}`
          );
          state.isSuccess = true;
          History.push(PathConstants.SUCCESS_REGISTRATION_FACILITY);
        }
      )
      .addCase(createFacility.rejected, (state, action: PayloadAction<any>) => {
        state.isProcessingRegistration = false;
        state.isSuccess = true;

        toast.error(
          `${action.payload
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload.slice(1)}`
        );
      });
  },
});

export const createFacility = createAsyncThunk(
  'registration/createFacility',
  async (userData: IFacilityFinalData, thunkAPI) => {
    try {
      const response = await registerFacility(userData);

      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);

export const selectRegistration = (state: RootState) => state.registration;
export const registrationReducer = registrationSlice.reducer;
