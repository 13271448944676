import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
} from '@dnd-kit/sortable';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { activeInactiveChecker } from '../../_helpers/checkerFunctions';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import AmenitiesForm from '../../components/amenitiesForm/amenitiesForm';
import { AmenityCard } from '../../components/amenityCard/amenityCard';
import { translateArrayList } from '../../components/cloudTranslationCommon/translation';
import DeleteConfirmation from '../../components/deleteConfirmation/deleteConfirmation';
import FullScreenImgComp from '../../components/fullScreenImage/fullScreenImgComp';
import { ModalComponent } from '../../components/modal';
import {
  // eslint-disable-next-line prettier/prettier
  openAddModalReducer,
} from '../../components/modal/modalSlice';
import NoDataComponent from '../../components/noDataComponent/noDataComponent';
import PageLoader from '../../components/pageLoading/pageLoader';
import Paginate, {
  IPaginateClickData,
} from '../../components/paginate/paginate';
import { PdfComponent } from '../../components/pdfViewer/pdfComponent';
import { getSelectedFacility, translateText } from '../loginPage/authSlice';
import {
  IAddNewAmenityPayload,
  IAmenitiesData,
  IAmenitiesDocSrc,
  IUpdateAmenityPayload,
} from './amenitiesInterface';
import './amenitiesPage.css';
import {
  addFilterPage,
  addNewAmenity,
  addTranslatedData,
  changeAmenityOrder,
  deleteSelectedAmenity,
  fetchAllAmenities,
  fetchAmenityById,
  fetchAmenityImageId_Add,
  fetchAmenityImageId_Update,
  // eslint-disable-next-line prettier/prettier
  updateSelectedAmenity,
} from './amenitiesSlice';
import {
  addAmenityDataChange,
  addAmenityImage,
  deleteAmenity,
  deleteAmenityPayload,
  editAmenity,
  editAmenityPayload,
  filterChange,
  // eslint-disable-next-line prettier/prettier
  updateAmenityImage,
} from './utilityFunctions';

const AmenitiesPage: React.FC = () => {
  const { f_id } = useParams();
  const { openAddModal, openEditModal, openDeleteModal } = useAppSelector(
    (state) => state.modal
  );
  // const [respArray, setRespArray] = useState<string[]>();
  // const [stringArray, setStringArray] = useState<string[]>([]);

  const {
    amenity,
    filter,
    amenityList,
    isAddingNewAmenity,
    isUpdatingAmenity,
    isDeletingAmenity,
    isProcessingAllAmenities,
    isFetchingImage,
    imageDetails,
    amenityPaginate,
  } = useAppSelector((state) => state.amenities);
  const { currentFacility } = useAppSelector((state) => state.authentication);
  const [selectedFile, setSelectedFile] = useState('');
  const [viewFileIndex, setViewFileIndex] = useState(-1);
  const [selectedImage, setSelectedImage] = useState('');

  const dispatch = useAppDispatch();
  const [t, i18n] = useTranslation('common');

  const [translation] = useTranslation('common');
  const language = i18n.language;

  useEffect(() => {
    if (language !== 'en' && amenityList?.length > 0) {
      translateArrayList(
        amenityList,
        language,
        dispatch,
        translateText,
        addTranslatedData,
        ['description'] // Pass the properties that need to be translated
      );
    }
  }, [language, isProcessingAllAmenities]);

  useEffect(() => {
    if (f_id) {
      language === 'en' &&
        dispatch(fetchAllAmenities(filterChange(f_id, filter)));
    }
  }, [language]);
  useEffect(() => {
    if (language !== 'en') {
      return () => {
        dispatch(addTranslatedData([]));
      };
    }
  }, []);
  useEffect(() => {
    if (f_id) {
      dispatch(getSelectedFacility(f_id));
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);

    if (f_id) {
      dispatch(fetchAllAmenities(filterChange(f_id, filter)));
    }
  }, [filter, f_id]);
  const addAmenityData = () => {
    dispatch(openAddModalReducer());
  };

  const addAmenityModalHandler = (data: IAddNewAmenityPayload) => {
    if (f_id) {
      if (data.image) {
        dispatch(fetchAmenityImageId_Add(addAmenityImage(data, f_id)));
      } else {
        dispatch(addNewAmenity(addAmenityDataChange(f_id, data)));
      }
    }
  };
  const viewFileList = (index: number) => {
    setViewFileIndex(index);
  };

  const editAmenityData = (id: string) => {
    dispatch(fetchAmenityById(editAmenity(id)));
  };
  const editAmenityModalData = (data: IUpdateAmenityPayload) => {
    if (f_id) {
      if (data.image) {
        dispatch(
          fetchAmenityImageId_Update(
            updateAmenityImage(data, f_id, amenity?.id)
          )
        );
      } else {
        dispatch(
          updateSelectedAmenity(editAmenityPayload(amenity?.id, f_id, data))
        );
      }
    }
  };

  const deleteAmenityData = (id: string) => {
    dispatch(fetchAmenityById(deleteAmenity(id)));
  };

  const deleteAmenityModalHandler = (id: string) => {
    if (f_id) {
      dispatch(deleteSelectedAmenity(deleteAmenityPayload(id, f_id)));
    }
  };
  const handlePageClick = (data: IPaginateClickData) => {
    if (data.selected !== amenityPaginate.totalPages)
      dispatch(addFilterPage(data?.selected));
  };
  const onSelectedFile = (doc: IAmenitiesDocSrc) => {
    doc.fileType === 'application/pdf'
      ? setSelectedFile(doc?.file)
      : setSelectedImage(doc?.file);
    setViewFileIndex(-1);
  };

  const closeViewer = () => {
    setSelectedFile('');
    setSelectedImage('');
  };

  const [activeId, setActiveId] = useState<any>(null);
  const [items, setItems] = useState<IAmenitiesData[]>([]);
  const [activeData, setActiveData] = useState<IAmenitiesData>(
    {} as IAmenitiesData
  );

  useEffect(() => {
    setItems(amenityList);
  }, [amenityList]);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      setActiveId(event.active.id);
      const activeObj = items.filter(
        (obj: IAmenitiesData) => obj.id == event.active.id
      );

      setActiveData(activeObj[0]);
    },
    [items]
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;

      if (active.id !== over?.id) {
        const oldIndex = items.findIndex(
          (item: IAmenitiesData) => item.id == active?.id
        );

        const newIndex = items.findIndex(
          (item: IAmenitiesData) => item.id === over?.id
        );
        setItems((items: IAmenitiesData[]) => {
          return arrayMove(items, oldIndex, newIndex);
        });

        const newArray = arrayMove(items, oldIndex, newIndex).map(
          (items: IAmenitiesData, index: number) => ({
            amenityId: items.id,
            position: index + 1,
          })
        );

        dispatch(changeAmenityOrder({ newPosition: newArray }));
      }

      setActiveId(null);
    },
    [items]
  );

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  return (
    <React.Fragment>
      {viewFileIndex !== -1 && (
        <div
          onClick={() => setViewFileIndex(-1)}
          className="view-file-wrap"
        ></div>
      )}
      {selectedFile ? (
        <PdfComponent close={closeViewer} url={selectedFile} />
      ) : (
        <div className="page-background amenities-page">
          {selectedImage && (
            <FullScreenImgComp
              image={selectedImage}
              state={true}
              closeClass={closeViewer}
            >
              <img src={selectedImage.toString()} alt="" />
            </FullScreenImgComp>
          )}
          <div className="amenitiesPage-head">
            <h2>{translation('AMENITIES_PAGE.TITLE')}</h2>
            <Button
              className="addAmenities-button shadow-none"
              disabled={!activeInactiveChecker(currentFacility?.status)}
              onClick={() => addAmenityData()}
            >
              {translation('AMENITIES_PAGE.ADD_AMENITIES')}
            </Button>
          </div>
          {isProcessingAllAmenities ? (
            <PageLoader />
          ) : amenityList?.length ? (
            <div className="amenities-card-div amenities-parent-card-R">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={handleDragStart}
                onDragEnd={handleDragEnd}
                onDragCancel={handleDragCancel}
              >
                <SortableContext items={items} strategy={rectSortingStrategy}>
                  {items &&
                    items?.map((data: IAmenitiesData, index: number) => (
                      // <div style={{ display: 'flex' }} key={data?.id}>
                      <>
                        <AmenityCard
                          id={data?.id}
                          index={index}
                          data={data}
                          editAmenityData={editAmenityData}
                          onSelectedFile={onSelectedFile}
                          deleteAmenityData={deleteAmenityData}
                          viewFileList={viewFileList}
                          viewFileIndex={viewFileIndex}
                        />
                      </>
                    ))}
                </SortableContext>
                <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
                  {activeId ? (
                    <AmenityCard
                      id={activeId}
                      index={0}
                      data={activeData}
                      editAmenityData={editAmenityData}
                      onSelectedFile={onSelectedFile}
                      deleteAmenityData={deleteAmenityData}
                      viewFileList={viewFileList}
                      viewFileIndex={viewFileIndex}
                    />
                  ) : null}
                </DragOverlay>
              </DndContext>
            </div>
          ) : (
            <NoDataComponent constant="NO_AMENITY" />
          )}

          {openAddModal && (
            <ModalComponent
              isOpen={openAddModal}
              modalHeading={'AMENITIES_FORM.MODAL_TITLE_CREATE'}
            >
              <AmenitiesForm
                onCreate={addAmenityModalHandler}
                isAdding={isAddingNewAmenity || isFetchingImage}
              />
            </ModalComponent>
          )}
          {amenity
            ? openEditModal && (
                <ModalComponent
                  isOpen={openEditModal}
                  modalHeading={'AMENITIES_FORM.MODAL_TITLE_EDIT'}
                >
                  <AmenitiesForm
                    content={amenity}
                    imageDetails={imageDetails}
                    onCreate={editAmenityModalData}
                    isAdding={isUpdatingAmenity || isFetchingImage}
                  />
                </ModalComponent>
              )
            : null}
          {amenity
            ? openDeleteModal && (
                <ModalComponent
                  isOpen={openDeleteModal}
                  modalHeading={'AMENITIES_FORM.MODAL_TITLE_DELETE'}
                >
                  <DeleteConfirmation
                    content={amenity}
                    onDelete={deleteAmenityModalHandler}
                    isDeleting={isDeletingAmenity}
                  />
                </ModalComponent>
              )
            : null}
          {amenityPaginate?.totalPages > 1 && (
            <Paginate
              handlePageClick={handlePageClick}
              totalPage={amenityPaginate?.totalPages}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default AmenitiesPage;
