import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { CreatePassword } from '../../pages/createPassword';
import { createPasswordConst } from '../../pages/createPassword/createPasswordConstants';
import { ICreatePasswordFinal } from '../../pages/createPassword/createPasswordInterface';
import { createUsersPassword } from '../../pages/createPassword/createPasswordSlice';
import { getAuthenticatedUser } from '../../pages/loginPage/authSlice';
import { History } from '../../_helpers/history';
import { isAuthenticated } from '../../_helpers/localStorage';

const CreatePasswordWrap = () => {
  const { isProcessingCreatePassword } = useAppSelector(
    (state) => state.createPassword
  );
  const dispatch = useAppDispatch();
  const { isProcessingLogin, activeFacility, loggedInUser } = useAppSelector(
    (state) => state.authentication
  );
  const { supportingBusiness } = useAppSelector((state) => state.businessAuth);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (isAuthenticated()) {
      dispatch(getAuthenticatedUser(null));
    }
  }, [isAuthenticated()]);

  useEffect(() => {
    if (
      activeFacility?.id &&
      isAuthenticated() &&
      window.location.pathname.split('/')[1] != 'business'
    ) {
      History.navigate(`/administration/activities/${activeFacility?.id}`);
    } else if (
      supportingBusiness?.id &&
      isAuthenticated() &&
      window.location.pathname.split('/')[1] == 'business'
    ) {
      History.navigate('/business/bookings');
    }
  }, [activeFacility?.id, supportingBusiness?.id]);
  const clickHandler = (data: ICreatePasswordFinal) => {
    dispatch(createUsersPassword(data));
  };
  return (
    <React.Fragment>
      <CreatePassword
        constantValues={createPasswordConst}
        onClick={clickHandler}
        processing={isProcessingCreatePassword}
      />
    </React.Fragment>
  );
};

export default CreatePasswordWrap;
