import { ISupportingBusinessList } from '../loginPage/loginInterface';
import { ICategorySelectOptions } from '../registrationPage/registrationTypes';
import { IBusinessProfileData } from './businessProfileInterface';

export const businessProfilePayload = (
  data: IBusinessProfileData,
  coverPic: any,
  supportingBusiness: ISupportingBusinessList
) => {
  const categoryIdArray = data?.category.map((item: ICategorySelectOptions) => {
    return Number(item.value);
  });
  const payload = {
    profileData: {
      id: supportingBusiness.id,
      name: data.name,
      description: data.description,
      email: data.email,
      categoryIds: categoryIdArray,
      location: {
        longitude: supportingBusiness.location.longitude,
        latitude: supportingBusiness.location.latitude,
      },
      minPrice: data.minPrice,
      maxPrice: data.maxPrice,
      capacity: data.capacity,
      photoId: supportingBusiness?.photoId,
      documents: data?.documents,
    },
    coverPic,
  };
  return payload;
};
