import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hook';
import { IBusinessCategories } from '../../pages/registrationPage/registrationTypes';
import { fetchCategoriesByIds } from '../../pages/supportingBusinessPage/supportingBusinessSlice';

const CategoriesList: React.FC<{
  className: string;
  categoryIds: number[];
}> = ({ className, categoryIds }) => {
  const dispatch = useAppDispatch();
  const [category, setCategory] = useState<IBusinessCategories[]>([]);

  useEffect(() => {
    const getCategory = async () => {
      if (categoryIds) {
        const response = await dispatch(
          fetchCategoriesByIds(categoryIds.toString())
        );
        setCategory(response.payload?.data[0]?.data);
      }
    };
    getCategory();
  }, [categoryIds]);
  return (
    <>
      {category.map((item: IBusinessCategories, index: number) => (
        <span key={index}>
          {item.name}
          {index != category.length - 1 && <span>,&nbsp;</span>}
        </span>
      ))}
    </>
  );
};

export default CategoriesList;
