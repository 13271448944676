/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {
  getAllGuestBymonth,
  getAllTotalSupportBuisnessCount
} from '../../services/dashboardService';
import { IDashbord } from './dashboardInterface';

const initialState: IDashbord = {
  isGetNoOfGuestByMonth: false,
  allGuestByMonthList: [],
  isGetNoOfFacilityByMonth: false,
  allGuestByLocationList: [],
  isGetNoOfFacilityCountByMonth: false,
  allFacilitiesCountByMonth: [],
  isGetTotalCount: false,
  allTotalCount: [],
  isGetTotalSupportBuisness: false,
  allTotalCountSupprtBuisness: [],
};

export const dashboardGraphInfoSlice = createSlice({
  name: 'dashboardGraphInfo',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder

      .addCase(fetchAllGuestByMonth.pending, (state) => {
        state.isGetNoOfGuestByMonth = true;
      })
      .addCase(fetchAllGuestByMonth.fulfilled, (state, action) => {
        state.isGetNoOfGuestByMonth = false;
        state.allGuestByMonthList = action.payload?.data;
      })
      .addCase(fetchAllGuestByMonth.rejected, (state) => {
        state.isGetNoOfGuestByMonth = false;
      })

      .addCase(totalSupportBuisneessByMonth.pending, (state) => {
        state.isGetTotalSupportBuisness = true;
      })
      .addCase(totalSupportBuisneessByMonth.fulfilled, (state, action) => {
        state.isGetTotalSupportBuisness = false;
        state.allTotalCountSupprtBuisness = action.payload.data;
      })
      .addCase(totalSupportBuisneessByMonth.rejected, (state) => {
        state.isGetTotalSupportBuisness = false;
      });
  },
});
export const fetchAllGuestByMonth = createAsyncThunk(
  'dashboardGraphInfoSlice/fetchAllGuestByMonth',
  async (id: string) => {
    const response = await getAllGuestBymonth(id);
    return response;
  }
);

export const totalSupportBuisneessByMonth = createAsyncThunk(
  'dashboardGraphInfoSlice/totalSupportBuisneessByMonth',
  async (id: string) => {
    const response = await getAllTotalSupportBuisnessCount(id);
    return response;
  }
);

export const dashboardInfo = (state: RootState) => state.dasboardInfo;

export const dashboardInfoReducer = dashboardGraphInfoSlice.reducer;
