import React from 'react';

const RatingStar: React.FC<{
  rating: string;
}> = ({ rating }) => {
  return (
    <div className="fit-content">
      {[...Array(Math.round(parseInt(rating)))].map((elem, index) => (
        <img
          key={index}
          className="rating-star-icon"
          src="/assets/starIcon.svg"
        />
      ))}
      {[...Array(5 - Math.round(parseInt(rating)))].map((elem, index) => (
        <img
          key={index}
          className="rating-star-icon"
          src="/assets/emptyStarIcon.svg"
        />
      ))}
    </div>
  );
};

export default RatingStar;
