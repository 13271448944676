import { yupResolver } from '@hookform/resolvers/yup';
import { Moment } from 'moment';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import {
  IDiningDynamicInput,
  IDiningItemsPayload,
  IMealItems,
  IMealsPerDay,
  IRepeatFormSchema,
  IRestaurants,
  IUpdateDailyDiningMealsPayload,
  ItemTypeConstants,
} from '../../pages/diningPage/diningInterface';
import {
  deleteDiningItemById,
  setDeleteArrayReducer,
} from '../../pages/diningPage/diningSlice';
import '../diningForm/diningForm.css';
import ItemsParentCardForm from '../diningForm/itemParentCardForm';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import { repeatFormSchema } from './repeatFormValidation';

const AddEditDailyMeal: React.FC<{
  mealObject: IMealsPerDay;
  diningItemsList: IMealItems[];
  currentDate: Moment;
  restaurantId: string;
  isAdding: boolean;
  onCreate: (data: IUpdateDailyDiningMealsPayload) => void;
  restaurant: IRestaurants;
}> = ({
  mealObject,
  currentDate,
  restaurantId,
  isAdding,
  onCreate,
  diningItemsList,
  restaurant,
}) => {
  const [diningItems, setDiningItems] =
    useState<IDiningItemsPayload[]>(diningItemsList);
  const [categoryId, setCategoryId] = useState<number | null>(
    mealObject?.mealCategory?.id as number
  );
  const { categories } = useAppSelector((state) => state.dining);

  useEffect(() => {
    setDiningItems(diningItemsList);
  }, [diningItemsList]);
  const { deleteArray } = useAppSelector((state) => state.dining);
  const [translation] = useTranslation('common');
  const elementRef: any = useRef();
  const [presentDate, setPresentDate] = useState<string>(
    currentDate.format('YYYY-MM-DD')
  );

  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IRepeatFormSchema>({
    resolver: yupResolver(repeatFormSchema),
    defaultValues: {
      name: mealObject?.mealType,
      startTime: mealObject?.startTime,
      categoryId: mealObject?.mealCategory?.id,
      endTime: mealObject?.endTime,
    },
  });

  const addMoreInputHandler = (e: MouseEvent<HTMLButtonElement>) => {
    const newField = {
      id: Math.random().toString(),
      title: '',
      price: '',
      description: '',
      type: ItemTypeConstants.exclusive,
    };
    setDiningItems([...diningItems, newField]);
  };
  useEffect(() => {
    reset({
      name: mealObject?.mealType,
      startTime: mealObject?.startTime,
      endTime: mealObject?.endTime,
    });
  }, [mealObject]);
  const AlwaysScrollToBottom = () => {
    elementRef?.current?.scrollIntoView();
  };

  useEffect(() => {
    AlwaysScrollToBottom();
  }, [diningItems]);

  const activeItemTypeButton = (type: string, id?: string) => {
    const itemsArray = [...diningItems];
    const newItemsArray = itemsArray.map((item: IDiningDynamicInput) => {
      if (item.id == id) {
        return {
          ...item,
          type,
        };
      }
      return item;
    });
    setDiningItems([...newItemsArray]);
  };

  const getData = (
    name: string,
    value: string,
    index: number,
    itemId?: string
  ) => {
    const valueArray: IDiningDynamicInput[] = [...diningItems];
    const valueIndex: number = valueArray.findIndex(
      (item: IDiningDynamicInput) => item.id == itemId
    );
    valueArray[valueIndex] = {
      ...valueArray[valueIndex],
      [name]: value,
    };
    setDiningItems([...valueArray]);
  };

  const removeItemClick = (
    itemIndex: number,
    selectedItem: IDiningDynamicInput,
    id?: string
  ) => {
    if (Number(id) >= 1) {
      const array = [...deleteArray, id];
      dispatch(setDeleteArrayReducer(Array.from(new Set(array))));
    }

    const clone = [...diningItems];
    clone.splice(itemIndex, 1);
    setDiningItems(clone);
  };

  const onSubmit = (data: IRepeatFormSchema) => {
    deleteArray.map((id: string) => {
      dispatch(deleteDiningItemById(id));
    });
    let payload = {} as IUpdateDailyDiningMealsPayload;

    payload = {
      id: mealObject?.id,
      title: data?.name,
      startTime: data?.startTime,
      categoryId: categoryId as number,
      endTime: data?.endTime,
      restaurantId,
      date: presentDate,
      applyToTheDate: mealObject?.date ? false : true,
      diningItems: [
        ...diningItems.map((item: IDiningDynamicInput) => {
          if (Number(item.id) < 1) {
            item.id = '0';
          }
          return item;
        }),
      ],
    };
    onCreate(payload);
  };

  return (
    <Form className="form width-100" onSubmit={handleSubmit(onSubmit)}>
      <div className="dining-modal-div">
        <div className="modal-left-div">
          <h2 className="add-items">{translation(`RESTAURANT.ADD_ITEMS`)}</h2>
          <div className="diningItems-scroll-container">
            {diningItems.length ? (
              <ItemsParentCardForm
                getData={getData}
                removeItemClick={removeItemClick}
                diningItems={diningItems}
                activeItemTypeButton={activeItemTypeButton}
              />
            ) : (
              <div className="no-dining-meals-div">
                <img
                  src={process.env.PUBLIC_URL + '/assets/noDiningSchedule.svg'}
                  alt=""
                />
                <span className="no-dining-meals-text">No Dining Items!</span>
              </div>
            )}
            <div ref={elementRef} />
          </div>
          <span className="addMore pointer" onClick={addMoreInputHandler}>
            {translation(`RESTAURANT.ADD_MORE`)}
          </span>
        </div>
        <div className="modal-right-date-div-wrap">
          <div className="modal-right-date-div">
            <span>{currentDate.format('DD/MM/YYYY')}</span>
          </div>
          <div className="modal-right-div">
            <h2 className="add-items">
              {translation(`RESTAURANT.TIME_AND_TITLE`)}
            </h2>
            <div className="modal-right-wrap">
              <FormGroup>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <Input
                      placeholder="Meal Name"
                      className="form-input shadow-none"
                      invalid={errors?.name ? true : false}
                      {...field}
                    />
                  )}
                />
                <FormFeedback>
                  {translation(`${errors?.name?.message}`)}
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Input
                  className="form-input shadow-none repeatType-dropdown"
                  name="categoryId"
                  type="select"
                  value={categoryId as number}
                  onChange={(e) => setCategoryId(e.target.value as any)}
                >
                  <option key={0} value={0}>
                    {translation(`AMENITIES_FORM.SELECT_NONE`)}
                  </option>
                  {categories.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item?.categoryName}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <div className="date-time-parent-div">
                <FormGroup className="date-time-div">
                  <Label className="input-label">
                    {translation(`ACTIVITIES.START_TME`)}
                  </Label>
                  <Controller
                    control={control}
                    name="startTime"
                    render={({ field }) => (
                      <Input
                        type="time"
                        min={restaurant?.startTime}
                        placeholder="--:--"
                        className="dining-date-input shadow-none pointer"
                        invalid={errors?.startTime ? true : false}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback>
                    {translation(`${errors?.startTime?.message}`)}
                  </FormFeedback>
                </FormGroup>
                <FormGroup className="date-time-div">
                  <Label className="input-label">
                    {translation(`ACTIVITIES.END_TIME`)}
                  </Label>
                  <Controller
                    control={control}
                    name="endTime"
                    render={({ field }) => (
                      <Input
                        type="time"
                        max={restaurant?.endTime}
                        placeholder="--:--"
                        className="dining-date-input shadow-none pointer"
                        invalid={errors?.endTime ? true : false}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback>
                    {translation(`${errors?.endTime?.message}`)}
                  </FormFeedback>
                </FormGroup>
              </div>
            </div>

            <div className="modal-submit-button-div">
              <Button type="submit" className="form-button shadow-none">
                {isAdding ? (
                  <LoadingSpinner />
                ) : (
                  translation(`AMENITIES_FORM.SAVE_UPDATE`)
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default AddEditDailyMeal;
