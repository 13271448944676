import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AccordionBody, AccordionHeader, AccordionItem } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import {
  IMealsByWeekData,
  IMealsPerDay,
} from '../../pages/diningPage/diningInterface';
import {
  accordionRefreshTriggerReducer,
  fectchDiningMealUsingWeeks,
} from '../../pages/diningPage/diningSlice';
import MealCard from '../diningCards/mealCard';
import { IDropdownList } from '../diningDropdown/dropdownComp';

const DiningAccordion: React.FC<{
  showOptions: (key: string) => void;
  dropdown: string;
  accKey: number;
  day: string;
  onCardClick: (data: IMealsPerDay) => void;
  addEditModalHandler: (data: IMealsPerDay, keys: IDropdownList) => void;
  getSelectedDay?: (data: string) => void;
  removeRepeatHandler: (
    data: IMealsPerDay,
    keys: IDropdownList,
    day: string
  ) => void;
  restaurantImg: string;
}> = ({
  showOptions,
  dropdown,
  accKey,
  day,
  onCardClick,
  addEditModalHandler,
  getSelectedDay,
  removeRepeatHandler,
  restaurantImg,
}) => {
  const { f_id, restaurant_id } = useParams();
  const { accordionRefreshTrigger } = useAppSelector((state) => state.dining);
  const [mealData, setMealData] = useState<IMealsByWeekData[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    const getMealDataFunc = async () => {
      if (f_id && restaurant_id && day) {
        const response = await dispatch(
          fectchDiningMealUsingWeeks({
            facilityIds: f_id,
            restaurantId: restaurant_id,
            weeks: day,
          })
        );
        setMealData(response.payload?.data[0]?.data);
        dispatch(accordionRefreshTriggerReducer(false));
      }
    };
    getMealDataFunc();
  }, [day, accordionRefreshTrigger]);

  return (
    <>
      {mealData.length
        ? mealData?.findIndex((data: IMealsByWeekData) =>
            data?.weekdays?.includes(day)
          ) != -1 && (
            <AccordionItem className="accordionItem">
              <AccordionHeader targetId={`${accKey + 1}`}>
                {`${day.charAt(0)}${day.slice(1).toLowerCase()}`}
              </AccordionHeader>
              <AccordionBody
                accordionId={`${accKey + 1}`}
                className="accordion-parent-card-R"
              >
                {mealData?.map((item: IMealsByWeekData, index: number) => (
                  <MealCard
                    key={`Rep-${accKey}${index}`}
                    compKey={`Rep-${accKey}${index}`}
                    className="repeat-mealCard"
                    showOptions={showOptions}
                    dropdown={dropdown}
                    perDayData={item}
                    onCardClick={onCardClick}
                    restaurantImg={restaurantImg}
                    addEditModalHandler={addEditModalHandler}
                    day={day}
                    getSelectedDay={getSelectedDay}
                    removeRepeatHandler={removeRepeatHandler}
                  />
                ))}
              </AccordionBody>
            </AccordionItem>
          )
        : null}
    </>
  );
};

export default DiningAccordion;
