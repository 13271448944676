import React from 'react';
import { ITBodyData, ITHead } from './table';
import TableWrapper from './tableWrapper';

const TableBody: React.FC<{
  body: ITBodyData[];
  header: ITHead[];
}> = ({ body, header }) => {
  return (
    <tbody className="table-body">
      {body.map((data: ITBodyData, index) => (
        <tr key={index}>
          {header.map((obj) => (
            <TableWrapper key={index + obj?.key} row={data[obj.key]} />
          ))}
        </tr>
      ))}
    </tbody>
  );
};

export default TableBody;
