import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import '../../mediaQueries.css';
import { IImageDetails } from '../../pages/amenitiesPage/amenitiesInterface';
import {
  IAddRestaurant,
  IMenuFile,
  IMenuFileSrc,
  IRestaurants,
  IRestaurantValidation,
} from '../../pages/diningPage/diningInterface';
import FullScreenImgComp from '../fullScreenImage/fullScreenImgComp';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import { restaurantSchema } from './diningTimeValidation';

const RestaurantForm: React.FC<{
  onCreate: (data: IAddRestaurant) => void;
  isAdding: boolean;
  content?: IRestaurants;
  imageDetails?: IImageDetails;
}> = ({ onCreate, isAdding, content, imageDetails }) => {
  const [imageState, setImageState] = useState<File[]>();
  const [imageName, setImageName] = useState<string>('');
  const [startTime, setStartTime] = useState<string>('');
  const [menuImage, setMenuImage] = useState<IMenuFile[]>([]);
  const [menuImgSrc, setMenuImgSrc] = useState<IMenuFileSrc[]>([]);

  const [menuFullscreen, setMenuFullscreen] = useState<IMenuFileSrc>(
    {} as IMenuFileSrc
  );
  const [openEnlage, setOpenEnlarge] = useState<boolean>(false);
  const myInput = document.getElementById('imageUploadDiv') as HTMLInputElement;
  const [translation] = useTranslation('common');
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<IRestaurantValidation>({
    resolver: yupResolver(restaurantSchema),
    defaultValues: {
      restaurantName: content?.restaurantName,
      startingTime: content?.startTime,
      endTime: content?.endTime,
    },
  });

  useEffect(() => {
    reset({
      restaurantName: content?.restaurantName,
      startingTime: content?.startTime,
      endTime: content?.endTime,
    });
    if (content?.menuIds?.length) {
      const menuIdsArr: any[] = content?.menuIds?.map((id: any) => {
        return {
          type: 'SERVER',
          file: id?.file,
        };
      });

      setMenuImgSrc(content?.menuIds);
      setMenuImage(menuIdsArr);
    }
    return () => {
      setMenuImgSrc([]);
      setMenuImage([]);
    };
  }, [content]);
  useEffect(() => {
    content?.imageId && imageDetails
      ? setImageName(imageDetails?.originalName)
      : setImageName('');
  }, [content, imageDetails]);
  useEffect(() => {
    watch('startingTime');
    setStartTime(watch('startingTime'));
  }, [watch('startingTime')]);

  const uploadRestaurantImageHandler = (idName: string) => {
    document.getElementById(idName)?.click();
  };
  const restaurantImageOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = e.target.files;
    if (fileObj) {
      setImageState(Array.from(fileObj));
      setImageName(fileObj[0].name);
    }
  };

  const uploadOnClick = (id: string) => {
    document.getElementById(id)?.click();
  };

  const menuImageOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files: fileObj } = e.target;

    if (fileObj) {
      setMenuImage([...menuImage, { type: 'LOCAL', file: fileObj }]);
      const menuNewUrl = URL.createObjectURL(fileObj[0]);
      setMenuImgSrc([
        ...menuImgSrc,
        {
          fileType: fileObj && fileObj[0].type,
          file: menuNewUrl,
          fileName: fileObj[0].name,
        },
      ]);
    }
  };

  const removeMenuPreview = (url: IMenuFileSrc, id: number) => {
    const imageArray = menuImage;
    const srcArray: IMenuFileSrc[] = menuImgSrc;
    const filteredSrcArray = srcArray.filter(
      (src: IMenuFileSrc) => url.file != src.file
    );
    setMenuImgSrc([...filteredSrcArray]);
    const filteredImageArray = imageArray?.filter(
      (__, index: number) => index != id
    );
    setMenuImage([...filteredImageArray]);
    myInput.value = '';
  };

  const closeEnlargeImage = () => {
    setMenuFullscreen({} as IMenuFileSrc);
    setOpenEnlarge(false);
  };

  const onSubmit = (data: {
    restaurantName: string;
    startingTime: string;
    endTime: string;
  }) => {
    const existingMenuIds: any[] = [];
    const menuArray = menuImage?.map((obj: IMenuFile) => {
      if (obj.type == 'SERVER') {
        existingMenuIds.push(obj.file);
        return;
      } else if (obj.type == 'LOCAL') {
        return Array.from(obj.file);
      }
    });
    const finalMenuArr = menuArray.filter(
      (obj: File[] | undefined) => obj != undefined
    );
    const finalExistingMenuIds = existingMenuIds?.map((id: string) =>
      id.split('/').pop()
    );
    onCreate({
      restaurantName: data.restaurantName,
      startingTime: `${data.startingTime}`,
      endTime: `${data.endTime}`,
      image: imageState,
      menuImages: finalMenuArr.length ? finalMenuArr : [],
      existingMenuIds: finalExistingMenuIds.length ? finalExistingMenuIds : [],
    });
  };

  return (
    <div className="modal-div">
      <FullScreenImgComp
        image={menuFullscreen.file}
        state={openEnlage}
        closeClass={closeEnlargeImage}
      >
        <img src={menuFullscreen.file} alt="" />
      </FullScreenImgComp>
      <Form className="form width-100" onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          {' '}
          <Controller
            control={control}
            name="restaurantName"
            render={({ field }) => (
              <Input
                placeholder={translation(`RESTAURANT.RESTAURANT_NAME`)}
                className="form-input shadow-none"
                invalid={errors?.restaurantName ? true : false}
                {...field}
              />
            )}
          />
          <FormFeedback>
            {translation(`${errors?.restaurantName?.message}`)}
          </FormFeedback>
        </FormGroup>
        <div className="date-time-parent-div">
          <FormGroup className="date-time-div">
            <Label className="input-label center-activity-label">
              {translation(`ACTIVITIES.START_TME`)}
            </Label>
            <Controller
              control={control}
              name="startingTime"
              render={({ field }) => (
                <Input
                  type="time"
                  placeholder="--:--"
                  className="dining-date-input shadow-none pointer"
                  invalid={errors?.startingTime ? true : false}
                  {...field}
                />
              )}
            />
            <FormFeedback>
              {translation(`${errors?.startingTime?.message}`)}
            </FormFeedback>
          </FormGroup>
          <FormGroup className="date-time-div">
            <Label className="input-label center-activity-label">
              {translation(`ACTIVITIES.END_TIME`)}
            </Label>
            <Controller
              control={control}
              name="endTime"
              render={({ field }) => (
                <Input
                  type="time"
                  placeholder="--:--"
                  className="dining-date-input shadow-none pointer"
                  invalid={errors?.endTime ? true : false}
                  {...field}
                />
              )}
            />
            <FormFeedback>
              {translation(`${errors?.endTime?.message}`)}
            </FormFeedback>
          </FormGroup>
        </div>
        <div className="restaurantImage-upload-parent">
          <div
            className="restaurantImage-upload pointer"
            onClick={() => uploadRestaurantImageHandler('restaurant_image')}
          >
            <img
              src={process.env.PUBLIC_URL + '/assets/uploadIcon.svg'}
              alt=""
            />
            <span className="text">
              {' '}
              {translation(`RESTAURANT.UPLOAD_IMAGE`)}
            </span>
          </div>
          {imageName ? (
            <span className="noImage-text">
              {imageName && translation(`${imageName}`)}
            </span>
          ) : (
            <span className="noImage-text">
              {' '}
              {translation(`RESTAURANT.NO_IMAGE_ADDED`)}
            </span>
          )}
          <input
            id="restaurant_image"
            type="file"
            style={{ display: 'none' }}
            accept="image/jpeg, image/png"
            onChange={(e) => restaurantImageOnChange(e)}
          />
        </div>
        <div className="menu-parent-div">
          <div className="menu-heading-div">
            <input
              id="menu_image"
              type="file"
              style={{ display: 'none' }}
              accept="image/jpeg, image/png, application/pdf"
              onChange={(e) => menuImageOnChange(e)}
            />
            <span className="menu-addMenu">
              {' '}
              {translation(`RESTAURANT.ADD_MENU`)}
            </span>
            <span
              className="menu-addMore pointer"
              onClick={() => uploadOnClick('menu_image')}
            >
              <img
                src={process.env.PUBLIC_URL + '/assets/addMoreSmallIcon.svg'}
                alt=""
              />
              {translation(`RESTAURANT.ADD_MORE`)}
            </span>
          </div>
          <div className="menu-image-parent-div">
            {menuImgSrc?.length ? (
              <div className="menuImg-frame-parent">
                {menuImgSrc?.map((url: IMenuFileSrc, index: number) => (
                  <div key={index} className="menuImg-frame margin-top-10">
                    {url.fileType === 'application/pdf' ? (
                      <>
                        <div className="pdf-viewer-wrap">
                          <a href={url.file} download>
                            <img
                              className="pointer"
                              src={'/assets/pdf_thumb.png'}
                              alt=""
                            />
                          </a>
                        </div>
                        <span className="menu-image-title ">
                          {url.fileName}
                        </span>
                      </>
                    ) : (
                      <>
                        <div>
                          <a href={url.file} download>
                            <img
                              height={'80px'}
                              className="pointer"
                              src={url?.file}
                              alt=""
                            />
                          </a>
                        </div>
                        <span className="menu-image-title ">
                          {url.fileName}
                        </span>
                      </>
                    )}

                    <div
                      className="menuPreviewClose"
                      onClick={() => removeMenuPreview(url, index)}
                    >
                      <img
                        className="pointer"
                        src={
                          process.env.PUBLIC_URL +
                          '/assets/menuPreviewCloseIcon.svg'
                        }
                        alt=""
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <span className="noMenuAdded">
                {' '}
                {translation(`RESTAURANT.NO_MENU`)}
              </span>
            )}
          </div>
        </div>
        <div className="modal-submit-button-div">
          <Button type="submit" className="form-button shadow-none">
            {isAdding ? (
              <LoadingSpinner />
            ) : (
              translation(`AMENITIES_FORM.SAVE_UPDATE`)
            )}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default RestaurantForm;
