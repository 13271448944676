import React from 'react';
import { Swiper } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { TBreakPoints } from './swiperBreakPoints';

const SwiperComp: React.FC<{
  setSwiperState: (data: any) => void;
  children: JSX.Element[];
  initialSlide: number;
  breakPoints: TBreakPoints;
}> = ({ children, setSwiperState, initialSlide, breakPoints }) => {
  return (
    <Swiper
      initialSlide={initialSlide - 2}
      slidesPerView={7}
      spaceBetween={10}
      onInit={(ev) => {
        setSwiperState(ev);
      }}
      loopFillGroupWithBlank={true}
      className="mySwiper"
      breakpoints={breakPoints}
    >
      {children}
    </Swiper>
  );
};

export default SwiperComp;
