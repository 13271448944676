import * as yup from 'yup';

export const usersSchema = yup
  .object({
    userName: yup.string().required('USERS_PAGE_DATA.NAME_REQUIRED'),
    designation: yup.string().required('USERS_PAGE_DATA.DESIGNATION_REQUIRED'),
    emailId: yup
      .string()
      .email('USERS_PAGE_DATA.EMAIL_INVALID')
      .required('USERS_PAGE_DATA.EMAIL_REQUIRED'),
  })
  .required();
