import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Spinner,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import {
  addFilterCategoryList,
  deleteCategory,
} from '../../pages/diningPage/diningSlice';
import '../diningForm/diningForm.css';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import { closeModalReducer } from '../modal/modalSlice';
import { categoryFormSchema } from './repeatFormValidation';

const ListCategory: React.FC<{
  onCreate: (data: { id: number; categoryName: string }) => void;
}> = ({ onCreate }) => {
  const [translation] = useTranslation('common');
  const [selectedCategory, setSelectedCategory] = useState<null | number>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [deleteCategoryData, setDeleteCategory] = useState({
    categoryName: '',
    id: 0,
  });
  const [catrgoryList, setCatrgoryList] = useState<
    { categoryName: string; id: number }[]
  >([]);
  const {
    categoryFilter,
    isFetchingCategories,
    isDeletingCategory,
    categories,
    isUpdatingCategory,
    categoriesPaginate,
  } = useAppSelector((state) => state.dining);

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const handleConfirm = async () => {
    // try{
    await dispatch(deleteCategory(deleteCategoryData.id));
    // Call the onDelete prop when the user confirms
    setPopoverOpen(false); // Close the popover
  };
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<{ category: string }>({
    resolver: yupResolver(categoryFormSchema),
    defaultValues: {
      category: '',
    },
  });

  useEffect(() => {
    reset({
      category: '',
    });
  }, []);
  useEffect(() => {
    setValue(
      'category',
      catrgoryList.filter((item) => item.id === selectedCategory)[0]
        ?.categoryName
    );
  }, [selectedCategory]);

  const onSubmit = (data: { category: string }) => {
    let payload = {} as { id: number; categoryName: string };

    payload = {
      id: selectedCategory as number,
      categoryName: data.category,
    };
    onCreate(payload);
  };
  const makeArrayUnique = (
    inputArray: { categoryName: string; id: number }[]
  ) => {
    const uniqueArray: { categoryName: string; id: number }[] = [];
    const idSet = new Set();

    inputArray.forEach((item) => {
      if (!idSet.has(item?.id)) {
        idSet.add(item?.id);
        uniqueArray.push(item);
      }
    });

    return uniqueArray;
  };
  const addNewRequestData = (list: { categoryName: string; id: number }[]) => {
    const updatedRequestArray = [...catrgoryList, ...list];

    setCatrgoryList(makeArrayUnique(updatedRequestArray));
  };
  useEffect(() => {
    addNewRequestData(categories as { categoryName: string; id: number }[]);
  }, [categories]);
  const changePageFilterHandler = (page: number) => {
    if (page + 1 <= categoriesPaginate.totalPages) {
      dispatch(addFilterCategoryList(page));
    }
  };
  return (
    <>
      {popoverOpen ? (
        <>
          <div className="dining-modal-header">
            <h2 className="modal-heading">
              {translation('BUTTONS.DELETE_SMALL')}
            </h2>
            <img
              onClick={() => {
                togglePopover();
                setDeleteCategory({
                  categoryName: '',
                  id: 0,
                });
              }}
              className="pointer"
              src={process.env.PUBLIC_URL + '/assets/closeModalIcon.svg'}
              alt="close-icon"
            />
          </div>
          <div className="p-10px delete-txt">
            {translation('DINING_SCHEDULES.DELETE_TXT_1')} &quot;
            {deleteCategoryData.categoryName}&quot;?
            <div className="category-list-action-popover">
              <Button
                type="submit"
                onClick={handleConfirm}
                className="form-button shadow-none"
              >
                {isDeletingCategory ? (
                  <LoadingSpinner />
                ) : (
                  translation('BUTTONS.DELETE')
                )}
              </Button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="dining-modal-header">
            <h2 className="modal-heading">
              {translation('DINING_SCHEDULES.CATEGORY')}
            </h2>
            <div
              onClick={() => {
                dispatch(closeModalReducer());
                dispatch(addFilterCategoryList(0));
              }}
            >
              <img
                className="pointer"
                src={process.env.PUBLIC_URL + '/assets/closeModalIcon.svg'}
                alt="close-icon"
              />
            </div>
          </div>
          <div className="category-parent-div">
            {catrgoryList.map((item) => (
              <div key={item?.id} className="modal-right-wrap">
                {selectedCategory === item?.id ? (
                  <Form
                    className="form  width-100"
                    onSubmit={handleSubmit(onSubmit)}
                    // defaultValue={{ category: item.category }}
                  >
                    <div className="category-form-div">
                      <FormGroup>
                        <Controller
                          control={control}
                          name="category"
                          render={({ field }) => (
                            <Input
                              placeholder="Category"
                              className="form-input shadow-none width-100"
                              invalid={errors?.category ? true : false}
                              {...field}
                            />
                          )}
                        />
                        <FormFeedback>
                          {translation(`${errors?.category?.message}`)}
                        </FormFeedback>
                      </FormGroup>

                      <div className="category-list-action-form">
                        <Button
                          type="submit"
                          className="form-button shadow-none"
                        >
                          {isUpdatingCategory ? (
                            <LoadingSpinner />
                          ) : (
                            translation(`AMENITIES_FORM.SAVE_UPDATE`)
                          )}
                        </Button>
                        <Button
                          onClick={() => setSelectedCategory(null)}
                          className="form-button shadow-none"
                        >
                          {translation('BUTTONS.CANCEL')}
                        </Button>
                      </div>
                    </div>
                  </Form>
                ) : (
                  <div className=" category-list-parent">
                    <div className="form-input category-list">
                      {item.categoryName}
                    </div>
                    <div className="category-list-action">
                      <img
                        src={process.env.PUBLIC_URL + '/assets/editIcon.svg'}
                        alt="edit-icon"
                        className="action-img"
                        onClick={() => setSelectedCategory(item?.id)}
                      />
                      <img
                        src={process.env.PUBLIC_URL + '/assets/deleteicon.svg'}
                        alt="delete-icon"
                        className="action-img"
                        onClick={() => {
                          togglePopover();
                          setDeleteCategory(item);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            ))}
            {categoryFilter.page + 1 < categoriesPaginate.totalPages &&
            catrgoryList?.length != 0 ? (
              <div className="pagination-down-arrow-container-category">
                {isFetchingCategories ? (
                  <Spinner
                    className="loading-spinner"
                    color="#080ccb"
                  ></Spinner>
                ) : (
                  <img
                    onClick={() =>
                      changePageFilterHandler(categoryFilter.page + 1)
                    }
                    src={process.env.PUBLIC_URL + '/assets/down-arrow.svg'}
                    alt="down-arrow"
                    className="pagination-down-arrow"
                  />
                )}
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  );
};

export default ListCategory;
