import * as yup from 'yup';

const phoneRegex = /^[0-9\-+ ]{8,14}$/;
export const faclityProfileSchema = yup
  .object({
    name: yup.string().required('FACILITY.NAME_REQUIRED'),
    email: yup.string().email('FACILITY.EMAIL_VALID'),
    phoneNo: yup
      .string()
      .matches(phoneRegex, 'FACILITY.PHONE_VALIDATION_ERROR')
      .required('FACILITY.PHONE_REQUIRED'),
    facilityNumber: yup.string(),
    addressLine1: yup.string(),
    addressLine2: yup.string(),
    addressLine3: yup.string(),
  })
  .required();
