import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../app/hook';
import UserProfileImage from '../../components/imageComponents/userProfileImage';
import { IUserDetails } from './facilityReviewsInterface';
import { fetchUserDetails } from './facilityReviewsSlice';

const UserComponent: React.FC<{
  userId: string;
}> = ({ userId }) => {
  const [userData, setUserData] = useState<IUserDetails>({} as IUserDetails);
  const dispatch = useAppDispatch();
  useEffect(() => {
    const getUser = async () => {
      if (userId) {
        const response = await dispatch(fetchUserDetails(userId));
        setUserData(response.payload?.data[0]);
      }
    };
    getUser();
  }, [userId]);

  return (
    <div className="review-card-flex-row">
      <UserProfileImage
        imageId={userData?.imageId}
        className="review-user-imageClass userImage-overflow"
        divClass="review-user-image userImage-overflow"
        src="/assets/guestNoImage.svg"
        loadingColor="light"
      />
      <div className="review-card-flex-column userName-overflow-div">
        <span className="review-user-name userName-overflow ">
          {userData?.userName ? (
            userData?.userName
          ) : (
            <span className="deleted-userName-class">
              <em>Anonymous User</em>
            </span>
          )}
        </span>
      </div>
    </div>
  );
};

export default UserComponent;
