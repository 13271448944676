import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { formDataConverter } from '../../_helpers/formDataConverter';
import { RootState } from '../../app/store';
import {
  closeModalReducer,
  openDeleteModalReducer,
  // eslint-disable-next-line prettier/prettier
  openEditModalReducer,
} from '../../components/modal/modalSlice';
import { getImageDetails } from '../../services/amenitiesService';
import { getImageId } from '../../services/facilityService';
import {
  addUser,
  deleteUser,
  getAllUsers,
  getUserById,
  resendInvitation,
  // eslint-disable-next-line prettier/prettier
  updateUser,
} from '../../services/usersService';
import { IImageDetails } from '../amenitiesPage/amenitiesInterface';
import { getUsersRequestParams } from './userParams';
import {
  IAddUserData,
  IAddUserPayload,
  IDeleteEditUserPayload,
  IUserDeletePayload,
  IUserPaginateData,
  IUsersFetchAllData,
  // eslint-disable-next-line prettier/prettier
  IUsersPayloadFilter,
} from './usersInterface';
import { addUserManupulator, filterChange } from './utilityFunctions';

export interface IUsers {
  isProcessingImage: boolean;
  isProcessingAllUsers: boolean;
  isProcessingUser: boolean;
  isAddingNewUser: boolean;
  addedNewUser: boolean;
  isDeletingUser: boolean;
  deletedUser: boolean;
  isUpdatingUser: boolean;
  updatedExistingUser: boolean;
  filter: IUsersPayloadFilter;
  userPaginate: IUserPaginateData;
  user: IUsersFetchAllData;
  userList: IUsersFetchAllData[];
  isFetchingImage: boolean;
  isFetchingImageDetails: boolean;
  imageDetails: IImageDetails;
  isSendingInvitation: boolean;
}
const initialState: IUsers = {
  isProcessingImage: false,
  isProcessingAllUsers: false,
  isProcessingUser: false,
  isAddingNewUser: false,
  addedNewUser: false,
  isDeletingUser: false,
  deletedUser: false,
  isUpdatingUser: false,
  updatedExistingUser: false,
  filter: {
    id: '',
    userName: '',
    sort: 'createdAt,Desc',
    page: 0,
    size: 10,
    facilityId: '',
  },
  userPaginate: {
    pageNumber: 0,
    pageSize: 0,
    numberOfElements: 0,
    totalPages: 0,
    totalElements: 0,
    first: false,
  },
  user: {
    id: '',
    userName: '',
    imageId: '',
    designation: '',
    email: '',
  },
  userList: [],
  isFetchingImage: false,
  isFetchingImageDetails: false,
  imageDetails: {} as IImageDetails,
  isSendingInvitation: false,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    resetUserState: () => {
      return initialState;
    },
    addFilterName: (state, action) => {
      state.filter.userName = action.payload;
    },
    addFilterPage: (state, action) => {
      state.filter.page = action.payload;
    },
    addFilterSort: (state, action) => {
      state.filter.sort = action.payload;
    },
    clearAllFilter: (state) => {
      state.filter.userName = '';
    },
    userPageFilterReset: (state) => {
      state.filter.page = 0;
    },
    clearUserState: (state) => {
      state.user = {
        id: '',
        userName: '',
        imageId: '',
        designation: '',
        email: '',
      };
    },
    filterReset: (state) => {
      state.filter = {
        userName: '',
        sort: 'createdAt,Desc',
        page: 0,
        size: 10,
        facilityId: '',
      };
    },
    addTranslatedData: (state, action) => {
      state.userList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserById.pending, (state) => {
        state.isProcessingUser = true;
      })
      .addCase(fetchUserById.fulfilled, (state, action) => {
        state.isProcessingUser = false;
        state.user = action.payload?.data[0];
      })
      .addCase(fetchUserById.rejected, (state) => {
        state.isProcessingUser = false;
      })
      .addCase(fetchAllUsers.pending, (state) => {
        state.isProcessingAllUsers = true;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.isProcessingAllUsers = false;
        state.userList = action.payload?.data[0]?.data;
        state.userPaginate = action.payload?.data[0];
      })
      .addCase(fetchAllUsers.rejected, (state) => {
        state.isProcessingAllUsers = false;
      })
      .addCase(addNewUser.pending, (state) => {
        state.isAddingNewUser = true;
        state.addedNewUser = false;
      })
      .addCase(addNewUser.fulfilled, (state, action) => {
        state.isAddingNewUser = false;
        state.addedNewUser = true;

        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(addNewUser.rejected, (state, action: PayloadAction<any>) => {
        state.isAddingNewUser = false;
        state.addedNewUser = false;

        toast.error(
          `${action.payload
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload.slice(1)}`
        );
      })
      .addCase(updateSelectedUser.pending, (state) => {
        state.isUpdatingUser = true;
        state.updatedExistingUser = false;
      })
      .addCase(updateSelectedUser.fulfilled, (state, action) => {
        state.isUpdatingUser = false;
        state.updatedExistingUser = true;
        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(
        updateSelectedUser.rejected,
        (state, action: PayloadAction<any>) => {
          state.isUpdatingUser = false;
          state.updatedExistingUser = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(deleteSelectedUser.pending, (state) => {
        state.isDeletingUser = true;
        state.deletedUser = false;
      })
      .addCase(deleteSelectedUser.fulfilled, (state, action) => {
        state.deletedUser = true;
        state.isDeletingUser = false;
        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(
        deleteSelectedUser.rejected,
        (state, action: PayloadAction<any>) => {
          state.isDeletingUser = false;
          state.deletedUser = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(fetchUserImageId_Add.pending, (state) => {
        state.isFetchingImage = true;
      })
      .addCase(fetchUserImageId_Add.fulfilled, (state, action) => {
        state.isFetchingImage = false;
      })
      .addCase(
        fetchUserImageId_Add.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingImage = false;
        }
      )
      .addCase(fetchUserImageId_Update.pending, (state) => {
        state.isFetchingImage = true;
      })
      .addCase(fetchUserImageId_Update.fulfilled, (state, action) => {
        state.isFetchingImage = false;
      })
      .addCase(fetchUserImageId_Update.rejected, (state) => {
        state.isFetchingImage = false;
      })
      .addCase(fetchUserImageDetails.pending, (state) => {
        state.isFetchingImageDetails = true;
        state.imageDetails = { id: '', originalName: '' };
      })
      .addCase(fetchUserImageDetails.fulfilled, (state, action) => {
        state.isFetchingImageDetails = false;
        state.imageDetails = action.payload?.data[0];
      })
      .addCase(fetchUserImageDetails.rejected, (state) => {
        state.isFetchingImageDetails = false;
      })
      .addCase(resendEmailInvitationAction.pending, (state) => {
        state.isSendingInvitation = true;
      })
      .addCase(resendEmailInvitationAction.fulfilled, (state, action) => {
        state.isSendingInvitation = false;
        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(
        resendEmailInvitationAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.isSendingInvitation = false;
          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      );
  },
});
export const fetchAllUsers = createAsyncThunk(
  'users/fetchAllUsers',
  async (data: IUsersPayloadFilter, { rejectWithValue }) => {
    try {
      const params = getUsersRequestParams(
        data.userName,
        data.sort,
        data.page,
        data.size,
        data.id,
        data.facilityId
      );
      const response = await getAllUsers(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchUserImageDetails = createAsyncThunk(
  'amenities/fetchUserImageDetails',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getImageDetails(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchUserById = createAsyncThunk(
  'users/fetchUserById',
  async (data: IDeleteEditUserPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await getUserById(data?.id);
      if (response?.data[0]?.imageId) {
        dispatch(fetchUserImageDetails(response?.data[0]?.imageId));
      }
      if (data?.for === 'delete') {
        dispatch(openDeleteModalReducer());
      } else if (data?.for === 'edit') {
        dispatch(openEditModalReducer());
      }
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const addNewUser = createAsyncThunk(
  'users/addNewUser',
  async (data: IAddUserData, { rejectWithValue, getState, dispatch }) => {
    const { users } = getState() as { users: IUsers };
    try {
      const response = await addUser(data);
      dispatch(closeModalReducer());
      dispatch(fetchAllUsers(filterChange(users.filter, data?.facilityId)));
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);
export const deleteSelectedUser = createAsyncThunk(
  'users/deleteSelectedUser',
  async (data: IUserDeletePayload, { rejectWithValue, getState, dispatch }) => {
    const { users } = getState() as { users: IUsers };
    try {
      const response = await deleteUser(data?.id);
      dispatch(closeModalReducer());
      dispatch(fetchAllUsers(filterChange(users.filter, data?.facilityId)));
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const updateSelectedUser = createAsyncThunk(
  'users/updateSelectedUser',
  async (data: IAddUserData, { rejectWithValue, getState, dispatch }) => {
    const { users } = getState() as { users: IUsers };
    try {
      const response = await updateUser(data);
      dispatch(closeModalReducer());
      dispatch(fetchAllUsers(filterChange(users.filter, data?.facilityId)));
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const fetchUserImageId_Add = createAsyncThunk(
  'users/fetchUserImageId_Add',
  async (data: IAddUserPayload, { rejectWithValue, dispatch }) => {
    try {
      const imageResponse = await getImageId(formDataConverter(data.imageData));
      const response = dispatch(
        addNewUser(addUserManupulator(data, imageResponse?.data[0]?.id))
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const fetchUserImageId_Update = createAsyncThunk(
  'users/fetchUserImageId_Update',
  async (data: IAddUserPayload, { rejectWithValue, dispatch }) => {
    try {
      const imageResponse = await getImageId(formDataConverter(data.imageData));
      const response = dispatch(
        updateSelectedUser(addUserManupulator(data, imageResponse?.data[0]?.id))
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const resendEmailInvitationAction = createAsyncThunk(
  'users/resendEmailInvitationAction',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await resendInvitation(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const {
  addFilterName,
  addFilterSort,
  clearAllFilter,
  clearUserState,
  addFilterPage,
  filterReset,
  resetUserState,
  addTranslatedData,
  userPageFilterReset,
} = usersSlice.actions;
export const selectUsers = (state: RootState) => state.users;
export const usersReducer = usersSlice.reducer;
