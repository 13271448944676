import React from 'react';

const ImagePreview: React.FC<{
  url: string;
  clearPreviewData: (data: void) => void;
  imgClass: string;
  parentClass: string;
  closeIconClass?: string;
}> = ({ url, clearPreviewData, imgClass, parentClass, closeIconClass }) => {
  return (
    <span className={parentClass}>
      <img className={imgClass} src={url} alt="" />
      <img
        className={`${closeIconClass} pointer`}
        src={process.env.PUBLIC_URL + '/assets/searchClose.svg'}
        alt="close-search"
        onClick={() => clearPreviewData()}
      />
    </span>
  );
};

export default ImagePreview;
