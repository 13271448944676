import i18next from 'i18next';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import App from './App';
import { store } from './app/store';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import commonGer from './translation/de/commonGer.json';
import commonEn from './translation/en/commonEn.json';
import commonFr from './translation/fr/commonFr.json';
import commonSp from './translation/sp/commonSp.json';

i18next.init({
  lng: 'en',
  resources: {
    en: {
      common: commonEn,
    },
    sp: {
      common: commonSp,
    },
    fr: {
      common: commonFr,
    },
    de: {
      common: commonGer,
    },
  },
});

const rootElement = document.getElementById('root')!;
const root = createRoot(rootElement);

root.render(
  <React.Fragment>
    {/* <StrictMode> */}
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
    {/* </StrictMode> */}
  </React.Fragment>
);

serviceWorkerRegistration.register();
reportWebVitals();
