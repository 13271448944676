import React from 'react';
import { IGuestUserData } from '../../pages/activitiesPage/activitiesInterface';
import EventImageComp from './eventImageComp';

const UsersCard: React.FC<{
  user: IGuestUserData;
}> = ({ user }) => {
  return (
    <div className="event-user-card">
      <EventImageComp
        className="guest-image-class"
        divClass="guest-div-class"
        noImageDivClass="guest-noImage-div"
        src="/assets/guestDefault.svg"
        imageId={user?.photoId}
      />
      <div className="event-user-details">
        <span className="name">{user?.userName}</span>
        <span className="email">{user?.email}</span>
        <div className="guest-card-separator"></div>
      </div>
    </div>
  );
};

export default UsersCard;
