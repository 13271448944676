import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { IRestaurants } from '../../pages/diningPage/diningInterface';
import { fetchRestaurantById } from '../../pages/diningPage/diningSlice';
import { activeInactiveChecker } from '../../_helpers/checkerFunctions';
import RestaurantImgComp from '../imageComponents/restaurantImgComp';
import DropdownComp, { IRestaurantOptions } from './dropDownComp';
import TimeCompWrap from './timeCompWrap';

const MainCard: React.FC<{
  navigateHandler: (data: string) => void;
  restaurant: IRestaurants;
  dropdown: string;
  showOptions: (key: string) => void;
  compKey: string;
}> = ({ navigateHandler, restaurant, dropdown, showOptions, compKey }) => {
  const { currentFacility } = useAppSelector((state) => state.authentication);
  const dispatch = useAppDispatch();

  const [translation] = useTranslation('common');
  const viewDiningNavigation = (id: string) => {
    navigateHandler(id);
  };
  const optionsDropdownHandler = (key: string) => {
    showOptions(key);
  };
  const optionClickParent = (item: IRestaurantOptions) => {
    if (item.tag == 'Edit Restaurant') {
      dispatch(
        fetchRestaurantById({ id: restaurant?.id, for: 'Edit Restaurant' })
      );
    }
    if (item.tag == 'Remove') {
      dispatch(fetchRestaurantById({ id: restaurant?.id, for: 'Remove' }));
    }

    showOptions('');
  };
  return (
    <div className="restaurant-card">
      <div
        className="pointer showMoreIcon"
        onClick={() => optionsDropdownHandler(compKey)}
      >
        <img
          className=""
          src={process.env.PUBLIC_URL + '/assets/showOptionIcon.svg'}
          alt="show-more-icon"
        />
      </div>
      {dropdown == compKey && (
        <DropdownComp optionClickParent={optionClickParent} />
      )}

      <RestaurantImgComp
        imageId={restaurant?.imageId}
        className="restaurant-image"
        divClass="restaurant-image-div restaurant-card-image-R"
        noImageClass="restaurant-noImage"
        noImageDivClass="restaurant-noImage-div restaurant-card-image-R"
        src="/assets/emptyImage.svg"
      />

      <div className="restaurant-details restaurant-card-details-R">
        <div className="restaurant-name-div">
          <span className="restaurant-name">{restaurant?.restaurantName}</span>
        </div>
        <TimeCompWrap
          startTime={restaurant?.startTime}
          endTime={restaurant?.endTime}
        />
        <div
          className="view-dining-button pointer"
          onClick={
            activeInactiveChecker(currentFacility?.status)
              ? () => viewDiningNavigation(restaurant?.id)
              : undefined
          }
        >
          <span className="view-dining-button-text ">
            {' '}
            {translation('DINING_SCHEDULES.VIEW_DINING')}
          </span>
        </div>
      </div>
    </div>
  );
};

export default MainCard;
