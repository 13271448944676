import React, { ChangeEvent, SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import BusinessImage from '../../components/BusinessDownloadImage/businessImage';
import CategoriesList from '../../components/categoriesList/listCategories';
import { translateArrayList } from '../../components/cloudTranslationCommon/translation';
import FullScreenImgComp from '../../components/fullScreenImage/fullScreenImgComp';
import { DropDownComponent } from '../../components/menuDropDown/dropDownComponent';
import NoDataComponent from '../../components/noDataComponent/noDataComponent';
import PageLoader from '../../components/pageLoading/pageLoader';
import Paginate, {
  // eslint-disable-next-line prettier/prettier
  IPaginateClickData,
} from '../../components/paginate/paginate';
import { PdfComponent } from '../../components/pdfViewer/pdfComponent';
import { IMenuFileSrc } from '../diningPage/diningInterface';
import { getSelectedFacility, translateText } from '../loginPage/authSlice';
import { addFilterName, filterReset } from '../usersPage/usersSlice';
import './supportBusiness.css';
import { IBussinessData } from './SupportBusinessInterface';
import {
  addFilterPage,
  addLatitudeFilter,
  addLongitudeFilter,
  addTranslatedData,
  applyFilterOnPage,
  clearAllFilter,
  fetchAllLocalSupportBusiness,
  // eslint-disable-next-line prettier/prettier
  pageFilterReset,
} from './supportingBusinessSlice';

const SupportingBusinessPage: React.FC = () => {
  const { f_id } = useParams();
  const [translation] = useTranslation('common');
  const [search, setSearch] = useState<string>('');
  const { currentFacility } = useAppSelector((state) => state.authentication);
  const [viewFileIndex, setViewFileIndex] = useState(-1);
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedImage, setSelectedImage] = useState('');

  const [searchParams, setSearchParams] = useSearchParams({
    category: '',
    name: '',
  });
  // const [filterParams, setFilterParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const [t, i18n] = useTranslation('common');

  const language = i18n.language;

  useEffect(() => {
    dispatch(pageFilterReset());
    if (f_id) {
      dispatch(getSelectedFacility(f_id));
    }
  }, []);

  useEffect(() => {
    if (currentFacility?.location) {
      dispatch(addLatitudeFilter(currentFacility?.location?.latitude));
      dispatch(addLongitudeFilter(currentFacility?.location?.longitude));
    }
  }, [currentFacility?.location?.latitude]);

  const handlePageClick = (data: IPaginateClickData) => {
    if (data.selected !== businessPaginate.totalPages)
      dispatch(addFilterPage(data?.selected));
  };
  const activePageFunction = (data: any) => {
    console.log('active page object', data);
  };
  const {
    businessPaginate,
    filter,
    localBusinessList,
    isProcessingAllLocalBusiness,
  } = useAppSelector((state) => state.business);
  // translation
  useEffect(() => {
    if (language !== 'en' && localBusinessList?.length > 0) {
      translateArrayList(
        localBusinessList,
        language,
        dispatch,
        translateText,
        addTranslatedData,
        ['description'] // Pass the properties that need to be translated
      );
    }
  }, [language, isProcessingAllLocalBusiness]);
  useEffect(() => {
    if (filter.latitude !== '') {
      language === 'en' && dispatch(fetchAllLocalSupportBusiness(filter));
    }
  }, [language, filter]);
  // -----
  useEffect(() => {
    if (language !== 'en') {
      return () => {
        dispatch(addTranslatedData([]));
      };
    }
  }, []);
  const onSearchTextHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setSearchParams({ name });
    if (!name) {
      dispatch(filterReset());
    }
  };

  const searchClickHandler = (e: SyntheticEvent) => {
    dispatch(addFilterName(searchParams.get('name')));
    dispatch(addFilterPage(0));
    if (!searchParams.get('name')) {
      dispatch(addFilterName(null));
    }
    e.preventDefault();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (filter.latitude !== '') {
      dispatch(fetchAllLocalSupportBusiness(filter));
    }
    const category = searchParams.get('category') || '';
    if (category) {
      dispatch(applyFilterOnPage(category));
    }
  }, [filter, searchParams, f_id]);

  const clearSearchText = () => {
    setSearch('');
    setSearchParams({ ...searchParams, name: '' });
    dispatch(clearAllFilter());
  };

  const onFilterTextHandler = (e: ChangeEvent<HTMLInputElement>) => {
    // const category = e.target.value;

    setSearchParams({ category: e.target.value, ...searchParams });
    // if (category === '') {
    //   dispatch(filterReset());
    //   setSearchParams({});
    // }
  };

  const onSelectedFile = (doc: IMenuFileSrc) => {
    doc.fileType === 'application/pdf'
      ? setSelectedFile(doc?.file)
      : setSelectedImage(doc?.file);
    setViewFileIndex(-1);
  };

  const viewFileList = (index: number) => {
    setViewFileIndex(index);
  };
  const closeViewer = () => {
    setSelectedFile('');
    setSelectedImage('');
  };

  return (
    <React.Fragment>
      {viewFileIndex !== -1 && (
        <div
          onClick={() => setViewFileIndex(-1)}
          className="view-file-wrap"
        ></div>
      )}

      {selectedFile ? (
        <PdfComponent close={closeViewer} url={selectedFile} />
      ) : (
        <div className="page-background amenities-page">
          {selectedImage && (
            <FullScreenImgComp
              image={selectedImage}
              state={true}
              closeClass={closeViewer}
            >
              <img src={selectedImage.toString()} alt="" />
            </FullScreenImgComp>
          )}
          <div className="support-bussiness-head">
            <h2>{translation('SUPPORTING_BUSINESS.TITLE')}</h2>
            <div className="search-wrap">
              {/* commenting since api is not ready for it */}

              {/* <div className="support-select-box">
              <Input
                placeholder="All Categories"
                type="select"
                name="select"
                id="exampleSelect"
                value={searchParams.get('category') || ''}
                className="shadow-none "
                onChange={onFilterTextHandler}
              >
                {businessFilter.map((filter: IBussinessFilters) => (
                  <option key={filter.name} value={filter.value}>
                    {filter?.name}
                  </option>
                ))}
              </Input>
            </div> */}

              {/* <FormGroup className="userPage-search-btn">
              <Input
                name="search"
                placeholder={translation('USERS_PAGE_DATA.TYPE_TO_SEARCH')}
                type="search"
                value={searchParams.get('name') || ''}
                className="searchBar-input shadow-none"
                onChange={onSearchTextHandler}
              />
              {searchParams.get('name') && (
                <img
                  className="searchClose-button pointer"
                  src={process.env.PUBLIC_URL + '/assets/searchClose.svg'}
                  alt="close-search"
                  onClick={clearSearchText}
                />
              )}
              <Button
                type="submit"
                className="searchButton shadow-none"
                onClick={searchClickHandler}
              >
                <img
                  src={process.env.PUBLIC_URL + '/assets/searchIcon.svg'}
                  alt="search-icon"
                />
              </Button>
            </FormGroup> */}
            </div>
          </div>
          {!isProcessingAllLocalBusiness ? (
            localBusinessList?.length > 0 ? (
              <div className="user-card-div business-parent-card-R">
                {localBusinessList.map(
                  (item: IBussinessData, index: number) => (
                    <div key={item?.id} className="business-card">
                      <BusinessImage
                        imageId={item?.photoId}
                        className="business-image"
                        // noImageClass="noImageClass"
                        noImageClass=""
                        // divClass="business-image-div"
                        divClass="business-image"
                        alt={''}
                        src="/assets/noImageDark.svg"
                        loadingColor="light"
                      />
                      <div className="business-card-content-wrap">
                        <div className="business-content-card">
                          <span className="business-card-title">
                            {item?.name}
                          </span>
                          <span className="business-card-subtitle">
                            {' '}
                            {item?.address?.city && item?.address?.subLocality
                              ? `${item?.address?.city}, ${item?.address?.subLocality}`
                              : item?.address?.city
                              ? item?.address?.city
                              : item?.address?.subLocality
                              ? item?.address?.subLocality
                              : 'No location'}
                          </span>
                          <span className="business-short-details">
                            {translation('SUPPORTING_BUSINESS.PRICE')}:{' '}
                            {item?.maxPrice}&nbsp;
                            <span className="business-details-divider">|</span>
                            &nbsp; {translation('SUPPORTING_BUSINESS.CAPACITY')}
                            : {item?.capacity}&nbsp;
                            {/* <span className="business-details-divider">|</span> */}
                            {/* &nbsp; Category:&nbsp; */}
                            {/* <CategoriesList
                            categoryIds={item?.categoryIds}
                            className={''}
                          /> */}
                          </span>
                          {
                            item?.documents.length > 0 ? (
                              <div>
                                <span
                                  onClick={() => viewFileList(index)}
                                  className="documents-text pointer"
                                >
                                  {translation(
                                    'SUPPORTING_BUSINESS.VIEW_DOCUMENTS'
                                  )}
                                </span>{' '}
                                {item?.documents.length > 0 &&
                                  viewFileIndex === index && (
                                    <DropDownComponent
                                      onSelected={onSelectedFile}
                                      menuFileList={item?.documents}
                                    />
                                  )}
                              </div>
                            ) : null
                            // <span className="no-documents-text ">
                            //   {' '}
                            //   {translation('SUPPORTING_BUSINESS.NO_DOCUMENTS')}
                            // </span>
                          }
                          <span className="business-short-details business-category-details">
                            {translation('SUPPORTING_BUSINESS.CATEGORY')}:&nbsp;
                            <CategoriesList
                              categoryIds={item?.categoryIds}
                              className={''}
                            />
                          </span>
                        </div>

                        <div className="card-content-description">
                          {item?.description}
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            ) : (
              <div className="no-data-wrap">
                <NoDataComponent constant="NO_BUSINESS" />
              </div>
            )
          ) : (
            <PageLoader />
          )}
          {businessPaginate?.totalPages > 1 && (
            <Paginate
              handlePageClick={handlePageClick}
              totalPage={businessPaginate.totalPages}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default SupportingBusinessPage;
