import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
import PageLoader from '../../components/pageLoading/pageLoader';
import ImagePreview from '../../components/profileImagePreview/imagePreview';
import { activeInactiveChecker } from '../../_helpers/checkerFunctions';
import { History } from '../../_helpers/history';
import { getSelectedFacility } from '../loginPage/authSlice';
import { IProfileValidation } from './profileInterface';
import { fetchProfileImageId, getImageById } from './profileSlice';
import { faclityProfileSchema } from './profileValidation';
import { facilityProfilePayload } from './utilityFunction';

const FacilityProfileEdit: React.FC = () => {
  const { f_id } = useParams();
  const { currentFacility, isProcessingFacility } = useAppSelector(
    (state) => state.authentication
  );
  const { isUpdatingProfile } = useAppSelector(
    (state) => state.facilityProfile
  );
  const [coverPic, setCoverPic] = useState<any>('');
  const [coverUrl, setCoverUrl] = useState<string>('');
  const [coloredLogo, setColoredLogo] = useState<any>('');
  const [coloredUrl, setColoredUrl] = useState<string>('');
  const [whiteLogo, setWhiteLogo] = useState<any>('');
  const [whiteUrl, setWhiteUrl] = useState<string>('');
  const [translation] = useTranslation('common');
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<IProfileValidation>({
    resolver: yupResolver(faclityProfileSchema),
    defaultValues: {
      name: currentFacility?.facilityName,
      phoneNo: currentFacility?.phoneNo,
      facilityNumber: currentFacility?.facilityNumber,
      addressLine1: currentFacility?.facilityAddress?.addressLine1,
      addressLine2: currentFacility?.facilityAddress?.addressLine2,
      addressLine3: currentFacility?.facilityAddress?.addressLine3,
    },
  });
  useEffect(() => {
    const getImage = async () => {
      if (f_id) {
        const response = await dispatch(getSelectedFacility(f_id));
        const coverResponse: any = await dispatch(
          getImageById(response?.payload?.data[0]?.coverPicId)
        );
        setCoverUrl(coverResponse?.payload);
        const coloredResponse: any = await dispatch(
          getImageById(response?.payload?.data[0]?.coloredLogoId)
        );
        setColoredUrl(coloredResponse?.payload);
        const whiteResponse: any = await dispatch(
          getImageById(response?.payload?.data[0]?.whiteLogoId)
        );
        setWhiteUrl(whiteResponse?.payload);
      }
    };
    getImage();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    reset({
      name: currentFacility?.facilityName,
      phoneNo: currentFacility?.phoneNo,
      facilityNumber: currentFacility?.facilityNumber,
      addressLine1: currentFacility?.facilityAddress?.addressLine1,
      addressLine2: currentFacility?.facilityAddress?.addressLine2,
      addressLine3: currentFacility?.facilityAddress?.addressLine3,
    });
  }, [currentFacility]);

  const uploadPicHandler = (idName: string) => {
    document.getElementById(idName)!.click();
  };

  const coverPicOnChange = (e: any) => {
    const fileObj = e.target.files;
    setCoverPic(Array.from(fileObj));
    const fileUrl = URL.createObjectURL(fileObj[0]);
    setCoverUrl(fileUrl);
    e.target.value = '';
  };
  const clearCoverPicData = () => {
    setCoverPic('');
    setCoverUrl('');
  };
  const coloredLogoOnChange = (e: any) => {
    const fileObj = e.target.files;
    setColoredLogo(Array.from(fileObj));
    const fileUrl = URL.createObjectURL(fileObj[0]);
    setColoredUrl(fileUrl);
    e.target.value = '';
  };
  const clearColoredLogoData = () => {
    setColoredLogo('');
    setColoredUrl('');
  };
  const whiteLogoOnChange = (e: any) => {
    const fileObj = e.target.files;
    setWhiteLogo(Array.from(fileObj));
    const fileUrl = URL.createObjectURL(fileObj[0]);
    setWhiteUrl(fileUrl);
    e.target.value = '';
  };
  const clearWhiteLogoData = () => {
    setWhiteLogo('');
    setWhiteUrl('');
  };
  const onSubmit = async (data: IProfileValidation) => {
    if (!currentFacility?.coverPicId && !coverPic) {
      toast.error('Facility Cover pic is required');
    } else if (!currentFacility?.whiteLogoId && !whiteLogo) {
      toast.error('Facility White logo is required');
    } else if (!currentFacility?.coloredLogoId && !coloredLogo) {
      toast.error('Facility Colored logo is required');
    } else {
      if (f_id && currentFacility) {
        dispatch(
          fetchProfileImageId(
            facilityProfilePayload(
              coverPic,
              coloredLogo,
              whiteLogo,
              data,
              f_id,
              currentFacility
            )
          )
        );
      }
    }
  };

  return (
    <div className="page-background facilityProfile">
      {isProcessingFacility ? (
        <PageLoader />
      ) : (
        <Form className="form width-100" onSubmit={handleSubmit(onSubmit)}>
          <div className="facilityProfile-container">
            <div className="facilityProfile-image-div">
              <div className="facilityProfile-image noImage-background">
                {!coverUrl && (
                  <>
                    <img
                      className="no-border-radius"
                      src={process.env.PUBLIC_URL + '/assets/noImageDark.svg'}
                      alt="no-image"
                      onClick={() => uploadPicHandler('cover_pic')}
                    />
                    <span
                      className="pointer"
                      onClick={() => uploadPicHandler('cover_pic')}
                    >
                      {translation('PROFILE_EDIT.UPLOAD_COVER_PIC')}
                    </span>
                  </>
                )}
                {coverUrl && (
                  <ImagePreview
                    url={coverUrl}
                    clearPreviewData={clearCoverPicData}
                    imgClass={'coverPic-preview'}
                    parentClass={'coverPic-preview-parent'}
                    closeIconClass={'previewClose-button'}
                  />
                )}
              </div>
              <input
                id="cover_pic"
                name="cover_pic"
                type="file"
                style={{ display: 'none' }}
                accept="image/jpeg, image/png"
                onChange={(e) => coverPicOnChange(e)}
              />
              <div className="upload-logo pointer">
                {!coloredUrl && (
                  <span>{translation('PROFILE_EDIT.UPLOAD_COLORED_LOGO')}</span>
                )}
                {coloredUrl && (
                  <ImagePreview
                    url={coloredUrl}
                    clearPreviewData={clearColoredLogoData}
                    imgClass={'coloredLogo-preview'}
                    parentClass={'coloredLogo-preview-parent'}
                    closeIconClass={'coloredClose-button-displayNone'}
                  />
                )}
                <div>
                  <img
                    onClick={() => uploadPicHandler('colored_logo')}
                    src={process.env.PUBLIC_URL + '/assets/uploadIcon.svg'}
                    alt="upload-icon"
                  />
                </div>
                <input
                  id="colored_logo"
                  type="file"
                  style={{ display: 'none' }}
                  accept="image/jpeg, image/png"
                  onChange={(e) => coloredLogoOnChange(e)}
                />
              </div>
              <div className="upload-logo pointer">
                {!whiteUrl && (
                  <span>{translation('PROFILE_EDIT.UPLOAD_WHITE_LOGO')}</span>
                )}
                {whiteUrl && (
                  <ImagePreview
                    url={whiteUrl}
                    clearPreviewData={clearWhiteLogoData}
                    imgClass={'coloredLogo-preview'}
                    parentClass={'coloredLogo-preview-parent'}
                    closeIconClass={'coloredClose-button-displayNone'}
                  />
                )}
                <div>
                  <img
                    onClick={() => uploadPicHandler('white_logo')}
                    src={process.env.PUBLIC_URL + '/assets/uploadIcon.svg'}
                    alt="upload-icon"
                  />
                </div>
                <input
                  id="white_logo"
                  type="file"
                  style={{ display: 'none' }}
                  accept="image/jpeg, image/png"
                  onChange={(e) => whiteLogoOnChange(e)}
                />
              </div>
              <div className="logo-instruction">
                <span>
                  <span>*</span>
                  {translation('PROFILE_EDIT.LOGO_INSTRUCTION_LINE1')}
                </span>
                <span>
                  {translation('PROFILE_EDIT.LOGO_INSTRUCTION_LINE2')}
                </span>
              </div>
            </div>
            <div className="facilityProfile-details">
              <div className="details-heading">
                <span>
                  {translation('PROFILE_EDIT.FACILITY_DETAILS_HEADING')}
                </span>
              </div>
              <div className="details-edit-facilityData">
                <div className="details-edit-flex-column editable-input-label">
                  <span>{translation('PROFILE_EDIT.FACILITY_NAME')}</span>
                  <FormGroup className="me-3">
                    <Controller
                      control={control}
                      name="name"
                      render={({ field }) => (
                        <Input
                          className="profile-form-input shadow-none"
                          placeholder={translation(
                            'PROFILE_EDIT.FACILITY_NAME_PLACEHOLDER'
                          )}
                          invalid={errors?.name ? true : false}
                          {...field}
                        />
                      )}
                    />
                    <FormFeedback className="validation-error">
                      {translation(`${errors.name?.message}`)}
                    </FormFeedback>
                  </FormGroup>
                </div>
                <div className="details-edit-flex-column editable-input-label">
                  <span>{translation('PROFILE_EDIT.PHONE_NUMBER')}</span>
                  <FormGroup className="me-3">
                    <Controller
                      control={control}
                      name="phoneNo"
                      render={({ field }) => (
                        <Input
                          placeholder={translation(
                            'PROFILE_EDIT.FACILITY_PHONE_PLACEHOLDER'
                          )}
                          className="profile-form-input shadow-none"
                          invalid={errors?.phoneNo ? true : false}
                          {...field}
                        />
                      )}
                    />
                    <FormFeedback className="validation-error">
                      {translation(`${errors.phoneNo?.message}`)}
                    </FormFeedback>
                  </FormGroup>
                </div>
                <div className="details-edit-flex-column">
                  <span>{translation('PROFILE_EDIT.FACILITY_NUMBER')}</span>
                  <FormGroup className="me-3">
                    <Controller
                      control={control}
                      name="facilityNumber"
                      render={({ field }) => (
                        <Input
                          placeholder={translation(
                            'FACILITY.FACILITY_NUMBER_PLACEHOLDER'
                          )}
                          className="profile-form-input form-edit-disabled shadow-none"
                          disabled
                          {...field}
                        />
                      )}
                    />
                  </FormGroup>
                </div>
              </div>
              <div className="details-border-line"></div>
              <div className="details-address">
                <span className="detailsLocation-heading">
                  {translation('PROFILE_EDIT.LOCATION_ADDRESS')}
                </span>
                <div className="details-edit-facilityData">
                  <div className="details-edit-flex-column">
                    <span>{translation('PROFILE_EDIT.APARTMENT_FLOOR')}</span>
                    <FormGroup className="me-3">
                      <Controller
                        control={control}
                        name="addressLine1"
                        render={({ field }) => (
                          <Input
                            placeholder={translation(
                              'FACILITY.ADDRESS_LINE1_PLACEHOLDER'
                            )}
                            className="profile-form-input form-edit-disabled shadow-none"
                            disabled
                            {...field}
                          />
                        )}
                      />
                    </FormGroup>
                  </div>
                  <div className="details-edit-flex-column">
                    <span>{translation('PROFILE_EDIT.STREET_NAME')}</span>
                    <FormGroup className="me-3">
                      <Controller
                        control={control}
                        name="addressLine2"
                        render={({ field }) => (
                          <Input
                            placeholder={translation(
                              'FACILITY.ADDRESS_LINE2_PLACEHOLDER'
                            )}
                            className="profile-form-input form-edit-disabled shadow-none"
                            disabled
                            {...field}
                          />
                        )}
                      />
                    </FormGroup>
                  </div>
                  <div className="details-edit-flex-column">
                    <span>State / Country / Zip</span>
                    <FormGroup className="me-3">
                      <Controller
                        control={control}
                        name="addressLine3"
                        render={({ field }) => (
                          <Input
                            placeholder="State / Country / Zip"
                            className="profile-form-input form-edit-disabled shadow-none"
                            disabled
                            {...field}
                          />
                        )}
                      />
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="profile-edit-button-div">
            <Button
              className="form-button shadow-none"
              type="submit"
              disabled={!activeInactiveChecker(currentFacility?.status)}
            >
              {isUpdatingProfile ? (
                <LoadingSpinner />
              ) : (
                translation('PROFILE_EDIT.UPDATE_BUTTON')
              )}
            </Button>
            <Button
              className="modal-cancel-button shadow-none"
              onClick={() => History.navigate(-1)}
            >
              {translation('PROFILE_EDIT.CANCEL_BUTTON')}
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};

export default FacilityProfileEdit;
