import React from 'react';
import ReactPaginate from 'react-paginate';
import './paginate.css';

export interface IPaginateClickData {
  selected: number;
}

const Paginate: React.FC<{
  totalPage: number;
  handlePageClick: (data: IPaginateClickData) => void;
}> = ({ totalPage, handlePageClick }) => {
  return (
    <div className="paginate">
      <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        pageCount={totalPage}
        marginPagesDisplayed={1}
        pageRangeDisplayed={1}
        onPageChange={handlePageClick}
        containerClassName={'pagination justify-content-center'}
        pageClassName={'page-item pageButton-bg border-radius'}
        pageLinkClassName={'page-link pageButton-bg border-radius shadow-none'}
        previousClassName={'page-item pageButton-bg border-radius margin-right'}
        previousLinkClassName={
          'page-link pageButton-bg border-radius shadow-none'
        }
        nextClassName={'page-item pageButton-bg border-radius margin-left'}
        nextLinkClassName={'page-link pageButton-bg border-radius shadow-none'}
        breakClassName={'page-item pageButton-bg border-radius'}
        breakLinkClassName={'page-link pageButton-bg border-radius'}
        activeClassName={'active pageButton-active'}
      />
    </div>
  );
};

export default Paginate;
