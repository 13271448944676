export interface IRatingsParams {
  sort: string;
  page: number;
  size: number;
  id?: string;
}
export const getRatingsRequestParams = (
  sort: string,
  page: number,
  size: number,
  id?: string
) => {
  const params = {} as IRatingsParams;
  if (sort) {
    params['sort'] = sort;
  }
  if (page) {
    params['page'] = page;
  }
  if (size) {
    params['size'] = size;
  }
  if (id) {
    params['id'] = id;
  }
  return params;
};
