import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams, useSearchParams } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { roleChecker } from '../../_helpers/checkerFunctions';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import {
  addNewFacilityAction,
  addNewFacilityActionWithImage,
  changePageFilter,
  getAllFacilities,
  // eslint-disable-next-line prettier/prettier
  getSelectedFacility,
} from '../../pages/loginPage/authSlice';
import {
  IFacility,
  IRoleIds,
  ISupportingBusinessList,
  // eslint-disable-next-line prettier/prettier
  IUser,
} from '../../pages/loginPage/loginInterface';
import {
  IAddFacilityForm,
  // eslint-disable-next-line prettier/prettier
  ILatLong,
} from '../../pages/registrationPage/registrationTypes';
import { addNewFacilityDataManipulator } from '../../pages/registrationPage/utilityFunctions';
import AddFacilityForm from '../addFacilityForm/addFacilityForm';
import ImageComp from '../imageComponents/imageComp';
import { ModalComponent } from '../modal';
import {
  openAddFacilityModalReducer,
  // eslint-disable-next-line prettier/prettier
  openQRModalReducer,
} from '../modal/modalSlice';
import QrCode from '../qrCode/qrCode';
import './profileDropdown.css';

const ProfileDropdown: React.FC<{
  profileDropdownToggleHandler: () => void;
  profileDropdownToggle: boolean;
  setProfileDropdownToggle: (data: boolean) => void;
  sidebarToggleOffHandler: () => void;
  handleLogout: () => void;
  currentFacilities?: IFacility[];
  activeFacility?: IFacility;
  supportingBusiness?: ISupportingBusinessList;
  loggedInUser: IUser;
  roleIds: IRoleIds;
}> = ({
  profileDropdownToggle,
  setProfileDropdownToggle,
  handleLogout,
  currentFacilities,
  supportingBusiness,
  loggedInUser,
  roleIds,
}) => {
  const { f_id } = useParams();
  const {
    currentFacility,
    isProcessingNewFacility,
    isFetchingNewFacilityImageId,
    isProcessingFacilities,
    facilityPaginate,
    filter,
  } = useAppSelector((state) => state.authentication);
  const { openQRModal, openAddFacilityModal } = useAppSelector(
    (state) => state.modal
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [facilityIdState, setFacilityIdState] = useState<any>('');
  const [allFacilities, setAllFacilities] = useState<IFacility[]>([]);
  const dispatch = useAppDispatch();
  const [translation] = useTranslation('common');

  useEffect(() => {
    if (f_id) {
      setFacilityIdState(f_id);
    } else if (searchParams.get('f_id') != null) {
      setFacilityIdState(searchParams.get('f_id'));
    }
    if (facilityIdState) {
      dispatch(getSelectedFacility(facilityIdState));
    }
  }, [facilityIdState, searchParams, f_id]);

  useEffect(() => {
    dispatch(getAllFacilities(filter));
  }, [filter.page]);

  const handleQRClickHandler = () => {
    dispatch(openQRModalReducer());
    setProfileDropdownToggle(false);
  };
  const handleNewFacility = () => {
    dispatch(openAddFacilityModalReducer());
    setProfileDropdownToggle(false);
  };
  const addNewFacilityModalHandler = (
    data: IAddFacilityForm,
    latLong: ILatLong,
    emailId: string,
    userId: string,
    disabledFeatures: string[] | void[],
    image?: any
  ) => {
    if (image) {
      dispatch(
        addNewFacilityActionWithImage(
          addNewFacilityDataManipulator(
            data,
            latLong,
            emailId,
            userId,
            disabledFeatures,
            image
          )
        )
      );
    } else {
      dispatch(
        addNewFacilityAction(
          addNewFacilityDataManipulator(
            data,
            latLong,
            emailId,
            userId,
            disabledFeatures
          )
        )
      );
    }
  };
  const makeArrayUnique = (inputArray: IFacility[]) => {
    const uniqueArray: IFacility[] = [];
    const idSet = new Set();

    inputArray.forEach((item) => {
      if (!idSet.has(item?.id)) {
        idSet.add(item?.id);
        uniqueArray.push(item);
      }
    });

    return uniqueArray;
  };
  const addNewRequestData = (purchaseRequests: IFacility[]) => {
    const updatedRequestArray = [...allFacilities, ...purchaseRequests];

    setAllFacilities(makeArrayUnique(updatedRequestArray));
  };
  useEffect(() => {
    addNewRequestData(currentFacilities as IFacility[]);
  }, [currentFacilities]);

  const changePageFilterHandler = (page: number) => {
    if (page + 1 <= facilityPaginate.totalPages) {
      dispatch(changePageFilter(page));
    }
  };
  function sortFacilitiesByName(facilities: IFacility[]) {
    return facilities?.sort((a, b) => {
      const nameA = a.facilityName.toLowerCase();
      const nameB = b.facilityName.toLowerCase();

      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  }
  return (
    <div
      className="profile-dropdown-container"
      // onClick={() =>
      //   !profileDropdownToggle && !openAddFacilityModal
      //     ? setProfileDropdownToggle(true)
      //     : setProfileDropdownToggle(false)
      // }
    >
      <div
        className={
          profileDropdownToggle ? 'navBar-overlay active' : 'navBar-overlay'
        }
        onClick={() => setProfileDropdownToggle(false)}
      >
        <div
          className="bellIcon-div profile-closeIcon"
          onClick={() =>
            profileDropdownToggle
              ? setProfileDropdownToggle(false)
              : setProfileDropdownToggle(true)
          }
        >
          <img
            src={process.env.PUBLIC_URL + '/assets/navClose.svg'}
            alt="hamberger"
          />
        </div>
      </div>
      <div
        className="bellIcon-div profileIcon-div pointer"
        onClick={() =>
          profileDropdownToggle
            ? setProfileDropdownToggle(false)
            : setProfileDropdownToggle(true)
        }
      >
        <img
          src={process.env.PUBLIC_URL + '/assets/profile.svg'}
          alt="profile-image"
        />
      </div>
      <div
        className={
          profileDropdownToggle
            ? 'profile-dropdown active profile-sidebar active'
            : 'profile-dropdown profile-sidebar'
        }
      >
        {profileDropdownToggle && (
          <>
            <span className="navBar-facilityProfileText">
              {roleChecker(
                loggedInUser?.roleId,
                roleIds.facilityAdminRoleId,
                roleIds.facilityUserRoleId
              ) && 'Facility Profile'}
              {roleChecker(
                loggedInUser?.roleId,
                roleIds.supportBusinessAdminRoleId
              ) && 'Business'}
            </span>
            <div className="profileText-bottom-shadow"></div>
          </>
        )}
        <div className="main-facility-div">
          <NavLink
            onClick={() => setProfileDropdownToggle(false)}
            to={
              roleChecker(
                loggedInUser?.roleId,
                roleIds.facilityAdminRoleId,
                roleIds.facilityUserRoleId
              )
                ? `facility-profile/${currentFacility?.id}`
                : roleChecker(
                    loggedInUser?.roleId,
                    roleIds.supportBusinessAdminRoleId
                  )
                ? 'business/business-profile'
                : '#'
            }
            className="main-facility-details"
          >
            <div className="main-facility-details-data">
              <span className="main-facility-name">
                {roleChecker(
                  loggedInUser?.roleId,
                  roleIds.facilityAdminRoleId,
                  roleIds.facilityUserRoleId
                )
                  ? currentFacility?.facilityName
                  : roleChecker(
                      loggedInUser?.roleId,
                      roleIds.supportBusinessAdminRoleId
                    )
                  ? supportingBusiness?.name && supportingBusiness.name
                  : null}
              </span>
              <span className="main-facility-email">
                {roleChecker(
                  loggedInUser?.roleId,
                  roleIds.facilityAdminRoleId,
                  roleIds.facilityUserRoleId
                )
                  ? currentFacility?.email && currentFacility.email
                  : roleChecker(
                      loggedInUser?.roleId,
                      roleIds.supportBusinessAdminRoleId
                    )
                  ? supportingBusiness?.email && supportingBusiness.email
                  : null}
              </span>
            </div>
          </NavLink>
          {roleChecker(
            loggedInUser?.roleId,
            roleIds.facilityAdminRoleId,
            roleIds.facilityUserRoleId
          ) && (
            <div
              className="bellIcon-div main-facility-details-qr pointer"
              onClick={handleQRClickHandler}
            >
              <img
                className="qr-icon"
                src={process.env.PUBLIC_URL + '/assets/qrIcon.svg'}
                alt="QR-icon"
              />
            </div>
          )}
        </div>
        {roleChecker(
          loggedInUser?.roleId,
          roleIds.facilityAdminRoleId,
          roleIds.facilityUserRoleId
        ) && (
          <div className="subFacility-scroll-div">
            {allFacilities?.map((facility: IFacility, index: number) => (
              <React.Fragment key={index}>
                {facility.id != facilityIdState && (
                  <NavLink
                    onClick={() => setProfileDropdownToggle(false)}
                    to={`facility-profile/${facility?.id}`}
                    className="sub-facility-details"
                  >
                    <ImageComp
                      imageId={facility?.coverPicId}
                      className="bellIcon-div background-white sub-facility-image"
                      noImageClass="bellIcon-div background-white"
                      divClass="bellIcon-div background-white"
                      alt=""
                      src="/assets/subfacility2Logo.svg"
                      loadingColor="primary"
                    />
                    <div className="sub-facility-data">
                      <span className="sub-facility-name">
                        {facility?.facilityName}
                      </span>
                      <span className="sub-facility-location">
                        {facility?.facilityAddress?.addressLine2}
                      </span>
                    </div>
                    <div className="border-bottom"></div>
                  </NavLink>
                )}
              </React.Fragment>
            ))}
            {filter.page + 1 < facilityPaginate.totalPages &&
            allFacilities?.length != 0 ? (
              <div className="pagination-down-arrow-container">
                {isProcessingFacilities ? (
                  <Spinner
                    className="loading-spinner"
                    color="#080ccb"
                  ></Spinner>
                ) : (
                  <img
                    onClick={() => changePageFilterHandler(filter.page + 1)}
                    src={process.env.PUBLIC_URL + '/assets/down-arrow.svg'}
                    alt="down-arrow"
                    className="pagination-down-arrow"
                  />
                )}
              </div>
            ) : null}
          </div>
        )}
        {roleChecker(loggedInUser?.roleId, roleIds.facilityAdminRoleId) && (
          <div className="newFacility pointer" onClick={handleNewFacility}>
            <span>{translation(`HEADER.ADD_NEW_FACILITY`)}</span>
          </div>
        )}
        <div className="logout pointer" onClick={handleLogout}>
          {translation(`HEADER.LOGOUT`)}
        </div>
      </div>
      {currentFacility
        ? openQRModal && (
            <ModalComponent
              isOpen={openQRModal}
              modalHeading={currentFacility?.facilityName}
            >
              <QrCode
                content={currentFacility}
                onCreate={null}
                isAdding={null}
              />
            </ModalComponent>
          )
        : null}
      {openAddFacilityModal && (
        <ModalComponent
          isOpen={openAddFacilityModal}
          modalHeading={translation(`HEADER.ADD_NEW_FACILITY`)}
        >
          <AddFacilityForm
            onCreate={addNewFacilityModalHandler}
            isAdding={isProcessingNewFacility || isFetchingNewFacilityImageId}
            setProfileDropdownToggle={setProfileDropdownToggle}
          />
        </ModalComponent>
      )}
    </div>
  );
};

export default ProfileDropdown;
