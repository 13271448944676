import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { useAppDispatch } from '../../app/hook';
import { IRejectBookingReason } from '../../pages/businessBookingPage/bookingsInterface';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import { closeModalReducer } from '../modal/modalSlice';
import { declineBookingSchema } from './declineBookingValidation';

const DeclineBookingComp: React.FC<{
  declineModalHandler: (data: string) => void;
  isDeclining: boolean;
}> = ({ declineModalHandler, isDeclining }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IRejectBookingReason>({
    resolver: yupResolver(declineBookingSchema),
  });
  const dispatch = useAppDispatch();
  const [translation] = useTranslation('common');
  const onSubmit = (reason: { rejectReason: string }) => {
    declineModalHandler(reason.rejectReason);
  };
  return (
    <div className="modal-div">
      <Form className="form width-100" onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Controller
            control={control}
            name="rejectReason"
            render={({ field }) => (
              <Input
                type="textarea"
                maxLength={250}
                row="10"
                className="textarea-input shadow-none"
                placeholder="Please mention the reason for Declining Booking"
                invalid={errors?.rejectReason ? true : false}
                {...field}
              />
            )}
          />
          <FormFeedback>
            {translation(`${errors?.rejectReason?.message}`)}
          </FormFeedback>
        </FormGroup>

        <div className="modal-button-group">
          <Button className="form-button shadow-none" type="submit">
            {isDeclining ? <LoadingSpinner /> : 'DECLINE'}
          </Button>
          <Button
            className="shadow-none modal-cancel-button"
            onClick={() => dispatch(closeModalReducer())}
          >
            {translation('BUTTONS.CANCEL')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default DeclineBookingComp;
