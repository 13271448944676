import React from 'react';
import { useTranslation } from 'react-i18next';

export interface IDropdownList {
  type: string;
  tag: string;
  label?: string;
}

const DinginConstants = {
  dailyType: 'DAILY',
  repeatType: 'REPEAT',
};

const DropdownComp: React.FC<{
  compKey: string;
  optionClickParent: (data: IDropdownList) => void;
}> = ({ compKey, optionClickParent }) => {
  const keyTag = compKey.split('-')[0];
  const [translation] = useTranslation('common');

  const dailyList: IDropdownList[] = [
    {
      type: DinginConstants.dailyType,
      tag: 'Add/Edit List',
      label: translation(`RESTAURANT.ADD/EDIT`),
    },
    {
      type: DinginConstants.dailyType,
      tag: 'Remove',
      label: translation(`RESTAURANT.REMOVE`),
    },
  ];
  const repeatList: IDropdownList[] = [
    {
      type: DinginConstants.repeatType,
      tag: 'Add to Schedule',
      label: translation(`RESTAURANT.ADD_TO_SCHEDULE`),
    },
    {
      type: DinginConstants.repeatType,
      tag: 'Add/Edit List',
      label: translation(`RESTAURANT.ADD/EDIT`),
    },
    {
      type: DinginConstants.repeatType,
      tag: 'Remove',
      label: translation(`RESTAURANT.REMOVE`),
    },
  ];

  const optionClickHandler = (item: IDropdownList) => {
    optionClickParent(item);
  };
  return (
    <div className="diningDropdown-wrap">
      <ul className="diningDropdown">
        {(keyTag === 'Daily' ? dailyList : repeatList).map(
          (item: IDropdownList, index: number) => (
            <li
              key={index}
              id="dropdown-comp"
              className="pointer"
              onClick={() => optionClickHandler(item)}
            >
              {item.label}
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default DropdownComp;
