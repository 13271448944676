/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { formDataConverter } from '../../_helpers/formDataConverter';
import { RootState } from '../../app/store';
import {
  closeModalReducer,
  openDeleteModalReducer,
  openDiningDailyAddEditModalReducer,
  openDiningRepeatAddEditModalReducer,
  openEditModalReducer,
  openMealDataModalReducer,
} from '../../components/modal/modalSlice';
import { env } from '../../config/env';
import { getImageDetails } from '../../services/amenitiesService';
import {
  addNewMeals,
  addRestaurant,
  addToSchedule,
  deleteCategoryById,
  deleteItem,
  deleteMealType,
  diningItems,
  editCategory,
  getCategories,
  getMealsUsingDate,
  getMealsUsingWeek,
  getRestaurant,
  getRestaurants,
  postDiningOrderChange,
  postnewCategory,
  removeRestaurant,
  removeUpdateRepeatList,
  skipApi,
  updateDailyItems,
  updateRepeatItems,
  updateRestaurant,
} from '../../services/diningService';
import { getImageId } from '../../services/facilityService';
import { IImageDetails } from '../amenitiesPage/amenitiesInterface';
import { getMealsRequestParams } from '../usersPage/userParams';
import { IUserPaginateData } from '../usersPage/usersInterface';
import {
  IAddNewDiningMealsParams,
  IAddRestaurantPayload,
  IAddToScheduleData,
  IDeleteMealsPayload,
  IDeleteRestaurantPayload,
  IdiningOrderPayload,
  IGetMealsByDate,
  IGetMealsByWeeks,
  IMealItems,
  IMealsByWeekData,
  IMealsPerDay,
  IRemoveWeeks,
  IRestaurantFilter,
  IRestaurantModal,
  IRestaurantPaginate,
  IRestaurants,
  ISkipMealsData,
  IUpdateDailyDiningMealsPayload,
  IUpdateRepeatDiningMealsParams,
  IUpdateRestaurantPayload,
} from './diningInterface';
import { getDiningRequestParams, IRestaurantParams } from './diningParams';
import { filterChange } from './utilityFunction';

export interface IDining {
  isFetchingRestaurants: boolean;
  restaurantPaginate: IRestaurantPaginate;
  restaurants: IRestaurants[];
  selectedRestaurant: IRestaurants;
  isAddingRestaurant: boolean;
  isAddingRestaurantImage: boolean;
  isUpdatingRestaurant: boolean;
  isDeletingRestaurant: boolean;
  isFetchingSelectedRestaurant: boolean;
  toDeleteRestaurant: IRestaurants;
  filter: IRestaurantFilter;
  mealsFilter: IRestaurantFilter;
  categoryFilter: IRestaurantFilter;
  isFetchingImageDetails: boolean;
  imageDetails: IImageDetails;
  isFetchingImage: boolean;
  isFetchingMealsUsingDate: boolean;
  mealsPerDay: IMealsPerDay[];
  isFetchingMealItems: boolean;
  mealItemsById: IMealItems[];
  isFetchingMealsByWeek: boolean;
  mealsByWeek: IMealsByWeekData[];
  isAddingDiningMeal: boolean;
  isFetchingDailyMealItems: boolean;
  isFetchingRepeatMealItems: boolean;
  dailyMealItems: IMealItems[];
  repeatMealItems: IMealItems[];
  deleteArray: string[];
  isUpdatingDailyMealItems: boolean;
  isUpdatingRepeatMealItems: boolean;
  isDeletingDiningItems: boolean;
  accordionRefreshTrigger: boolean;
  isAddingToSchedule: boolean;
  isDeletingMeal: boolean;
  selectedDay: string;
  isRemovingRepeatingMeal: boolean;
  isCreatingNewCategory: boolean;
  categoryRefreshTrigger: boolean;
  isFetchingCategories: boolean;
  categories: { categoryName: string; id: number }[];
  isUpdatingCategory: boolean;
  isDeletingCategory: boolean;
  mealsPerDayPaginate: IUserPaginateData;
  categoriesPaginate: IUserPaginateData;
}
const initialState: IDining = {
  isFetchingRestaurants: false,
  restaurantPaginate: {
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
    size: 0,
    number: 0,
  },
  restaurants: [],
  selectedRestaurant: {} as IRestaurants,
  isAddingRestaurant: false,
  isAddingRestaurantImage: false,
  isUpdatingRestaurant: false,
  isDeletingRestaurant: false,
  isFetchingSelectedRestaurant: false,
  toDeleteRestaurant: {} as IRestaurants,
  filter: {
    sort: 'createdAt,Desc',
    page: 0,
    size: 10,
  },
  mealsFilter: {
    sort: 'position,asc',
    page: 0,
    size: 10,
  },
  categoryFilter: {
    sort: 'createdAt,Desc',
    page: 0,
    size: 10,
  },
  isFetchingImageDetails: false,
  imageDetails: {} as IImageDetails,
  isFetchingImage: false,
  isFetchingMealsUsingDate: false,
  mealsPerDay: [],
  isFetchingMealItems: false,
  mealItemsById: [],
  isFetchingMealsByWeek: false,
  mealsByWeek: [],
  isAddingDiningMeal: false,
  isFetchingDailyMealItems: false,
  isFetchingRepeatMealItems: false,
  dailyMealItems: [],
  repeatMealItems: [],
  deleteArray: [],
  isUpdatingDailyMealItems: false,
  isUpdatingRepeatMealItems: false,
  isDeletingDiningItems: false,
  accordionRefreshTrigger: false,
  isAddingToSchedule: false,
  isDeletingMeal: false,
  selectedDay: '',
  isRemovingRepeatingMeal: false,
  isCreatingNewCategory: false,
  categoryRefreshTrigger: false,
  isFetchingCategories: false,
  categories: [],
  isUpdatingCategory: false,
  isDeletingCategory: false,
  mealsPerDayPaginate: {
    pageNumber: 0,
    pageSize: 0,
    numberOfElements: 0,
    totalPages: 0,
    totalElements: 0,
    first: false,
  },
  categoriesPaginate: {
    pageNumber: 0,
    pageSize: 0,
    numberOfElements: 0,
    totalPages: 0,
    totalElements: 0,
    first: false,
  },
};

export const diningSlice = createSlice({
  name: 'dining',
  initialState,
  reducers: {
    resetDiningState: () => {
      return initialState;
    },
    addFilterPage: (state, action) => {
      state.filter.page = action.payload;
    },
    restaurantFilterPageReset: (state) => {
      state.filter.page = 0;
    },
    selectedRestaurantReducer: (state, action) => {
      state.selectedRestaurant = action.payload;
    },
    clearMealItemsReducer: (state) => {
      state.mealItemsById = [];
    },
    setDeleteArrayReducer: (state, action) => {
      state.deleteArray = action.payload;
    },
    clearDeleteArrayReducer: (state) => {
      state.deleteArray = [];
    },
    accordionRefreshTriggerReducer: (state, action) => {
      state.accordionRefreshTrigger = action.payload;
    },
    addSelectedDay: (state, action) => {
      state.selectedDay = action.payload;
    },
    addTranslatedData: (state, action) => {
      state.restaurants = action.payload;
    },
    addFilterMealsPage: (state, action) => {
      state.mealsFilter.page = action.payload;
    },
    addFilterCategoryList: (state, action) => {
      state.categoryFilter.page = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllRestaurants.pending, (state) => {
        state.isFetchingRestaurants = true;
      })
      .addCase(fetchAllRestaurants.fulfilled, (state, action) => {
        state.isFetchingRestaurants = false;
        state.restaurantPaginate = action.payload.data[0];
        state.restaurants = action.payload?.data[0]?.data;
      })
      .addCase(
        fetchAllRestaurants.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingRestaurants = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(fetchRestaurantImageId_Add.pending, (state) => {
        state.isAddingRestaurantImage = true;
      })
      .addCase(fetchRestaurantImageId_Add.fulfilled, (state) => {
        state.isAddingRestaurantImage = false;
      })
      .addCase(fetchRestaurantImageId_Add.rejected, (state) => {
        state.isAddingRestaurantImage = false;
      })
      .addCase(addNewRestaurant.pending, (state) => {
        state.isAddingRestaurant = true;
      })
      .addCase(addNewRestaurant.fulfilled, (state, action) => {
        state.isAddingRestaurant = false;

        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message?.slice(1)}`
        );
      })
      .addCase(
        addNewRestaurant.rejected,
        (state, action: PayloadAction<any>) => {
          state.isAddingRestaurant = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(fetchRestaurantById.pending, (state) => {
        state.isFetchingSelectedRestaurant = true;
      })
      .addCase(fetchRestaurantById.fulfilled, (state, action) => {
        state.isFetchingSelectedRestaurant = false;
        state.toDeleteRestaurant = action.payload?.data[0];
      })
      .addCase(
        fetchRestaurantById.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingSelectedRestaurant = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(deleteRestaurantById.pending, (state) => {
        state.isDeletingRestaurant = true;
      })
      .addCase(deleteRestaurantById.fulfilled, (state, action) => {
        state.isDeletingRestaurant = false;

        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(
        deleteRestaurantById.rejected,
        (state, action: PayloadAction<any>) => {
          state.isDeletingRestaurant = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(updateRestaurantById.pending, (state) => {
        state.isUpdatingRestaurant = true;
      })
      .addCase(updateRestaurantById.fulfilled, (state, action) => {
        state.isUpdatingRestaurant = false;

        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(
        updateRestaurantById.rejected,
        (state, action: PayloadAction<any>) => {
          state.isUpdatingRestaurant = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(fetchRestaurantImageDetails.pending, (state) => {
        state.isFetchingImageDetails = true;
        state.imageDetails = { id: '', originalName: '' };
      })
      .addCase(fetchRestaurantImageDetails.fulfilled, (state, action) => {
        state.isFetchingImageDetails = false;
        state.imageDetails = action.payload?.data[0];
      })
      .addCase(
        fetchRestaurantImageDetails.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingImageDetails = false;
        }
      )
      .addCase(fetchRestaurantImageId_Update.pending, (state) => {
        state.isFetchingImage = true;
      })
      .addCase(fetchRestaurantImageId_Update.fulfilled, (state, action) => {
        state.isFetchingImage = false;
      })
      .addCase(
        fetchRestaurantImageId_Update.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingImage = false;
        }
      )
      .addCase(fetchDiningMealUsingDate.pending, (state) => {
        state.isFetchingMealsUsingDate = true;
      })
      .addCase(fetchDiningMealUsingDate.fulfilled, (state, action) => {
        state.isFetchingMealsUsingDate = false;
        state.mealsPerDay = action.payload?.data[0]?.data;
        state.mealsPerDayPaginate = action.payload?.data[0];
      })
      .addCase(
        fetchDiningMealUsingDate.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingMealsUsingDate = false;
          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload?.slice(1)}`
          );
        }
      )
      .addCase(fetchMealItemsUsingMealId.pending, (state) => {
        state.isFetchingMealItems = true;
      })
      .addCase(fetchMealItemsUsingMealId.fulfilled, (state, action) => {
        state.isFetchingMealItems = false;
        state.mealItemsById = action.payload?.data[0]?.data;
      })
      .addCase(
        fetchMealItemsUsingMealId.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingMealItems = false;
          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload?.slice(1)}`
          );
        }
      )
      .addCase(fectchDiningMealUsingWeeks.pending, (state) => {
        state.isFetchingMealsByWeek = true;
      })
      .addCase(fectchDiningMealUsingWeeks.fulfilled, (state, action) => {
        state.isFetchingMealsByWeek = false;
        state.mealsByWeek = action.payload?.data[0]?.data;
      })
      .addCase(
        fectchDiningMealUsingWeeks.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingMealsByWeek = false;
          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload?.slice(1)}`
          );
        }
      )
      .addCase(addNewDiningMeals.pending, (state) => {
        state.isAddingDiningMeal = true;
      })
      .addCase(addNewDiningMeals.fulfilled, (state, action) => {
        state.isAddingDiningMeal = false;
        state.accordionRefreshTrigger = true;
        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(
        addNewDiningMeals.rejected,
        (state, action: PayloadAction<any>) => {
          state.isAddingDiningMeal = false;
          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload?.slice(1)}`
          );
        }
      )
      .addCase(fetchDailyMealItemsUsingMealId.pending, (state) => {
        state.isFetchingDailyMealItems = true;
      })
      .addCase(fetchDailyMealItemsUsingMealId.fulfilled, (state, action) => {
        state.isFetchingDailyMealItems = false;
        state.dailyMealItems = action.payload?.data[0]?.data;
      })
      .addCase(
        fetchDailyMealItemsUsingMealId.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingDailyMealItems = false;
          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload?.slice(1)}`
          );
        }
      )
      .addCase(fetchRepeatMealItemsUsingMealId.pending, (state) => {
        state.isFetchingRepeatMealItems = true;
      })
      .addCase(fetchRepeatMealItemsUsingMealId.fulfilled, (state, action) => {
        state.isFetchingRepeatMealItems = false;
        state.repeatMealItems = action.payload?.data[0]?.data;
      })
      .addCase(
        fetchRepeatMealItemsUsingMealId.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingRepeatMealItems = false;
          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload?.slice(1)}`
          );
        }
      )
      .addCase(updateDailyMealItems.pending, (state) => {
        state.isUpdatingDailyMealItems = true;
      })
      .addCase(updateDailyMealItems.fulfilled, (state, action) => {
        state.isUpdatingDailyMealItems = false;

        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(
        updateDailyMealItems.rejected,
        (state, action: PayloadAction<any>) => {
          state.isUpdatingDailyMealItems = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(updateRepeatMealItems.pending, (state) => {
        state.isUpdatingRepeatMealItems = true;
      })
      .addCase(updateRepeatMealItems.fulfilled, (state, action) => {
        state.isUpdatingRepeatMealItems = false;
        state.accordionRefreshTrigger = true;
        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(
        updateRepeatMealItems.rejected,
        (state, action: PayloadAction<any>) => {
          state.isUpdatingRepeatMealItems = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(deleteDiningItemById.pending, (state) => {
        state.isDeletingDiningItems = true;
      })
      .addCase(deleteDiningItemById.fulfilled, (state, action) => {
        state.isDeletingDiningItems = false;
      })
      .addCase(
        deleteDiningItemById.rejected,
        (state, action: PayloadAction<any>) => {
          state.isDeletingDiningItems = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(addToScheduleAction.pending, (state) => {
        state.isAddingToSchedule = true;
      })
      .addCase(addToScheduleAction.fulfilled, (state, action) => {
        state.isAddingToSchedule = false;
        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(
        addToScheduleAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.isAddingToSchedule = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(deleteMealsAction.pending, (state) => {
        state.isDeletingMeal = true;
      })
      .addCase(deleteMealsAction.fulfilled, (state, action) => {
        state.isDeletingMeal = false;
        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(
        deleteMealsAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.isDeletingMeal = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(skipDailymealsApi.pending, (state) => {
        state.isDeletingMeal = true;
      })
      .addCase(skipDailymealsApi.fulfilled, (state, action) => {
        state.isDeletingMeal = false;
        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(
        skipDailymealsApi.rejected,
        (state, action: PayloadAction<any>) => {
          state.isDeletingMeal = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(removeMultipleRepeatMealsData.pending, (state) => {
        state.isRemovingRepeatingMeal = true;
      })
      .addCase(removeMultipleRepeatMealsData.fulfilled, (state, action) => {
        state.isRemovingRepeatingMeal = false;
        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(
        removeMultipleRepeatMealsData.rejected,
        (state, action: PayloadAction<any>) => {
          state.isRemovingRepeatingMeal = false;
          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )

      .addCase(createCategory.pending, (state) => {
        state.isCreatingNewCategory = true;
        state.categoryRefreshTrigger = false;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.isCreatingNewCategory = false;
        state.categoryRefreshTrigger = !state.categoryRefreshTrigger;
        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(createCategory.rejected, (state, action: PayloadAction<any>) => {
        state.isCreatingNewCategory = false;

        toast.error(
          `${action.payload
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload.slice(1)}`
        );
      })

      .addCase(deleteCategory.pending, (state) => {
        state.isDeletingCategory = true;
        state.categoryRefreshTrigger = false;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.isDeletingCategory = false;
        state.categoryRefreshTrigger = !state.categoryRefreshTrigger;
        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(deleteCategory.rejected, (state, action: PayloadAction<any>) => {
        state.isDeletingCategory = false;

        toast.error(
          `${action.payload
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload.slice(1)}`
        );
      })

      .addCase(getAllCategoryById.pending, (state) => {
        state.isFetchingCategories = true;
      })
      .addCase(getAllCategoryById.fulfilled, (state, action) => {
        state.isFetchingCategories = false;
        state.categories = action.payload?.data[0]?.data;
        state.categoriesPaginate = action.payload?.data[0];
      })
      .addCase(
        getAllCategoryById.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingCategories = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(updateCategory.pending, (state) => {
        state.isUpdatingCategory = true;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.isUpdatingCategory = false;
        state.categoryRefreshTrigger = !state.categoryRefreshTrigger;
        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(updateCategory.rejected, (state, action: PayloadAction<any>) => {
        state.isUpdatingCategory = false;

        toast.error(
          `${action.payload
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload.slice(1)}`
        );
      });
  },
});

// -----new code------------------

export const getAllCategoryById = createAsyncThunk(
  'authentication/getAllCategoryById',
  async (id: string, { rejectWithValue, getState }) => {
    try {
      const { dining } = getState() as { dining: IDining };

      const params = getMealsRequestParams(
        dining.categoryFilter.page,
        dining.categoryFilter.size
      );
      const response = await getCategories(id, params);
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const fetchAllRestaurants = createAsyncThunk(
  'dining/fetchAllRestaurants',
  async (data: IRestaurantParams, { rejectWithValue }) => {
    try {
      const params = getDiningRequestParams(
        data.sort,
        data.page,
        data.size,
        data.facilityIds
      );
      const response = await getRestaurants(params);
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const addNewRestaurant = createAsyncThunk(
  'dining/addNewRestaurants',
  async (
    data: IAddRestaurantPayload,
    { rejectWithValue, dispatch, getState }
  ) => {
    const { dining } = getState() as { dining: IDining };
    try {
      const response = await addRestaurant(data);
      dispatch(closeModalReducer());
      dispatch(
        fetchAllRestaurants(filterChange(dining.filter, data.facilityId))
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const fetchRestaurantImageId_Add = createAsyncThunk(
  'dining/fetchRestaurantImageId_Add',
  async (data: IAddRestaurantPayload, { rejectWithValue, dispatch }) => {
    try {
      const menuImgResponse: string[] = [];
      let restaurantImgResponse = '';
      if (data?.image) {
        const imageResponse = await getImageId(formDataConverter(data.image));
        restaurantImgResponse = imageResponse?.data[0]?.id;
      }
      if (data?.menuImages?.length) {
        await Promise.all(
          data?.menuImages?.map(async (obj: File) => {
            const menuResponse = await getImageId(formDataConverter(obj));
            menuImgResponse.push(menuResponse?.data[0]?.id);
          })
        );
      }
      const response = dispatch(
        addNewRestaurant({
          restaurantName: data?.restaurantName,
          startingTime: data?.startingTime,
          endTime: data?.endTime,
          facilityId: data?.facilityId,
          imageId: restaurantImgResponse ? restaurantImgResponse : '',
          menuIds: menuImgResponse?.length ? menuImgResponse : [],
        })
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const fetchRestaurantImageId_Update = createAsyncThunk(
  'dining/fetchRestaurantImageId_Update',
  async (data: IUpdateRestaurantPayload, { rejectWithValue, dispatch }) => {
    try {
      const imageResponse = await getImageId(formDataConverter(data.image));
      const response = dispatch(
        updateRestaurantById({ ...data, imageId: imageResponse?.data[0]?.id })
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const fetchRestaurantImageDetails = createAsyncThunk(
  'dining/fetchRestaurantImageDetails',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getImageDetails(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchRestaurantById = createAsyncThunk(
  'dining/fetchRestaurantById',
  async (data: IRestaurantModal, { rejectWithValue, dispatch }) => {
    try {
      const response = await getRestaurant(data.id);
      if (response?.data[0]?.menuIds?.length) {
        const imageDetailsArray = await Promise.all(
          response?.data[0]?.menuIds?.map(async (id: string) => {
            const data = await getImageDetails(id);
            const array1 = [];

            array1.push({
              fileType: data.data[0].mimeType,
              fileName: data.data[0].originalName,
              file: `${env.development.BASE_URL}/files/${id}`,
            });
            return array1;
          })
        );
        const result = imageDetailsArray.flat().reduce((acc, item) => {
          acc.push(item);
          return acc;
        }, []);

        response.data[0] = { ...response?.data[0], menuIds: result };
      }
      if (response?.data[0]?.imageId) {
        dispatch(fetchRestaurantImageDetails(response?.data[0]?.imageId));
      }
      if (data.for === 'Remove') {
        dispatch(openDeleteModalReducer());
      } else if (data.for === 'Edit Restaurant') {
        dispatch(openEditModalReducer());
      }
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const deleteRestaurantById = createAsyncThunk(
  'dining/deleteRestaurantById',
  async (
    data: IDeleteRestaurantPayload,
    { rejectWithValue, dispatch, getState }
  ) => {
    const { dining } = getState() as { dining: IDining };
    try {
      const response = await removeRestaurant(data.id);
      dispatch(closeModalReducer());
      dispatch(
        fetchAllRestaurants(filterChange(dining.filter, data?.facilityId))
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const updateRestaurantById = createAsyncThunk(
  'dining/updateRestaurantById',
  async (
    data: IUpdateRestaurantPayload,
    { rejectWithValue, dispatch, getState }
  ) => {
    const { dining } = getState() as { dining: IDining };
    try {
      const fixedValues = {
        id: data?.id,
        restaurantName: data?.restaurantName,
        startingTime: data?.startingTime,
        endTime: data?.endTime,
        facilityId: data?.facilityId,
        imageId: data?.imageId,
      };
      const menuImgResponse: string[] = [];
      let payload = {} as IUpdateRestaurantPayload;
      if (data?.menuImages?.length) {
        await Promise.all(
          data?.menuImages?.map(async (obj: File) => {
            const menuResponse = await getImageId(formDataConverter(obj));

            menuImgResponse.push(menuResponse?.data[0]?.id);
          })
        );
      }
      if (data?.menuImages?.length && menuImgResponse?.length) {
        if (data?.existingMenuIds?.length) {
          payload = {
            ...fixedValues,
            menuIds: [...data.existingMenuIds, ...menuImgResponse],
          };
        } else {
          payload = {
            ...fixedValues,
            menuIds: [...menuImgResponse],
          };
        }
      } else {
        if (data?.existingMenuIds?.length) {
          payload = {
            ...fixedValues,
            menuIds: [...data.existingMenuIds],
          };
        } else {
          payload = {
            ...fixedValues,
            menuIds: [],
          };
        }
      }
      const response = await updateRestaurant(payload);
      dispatch(closeModalReducer());
      dispatch(
        fetchAllRestaurants(filterChange(dining.filter, data?.facilityId))
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const fetchDiningMealUsingDate = createAsyncThunk(
  'dining/fetchDiningMealUsingDate',
  async (params: IGetMealsByDate, { rejectWithValue, getState }) => {
    try {
      const { dining } = getState() as { dining: IDining };
      const filter = getMealsRequestParams(
        dining.mealsFilter.page,
        dining.mealsFilter.size
      );
      const response = await getMealsUsingDate(params, filter);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchMealItemsUsingMealId = createAsyncThunk(
  'dining/fetchMealItemsUsingMealId',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await diningItems(id);
      dispatch(openMealDataModalReducer());
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const fetchDailyMealItemsUsingMealId = createAsyncThunk(
  'dining/fetchDailyMealItemsUsingMealId',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await diningItems(id);
      dispatch(openDiningDailyAddEditModalReducer());
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const fetchRepeatMealItemsUsingMealId = createAsyncThunk(
  'dining/fetchRepeatMealItemsUsingMealId',
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await diningItems(id);
      dispatch(openDiningRepeatAddEditModalReducer());
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fectchDiningMealUsingWeeks = createAsyncThunk(
  'dining/fectchDiningMealUsingWeeks',
  async (params: IGetMealsByWeeks, { rejectWithValue, dispatch }) => {
    try {
      const response = await getMealsUsingWeek(params);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const addNewDiningMeals = createAsyncThunk(
  'dining/addNewDiningMeals',
  async (data: IAddNewDiningMealsParams, { rejectWithValue, dispatch }) => {
    try {
      const response = await addNewMeals(data.item);
      if (data?.date) {
        dispatch(
          fetchDiningMealUsingDate({
            restaurantId: data.item.restaurantId,
            date: data.date,
          })
        );
      }
      dispatch(closeModalReducer());
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateDailyMealItems = createAsyncThunk(
  'dining/updateDailyMealItems',
  async (
    data: IUpdateDailyDiningMealsPayload,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await updateDailyItems(data);
      dispatch(
        fetchDiningMealUsingDate({
          restaurantId: data.restaurantId,
          date: data.date,
        })
      );
      dispatch(closeModalReducer());
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const updateRepeatMealItems = createAsyncThunk(
  'dining/updateRepeatMealItems',
  async (
    data: IUpdateRepeatDiningMealsParams,
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await updateRepeatItems(data.item);
      if (data?.date) {
        dispatch(
          fetchDiningMealUsingDate({
            restaurantId: data.item.restaurantId,
            date: data.date,
          })
        );
      }
      // dispatch(closeModalReducer());
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const createCategory = createAsyncThunk(
  'dining/createCategory',
  async (
    data: {
      restaurantId: string;
      categoryName: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await postnewCategory(data);

      dispatch(closeModalReducer());
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const deleteCategory = createAsyncThunk(
  'dining/deleteCategory',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteCategoryById(id);
      dispatch(addFilterCategoryList(0));
      dispatch(closeModalReducer());
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const updateCategory = createAsyncThunk(
  'dining/updateCategory',
  async (
    data: {
      id: number;
      categoryName: string;
    },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await editCategory(data);
      dispatch(addFilterCategoryList(0));
      dispatch(closeModalReducer());
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const removeMultipleRepeatMealsData = createAsyncThunk(
  'dining/removeMultipleRepeatMealsData',
  async (data: IRemoveWeeks, { rejectWithValue, dispatch }) => {
    try {
      const response = await removeUpdateRepeatList(data);
      if (data?.weekdaysList?.length) {
        dispatch(accordionRefreshTriggerReducer(true));
      }
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteDiningItemById = createAsyncThunk(
  'dining/deleteDiningItemById',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await deleteItem(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const addToScheduleAction = createAsyncThunk(
  'dining/addToScheduleAction',
  async (data: IAddToScheduleData, { rejectWithValue, dispatch }) => {
    try {
      const diningResponse = await diningItems(data.id);
      const response = await addToSchedule({
        diningItems: diningResponse?.data[0].data,
        ...data.item,
      });
      if (data?.item?.date) {
        dispatch(
          fetchDiningMealUsingDate({
            restaurantId: data.item.restaurantId,
            date: data.item.date,
          })
        );
      }
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const deleteMealsAction = createAsyncThunk(
  'dining/deleteMealsAction',
  async (data: IDeleteMealsPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteMealType(data.id);
      if (data?.date) {
        dispatch(
          fetchDiningMealUsingDate({
            restaurantId: data.restaurantId,
            date: data.date,
          })
        );
      }
      if (data?.weeks?.length) {
        dispatch(accordionRefreshTriggerReducer(true));
      }
      dispatch(closeModalReducer());
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const skipDailymealsApi = createAsyncThunk(
  'dining/skipDailymealsApi',
  async (data: ISkipMealsData, { rejectWithValue, dispatch }) => {
    try {
      const response = await skipApi(data);
      if (data?.date) {
        dispatch(
          fetchDiningMealUsingDate({
            restaurantId: data.restaurantId,
            date: data.date,
          })
        );
      }
      if (data?.weeks?.length) {
        dispatch(accordionRefreshTriggerReducer(true));
      }
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const changeDiningOrder = createAsyncThunk(
  'dining/changeDiningOrder',
  async (data: IdiningOrderPayload, { rejectWithValue }) => {
    try {
      const response = await postDiningOrderChange(data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const {
  resetDiningState,
  addFilterCategoryList,
  selectedRestaurantReducer,
  restaurantFilterPageReset,
  addFilterPage,
  clearMealItemsReducer,
  setDeleteArrayReducer,
  clearDeleteArrayReducer,
  accordionRefreshTriggerReducer,
  addSelectedDay,
  addFilterMealsPage,
  addTranslatedData,
} = diningSlice.actions;
export const selectDining = (state: RootState) => state.dining;
export const diningReducer = diningSlice.reducer;
