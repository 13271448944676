import React from 'react';
import { convert24hourTo12HourFormat } from '../../pages/activitiesPage/utilityFunctions';

const TimeComponent: React.FC<{
  startTime: string;
  endTime: string;
}> = ({ startTime, endTime }) => {
  const [firstTime, secondTime] =
    startTime <= endTime ? [startTime, endTime] : [endTime, startTime];

  return (
    <div className="restaurant-time">
      <span>{convert24hourTo12HourFormat(firstTime)}</span>
      <span>&nbsp;-&nbsp;</span>
      <span>{convert24hourTo12HourFormat(secondTime)}</span>
    </div>
  );
};

export default TimeComponent;
