/* eslint-disable prettier/prettier */
import {
  IAddNewDiningMealsPayload,
  IAddRestaurantPayload,
  IGetMealsByDate,
  IGetMealsByWeeks,
  IRemoveWeeks,
  ISkipMealsData,
  IUpdateDailyDiningMealsPayload,
  IUpdateRepeatDiningMealsPayload,
  IUpdateRestaurantPayload,
  IdiningOrderPayload,
} from '../pages/diningPage/diningInterface';
import { IRestaurantParams } from '../pages/diningPage/diningParams';
import { IMealsParams } from '../pages/usersPage/userParams';
import { deleteApi, get, patch, post, put } from './apiService';

export const addNewMeals = async (data: IAddNewDiningMealsPayload) => {
  return await post(`/dining-meals`, data);
};
export const addRestaurant = async (data: IAddRestaurantPayload) => {
  return await post(`/dining-restaurant`, data);
};
export const addToSchedule = async (data: IAddNewDiningMealsPayload) => {
  return await post(`/dining-meals`, data);
};
export const skipApi = async (data: ISkipMealsData) => {
  return await post(`/dining-meals/${data?.id}/skip/${data?.skipDate}`, data);
};
export const deleteItem = async (id: string) => {
  return await deleteApi(`/dining-items/${id}`);
};
export const deleteMealType = async (id: string) => {
  return await deleteApi(`/dining-meals/${id}`);
};
export const diningItems = async (mealId: string) => {
  return await get(`/dining-items?mealId=${mealId}`);
};
export const getMealsUsingDate = async (
  params: IGetMealsByDate,
  filter: IMealsParams
) => {
  return await get(`/dining-meals?sort=position,asc&`, {
    ...params,
    ...filter,
  });
};
export const getMealsUsingWeek = async (params: IGetMealsByWeeks) => {
  return await get(`/dining-meals`, params);
};
export const getRestaurant = async (id: string) => {
  return await get(`/dining-restaurant/${id}`);
};
export const getRestaurants = async (params: IRestaurantParams) => {
  return await get(`/dining-restaurant`, params);
};
export const getCategories = async (id: string, params: IMealsParams) => {
  return await get(`/dining-meal-categories?restaurantId=` + id, params);
};
export const removeRestaurant = async (id: string) => {
  return await deleteApi(`/dining-restaurant/${id}`);
};
export const updateDailyItems = async (
  data: IUpdateDailyDiningMealsPayload
) => {
  return await patch(`/dining-meals/${data?.id}`, data);
};
export const updateRepeatItems = async (
  data: IUpdateRepeatDiningMealsPayload
) => {
  return await patch(`/dining-meals/${data?.id}`, data);
};
export const updateRestaurant = async (data: IUpdateRestaurantPayload) => {
  return await put(`/dining-restaurant/${data.id}`, data);
};
export const removeUpdateRepeatList = async (data: IRemoveWeeks) => {
  return await patch(`/dining-meals/${data?.id}`, data);
};
export const postnewCategory = async (data: {
  restaurantId: string;
  categoryName: string;
}) => {
  return await post(`/dining-meal-categories`, data);
};
export const editCategory = async (data: {
  id: number;
  categoryName: string;
}) => {
  return await put(`/dining-meal-categories/` + data?.id, {
    categoryName: data?.categoryName,
  });
};
export const deleteCategoryById = async (id: number) => {
  return await deleteApi(`/dining-meal-categories/` + id);
};
export const postDiningOrderChange = async (data: IdiningOrderPayload) => {
  return await post(`/dining-meals/position-change`, data);
};
