import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import NoDataComponent from '../../components/noDataComponent/noDataComponent';
import PageLoader from '../../components/pageLoading/pageLoader';
import Paginate, {
  // eslint-disable-next-line prettier/prettier
  IPaginateClickData,
} from '../../components/paginate/paginate';
import RatingStar from '../../components/ratingStar/ratingStar';
import BusinessUserComponent from './businessUserComp';
import './ratingsReviews.css';
import { IRatingsReviewsData } from './ratingsReviewsInterface';
import {
  addRatingsFilterPage,
  fetchAllRatingsReviews,
  fetchAvgRatingsReviewsStats,
  // eslint-disable-next-line prettier/prettier
  ratingReviewFilterPageReset,
} from './ratingsReviewsSlice';
import { ratingsPayloadSetter } from './utilityFunctions';

const RatingReviewPage: React.FC = () => {
  const { supportingBusiness } = useAppSelector((state) => state.businessAuth);
  const {
    businessRatingsReviewsData,
    avgRatingsReviewsStats,
    isProcessingRatingsReviews,
    ratingsFilter,
    ratingsPaginate,
  } = useAppSelector((state) => state.ratingsReviews);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(ratingReviewFilterPageReset());
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (supportingBusiness?.id) {
      dispatch(
        fetchAllRatingsReviews(
          ratingsPayloadSetter(ratingsFilter, supportingBusiness?.id)
        )
      );
      dispatch(fetchAvgRatingsReviewsStats(supportingBusiness?.id));
    }
  }, [supportingBusiness?.id, ratingsFilter]);
  const handlePageClick = (data: IPaginateClickData) => {
    if (data.selected !== ratingsPaginate.totalPages) {
      dispatch(addRatingsFilterPage(data?.selected));
    }
  };
  return (
    <div className="page-background amenities-page bookings-page">
      <div className="amenitiesPage-head ratingsreviews-head">
        <h2>Ratings & Reviews</h2>
        <div className="avg-reviews-ratings">
          {businessRatingsReviewsData.length >= 1
            ? avgRatingsReviewsStats && (
                <>
                  {avgRatingsReviewsStats?.ratingStats?.reviewCount > 0 && (
                    <div>
                      {avgRatingsReviewsStats?.ratingStats?.reviewCount}
                      &nbsp;&nbsp;&nbsp;
                      <span>Reviews</span>
                    </div>
                  )}
                  {avgRatingsReviewsStats?.ratingStats?.averageRating > 0 && (
                    <div className="avg-ratings">
                      <span className="blue-rating-font">
                        {avgRatingsReviewsStats?.ratingStats?.averageRating.toFixed(
                          1
                        )}
                      </span>
                      &nbsp;
                      <RatingStar
                        rating={`${avgRatingsReviewsStats?.ratingStats?.averageRating}`}
                      />
                    </div>
                  )}
                </>
              )
            : null}
        </div>
      </div>
      {isProcessingRatingsReviews ? (
        <PageLoader />
      ) : businessRatingsReviewsData.length ? (
        <div className="review-card-parent facility-reviews-parent-card">
          {businessRatingsReviewsData.map(
            (item: IRatingsReviewsData, index: number) => (
              <div key={index} className="business-reviews-card">
                <div className="review-card-flex-column">
                  <div className="review-card-flex-row-spaceBetween padding-bottom-min">
                    <BusinessUserComponent
                      userId={item?.userId}
                      createdTime={item?.createdDateTime?.split('T')[0]}
                    />
                    <div className="review-card-flex-row fit-content smallScreen-rating">
                      <span className="blue-rating-font">{item?.rating}</span>
                      <RatingStar rating={`${item?.rating}`} />
                    </div>
                  </div>
                  <div className="padding-top-1rem review-description facility-review-description">
                    {item?.review ? (
                      <span>{item?.review}</span>
                    ) : (
                      <span className="no-reviews-class">No review</span>
                    )}
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        <NoDataComponent constant="NO_REVIEW" />
      )}
      {ratingsPaginate?.totalPages > 1 && (
        <Paginate
          handlePageClick={handlePageClick}
          totalPage={ratingsPaginate?.totalPages}
        />
      )}
    </div>
  );
};

export default RatingReviewPage;
