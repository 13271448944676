import * as yup from 'yup';

export const amenitiesSchema = yup
  .object({
    name: yup
      .string()
      .max(250, 'Maximum 200 characters')
      .required('AMENITIES_FORM.NAME_REQUIRED'),
    description: yup
      .string()
      .max(500, 'Maximum 500 Characters')
      .required('AMENITIES_FORM.DESCRIPTION_REQUIRED'),
  })
  .required();
