import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { activeInactiveChecker } from '../../_helpers/checkerFunctions';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { translateArrayList } from '../../components/cloudTranslationCommon/translation';
import DeleteConfirmation from '../../components/deleteConfirmation/deleteConfirmation';
import RestaurantForm from '../../components/diningForm/restaurantForm';
import { ModalComponent } from '../../components/modal';
import { openAddModalReducer } from '../../components/modal/modalSlice';
import NoDataComponent from '../../components/noDataComponent/noDataComponent';
import PageLoader from '../../components/pageLoading/pageLoader';
import Paginate, {
  IPaginateClickData,
} from '../../components/paginate/paginate';
import MainCard from '../../components/restaurants/mainCard';
import '../../mediaQueries.css';
import '../amenitiesPage/amenitiesPage.css';
import { getSelectedFacility, translateText } from '../loginPage/authSlice';
import { IAddRestaurant, IRestaurants } from './diningInterface';
import {
  addFilterPage,
  addTranslatedData,
  deleteRestaurantById,
  fetchAllRestaurants,
  fetchRestaurantImageId_Add,
  fetchRestaurantImageId_Update,
  restaurantFilterPageReset,
  updateRestaurantById,
} from './diningSlice';
import './restaurant.css';
import { filterChange } from './utilityFunction';

const RestaurantPage: React.FC = () => {
  const { f_id } = useParams();
  const { currentFacility } = useAppSelector((state) => state.authentication);
  const {
    isAddingRestaurant,
    isAddingRestaurantImage,
    filter,
    restaurants,
    restaurantPaginate,
    toDeleteRestaurant,
    isDeletingRestaurant,
    imageDetails,
    isUpdatingRestaurant,
    isFetchingImage,
    isFetchingRestaurants,
  } = useAppSelector((state) => state.dining);
  const { openAddModal, openDeleteModal, openEditModal } = useAppSelector(
    (state) => state.modal
  );
  const [dropdown, setDropdown] = useState<string>('');
  const [t, i18n] = useTranslation('common');

  const [translation] = useTranslation('common');
  const language = i18n.language;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // translation

  useEffect(() => {
    if (language !== 'en' && restaurants?.length > 0) {
      translateArrayList(
        restaurants,
        language,
        dispatch,
        translateText,
        addTranslatedData,
        ['restaurantName'] // Pass the properties that need to be translated
      );
    }
  }, [language, isFetchingRestaurants]);
  useEffect(() => {
    if (f_id) {
      language === 'en' &&
        dispatch(fetchAllRestaurants(filterChange(filter, f_id)));
    }
  }, [language]);

  useEffect(() => {
    if (language !== 'en') {
      return () => {
        dispatch(addTranslatedData([]));
      };
    }
  }, []);

  // -----
  useEffect(() => {
    dispatch(restaurantFilterPageReset());
    if (f_id) {
      dispatch(getSelectedFacility(f_id));
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (f_id) {
      dispatch(fetchAllRestaurants(filterChange(filter, f_id)));
    }
  }, [filter, f_id]);

  const addRestaurantData = () => {
    dispatch(openAddModalReducer());
  };
  const addRestaurantModalHandler = (data: IAddRestaurant) => {
    if (f_id) {
      dispatch(fetchRestaurantImageId_Add({ ...data, facilityId: f_id }));
    }
  };
  const editRestaurantModalHandler = (data: IAddRestaurant) => {
    if (f_id) {
      if (data.image) {
        dispatch(
          fetchRestaurantImageId_Update({
            ...data,
            facilityId: f_id,
            id: toDeleteRestaurant?.id,
          })
        );
      } else {
        dispatch(
          updateRestaurantById({
            ...data,
            facilityId: f_id,
            id: toDeleteRestaurant?.id,
          })
        );
      }
    }
  };
  const deleteRestaurantModalHandler = (id: string) => {
    if (f_id) {
      dispatch(deleteRestaurantById({ id, facilityId: f_id }));
    }
  };
  const navigateToDiningPage = (id: string) => {
    if (f_id) {
      navigate(`/administration/dining-schedules/restaurant/${f_id}/${id}`);
    }
  };

  const handlePageClick = (data: IPaginateClickData) => {
    if (data.selected !== restaurantPaginate.totalPages)
      dispatch(addFilterPage(data?.selected));
  };
  const showOptions = (key: string) => {
    setDropdown(dropdown == key ? '' : key);
  };

  return (
    <>
      <div
        className={dropdown && 'dingingDropdown-overlay'}
        onClick={() => setDropdown('')}
      ></div>
      <div className="page-background amenities-page">
        <div className="amenitiesPage-head">
          <h2>{translation('DINING_SCHEDULES.TITLE')}</h2>
          <Button
            className="addAmenities-button shadow-none"
            disabled={!activeInactiveChecker(currentFacility?.status)}
            onClick={addRestaurantData}
          >
            {translation('RESTAURANT.ADD_RESTAURANT_BUTTON')}
          </Button>
        </div>
        {isFetchingRestaurants ? (
          <PageLoader />
        ) : restaurants?.length ? (
          <div className="activities-card-div restaurant-parent-card-R">
            {restaurants?.map((restaurant: IRestaurants, index: number) => (
              <MainCard
                key={index}
                compKey={restaurant?.id}
                navigateHandler={navigateToDiningPage}
                restaurant={restaurant}
                dropdown={dropdown}
                showOptions={showOptions}
              />
            ))}
          </div>
        ) : (
          <NoDataComponent constant="NO_MEAL" />
        )}
        {openAddModal && (
          <ModalComponent
            isOpen={openAddModal}
            modalHeading={translation('DINING_SCHEDULES.ADD_RESTAURANT')}
          >
            <RestaurantForm
              onCreate={addRestaurantModalHandler}
              isAdding={isAddingRestaurant || isAddingRestaurantImage}
              // buttonTitle={translation('BUTTONS.CREATE')}
            />
          </ModalComponent>
        )}

        {toDeleteRestaurant
          ? openEditModal && (
              <ModalComponent
                isOpen={openEditModal}
                modalHeading={translation('RESTAURANT.EDIT_RESTAURANT')}
              >
                <RestaurantForm
                  onCreate={editRestaurantModalHandler}
                  isAdding={isFetchingImage || isUpdatingRestaurant}
                  content={toDeleteRestaurant}
                  imageDetails={imageDetails}
                  // buttonTitle={translation('BUTTONS.CREATE')}
                />
              </ModalComponent>
            )
          : null}
        {toDeleteRestaurant
          ? openDeleteModal && (
              <ModalComponent isOpen={openDeleteModal} modalHeading="Delete">
                <DeleteConfirmation
                  content={toDeleteRestaurant}
                  onDelete={deleteRestaurantModalHandler}
                  isDeleting={isDeletingRestaurant}
                />
              </ModalComponent>
            )
          : null}
        {restaurantPaginate?.totalPages > 1 && (
          <Paginate
            handlePageClick={handlePageClick}
            totalPage={restaurantPaginate?.totalPages}
          />
        )}
      </div>
    </>
  );
};

export default RestaurantPage;
