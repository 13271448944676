import React from 'react';
import { Spinner } from 'reactstrap';

const ImageLoadingSpinner: React.FC<{
  color: string | undefined;
}> = ({ color }) => {
  return (
    <div className="image-loading-spinner">
      <Spinner
        className="image-loading-spinner"
        color={color}
        size="sm"
      ></Spinner>
    </div>
  );
};

export default ImageLoadingSpinner;
