/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { History } from '../../_helpers/history';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import FullScreenImgComp from '../../components/fullScreenImage/fullScreenImgComp';
import BusinessImageComp from '../../components/imageComponents/businessImageComp';
import { DropDownComponent } from '../../components/menuDropDown/dropDownComponent';
import PageLoader from '../../components/pageLoading/pageLoader';
import { PdfComponent } from '../../components/pdfViewer/pdfComponent';
import { env } from '../../config/env';
// import { IMenuFileSrc } from '../diningPage/diningInterface';
import '../facilityProfilePage/facilityProfile.css';
import { fetchBusinessUnderUser } from '../loginPage/businessAuthSlice';
import { ISupportingBusinessList } from '../loginPage/loginInterface';
import { IBusinessCategories } from '../registrationPage/registrationTypes';
import { IBusinessDocSrc } from './businessProfileInterface';

const BusinessProfilePage: React.FC = () => {
  const { supportingBusiness, selectedBusinessCategory } = useAppSelector(
    (state) => state.businessAuth
  );
  const [business, setBusiness] = useState<ISupportingBusinessList>(
    {} as ISupportingBusinessList
  );
  const [viewDocuments, setViewDocuments] = useState(false);

  const [pdfComponentData, setPdfComponentData] = useState<IBusinessDocSrc>();
  const [ispdfComponent, setIsPdfComponent] = useState<boolean>(false);
  const [singleImageComponent, setSingleImageComponent] =
    useState<IBusinessDocSrc>();
  const [isSingleImageComponent, setIsSingleImageComponent] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();
  const [translation] = useTranslation('common');
  useEffect(() => {
    window.scrollTo(0, 0);
    const getBusinessFunc = async () => {
      const response = await dispatch(fetchBusinessUnderUser(null));
      setBusiness(response.payload?.data[0]?.data[0]);
    };
    getBusinessFunc();
  }, []);
  const handleEditPage = () => {
    History.navigate(`/business/business-profile/edit`);
  };
  const onDocumentFileItemSelected = (data: IBusinessDocSrc) => {
    if (data.fileType === 'application/pdf') {
      setPdfComponentData(data);
      setIsPdfComponent(true);
    } else if (data.fileType !== 'application/pdf') {
      setSingleImageComponent(data);
      setIsSingleImageComponent(true);
    }
  };

  const closeEnlargeImage = () => {
    setIsSingleImageComponent(false);
    setIsPdfComponent(false);
  };

  const businessDocumentdata = business?.documents?.map(
    (item: IBusinessDocSrc) => ({
      file: `${env.development.BASE_URL}/files/${item?.file}`,
      fileType: item?.fileType,
      fileName: item?.fileName,
    })
  );

  return (
    <>
      {viewDocuments && (
        <div
          className="view-document-background-wrap"
          onClick={() => setViewDocuments(!viewDocuments)}
        ></div>
      )}
      {ispdfComponent && pdfComponentData?.file ? (
        <PdfComponent
          close={closeEnlargeImage}
          url={`${pdfComponentData?.file}`}
        />
      ) : (
        <div className="page-background facilityProfile">
          {business?.id ? (
            <div className="facilityProfile-container">
              <div className="facilityProfile-image-div">
                <BusinessImageComp
                  imageId={business?.photoId}
                  noImageClass="noImageClass"
                  className="profile-image"
                  divClass="facilityProfile-image noColor-image-background"
                  alt="facility-profile-image"
                  src="/assets/emptyImage.svg"
                  loadingColor="light"
                />
              </div>
              {isSingleImageComponent && singleImageComponent?.file && (
                <FullScreenImgComp
                  image={`${env.development.BASE_URL}/files/${singleImageComponent?.file}`}
                  state={true}
                  closeClass={closeEnlargeImage}
                >
                  <img src={singleImageComponent?.file} alt="" />
                </FullScreenImgComp>
              )}
              <div className="facilityProfile-details">
                <div className="details-header">
                  <span className="business-name">
                    {translation(business?.name)}
                  </span>
                  <Button
                    className="editProfile-button shadow-none"
                    onClick={handleEditPage}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL + '/assets/editIcon-white.svg'
                      }
                      alt="edit-icon"
                    />
                    {translation('PROFILE_EDIT.EDIT_PROFILE_BUTTON')}
                  </Button>
                </div>
                <div className="details-facilityData">
                  <div className="details-flex-column">
                    <span>{translation('PROFILE_EDIT.EMAIL_ADDRESS')}</span>
                    <span>{translation(`${business?.email}`)}</span>
                  </div>
                  <div className="details-flex-column">
                    <span>
                      {translation(
                        selectedBusinessCategory.length > 1
                          ? 'Categories'
                          : 'Category'
                      )}
                    </span>
                    <span className="category-name-list">
                      {selectedBusinessCategory.length > 0 &&
                        selectedBusinessCategory.map(
                          (item: IBusinessCategories, index: number) => (
                            <span key={index}>
                              {translation(`${item?.name}`)}
                            </span>
                          )
                        )}
                    </span>
                  </div>
                </div>
                <div className="details-border-line"></div>
                <div className="details-address">
                  <div className="details-flex-column">
                    <span>{translation('Documents')}</span>
                    <div className="details-flex-column">
                      {businessDocumentdata.length > 0 ? (
                        <span
                          onClick={() => setViewDocuments(!viewDocuments)}
                          className="pointer view-document-text"
                        >
                          {translation(`View Documents`)}
                        </span>
                      ) : (
                        <span> {translation(`No Documents`)}</span>
                      )}
                      {viewDocuments && (
                        <DropDownComponent
                          onSelected={onDocumentFileItemSelected}
                          menuFileList={businessDocumentdata}
                        />
                      )}
                    </div>
                  </div>
                  <div className="details-facilityData">
                    <div className="details-flex-column">
                      <span>{translation('Capacity')}</span>
                      <span>{translation(`${business?.capacity}`)}</span>
                    </div>
                    <div className="details-flex-column">
                      <span>{translation('Maximum Price')}</span>
                      <span>{translation(`${business?.maxPrice}`)}</span>
                    </div>
                    <div className="details-flex-column">
                      <span>{translation('Minimum Price')}</span>
                      <span>{translation(`${business?.minPrice}`)}</span>
                    </div>
                    <div className="details-flex-column">
                      <span>{translation('Description')}</span>
                      <span>{translation(`${business?.description}`)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <PageLoader />
          )}
        </div>
      )}
    </>
  );
};

export default BusinessProfilePage;
