import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '../../app/store';
import {
  closeModalReducer,
  openDeleteModalReducer,
  // eslint-disable-next-line prettier/prettier
  openEditModalReducer,
} from '../../components/modal/modalSlice';
import {
  addAmenity,
  deleteAmenity,
  getAllAmenities,
  getAmenityById,
  getImageDetails,
  // eslint-disable-next-line prettier/prettier
  updateAmenity,
} from '../../services/amenitiesService';
import { getImageId, postOrderChange } from '../../services/facilityService';
import { formDataConverter } from '../../_helpers/formDataConverter';
import { IActivityPaginate } from '../activitiesPage/activitiesInterface';
import { fetchDocumentDetails } from '../supportingBusinessPage/supportingBusinessSlice';
import {
  IAddAmenityPayload,
  IAddNewAmenityPayload,
  IAmenitiesData,
  IAmenitiesDocSrc,
  IAmenitiesPayloadFilter,
  IAmenityOrderPayload,
  IDeleteAmenityPayload,
  IDeleteEditAmenityPayload,
  IEditAmenityPayload,
  IImageDetails,
  // eslint-disable-next-line prettier/prettier
  IUpdateAmenityPayload,
} from './amenitiesInterface';
import { getAmenityRequestParams } from './amenityParams';
import {
  addAmenityManupulator,
  filterChange,
  // eslint-disable-next-line prettier/prettier
  updateAmenityManupulator,
} from './utilityFunctions';

export interface IAmenities {
  isProcessingAmenity: boolean;
  isProcessingAllAmenities: boolean;
  isAddingNewAmenity: boolean;
  addedNewAmenity: boolean;
  isUpdatingAmenity: boolean;
  updatedExistingAmenity: boolean;
  isDeletingAmenity: boolean;
  deletedAmenity: boolean;
  filter: IAmenitiesPayloadFilter;
  amenity: IAmenitiesData;
  amenityList: IAmenitiesData[];
  amenityPaginate: IActivityPaginate;
  defaultAmenities: IAmenitiesData[];
  isFetchingImage: boolean;
  isFetchingImageDetails: boolean;
  imageDetails: IImageDetails;
}
const initialState: IAmenities = {
  isProcessingAmenity: false,
  isProcessingAllAmenities: false,
  isAddingNewAmenity: false,
  addedNewAmenity: false,
  isUpdatingAmenity: false,
  updatedExistingAmenity: false,
  isDeletingAmenity: false,
  deletedAmenity: false,
  filter: {
    facilityIds: '',
    page: 0,
    size: 50,
    sort: 'position,asc',
  },
  amenity: {
    id: '',
    name: '',
    image: '',
    description: '',
    photoId: '',
    position: 0,
  },
  amenityPaginate: {
    totalPages: 0,
    totalElements: 0,
    numberOfElements: 0,
    size: 0,
    number: 0,
  },
  amenityList: [],
  defaultAmenities: [],
  isFetchingImage: false,
  isFetchingImageDetails: false,
  imageDetails: {} as IImageDetails,
};

export const amenitiesSlice = createSlice({
  name: 'amenities',
  initialState,
  reducers: {
    resetAmenityState: () => {
      return initialState;
    },
    addFilterPage: (state, action) => {
      state.filter.page = action.payload;
    },
    addTranslatedData: (state, action) => {
      state.amenityList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAmenityById.pending, (state) => {
        state.isProcessingAmenity = true;
      })
      .addCase(fetchAmenityById.fulfilled, (state, action) => {
        state.isProcessingAmenity = false;
        state.amenity = action.payload?.data[0];
      })
      .addCase(fetchAmenityById.rejected, (state) => {
        state.isProcessingAmenity = false;
      })
      .addCase(fetchAllAmenities.pending, (state) => {
        state.isProcessingAllAmenities = true;
      })
      .addCase(fetchAllAmenities.fulfilled, (state, action) => {
        state.isProcessingAllAmenities = false;
        state.amenityList = action.payload?.data[0]?.data;
        state.amenityPaginate = action.payload?.data[0];
      })
      .addCase(fetchAllAmenities.rejected, (state) => {
        state.isProcessingAllAmenities = false;
      })
      .addCase(addNewAmenity.pending, (state) => {
        state.isAddingNewAmenity = true;
        state.addedNewAmenity = false;
      })
      .addCase(addNewAmenity.fulfilled, (state, action) => {
        state.isAddingNewAmenity = false;
        state.addedNewAmenity = true;

        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(addNewAmenity.rejected, (state, action: PayloadAction<any>) => {
        state.isAddingNewAmenity = false;
        state.addedNewAmenity = false;

        toast.error(
          `${action.payload
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload.slice(1)}`
        );
      })
      .addCase(updateSelectedAmenity.pending, (state) => {
        state.isUpdatingAmenity = true;
        state.updatedExistingAmenity = false;
      })
      .addCase(updateSelectedAmenity.fulfilled, (state, action) => {
        state.isUpdatingAmenity = false;
        state.updatedExistingAmenity = true;

        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(
        updateSelectedAmenity.rejected,
        (state, action: PayloadAction<any>) => {
          state.isUpdatingAmenity = false;
          state.updatedExistingAmenity = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(deleteSelectedAmenity.pending, (state) => {
        state.isDeletingAmenity = true;
        state.deletedAmenity = false;
      })
      .addCase(deleteSelectedAmenity.fulfilled, (state, action) => {
        state.isDeletingAmenity = false;
        state.deletedAmenity = true;

        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
      })
      .addCase(
        deleteSelectedAmenity.rejected,
        (state, action: PayloadAction<any>) => {
          state.isDeletingAmenity = false;
          state.deletedAmenity = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(fetchAmenityImageId_Add.pending, (state) => {
        state.isFetchingImage = true;
      })
      .addCase(fetchAmenityImageId_Add.fulfilled, (state, action) => {
        state.isFetchingImage = false;
      })
      .addCase(
        fetchAmenityImageId_Add.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingImage = false;
        }
      )
      .addCase(fetchAmenityImageId_Update.pending, (state) => {
        state.isFetchingImage = true;
      })
      .addCase(fetchAmenityImageId_Update.fulfilled, (state, action) => {
        state.isFetchingImage = false;
      })
      .addCase(
        fetchAmenityImageId_Update.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingImage = false;
        }
      )
      .addCase(fetchAmenityImageDetails.pending, (state) => {
        state.isFetchingImageDetails = true;
        state.imageDetails = { id: '', originalName: '' };
      })
      .addCase(fetchAmenityImageDetails.fulfilled, (state, action) => {
        state.isFetchingImageDetails = false;
        state.imageDetails = action.payload?.data[0];
      })
      .addCase(
        fetchAmenityImageDetails.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingImageDetails = false;
        }
      );
  },
});

export const fetchAmenityImageDetails = createAsyncThunk(
  'amenities/fetchAmenityImageDetails',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getImageDetails(id);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchAmenityById = createAsyncThunk(
  'amenities/fetchAmenityById',
  async (data: IDeleteEditAmenityPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await getAmenityById(data?.id);

      //.................. get file details on get by id

      if (response?.data[0]?.documents?.length > 0) {
        const imageDetailsArray = await Promise.all(
          response?.data[0]?.documents?.map(async (id: string) => {
            const data = await getImageDetails(id);
            const array1 = [];

            array1.push({
              fileType: data?.data[0].mimeType,
              fileName: data?.data[0].originalName,
              file: `${id}`,
            });
            return array1;
          })
        );

        const result = imageDetailsArray.flat().reduce((acc, item) => {
          acc.push(item);
          return acc;
        }, []);
        console.log('result', result);

        response.data[0] = {
          ...response?.data[0],
          documents: result,
        };
      }
      // .........................................

      if (response?.data[0]?.photoId) {
        dispatch(fetchAmenityImageDetails(response?.data[0]?.photoId));
      }

      if (data?.for === 'delete') {
        dispatch(openDeleteModalReducer());
      } else if (data?.for === 'edit') {
        dispatch(openEditModalReducer());
      }
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const addNewAmenity = createAsyncThunk(
  'amenities/addNewAmenity',
  async (
    data: IAddNewAmenityPayload,
    { rejectWithValue, getState, dispatch }
  ) => {
    const { amenities } = getState() as { amenities: IAmenities };
    try {
      const businessDocumentsResponse: string[] = [];

      if (data?.documents?.length > 0) {
        await Promise.all(
          data?.documents?.map(async (obj: IAmenitiesDocSrc) => {
            const documentsResponse =
              obj?.fileSource !== 'server' &&
              (await getImageId(formDataConverter(obj?.file)));
            obj?.fileSource !== 'server'
              ? businessDocumentsResponse?.push(
                  documentsResponse?.data?.[0]?.id
                )
              : businessDocumentsResponse?.push(obj?.file);
          })
        );
      }
      const formData = {
        ...data,
        documents: businessDocumentsResponse,
      };

      const response = await addAmenity(formData);
      dispatch(closeModalReducer());
      dispatch(
        fetchAllAmenities(filterChange(data?.facilityId, amenities.filter))
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);
export const fetchAllAmenities = createAsyncThunk(
  'amenities/fetchAllAmenities',
  async (data: IAmenitiesPayloadFilter, { rejectWithValue }) => {
    try {
      const params = getAmenityRequestParams(
        data.sort,
        data.page,
        data.size,
        data.facilityIds
      );
      const response = await getAllAmenities(params);

      // ... get all  documents details for all amenities

      const manipulatedArray = await Promise.all(
        response?.data[0]?.data.map(async (obj: any) => {
          const documents = await Promise.all(
            obj.documents.map(fetchDocumentDetails)
          );
          return { ...obj, documents };
        })
      );

      response.data[0].data = [...manipulatedArray];

      // ................

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const updateSelectedAmenity = createAsyncThunk(
  'amenities/updateSelectedAmenity',
  async (
    data: IUpdateAmenityPayload,
    { rejectWithValue, getState, dispatch }
  ) => {
    const { amenities } = getState() as { amenities: IAmenities };
    try {
      const businessDocumentsResponse: string[] = [];

      if (data?.documents?.length > 0) {
        await Promise.all(
          data?.documents?.map(async (obj: IAmenitiesDocSrc) => {
            const documentsResponse =
              obj?.fileSource !== 'server' &&
              (await getImageId(formDataConverter(obj?.file)));
            obj?.fileSource !== 'server'
              ? businessDocumentsResponse?.push(
                  documentsResponse?.data?.[0]?.id
                )
              : businessDocumentsResponse?.push(obj?.file);
          })
        );
      }
      const formData = {
        ...data,
        documents: businessDocumentsResponse,
      };

      const response = await updateAmenity(formData);
      dispatch(closeModalReducer());
      dispatch(
        fetchAllAmenities(filterChange(data?.facilityId, amenities.filter))
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);
export const deleteSelectedAmenity = createAsyncThunk(
  'amenities/deleteSelectedAmenity',
  async (
    data: IDeleteAmenityPayload,
    { rejectWithValue, getState, dispatch }
  ) => {
    const { amenities } = getState() as { amenities: IAmenities };
    try {
      const response = await deleteAmenity(data?.id);
      dispatch(closeModalReducer());
      dispatch(
        fetchAllAmenities(filterChange(data?.facilityId, amenities.filter))
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchAmenityImageId_Add = createAsyncThunk(
  'amenities/fetchAmenityImageId_Add',
  async (data: IAddAmenityPayload, { rejectWithValue, dispatch }) => {
    try {
      const imageResponse = await getImageId(formDataConverter(data.imageData));

      const response = dispatch(
        addNewAmenity(addAmenityManupulator(data, imageResponse?.data[0]?.id))
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchAmenityImageId_Update = createAsyncThunk(
  'amenities/fetchAmenityImageId_Update',
  async (data: IEditAmenityPayload, { rejectWithValue, dispatch }) => {
    try {
      const imageResponse = await getImageId(formDataConverter(data.imageData));
      const response = dispatch(
        updateSelectedAmenity(
          updateAmenityManupulator(data, imageResponse?.data[0]?.id)
        )
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

// amenity swap api

export const changeAmenityOrder = createAsyncThunk(
  'amenities/changeAmenityOrder',
  async (data: IAmenityOrderPayload, { rejectWithValue }) => {
    try {
      const response = await postOrderChange(data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const { resetAmenityState, addFilterPage, addTranslatedData } =
  amenitiesSlice.actions;
export const selectamenities = (state: RootState) => state.amenities;
export const amenitiesReducer = amenitiesSlice.reducer;
