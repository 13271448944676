import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormFeedback, FormGroup, Input } from 'reactstrap';
import { ICreatePassConstants } from '../../pages/createPassword/createPasswordInterface';

export interface IPassword {
  newPassword: boolean;
  confirmPassword: boolean;
}

const PasswordComponent: React.FC<{
  control: any;
  errors: any;
  constants: ICreatePassConstants;
}> = ({ control, errors, constants }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<IPassword>({
    newPassword: false,
    confirmPassword: false,
  });

  const [translation] = useTranslation('common');

  const newPasswordIconToggler = () => {
    setIsPasswordVisible((prevVal: IPassword) => ({
      ...prevVal,
      newPassword: !isPasswordVisible.newPassword,
    }));
  };
  const confirmPasswordIconToggler = () => {
    setIsPasswordVisible((prevVal: IPassword) => ({
      ...prevVal,
      confirmPassword: !isPasswordVisible.confirmPassword,
    }));
  };

  return (
    <>
      <FormGroup className="password-hideIcon-wrap">
        <Controller
          control={control}
          name="newPassword"
          render={({ field }) => (
            <Input
              type={isPasswordVisible.newPassword ? 'text' : 'password'}
              className="form-input shadow-none"
              placeholder={translation(constants.passwordText)}
              autoComplete="off"
              invalid={errors?.newPassword ? true : false}
              {...field}
            />
          )}
        />
        <FormFeedback className="validation-error">
          {errors?.newPassword?.message &&
            translation(`${constants?.passwordInvalid}`)}
        </FormFeedback>
        {isPasswordVisible.newPassword ? (
          <img
            className="password-eye"
            src={process.env.PUBLIC_URL + '/assets/passwordEye.svg'}
            alt=""
            onClick={newPasswordIconToggler}
          />
        ) : (
          <img
            className="password-eye"
            src={process.env.PUBLIC_URL + '/assets/passwordHideEye.svg'}
            alt=""
            onClick={newPasswordIconToggler}
          />
        )}
      </FormGroup>
      <FormGroup className="password-hideIcon-wrap">
        <Controller
          control={control}
          name="confirmPassword"
          render={({ field }) => (
            <Input
              type={isPasswordVisible.confirmPassword ? 'text' : 'password'}
              className="form-input shadow-none"
              placeholder={translation(constants.confirmPasswordText)}
              autoComplete="off"
              invalid={errors?.confirmPassword ? true : false}
              {...field}
            />
          )}
        />
        <FormFeedback className="validation-error">
          {translation(`${errors?.confirmPassword?.message}`)}
        </FormFeedback>
        {isPasswordVisible.confirmPassword ? (
          <img
            className="password-eye"
            src={process.env.PUBLIC_URL + '/assets/passwordEye.svg'}
            alt=""
            onClick={confirmPasswordIconToggler}
          />
        ) : (
          <img
            className="password-eye"
            src={process.env.PUBLIC_URL + '/assets/passwordHideEye.svg'}
            alt=""
            onClick={confirmPasswordIconToggler}
          />
        )}
      </FormGroup>
    </>
  );
};

export default PasswordComponent;
