import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { getUserImageById } from '../../pages/facilityReviewsPage/facilityReviewsSlice';
import ImageLoadingSpinner from '../loadingSpinner/imageLoadingSpinner';

const UserProfileImage: React.FC<{
  imageId: string | undefined;
  className?: string;
  divClass?: string;
  noImageClass?: string;
  alt?: string;
  src: string;
  loadingColor?: string;
}> = ({
  className,
  alt,
  src,
  imageId,
  divClass,
  noImageClass,
  loadingColor,
}) => {
  const { isFetchingUserImage } = useAppSelector(
    (state) => state.facilityReviews
  );
  const dispatch = useAppDispatch();
  const [image, setImage] = useState<any>();
  const [translation] = useTranslation('common');
  useEffect(() => {
    const getImage = async () => {
      if (imageId) {
        const response = await dispatch(getUserImageById(imageId));
        setImage(response.payload);
      }
    };
    getImage();
  }, [imageId]);
  return !imageId ? (
    <div className={divClass}>
      <img
        className={noImageClass}
        src={process.env.PUBLIC_URL + src}
        // alt={translation(`${alt}`)}
        alt=""
      />
    </div>
  ) : isFetchingUserImage ? (
    <div className={divClass}>
      <ImageLoadingSpinner color={loadingColor} />
    </div>
  ) : (
    <div className={divClass}>
      <img className={className} src={image} alt="" />
    </div>
  );
};

export default UserProfileImage;
