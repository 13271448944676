import React, { FC } from 'react';
import { IMenuFileSrc } from '../../pages/diningPage/diningInterface';
import './dropdown.css';

interface IMenuList {
  menuFileList: IMenuFileSrc[];
  onSelected: (data: IMenuFileSrc) => void;
}

export const DropDownComponent: FC<IMenuList> = ({
  menuFileList,
  onSelected,
}) => {
  return (
    <div className="dropdown-content">
      <div className="dropdown-container">
        {menuFileList.map((files: IMenuFileSrc, index) => (
          <ul key={index} onClick={() => onSelected(files)}>
            <li>
              {' '}
              {files.fileType !== 'application/pdf' ? (
                <div className="menu-file-wrap">
                  <div className="menu-file-row pointer">
                    <img
                      style={{
                        borderRadius: '5px',
                      }}
                      src={files?.file}
                      alt=""
                    />
                  </div>
                  <span className="menu-file-titles">{files?.fileName}</span>
                </div>
              ) : (
                <div className="menu-file-wrap pointer">
                  {' '}
                  <div className="menu-file-row">
                    <img src={'/assets/pdf_thumb.png'} alt="" />
                  </div>
                  <span className="menu-file-titles">{files?.fileName}</span>
                </div>
              )}
            </li>
          </ul>
        ))}
      </div>
    </div>
  );
};
