import { ResponsiveBar } from '@nivo/bar';
import React, { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Col } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { fetchAllGuestByMonth } from '../../pages/dashboard/dashboardSlice';
import DashboardNoData from './dashboardNoData';

export const GuestChart: React.FC = () => {
  const { allGuestByMonthList, isGetNoOfGuestByMonth } = useAppSelector(
    (state) => state.dasboardInfo
  );
  const [searchParams, setSearchParams] = useSearchParams();
  const [facilityId, setFacilityId] = useState(searchParams.get('f_id'));
  const [translation] = useTranslation('common');

  useEffect(() => {
    if (facilityId) {
      dispatch(fetchAllGuestByMonth(facilityId));
    }
  }, [facilityId]);

  const dispatch = useAppDispatch();
  const guest =
    allGuestByMonthList &&
    allGuestByMonthList
      .slice()
      .sort((a: any, b: any) => (a.month < b.month ? 1 : -1))
      .map((item: any) => {
        const newMonthName = item?.monthName && item?.monthName?.slice(0, 3);
        const lowercase = newMonthName?.toLowerCase();

        const upperCaseMonthName =
          lowercase &&
          lowercase?.charAt(0)?.toUpperCase() + lowercase?.slice(1);

        return {
          count: item?.count,
          month: item?.month,
          monthName: upperCaseMonthName,
          year: item?.year,
        };
      });
  const guestName =
    guest &&
    guest.slice().sort((a: any, b: any) => (a?.year > b?.year ? 1 : -1));

  const data = [
    {
      country: 'AD',
      fries: 62,
      friesColor: 'hsl(187, 70%, 50%)',
    },
    {
      country: 'AE',
      fries: 197,
      friesColor: 'hsl(155, 70%, 50%)',
    },
    {
      country: 'AF',
      fries: 47,
      friesColor: 'hsl(147, 70%, 50%)',
    },
    {
      country: 'AG',
      fries: 120,
      friesColor: 'hsl(204, 70%, 50%)',
    },
    {
      country: 'AI',
      fries: 46,
      friesColor: 'hsl(246, 70%, 50%)',
    },
    {
      country: 'AL',
      fries: 88,
      friesColor: 'hsl(270, 70%, 50%)',
    },
    {
      country: 'AM',
      fries: 175,
      friesColor: 'hsl(160, 70%, 50%)',
    },
  ];
  const theme = {
    axis: {
      fontSize: '14px',
      tickColor: '',
      ticks: {
        line: {
          stroke: '#555555',
        },
        text: {
          fill: '#777777',
        },
      },
      legend: {
        text: {
          fill: '#F0F0F5',
          legendPosition: 'middle',
        },
      },
    },
    grid: {
      line: {
        stroke: '#F0F0F5',
      },
    },
  };
  return (
    <Col lg={6} sm={12}>
      <div className="first-graph">
        <div className="graph-heading">
          {' '}
          {translation('DASHBOARD.NUMBER_OF_GUEST')}
        </div>
        {isGetNoOfGuestByMonth ? (
          <div className=" w-full  h-4/5 flex justify-center items-center">
            <ContentLoader width={800} height={300} viewBox="0 0 200 200">
              <rect x="0" y="130" rx="2" ry="2" width="27" height="60" />
              <rect x="40" y="115" rx="2" ry="2" width="27" height="75" />
              <rect x="80" y="96" rx="2" ry="2" width="27" height="94" />
              <rect x="120" y="50" rx="2" ry="2" width="27" height="140" />
              <rect x="160" y="112" rx="2" ry="2" width="27" height="78" />
            </ContentLoader>
          </div>
        ) : allGuestByMonthList?.length > 0 ? (
          <ResponsiveBar
            data={guestName && guestName}
            keys={['count']}
            indexBy="monthName"
            margin={{ top: 30, right: 20, bottom: 90, left: 50 }}
            padding={0.25}
            groupMode="grouped"
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={['#FF5F9D']}
            theme={theme}
            defs={[
              {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true,
              },
              {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10,
              },
            ]}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendOffset: -40,
              legendPosition: 'middle',
            }}
            axisBottom={{
              tickSize: 0,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: 'middle',
              legendOffset: 32,
            }}
            enableGridY={false}
            enableLabel={false}
            labelSkipWidth={0}
            labelSkipHeight={0}
            labelTextColor={{
              from: 'color',
              modifiers: [['darker', 1.6]],
            }}
          />
        ) : (
          <div className="tab-no-data">
            <DashboardNoData name={' Data'} />
          </div>
        )}
      </div>
    </Col>
  );
};
