import React from 'react';
import { useTranslation } from 'react-i18next';
import './noDataComponent.css';

const NoDataComponent: React.FC<{
  constant: string;
}> = ({ constant }) => {
  const [translation] = useTranslation('common');

  const data = [
    {
      img: '/assets/no-business.svg',
      text: translation(`DATA.NO_BUSINESS`),
      constant: 'NO_BUSINESS',
    },

    {
      img: '/assets/no-meal.svg',
      text: translation(`DATA.NO_MEAL`),
      constant: 'NO_MEAL',
    },
    {
      img: '/assets/no-event.svg',
      text: translation(`DATA.NO_AMENITY`),
      constant: 'NO_AMENITY',
    },
    {
      text: translation(`DATA.NO_ACTIVITY`),
      img: '/assets/no-event.svg',
      constant: 'NO_ACTIVITY',
    },
    {
      text: translation(`DATA.NO_REVIEW`),
      img: '/assets/no-review.svg',
      constant: 'NO_REVIEW',
    },
    {
      text: translation(`DATA.NO_USER`),
      img: '/assets/no-user.svg',
      constant: 'NO_USER',
    },
  ];
  // Find the matching object based on the constant prop
  const selectedData = data.find((item) => item.constant === constant);
  return (
    <div>
      <div className="noData">
        <img
          src={
            process.env.PUBLIC_URL + selectedData?.img ||
            process.env.PUBLIC_URL + '/assets/noData.svg'
          }
          alt=""
        />
        <span className="no-data-txt">
          {' '}
          {selectedData?.text || translation(`HOME.NO_DATA`)}
        </span>
      </div>
    </div>
  );
};

export default NoDataComponent;
