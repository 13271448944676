import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { PathConstants } from '../../constants/pathConstants';
import { getAllFacilities } from '../../pages/loginPage/authSlice';
import { IRoleIds, IUser } from '../../pages/loginPage/loginInterface';
import { roleChecker } from '../../_helpers/checkerFunctions';

const HomeRouteWrap: React.FC<{
  children: JSX.Element;
  hostName: string;
  loggedInUser: IUser;
  roleIds: IRoleIds;
}> = ({ children, hostName, loggedInUser, roleIds }) => {
  const { activeFacility, filter } = useAppSelector(
    (state) => state.authentication
  );
  const disapatch = useAppDispatch();
  useEffect(() => {
    disapatch(getAllFacilities(filter));
  }, []);
  return roleChecker(
    loggedInUser?.roleId,
    roleIds.supportBusinessAdminRoleId
  ) ? (
    <Navigate to={PathConstants.BUSINESS_BOOKINGS} />
  ) : roleChecker(loggedInUser?.roleId, roleIds.facilityUserRoleId) ? (
    <Navigate to={`/administration/activities/${activeFacility?.id}`} />
  ) : roleChecker(loggedInUser?.roleId, roleIds.facilityAdminRoleId) ? (
    children
  ) : null;
};

export default HomeRouteWrap;
