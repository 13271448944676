import { ResponsiveLine } from '@nivo/line';
import React, { useEffect, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Col } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { totalSupportBuisneessByMonth } from '../../pages/dashboard/dashboardSlice';
import DashboardNoData from './dashboardNoData';

export const SupportBuisness: React.FC = () => {
  const { allTotalCountSupprtBuisness, isGetTotalSupportBuisness } =
    useAppSelector((state) => state.dasboardInfo);
  const [searchParams, setSearchParams] = useSearchParams();
  const [facilityId, setFacilityId] = useState(searchParams.get('f_id'));
  const [translation] = useTranslation('common');

  useEffect(() => {
    if (facilityId) {
      dispatch(totalSupportBuisneessByMonth(facilityId));
    }
  }, [facilityId]);
  const dispatch = useAppDispatch();

  const guest =
    allTotalCountSupprtBuisness &&
    allTotalCountSupprtBuisness
      .slice()
      .sort((a: any, b: any) => (a.month > b.month ? 1 : -1))

      .map((item: any) => {
        const newMonthName = item?.monthName?.slice(0, 3);
        const lowercase = newMonthName.toLowerCase();

        const upperCaseMonthName =
          lowercase.charAt(0).toUpperCase() + lowercase.slice(1);
        return {
          x: upperCaseMonthName,
          year: item?.year,
          y: item.count,
        };
      });
  const guestName =
    guest &&
    guest.slice().sort((a: any, b: any) => (a?.year <= b?.year ? 1 : -1));

  const location = [
    {
      id: 'japan',
      color: 'hsl(247, 70%, 50%)',
      data: guestName,
    },
  ];
  const theme = {
    axis: {
      fontSize: '14px',
      tickColor: '',
      ticks: {
        line: {
          stroke: '#555555',
        },
        text: {
          fill: '#777777',
        },
      },
      legend: {
        text: {
          fill: '#F0F0F5',
          legendPosition: 'middle',
        },
      },
    },
    grid: {
      line: {
        stroke: '#F0F0F5',
      },
    },
  };
  const datas = [
    {
      id: 'japan',
      color: 'hsl(247, 70%, 50%)',
      data: [
        {
          x: 'plane',
          y: 120,
        },
        {
          x: 'helicopter',
          y: 141,
        },
        {
          x: 'boat',
          y: 259,
        },
        {
          x: 'train',
          y: 280,
        },
        {
          x: 'subway',
          y: 184,
        },
        {
          x: 'bus',
          y: 33,
        },
        {
          x: 'car',
          y: 107,
        },
        {
          x: 'moto',
          y: 80,
        },
        {
          x: 'bicycle',
          y: 18,
        },
        {
          x: 'horse',
          y: 121,
        },
        {
          x: 'skateboard',
          y: 4,
        },
        {
          x: 'others',
          y: 250,
        },
      ],
    },
  ];
  return (
    <Col lg={6} sm={12}>
      <div className="first-graph">
        <div className="graph-heading">
          {translation('DASHBOARD.NUMBER_OF_SUPPORT')}
        </div>
        {isGetTotalSupportBuisness ? (
          <div className=" content-loader-guest">
            <ContentLoader width={800} height={300} viewBox="0 0 200 200">
              <rect x="0" y="130" rx="2" ry="2" width="27" height="60" />
              <rect x="40" y="115" rx="2" ry="2" width="27" height="75" />
              <rect x="80" y="96" rx="2" ry="2" width="27" height="94" />
              <rect x="120" y="50" rx="2" ry="2" width="27" height="140" />
              <rect x="160" y="112" rx="2" ry="2" width="27" height="78" />
            </ContentLoader>
          </div>
        ) : allTotalCountSupprtBuisness?.length > 0 ? (
          <ResponsiveLine
            data={location}
            margin={{ top: 30, right: 30, bottom: 100, left: 50 }}
            xScale={{ type: 'point' }}
            yScale={{
              type: 'linear',
              min: 0,
              max: 'auto',
              stacked: true,
              reverse: false,
            }}
            theme={theme}
            // yFormat=" >-.2f"
            axisTop={null}
            axisLeft={{
              // orient: 'left',
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: '',
              legendOffset: -40,
              legendPosition: 'middle',
            }}
            axisBottom={{
              tickSize: 0,
              tickPadding: 20,
              tickRotation: 0,
              legendOffset: 36,
              legendPosition: 'middle',
              legend: '',
            }}
            enableCrosshair={false}
            enableArea={true}
            // axisLeft={null}
            enableGridX={false}
            enableGridY={false}
            pointSize={5}
            pointColor={{ theme: 'background' }}
            pointBorderWidth={2}
            pointBorderColor={{ from: 'serieColor' }}
            enablePointLabel={false}
            pointLabelYOffset={-12}
            useMesh={true}
            colors={['#00C68A']}
            tooltip={({ point }) => {
              return (
                <>
                  <div
                    style={{
                      background: 'white',
                      padding: '9px 12px',
                      fontWeight: '500',
                      boxShadow:
                        'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
                    }}
                  >
                    <div>
                      {' '}
                      {point.data.xFormatted}&nbsp;&nbsp;:&nbsp;&nbsp;
                      {point.data.yFormatted}
                    </div>
                    {/* {point.data.x}&nbsp;&nbsp;:&nbsp;&nbsp;{point.data.y} */}
                  </div>
                </>
              );
            }}
          />
        ) : (
          <div className="tab-no-data">
            <DashboardNoData name={' Data'} />
          </div>
        )}
      </div>
    </Col>
  );
};
