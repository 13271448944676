import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import FormTemplateWrap from '../../components/formTemplateWrap/formTemplateWrap';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
import PageLoader from '../../components/pageLoading/pageLoader';
import { PathConstants } from '../../constants/pathConstants';
import { roleChecker } from '../../_helpers/checkerFunctions';
import { History } from '../../_helpers/history';
import {
  // eslint-disable-next-line prettier/prettier
  isAuthenticated,
} from '../../_helpers/localStorage';
import { authenticateUser } from './authSlice';
import { ILoginForm } from './loginInterface';
import { loginSchema } from './loginValidation';

const LoginPage = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ILoginForm>({
    resolver: yupResolver(loginSchema),
  });
  const {
    isProcessingLogin,
    isProcessingUser,
    isProcessingFacilities,
    activeFacility,
    loggedInUser,
    roleIds,
    isRedirectingHome,
  } = useAppSelector((state) => state.authentication);
  const {
    supportingBusiness,
    isFetchingBusinessUnderUser,
    isRedirectingBusiness,
  } = useAppSelector((state) => state.businessAuth);
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (isAuthenticated()) {
      if (
        activeFacility?.id &&
        window.location.pathname.split('/')[1] != 'business'
      ) {
        toast.success('Successfully logged in');
        History.navigate(`/administration/activities/${activeFacility?.id}`);
      } else if (
        supportingBusiness?.id &&
        window.location.pathname.split('/')[1] == 'business'
      ) {
        toast.success('Successfully logged in');
        History.navigate('/business/bookings');
      }
      // else {
      //   clearLocalStorage();
      //   toast.error("You'r not Authorised");
      // }
    }
  }, [activeFacility, supportingBusiness]);
  const dispatch = useAppDispatch();
  const passwordIconToggler = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };
  const onSubmit = (data: ILoginForm) => {
    const payload = {
      email: data.email.toLowerCase().trim(),
      password: data.password.trim(),
    };
    dispatch(authenticateUser(payload));
  };

  const [translation] = useTranslation('common');

  return (
    <React.Fragment>
      {roleChecker(
        loggedInUser?.roleId,
        roleIds.facilityAdminRoleId,
        roleIds.facilityUserRoleId
      ) &&
        (isProcessingLogin || isProcessingUser || isProcessingFacilities) && (
          <PageLoader />
        )}
      {roleChecker(loggedInUser?.roleId, roleIds.supportBusinessAdminRoleId) &&
        (isProcessingLogin ||
          isProcessingUser ||
          isFetchingBusinessUnderUser) && <PageLoader />}
      <FormTemplateWrap>
        <>
          <div className="form-bg">
            <img
              className="padding-bottom-2rem logo"
              src={process.env.PUBLIC_URL + '/assets/logo.svg'}
              alt="Logo"
            />
            <h2 className="padding-bottom-1rem form-title">
              {translation(`LOGIN.TITLE`)}
            </h2>
            <Form className="form width-100" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup>
                <Controller
                  control={control}
                  name="email"
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      id="username"
                      className="form-input shadow-none"
                      placeholder={translation('LOGIN.EMAIL_PLACEHOLDER')}
                      autoComplete="off"
                      invalid={errors?.email ? true : false}
                      {...field}
                    />
                  )}
                />
                <FormFeedback className="validation-error">
                  {translation(`${errors.email?.message}`)}
                </FormFeedback>
              </FormGroup>
              <FormGroup className="password-hideIcon-wrap">
                <Controller
                  control={control}
                  name="password"
                  defaultValue=""
                  render={({ field }) => (
                    <Input
                      type={isPasswordVisible ? 'text' : 'password'}
                      id="password"
                      className="form-input shadow-none"
                      placeholder={translation('LOGIN.PASSWORD_PLACEHOLDER')}
                      autoComplete="off"
                      invalid={errors?.password ? true : false}
                      {...field}
                    />
                  )}
                />
                <FormFeedback className="validation-error">
                  {' '}
                  {translation(`${errors.password?.message}`)}
                </FormFeedback>
                {isPasswordVisible ? (
                  <img
                    className="password-eye"
                    src={process.env.PUBLIC_URL + '/assets/passwordEye.svg'}
                    alt=""
                    onClick={passwordIconToggler}
                  />
                ) : (
                  <img
                    className="password-eye"
                    src={process.env.PUBLIC_URL + '/assets/passwordHideEye.svg'}
                    alt=""
                    onClick={passwordIconToggler}
                  />
                )}
              </FormGroup>
              <div className="button-div">
                <Button
                  className="custom-btn form-button shadow-none"
                  type="submit"
                >
                  {isProcessingLogin ? (
                    <LoadingSpinner />
                  ) : (
                    translation('LOGIN.LOGIN_BUTTON')
                  )}
                </Button>
                <Link
                  className="d-flex align-items-center form-guide-link"
                  to={
                    window.location.pathname === PathConstants.BUSINESS_LOGIN
                      ? PathConstants.BUSINESS_FORGOT_PASSWORD
                      : PathConstants.FORGOT_PASSWORD
                  }
                >
                  {' '}
                  {translation(`LOGIN.FORGOT_PASSWORD`)}
                </Link>
              </div>
            </Form>
          </div>
          <p className="form-link-text">
            {translation('LOGIN.NO_ACCOUNT')}
            <Link
              className="px-1 form-guide-link"
              to={
                window.location.pathname === PathConstants.BUSINESS_LOGIN
                  ? PathConstants.BUSINESS_REGISTRATION
                  : PathConstants.REGISTRATION
              }
            >
              {translation('LOGIN.SIGNUP')}
            </Link>{' '}
          </p>
        </>
      </FormTemplateWrap>
    </React.Fragment>
  );
};

export default LoginPage;
