import { createSlice } from '@reduxjs/toolkit';

export interface IModalState {
  openAddModal: boolean;
  openEditModal: boolean;
  openDeleteModal: boolean;
  openQRModal: boolean;
  openDiningRepeatModal: boolean;
  openDiningDailyAddEditModal: boolean;
  openDiningRepeatAddEditModal: boolean;
  openMealDataModal: boolean;
  openAddFacilityModal: boolean;
  openImagesModal: boolean;
  openDiningCategoryModal: boolean;
  openDiningCategoryListModal: boolean;
}

const initialState: IModalState = {
  openAddModal: false,
  openEditModal: false,
  openDeleteModal: false,
  openQRModal: false,
  openDiningRepeatModal: false,
  openDiningDailyAddEditModal: false,
  openDiningRepeatAddEditModal: false,
  openMealDataModal: false,
  openAddFacilityModal: false,
  openImagesModal: false,
  openDiningCategoryModal: false,
  openDiningCategoryListModal: false,
};

export const modalSlice = createSlice({
  name: 'activities',
  initialState,
  reducers: {
    openAddModalReducer: (state) => {
      state.openAddModal = true;
    },
    openEditModalReducer: (state) => {
      state.openEditModal = true;
    },
    openDeleteModalReducer: (state) => {
      state.openDeleteModal = true;
    },
    openQRModalReducer: (state) => {
      state.openQRModal = true;
    },
    openDiningRepeatAddModalReducer: (state) => {
      state.openDiningRepeatModal = true;
    },
    openCategoryAddModalReducer: (state) => {
      state.openDiningCategoryModal = true;
    },
    openCategoryListModalReducer: (state) => {
      state.openDiningCategoryListModal = true;
    },
    openDiningDailyAddEditModalReducer: (state) => {
      state.openDiningDailyAddEditModal = true;
    },
    openDiningRepeatAddEditModalReducer: (state) => {
      state.openDiningRepeatAddEditModal = true;
    },
    openMealDataModalReducer: (state) => {
      state.openMealDataModal = true;
    },
    openAddFacilityModalReducer: (state) => {
      state.openAddFacilityModal = true;
    },
    openImagesModalReducer: (state) => {
      state.openImagesModal = true;
    },
    closeModalReducer: (state) => {
      state.openAddModal = false;
      state.openEditModal = false;
      state.openDeleteModal = false;
      state.openQRModal = false;
      state.openDiningRepeatModal = false;
      state.openDiningDailyAddEditModal = false;
      state.openDiningRepeatAddEditModal = false;
      state.openMealDataModal = false;
      state.openAddFacilityModal = false;
      state.openImagesModal = false;
      state.openDiningCategoryModal = false;
      state.openDiningCategoryListModal = false;
    },
  },
  extraReducers: {},
});

export const {
  openAddModalReducer,
  closeModalReducer,
  openEditModalReducer,
  openDeleteModalReducer,
  openQRModalReducer,
  openAddFacilityModalReducer,
  openDiningRepeatAddModalReducer,
  openDiningDailyAddEditModalReducer,
  openDiningRepeatAddEditModalReducer,
  openMealDataModalReducer,
  openImagesModalReducer,
  openCategoryAddModalReducer,
  openCategoryListModalReducer,
} = modalSlice.actions;
export const modalReducer = modalSlice.reducer;
