import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { roleChecker } from '../../_helpers/checkerFunctions';
import { History } from '../../_helpers/history';
import { clearLocalStorage } from '../../_helpers/localStorage';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { PathConstants } from '../../constants/pathConstants';
import { resetActivityState } from '../../pages/activitiesPage/activiitiesSlice';
import { resetAmenityState } from '../../pages/amenitiesPage/amenitiesSlice';
import { resetBookingsState } from '../../pages/businessBookingPage/bookingsSlice';
import { resetDiningState } from '../../pages/diningPage/diningSlice';
import { resetProfileState } from '../../pages/facilityProfilePage/profileSlice';
import {
  getSelectedFacility,
  // eslint-disable-next-line prettier/prettier
  resetAuthState,
} from '../../pages/loginPage/authSlice';
import {
  // eslint-disable-next-line prettier/prettier
  resetBusinessAuthState,
} from '../../pages/loginPage/businessAuthSlice';
import { resetBusinessRegistrationState } from '../../pages/registrationPage/businessRegistrationSlice';
import { resetUserState } from '../../pages/usersPage/usersSlice';
import ImageComp from '../imageComponents/imageComp';
import { LanguageSelector } from '../languageSelector';
import './header.css';
import ProfileDropdown from './profileDropdown';

const Header = () => {
  const [translation] = useTranslation('common');

  const { f_id } = useParams();
  const {
    currentFacilities,
    activeFacility,
    loggedInUser,
    roleIds,
    currentFacility,
  } = useAppSelector((state) => state.authentication);
  const { supportingBusiness } = useAppSelector((state) => state.businessAuth);
  const [sidebarToggle, setSidebarToggle] = useState<boolean>(false);
  const [dropdownToggle, setDropdownToggle] = useState<boolean>(false);
  const [profileDropdownToggle, setProfileDropdownToggle] =
    useState<boolean>(false);
  const [isLanguageSelector, setIsLanguageselector] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const resizeScreen = () => {
    if (window.innerWidth >= 980) {
      setSidebarToggle(false);
      setProfileDropdownToggle(false);
      setDropdownToggle(false);
    }
  };
  useEffect(() => {
    if (f_id) {
      dispatch(getSelectedFacility(f_id));
    }
  }, []);
  useEffect(() => {
    window.addEventListener('resize', resizeScreen);
    return () => {
      window.removeEventListener('resize', resizeScreen);
    };
  });

  useEffect(() => {
    setDropdownToggle(false);
  }, []);

  const dropdownToggleHandler = () => {
    setDropdownToggle(!dropdownToggle);
  };
  const profileDropdownToggleHandler = () => {
    // setProfileDropdownToggle(!profileDropdownToggle);
  };
  const allDropdownClose = () => {
    setDropdownToggle(false);
    setProfileDropdownToggle(false);
    setIsLanguageselector(false);
  };
  const sidebarDropdownClose = () => {
    setDropdownToggle(false);
    setSidebarToggle(false);
  };
  const sidebarToggleOffHandler = () => {
    setSidebarToggle(false);
    setDropdownToggle(false);
    setProfileDropdownToggle(false);
  };
  const sidebarToggleOnHandler = () => {
    setSidebarToggle(true);
  };
  const handleLogout = () => {
    setProfileDropdownToggle(false);
    clearLocalStorage();
    if (window.location.pathname.split('/')[1] === 'business') {
      dispatch(resetBusinessAuthState());
      dispatch(resetBusinessRegistrationState());
      dispatch(resetBookingsState());
      History.push(PathConstants.BUSINESS_LOGIN);
    } else {
      dispatch(resetUserState());
      dispatch(resetAuthState());
      dispatch(resetActivityState());
      dispatch(resetAmenityState());
      dispatch(resetDiningState());
      dispatch(resetProfileState());
      History.push(PathConstants.LOGIN);
    }
    toast.success('Successfully logged out');
  };

  return (
    <React.Fragment>
      <header className="header-div-fixed">
        <nav className="grid-container">
          <div
            className={
              sidebarToggle ? 'navBar-overlay active' : 'navBar-overlay'
            }
            onClick={sidebarToggleOffHandler}
          ></div>
          <div
            className={
              profileDropdownToggle || dropdownToggle || isLanguageSelector
                ? sidebarToggle
                  ? 'navBar-dropdown-overlay'
                  : 'navBar-dropdown-overlay active'
                : 'navBar-dropdown-overlay'
            }
            onClick={allDropdownClose}
          ></div>
          <div className="navBar-div">
            <NavLink
              to={
                roleChecker(loggedInUser?.roleId, roleIds.facilityAdminRoleId)
                  ? '/'
                  : '#'
              }
              className="navBar-brand"
            >
              {roleChecker(
                loggedInUser?.roleId,
                roleIds.facilityAdminRoleId,
                roleIds.facilityUserRoleId
              ) && (
                <ImageComp
                  imageId={currentFacility?.coloredLogoId}
                  className="profile-coloredLogo navBar-brand-image"
                  loadingColor="primary"
                  noImageClass=""
                  divClass=""
                  alt="facility-logo"
                  src="/assets/defaultLogoBlack.svg"
                />
              )}
              {roleChecker(
                loggedInUser?.roleId,
                roleIds.supportBusinessAdminRoleId
              ) && (
                <img
                  src={process.env.PUBLIC_URL + '/assets/away2getherLogo.svg'}
                  alt=""
                />
              )}
            </NavLink>
            <div
              className={
                sidebarToggle
                  ? 'navBar-collapse-container active'
                  : 'navBar-collapse-container'
              }
            >
              <div
                className={
                  sidebarToggle
                    ? 'navBar-dropdown-div active'
                    : 'navBar-dropdown-div'
                }
              >
                {sidebarToggle ? (
                  <>
                    <span className="navBar-navigationText">
                      {translation(`HEADER.NAVIGATION`)}
                    </span>
                    <div className="navigation-bottom-shadow"></div>
                  </>
                ) : null}
                {roleChecker(
                  loggedInUser?.roleId,
                  roleIds.facilityAdminRoleId,
                  roleIds.facilityUserRoleId
                ) && (
                  <>
                    <div
                      className={
                        dropdownToggle
                          ? 'activeNavClass navBar-administration'
                          : 'navLink-color navBar-administration'
                      }
                      onClick={dropdownToggleHandler}
                    >
                      {translation(`HEADER.ADMINISTRATION`)}
                      {/* Administration */}
                      <img
                        src={process.env.PUBLIC_URL + '/assets/carot.svg'}
                        alt=""
                      />
                    </div>
                    <div
                      className={
                        dropdownToggle
                          ? 'navBar-dropdown active'
                          : 'navBar-dropdown'
                      }
                    >
                      <NavLink
                        to={`administration/activities/${currentFacility?.id}`}
                        className={({ isActive }) =>
                          isActive ? 'activeNavClass' : 'navLink-color'
                        }
                        onClick={sidebarDropdownClose}
                      >
                        {translation(`HEADER.ACTIVITIES`)}
                      </NavLink>
                      <NavLink
                        to={`administration/amenities/${currentFacility?.id}`}
                        className={({ isActive }) =>
                          isActive ? 'activeNavClass' : 'navLink-color'
                        }
                        onClick={sidebarDropdownClose}
                      >
                        {translation(`HEADER.AMENITIES`)}
                      </NavLink>
                      <NavLink
                        to={`administration/dining-schedules/${currentFacility?.id}`}
                        className={({ isActive }) =>
                          isActive ? 'activeNavClass' : 'navLink-color'
                        }
                        onClick={sidebarDropdownClose}
                      >
                        {translation(`HEADER.DINING_SCHEDULES`)}
                      </NavLink>
                    </div>
                  </>
                )}
              </div>

              {roleChecker(
                loggedInUser?.roleId,
                roleIds.facilityAdminRoleId,
                roleIds.facilityUserRoleId
              ) && (
                <NavLink
                  to={`guests/${currentFacility?.id}`}
                  className={({ isActive }) =>
                    isActive
                      ? 'activeNavClass sidebar-underline'
                      : 'navLink-color sidebar-underline'
                  }
                  onClick={sidebarToggleOffHandler}
                >
                  {translation(`HEADER.GUESTS`)}
                </NavLink>
              )}

              {roleChecker(
                loggedInUser?.roleId,
                roleIds.facilityAdminRoleId,
                roleIds.supportBusinessAdminRoleId
              ) && (
                <NavLink
                  to={
                    roleChecker(
                      loggedInUser?.roleId,
                      roleIds.facilityAdminRoleId
                    )
                      ? `users/${currentFacility?.id}`
                      : roleChecker(
                          loggedInUser?.roleId,
                          roleIds.supportBusinessAdminRoleId
                        )
                      ? PathConstants.BUSINESS_BOOKINGS
                      : '#'
                  }
                  className={({ isActive }) =>
                    isActive
                      ? 'activeNavClass sidebar-underline'
                      : 'navLink-color sidebar-underline'
                  }
                  onClick={sidebarToggleOffHandler}
                >
                  {roleChecker(
                    loggedInUser?.roleId,
                    roleIds.facilityAdminRoleId
                  ) && translation(`HEADER.USERS`)}
                  {roleChecker(
                    loggedInUser?.roleId,
                    roleIds.supportBusinessAdminRoleId
                  ) && 'Bookings'}
                </NavLink>
              )}

              {roleChecker(
                loggedInUser?.roleId,
                roleIds.facilityAdminRoleId,
                roleIds.facilityUserRoleId
              ) && (
                <NavLink
                  to={`supporting-business/${currentFacility?.id}`}
                  className={({ isActive }) =>
                    isActive
                      ? 'activeNavClass sidebar-underline'
                      : 'navLink-color sidebar-underline'
                  }
                  onClick={sidebarToggleOffHandler}
                >
                  {translation(`HEADER.SUPPORTING_BUSINESS`)}
                </NavLink>
              )}

              {roleChecker(
                loggedInUser?.roleId,
                roleIds.facilityAdminRoleId,
                roleIds.facilityUserRoleId,
                roleIds.supportBusinessAdminRoleId
              ) && (
                <NavLink
                  to={
                    roleChecker(
                      loggedInUser?.roleId,
                      roleIds.facilityAdminRoleId,
                      roleIds.facilityUserRoleId
                    )
                      ? `rating-review/${currentFacility?.id}`
                      : PathConstants.BUSINESS_RATING_REVIEW
                  }
                  className={({ isActive }) =>
                    isActive
                      ? 'activeNavClass sidebar-underline'
                      : 'navLink-color sidebar-underline'
                  }
                  onClick={sidebarToggleOffHandler}
                >
                  {translation(`HEADER.RATING_AND_REVIEWS`)}
                </NavLink>
              )}
            </div>
            {roleChecker(
              loggedInUser?.roleId,
              roleIds.facilityAdminRoleId,
              roleIds.facilityUserRoleId,
              roleIds.supportBusinessAdminRoleId
            ) && (
              <div className="navBar-nonCollapse-div">
                {/* <NavLink to="#" className="bellIcon-div pointer">
                  <img
                    src={process.env.PUBLIC_URL + '/assets/bellIcon.svg'}
                    alt="notification"
                  />
                </NavLink> */}
                <ProfileDropdown
                  profileDropdownToggleHandler={profileDropdownToggleHandler}
                  profileDropdownToggle={profileDropdownToggle}
                  setProfileDropdownToggle={setProfileDropdownToggle}
                  sidebarToggleOffHandler={sidebarToggleOffHandler}
                  handleLogout={handleLogout}
                  currentFacilities={currentFacilities}
                  activeFacility={activeFacility}
                  supportingBusiness={supportingBusiness}
                  loggedInUser={loggedInUser}
                  roleIds={roleIds}
                />
                {roleChecker(
                  loggedInUser?.roleId,
                  roleIds.facilityAdminRoleId,
                  roleIds.facilityUserRoleId
                ) && (
                  <LanguageSelector
                    setIsLanguageselector={setIsLanguageselector}
                    isLanguageSelector={isLanguageSelector}
                  />
                )}
              </div>
            )}
          </div>

          <div>
            <div
              className="bellIcon-div hambergerIcon pointer"
              onClick={sidebarToggleOnHandler}
            >
              <img
                src={process.env.PUBLIC_URL + '/assets/hamberger.svg'}
                alt="hamberger"
              />
            </div>
            {sidebarToggle && (
              <div
                className="bellIcon-div closeIcon pointer"
                onClick={sidebarToggleOffHandler}
              >
                <img
                  src={process.env.PUBLIC_URL + '/assets/navClose.svg'}
                  alt="hamberger"
                />
              </div>
            )}
          </div>
        </nav>
      </header>
    </React.Fragment>
  );
};

export default Header;
