import { Dispatch } from '@reduxjs/toolkit';
import { getLanguageCode } from '../../_helpers/checkerFunctions';
export const translateArrayList = async (
  amenityList: any,
  language: string,
  dispatch: Dispatch,
  translateText: any,
  addTranslatedData: any,
  properties: string[]
) => {
  const strings: string[] = [];
  const newAmenityList = amenityList.map((data: any) => {
    const obj = { ...data }; // Spread the original object to create a shallow copy
    properties.forEach((property: string) => {
      obj[`${property}Index`] = strings.length; // Add the propertyIndex property
      strings.push(data[property]); // Add the property value to the strings array
    });
    return obj;
  });

  const resp = await dispatch(
    translateText({
      q: strings,
      target: getLanguageCode(language),
    })
  );

  const respArray = resp?.payload?.data?.translations;

  const objects = [];

  for (let i = 0; i < respArray.length; i += properties.length) {
    const obj: any = { ...newAmenityList[objects.length] };
    properties.forEach((property: string, index: number) => {
      if (i + index < respArray.length) {
        obj[property] = respArray[i + index]?.translatedText;
      }
    });
    objects.push(obj);
  }

  dispatch(addTranslatedData(objects));
};
