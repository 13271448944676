import React from 'react';
import { useTranslation } from 'react-i18next';
import { Input } from 'reactstrap';
import {
  IDiningDynamicInput,
  ItemTypeConstants,
} from '../../pages/diningPage/diningInterface';
import '../../pages/diningPage/diningPage.css';

const ItemsParentCardForm: React.FC<{
  getData: (name: string, value: string, index: number, id?: string) => void;
  removeItemClick: (
    index: number,
    item: IDiningDynamicInput,
    id?: string
  ) => void;
  diningItems: IDiningDynamicInput[];
  activeItemTypeButton: (type: string, id?: string) => void;
}> = ({ removeItemClick, getData, diningItems, activeItemTypeButton }) => {
  const [translation] = useTranslation('common');

  return (
    <>
      {diningItems &&
        diningItems?.map((item: IDiningDynamicInput, index: number) => (
          <div key={`${item?.id}${index}`} className="diningItems-wrap">
            <div className="deleteIcon-wrap">
              <img
                src={process.env.PUBLIC_URL + '/assets/removeIcon.svg'}
                alt="delete-icon"
                onClick={() => removeItemClick(index, item, item.id)}
              />
              <div
                className={
                  diningItems.length == index + 1
                    ? 'hide-progress-bar'
                    : 'delete-progress-bar'
                }
              ></div>
            </div>
            <div className="item-inputs">
              <Input
                name="title"
                type="text"
                className="dining-input-title shadow-none"
                placeholder={translation(`RESTAURANT.ITEM_NAME`)}
                defaultValue={item?.title}
                onChange={(e) =>
                  getData(e.target.name, e.target.value, index, item?.id)
                }
                required
                onInvalid={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity(
                    'Item Name is required'
                  )
                }
                onInput={(e) =>
                  (e.target as HTMLInputElement).setCustomValidity('')
                }
              />
              <div className="item-type-buttons">
                <div
                  className={
                    item.type == ItemTypeConstants.regular
                      ? 'exclusiveItem-button'
                      : 'normalItem-button'
                  }
                  onClick={() =>
                    activeItemTypeButton(ItemTypeConstants.regular, item.id)
                  }
                >
                  {translation(`RESTAURANT.NORMAL_ITEM`)}
                </div>
                <div
                  className={
                    item.type == ItemTypeConstants.exclusive
                      ? 'exclusiveItem-button'
                      : 'normalItem-button'
                  }
                  onClick={() =>
                    activeItemTypeButton(ItemTypeConstants.exclusive, item.id)
                  }
                >
                  {translation(`RESTAURANT.EXCLUSIVE_ITEMS`)}
                </div>
                <Input
                  name="price"
                  type="number"
                  className="dining-input-price shadow-none"
                  defaultValue={item?.price}
                  placeholder={translation(
                    'DINING_SCHEDULES.PLACEHOLDER_PRICE'
                  )}
                  onChange={(e) =>
                    getData(e.target.name, e.target.value, index, item.id)
                  }
                />
              </div>
              <Input
                name="price"
                type="number"
                className="dining-input-price mobile shadow-none"
                defaultValue={item?.price}
                placeholder={translation('DINING_SCHEDULES.PLACEHOLDER_PRICE')}
                onChange={(e) =>
                  getData(e.target.name, e.target.value, index, item.id)
                }
              />
              <Input
                type="textarea"
                maxLength={250}
                name="description"
                defaultValue={item?.description}
                className="dining-input-description  shadow-none"
                placeholder={translation(
                  'DINING_SCHEDULES.PLACEHOLDER_DESCRIPTION'
                )}
                onChange={(e) =>
                  getData(e.target.name, e.target.value, index, item.id)
                }
              />
            </div>
          </div>
        ))}
    </>
  );
};
export default ItemsParentCardForm;
