export interface IFacilityParams {
  sort: string;
  page: number;
  size: number;
}
export const getFacilityRequestParams = (
  sort: string,
  page: number,
  size: number
) => {
  const params = {} as IFacilityParams;
  if (sort) {
    params['sort'] = sort;
  }
  if (page) {
    params['page'] = page;
  }
  if (size) {
    params['size'] = size;
  }
  return params;
};
