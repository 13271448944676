import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './disabledFeaturesToggler.css';
import {
  disabledFeaturesConstants,
  IDisabledFeaturesConst,
  IDisabledFeaturesState,
  IDisabledFeaturesStateConst,
} from './disabledFeaturesTogglerConst';

const DisabledFeaturesToggler: React.FC<{
  getDisabledFeatures: (data: string[] | void[]) => void;
  parentDivClass: string;
  checkboxDivClass: string;
  checkboxClass: string;
  toggleDivClass: string;
}> = ({
  getDisabledFeatures,
  parentDivClass,
  checkboxDivClass,
  checkboxClass,
  toggleDivClass,
}) => {
  const [disabledFeatures, setDisabledFeatures] = useState<
    IDisabledFeaturesState[]
  >(IDisabledFeaturesStateConst);

  const checkedDataManipulator = (checked: boolean, id: string) => {
    let newArray: IDisabledFeaturesState[] = [];
    newArray = disabledFeatures.map((item: IDisabledFeaturesState) => {
      if (item.id == id) {
        return { ...item, checked };
      } else {
        return item;
      }
    });
    return newArray;
  };
  const [translation] = useTranslation('common');

  const checkBoxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, id } = e.target;
    setDisabledFeatures([...checkedDataManipulator(checked, id)]);
  };

  useEffect(() => {
    const filteredFeatures =
      disabledFeatures?.length > 0
        ? disabledFeatures?.filter(
            (item: IDisabledFeaturesState) => item.checked == false
          )
        : [];
    const disabledFeaturePayload =
      filteredFeatures?.length > 0
        ? filteredFeatures?.map((item: IDisabledFeaturesState) => item.value)
        : [];
    getDisabledFeatures(disabledFeaturePayload);
  }, [disabledFeatures]);

  const normalCheckbox = (
    key: number,
    htmlFor: string,
    feature: string,
    id: string,
    label: string
  ) => (
    <label htmlFor={htmlFor} className={checkboxClass} key={key}>
      <input
        id={id}
        className=""
        type="checkbox"
        value={feature}
        name={id}
        defaultChecked={true}
        // disabled
        onChange={(e) => checkBoxChange(e)}
      />
      {label}
    </label>
  );
  return (
    <div className={parentDivClass}>
      <div className={checkboxDivClass}>
        {disabledFeaturesConstants?.map(
          (feat: IDisabledFeaturesConst, index: number) =>
            normalCheckbox(
              index,
              feat.htmlFor,
              feat.feature,
              feat.id,
              feat.label
            )
        )}
      </div>
      <div className={`form-check form-switch ${toggleDivClass}`}>
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          id="connectWithGuests"
          defaultChecked={true}
          onChange={(e) => checkBoxChange(e)}
        />
        <label className="form-check-label" htmlFor="connectWithGuests">
          {translation('FACILITY.CONNECT_WITH_GUESTS')}
        </label>
      </div>
    </div>
  );
};

export default DisabledFeaturesToggler;
