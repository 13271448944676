import React from 'react';
import { convertDiningTime } from '../../pages/diningPage/utilityFunction';

const TimeComponent: React.FC<{
  startTime: string;
  endTime: string;
}> = ({ startTime, endTime }) => {
  const [firstTime, secondTime] =
    startTime <= endTime ? [startTime, endTime] : [endTime, startTime];

  return (
    <div className="restaurant-time">
      <span>{convertDiningTime(firstTime)}</span>
      <span>&nbsp;-&nbsp;</span>
      <span>{convertDiningTime(secondTime)}</span>
    </div>
  );
};

export default TimeComponent;
