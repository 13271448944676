/* eslint-disable prettier/prettier */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { default as MultiSelect } from 'react-select';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import FormTemplateWrap from '../../components/formTemplateWrap/formTemplateWrap';
import GeoLocationInput from '../../components/geoLocation/geoLoactionInput';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
import { PathConstants } from '../../constants/pathConstants';
import {
  createSupportingBusiness,
  fetchAllBusinessCategory,
} from './businessRegistrationSlice';
import { businessRegistrationSchema } from './businessValidation';
import './registration.css';
import {
  IAddress,
  IBusinessCategories,
  IBusinessRegistrationInput,
  IDocumentsType,
  ILatLong,
} from './registrationTypes';
import { businessRegistrationDataManipulator } from './utilityFunctions';

const BusinessRegistration: React.FC = () => {
  const { isProcessingBusinessRegistration, businessCategories, isSuccess } =
    useAppSelector((state) => state.businessRegistration);
  const [latLong, setLatLong] = useState<ILatLong>({} as ILatLong);
  const [mapAddress, setMapAddress] = useState<IAddress>({} as IAddress);
  const [notEmpty, setNotEmpty] = useState<boolean>(false);
  const [minPrice, setMinPrice] = useState<number>();
  const [maxPrice, setMaxPrice] = useState<number>();
  const [documentsState] = useState<IDocumentsType[]>([]);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<IBusinessRegistrationInput>({
    resolver: yupResolver(businessRegistrationSchema),
  });
  const dispatch = useAppDispatch();
  const [translation] = useTranslation('common');

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchAllBusinessCategory(null));
  }, []);

  const categoryOptions: any[] = businessCategories.map(
    (item: IBusinessCategories) => {
      return {
        value: item.id,
        label: item.name,
      };
    }
  );

  const onSubmit = (data: IBusinessRegistrationInput) => {
    const businessData = {
      ...data,
      documents: documentsState,
    };

    if (latLong.lat && latLong.lng) {
      dispatch(
        createSupportingBusiness(
          businessRegistrationDataManipulator(businessData, latLong)
        )
      );
    }
  };

  useEffect(() => {
    setMinPrice(watch('minPrice'));
    setMaxPrice(watch('maxPrice'));
  }, [watch('minPrice'), watch('maxPrice')]);

  const checkMinPrice = () => {
    if (minPrice) {
      const newMinPrice = Number(minPrice) + 1;

      return newMinPrice;
    }
  };

  return (
    <FormTemplateWrap>
      <div className="registration-form-bg">
        <h2 className="padding-bottom-1rem form-title">
          Business Registration
        </h2>
        <Form
          className="form width-100 registration-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="registration-form">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <FormGroup className="me-3">
                  <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                      <Input
                        className="registration-form-input shadow-none"
                        placeholder="Business Name"
                        defaultValue=""
                        invalid={errors?.name ? true : false}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback className="validation-error">
                    {translation(`${errors.name?.message}`)}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <FormGroup className="me-3">
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <Input
                        type="email"
                        placeholder="Email Address"
                        className="registration-form-input shadow-none"
                        defaultValue=""
                        invalid={errors?.email ? true : false}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback className="validation-error">
                    {translation(`${errors.email?.message}`)}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row className="">
              <Col sm={12} md={6} lg={6}>
                <FormGroup className="me-3">
                  <Controller
                    control={control}
                    name="category"
                    render={({ field }) => (
                      <MultiSelect
                        options={categoryOptions}
                        isMulti
                        {...field}
                        className={
                          errors.category?.message
                            ? 'category-error-class'
                            : 'category-select-box'
                        }
                        isClearable
                        isSearchable={true}
                        menuPlacement="auto"
                        placeholder="Pick Business Category"
                      />
                    )}
                  />
                  <span className="error">
                    {errors.category?.message &&
                      translation(`${errors.category?.message}`)}
                  </span>
                </FormGroup>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <FormGroup className="me-3">
                  <Controller
                    control={control}
                    name="capacity"
                    render={({ field }) => (
                      <Input
                        type="number"
                        placeholder="Enter seating capacity"
                        className="registration-form-input shadow-none"
                        // defaultValue=""
                        invalid={errors?.capacity ? true : false}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback className="validation-error">
                    {translation(`${errors.capacity?.message}`)}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row className="padding-bottom-min">
              <Col sm={12} md={6} lg={6}>
                <FormGroup className="me-3">
                  <Controller
                    control={control}
                    name="minPrice"
                    render={({ field }) => (
                      <Input
                        type="number"
                        placeholder="Minimum price"
                        className="registration-form-input shadow-none"
                        defaultValue=""
                        invalid={errors?.minPrice ? true : false}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback className="validation-error">
                    {translation(`${errors.minPrice?.message}`)}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <FormGroup className="me-3">
                  <Controller
                    control={control}
                    name="maxPrice"
                    render={({ field }) => (
                      <Input
                        type="number"
                        placeholder="Maximum price"
                        className="registration-form-input shadow-none"
                        defaultValue=""
                        invalid={errors?.maxPrice ? true : false}
                        {...field}
                        min={checkMinPrice()}
                      />
                    )}
                  />
                  <FormFeedback className="validation-error">
                    {translation(`${errors.maxPrice?.message}`)}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <h3 className="padding-bottom-1rem location-address">
              {translation('FACILITY.LOCATION_ADDRESS')}
            </h3>
            <GeoLocationInput
              placeholderText="Please find Business here"
              setMapAddress={setMapAddress}
              setLatLong={setLatLong}
              setNotEmpty={setNotEmpty}
            />
            <Row>
              <Col sm={12} md={12} lg={12}>
                <FormGroup className="me-3">
                  <Controller
                    control={control}
                    name="description"
                    render={({ field }) => (
                      <Input
                        type="textarea"
                        row="10"
                        placeholder="Business description"
                        className=" shadow-none textarea-input"
                        invalid={errors?.description ? true : false}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback className="validation-error">
                    {translation(`${errors.description?.message}`)}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </div>

          <div className="registration-button-div">
            <Button
              className="form-button shadow-none"
              type="submit"
              disabled={!notEmpty}
            >
              {isProcessingBusinessRegistration ? (
                <LoadingSpinner />
              ) : (
                translation('FACILITY.SUBMIT')
              )}
            </Button>
            <span className="padding-top-min registration-form-link-text">
              {translation('FACILITY.ALREADY_HAVE_AN_ACCOUNT')}
              <Link
                className="px-1 form-guide-link"
                to={PathConstants.BUSINESS_LOGIN}
              >
                {' '}
                &nbsp;
                {translation('LOGIN.TITLE')}
              </Link>{' '}
            </span>
          </div>
        </Form>
      </div>
    </FormTemplateWrap>
  );
};

export default BusinessRegistration;
