import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { useAppDispatch } from '../../app/hook';
import { env } from '../../config/env';
import {
  IAddNewAmenityPayload,
  IAmenitiesData,
  IAmenitiesDocSrc,
  // eslint-disable-next-line prettier/prettier
  IImageDetails,
} from '../../pages/amenitiesPage/amenitiesInterface';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import { amenitiesSchema } from './amenitiesValidation';

const AmenitiesForm: FC<{
  content?: IAmenitiesData;
  imageDetails?: IImageDetails;
  onCreate: (data: IAddNewAmenityPayload) => void;
  isAdding: boolean;
}> = ({ content, imageDetails, onCreate, isAdding }) => {
  const [imageState, setImageState] = useState<any>('');
  const [imageName, setImageName] = useState<string>('');
  const [documentFiles, setDocumentFiles] = useState<IAmenitiesDocSrc[]>([]);
  const dispatch = useAppDispatch();

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IAddNewAmenityPayload>({
    resolver: yupResolver(amenitiesSchema),
    defaultValues: {
      name: content?.name,
      description: content?.description,
      image: '',
    },
  });

  useEffect(() => {
    if (content) {
      setDocumentFiles(
        content?.documents?.map((item: IAmenitiesDocSrc) => ({
          ...item,
          fileSource: 'server',
        })) || []
      );
    }
  }, [content]);

  useEffect(() => {
    reset({
      name: content?.name,
      description: content?.description,
      image: '',
      documents: [],
    });
  }, [content]);

  useEffect(() => {
    content?.photoId && imageDetails
      ? setImageName(imageDetails?.originalName)
      : setImageName('');
  }, [content, imageDetails]);
  const [translation] = useTranslation('common');
  const onSubmit = (data: IAddNewAmenityPayload) => {
    const response = {
      ...data,
      image: imageState,
      documents: documentFiles,
    };
    onCreate(response);
  };

  const uploadAmenityImageHandler = (idName: string) => {
    document.getElementById(idName)!.click();
  };

  const uploadAmenityDocumentsHandler = (idName: string) => {
    document.getElementById(idName)!.click();
  };

  const amenityImageOnChange = (e: any) => {
    const fileObj = e.target.files;
    setImageState(Array.from(fileObj));
    setImageName(fileObj[0].name);
  };

  const amenityDocumentOnChange = (e: any) => {
    const fileObj = e?.target?.files;
    const fileType = e.target?.files[0]?.type;

    setDocumentFiles([
      ...documentFiles,
      { fileName: fileObj[0]?.name, file: fileObj, fileType: fileType },
    ]);
  };

  const handleRemoveFile = (index: number) => {
    if (documentFiles && documentFiles.length > index) {
      const updatedArray = [...documentFiles];
      updatedArray.splice(index, 1);
      setDocumentFiles(updatedArray);
    }
  };

  return (
    <React.Fragment>
      <div className="modal-div">
        <Form className="form width-100" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <Input
                  className="form-input shadow-none"
                  placeholder={translation('AMENITIES_FORM.NAME_LABEL')}
                  invalid={errors?.name ? true : false}
                  {...field}
                />
              )}
            />
            <FormFeedback>
              {translation(`${errors?.name?.message}`)}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <Input
                  type="textarea"
                  className="textarea-input shadow-none"
                  placeholder={translation('AMENITIES_FORM.DESCRIPTION_LABEL')}
                  row="10"
                  // maxLength={250}
                  invalid={errors?.description ? true : false}
                  {...field}
                />
              )}
            />
            <FormFeedback>
              {translation(`${errors?.description?.message}`)}
            </FormFeedback>
          </FormGroup>
          <div
            className="users-imageUpload-div pointer"
            onClick={() => uploadAmenityImageHandler('amenity_image')}
          >
            {imageName ? (
              <span className="uploadImage-input">
                {imageName && translation(`${imageName}`)}
              </span>
            ) : (
              <span className="uploadImage-text">
                {translation(`AMENITIES_FORM.UPLOAD_PHOTO`)}
              </span>
            )}
            <div className="">
              <img
                src={process.env.PUBLIC_URL + '/assets/uploadIcon-dark.svg'}
                alt="upload-icon"
              />
            </div>
            <input
              id="amenity_image"
              type="file"
              style={{ display: 'none' }}
              accept="image/jpeg, image/png "
              onChange={(e) => amenityImageOnChange(e)}
            />
          </div>

          <div
            className="users-imageUpload-div pointer"
            onClick={() => uploadAmenityDocumentsHandler('amenity_document')}
          >
            {
              <span className="uploadImage-text">
                {translation(`AMENITIES_FORM.UPLOAD_DOCUMENTS`)}
              </span>
            }
            <div className="">
              <img
                src={process.env.PUBLIC_URL + '/assets/uploadIcon-dark.svg'}
                alt="upload-icon"
              />
            </div>
            <input
              id="amenity_document"
              type="file"
              style={{ display: 'none' }}
              accept=".pdf, image/*"
              multiple
              onChange={(e) => amenityDocumentOnChange(e)}
            />
          </div>
          {documentFiles?.length > 0 && (
            <div className="document-file-name">
              {documentFiles?.map((item: IAmenitiesDocSrc, index: number) => (
                <div className="multi-files-wrap" key={index}>
                  <a
                    href={
                      item?.fileSource === 'server'
                        ? `${env.development.BASE_URL}/files/${item?.file}`
                        : URL.createObjectURL(
                            new Blob([item?.file[0]], {
                              type: item?.file[0]?.type,
                            })
                          )
                    }
                    download
                  >
                    <span className="uploadImage-input">
                      {item?.fileName &&
                        translation(
                          `${
                            item?.fileName?.length < 30
                              ? item?.fileName
                              : item?.fileName.substring(0, 30) + '..'
                          }`
                        )}
                    </span>
                  </a>
                  <span className="pointer">
                    <img
                      onClick={() => handleRemoveFile(index)}
                      src="/assets/deleteicon.svg"
                    ></img>
                  </span>
                </div>
              ))}
            </div>
          )}
          <div className="activityModal-separator"></div>
          <div className="modal-submit-button-div">
            <Button type="submit" className="form-button shadow-none">
              {isAdding ? (
                <LoadingSpinner />
              ) : (
                translation(`AMENITIES_FORM.SAVE_UPDATE`)
              )}
            </Button>
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default AmenitiesForm;
