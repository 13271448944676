import React, { FC } from 'react';

const FormTemplateWrap: FC<{
  children: JSX.Element;
}> = ({ children }) => {
  return (
    <div className="d-flex">
      <div className="blue-bg"></div>
      <div className="login-bg">
        <div className="form-div">{children}</div>
      </div>
    </div>
  );
};

export default FormTemplateWrap;
