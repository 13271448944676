export interface IGuestPayloadFilter {
  sort: string;
  page: number;
  size: number;
  facilityId: string;
}
export const getGuestParams = (
  sort: string,
  page: number,
  size: number,
  facilityId: string
) => {
  const params = {} as IGuestPayloadFilter;

  if (sort) {
    params['sort'] = sort;
  }
  if (page) {
    params['page'] = page;
  }
  if (size) {
    params['size'] = size;
  }
  if (facilityId) {
    params['facilityId'] = facilityId;
  }
  return params;
};
