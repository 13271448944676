import {
  IBusinessRegistrationFinalData,
  // eslint-disable-next-line prettier/prettier
  IFacilityFinalData,
} from '../pages/registrationPage/registrationTypes';
import { get, post } from './apiService';

export const registerFacility = async (data: IFacilityFinalData) => {
  return await post('/facility', data);
};
export const registerSupportingBusiness = async (
  data: IBusinessRegistrationFinalData
) => {
  return await post('/support-business/register', data);
};
export const getAllBusinessCategory = async () => {
  return await get('/support-business-category');
};
