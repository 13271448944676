/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '../../app/store';
import { closeModalReducer } from '../../components/modal/modalSlice';
import {
  approveBookings,
  declineBookings,
  getAllBookings,
  getBookedUser,
} from '../../services/bookingsService';
import { getBookingsRequestParams } from './bookingParams';
import {
  IBookingPaginate,
  IBookingsData,
  IBookingsFilter,
  IBookingsFinalData,
  IRejectBookingPayload,
} from './bookingsInterface';

export interface IBookings {
  isProcessingPendingBookings: boolean;
  isProcessingApprovedBookings: boolean;
  isProcessingRejectedBookings: boolean;
  finalPendingBookingsData: IBookingsFinalData[];
  finalApprovedBookingsData: IBookingsFinalData[];
  finalRejectedBookingsData: IBookingsFinalData[];
  isDeclining: boolean;
  declinedBooking: boolean;
  approvedBooking: boolean;
  isApproving: boolean;
  pendingFilter: IBookingsFilter;
  approvedFilter: IBookingsFilter;
  rejectedFilter: IBookingsFilter;
  pendingPaginate: IBookingPaginate;
  approvedPaginate: IBookingPaginate;
  rejectedPaginate: IBookingPaginate;
}
const initialState: IBookings = {
  isProcessingPendingBookings: false,
  isProcessingApprovedBookings: false,
  isProcessingRejectedBookings: false,
  finalPendingBookingsData: [],
  finalApprovedBookingsData: [],
  finalRejectedBookingsData: [],
  isDeclining: false,
  declinedBooking: false,
  isApproving: false,
  approvedBooking: false,
  pendingFilter: {
    status: 'PENDING',
    sort: 'createdAt,Desc',
    page: 0,
    size: 10,
    id: '',
  },
  approvedFilter: {
    status: 'APPROVED',
    sort: 'createdAt,Desc',
    page: 0,
    size: 10,
    id: '',
  },
  rejectedFilter: {
    status: 'REJECTED',
    sort: 'createdAt,Desc',
    page: 0,
    size: 10,
    id: '',
  },
  pendingPaginate: {} as IBookingPaginate,
  approvedPaginate: {} as IBookingPaginate,
  rejectedPaginate: {} as IBookingPaginate,
};

export const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    resetBookingsState: () => {
      return initialState;
    },
    addPendingPage: (state, action) => {
      state.pendingFilter.page = action.payload;
    },
    addApprovedPage: (state, action) => {
      state.approvedFilter.page = action.payload;
    },
    addRejectedPage: (state, action) => {
      state.rejectedFilter.page = action.payload;
    },
    setPendingPaginate: (state, action) => {
      state.pendingPaginate = action.payload;
    },
    setApprovedPaginate: (state, action) => {
      state.approvedPaginate = action.payload;
    },
    setRejectedPaginate: (state, action) => {
      state.rejectedPaginate = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllPendingBookings.pending, (state) => {
        state.isProcessingPendingBookings = true;
      })
      .addCase(
        fetchAllPendingBookings.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isProcessingPendingBookings = false;
          state.finalPendingBookingsData = action.payload;
        }
      )
      .addCase(
        fetchAllPendingBookings.rejected,
        (state, action: PayloadAction<any>) => {
          state.isProcessingPendingBookings = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(fetchAllApprovedBookings.pending, (state) => {
        state.isProcessingApprovedBookings = true;
      })
      .addCase(
        fetchAllApprovedBookings.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isProcessingApprovedBookings = false;
          state.finalApprovedBookingsData = action.payload;
        }
      )
      .addCase(
        fetchAllApprovedBookings.rejected,
        (state, action: PayloadAction<any>) => {
          state.isProcessingApprovedBookings = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(fetchAllRejectedBookings.pending, (state) => {
        state.isProcessingRejectedBookings = true;
      })
      .addCase(
        fetchAllRejectedBookings.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isProcessingRejectedBookings = false;
          state.finalRejectedBookingsData = action.payload;
        }
      )
      .addCase(
        fetchAllRejectedBookings.rejected,
        (state, action: PayloadAction<any>) => {
          state.isProcessingRejectedBookings = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(declineBookingsAction.pending, (state) => {
        state.isDeclining = true;
        state.declinedBooking = false;
      })
      .addCase(
        declineBookingsAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isDeclining = false;
          state.declinedBooking = true;

          toast.success(
            `${action.payload?.message
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload?.message.slice(1)}`
          );
        }
      )
      .addCase(
        declineBookingsAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.isDeclining = false;
          state.declinedBooking = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(approveBookingsAction.pending, (state) => {
        state.isApproving = true;
        state.approvedBooking = false;
      })
      .addCase(
        approveBookingsAction.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isApproving = false;
          state.approvedBooking = true;

          toast.success(
            `${action.payload?.message
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload?.message.slice(1)}`
          );
        }
      )
      .addCase(
        approveBookingsAction.rejected,
        (state, action: PayloadAction<any>) => {
          state.isApproving = false;
          state.approvedBooking = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      );
  },
});

export const fetchAllPendingBookings = createAsyncThunk(
  'bookings/fetchAllPendingBookings',
  async (data: IBookingsFilter, { rejectWithValue, dispatch }) => {
    try {
      const params = getBookingsRequestParams(
        data.status,
        data.sort,
        data.page,
        data.size,
        data.id
      );
      const response = await getAllBookings(params);
      dispatch(setPendingPaginate(response?.data[0]));
      let bookingsArray = [];
      bookingsArray = response.data[0]?.data;
      const newBookingArray = await Promise.all(
        bookingsArray.map(async (item: IBookingsData) => {
          const userResponse = await getBookedUser(item.userId);
          return { ...userResponse?.data[0], ...item };
        })
      );
      return newBookingArray;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const fetchAllApprovedBookings = createAsyncThunk(
  'bookings/fetchAllApprovedBookings',
  async (data: IBookingsFilter, { rejectWithValue, dispatch }) => {
    try {
      const params = getBookingsRequestParams(
        data.status,
        data.sort,
        data.page,
        data.size,
        data.id
      );
      const response = await getAllBookings(params);
      dispatch(setApprovedPaginate(response?.data[0]));
      let bookingsArray = [];
      bookingsArray = response.data[0]?.data;
      const newBookingArray = await Promise.all(
        bookingsArray.map(async (item: IBookingsData) => {
          const userResponse = await getBookedUser(item.userId);
          return { ...userResponse?.data[0], ...item };
        })
      );
      return newBookingArray;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);
export const fetchAllRejectedBookings = createAsyncThunk(
  'bookings/fetchAllRejectedBookings',
  async (data: IBookingsFilter, { rejectWithValue, dispatch }) => {
    try {
      const params = getBookingsRequestParams(
        data.status,
        data.sort,
        data.page,
        data.size,
        data.id
      );
      const response = await getAllBookings(params);
      dispatch(setRejectedPaginate(response?.data[0]));
      let bookingsArray = [];
      bookingsArray = response.data[0]?.data;
      const newBookingArray = await Promise.all(
        bookingsArray.map(async (item: IBookingsData) => {
          const userResponse = await getBookedUser(item.userId);
          return { ...userResponse?.data[0], ...item };
        })
      );
      return newBookingArray;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const approveBookingsAction = createAsyncThunk(
  'bookings/approveBookings',
  async (id: string, thunkAPI) => {
    try {
      const response = await approveBookings(id);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.message);
    }
  }
);
export const declineBookingsAction = createAsyncThunk(
  'bookings/declineBookingsAction',
  async (data: IRejectBookingPayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await declineBookings(data);
      dispatch(closeModalReducer());
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const {
  resetBookingsState,
  setPendingPaginate,
  setApprovedPaginate,
  setRejectedPaginate,
  addPendingPage,
  addApprovedPage,
  addRejectedPage,
} = bookingsSlice.actions;
export const selectBookings = (state: RootState) => state.bookings;
export const bookingsReducer = bookingsSlice.reducer;
