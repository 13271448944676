/* eslint-disable prettier/prettier */
import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { useAppDispatch } from '../../app/hook';
import { closeModalReducer } from './modalSlice';

export const DiningModalWrap: React.FC<{
  isOpen: boolean;
  modalHeading: string;
}> = ({ isOpen, modalHeading, children }) => {
  const [translation] = useTranslation('common');
  const dispatch = useAppDispatch();
  return ReactDOM.createPortal(
    <div className="container-fluid">
      <Modal
        isOpen={isOpen}
        fade={true}
        centered={true}
        size="xl"
        className="dining-modal"
      >
        <div className="dining-modal-header">
          <h2 className="modal-heading">
            {modalHeading && translation(modalHeading)}
          </h2>
          <div onClick={() => dispatch(closeModalReducer())}>
            <img
              className="pointer"
              src={process.env.PUBLIC_URL + '/assets/closeModalIcon.svg'}
              alt="close-icon"
            />
          </div>
        </div>
        {children}
      </Modal>
    </div>,
    document.getElementById('modal-root')!
  );
};
