export interface IUsersParams {
  userName: string;
  sort: string;
  page: number;
  size: number;
  id?: string;
  facilityId?: string;
}
export const getUsersRequestParams = (
  userName: string,
  sort: string,
  page: number,
  size: number,
  id?: string,
  facilityId?: string
) => {
  const params = {} as IUsersParams;
  if (userName) {
    params['userName'] = userName;
  }
  if (sort) {
    params['sort'] = sort;
  }
  if (page) {
    params['page'] = page;
  }
  if (size) {
    params['size'] = size;
  }
  if (id) {
    params['id'] = id;
  }
  if (facilityId) {
    params['facilityId'] = facilityId;
  }
  return params;
};

export interface IMealsParams {
  page: number;
  size: number;
}
export const getMealsRequestParams = (page: number, size: number) => {
  const params = {} as IMealsParams;

  if (page) {
    params['page'] = page;
  }
  if (size) {
    params['size'] = size;
  }

  return params;
};
