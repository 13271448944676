export interface IDisabledFeaturesConst {
  feature: string;
  htmlFor: string;
  id: string;
  label: string;
}
export interface IDisabledFeaturesState {
  id: string;
  value: string;
  checked: boolean;
}
export const IDisabledFeaturesStateConst: IDisabledFeaturesState[] = [
  {
    id: 'amenities',
    value: 'AMENITIES',
    checked: true,
  },
  {
    id: 'activities',
    value: 'ACTIVITIES',
    checked: true,
  },
  {
    id: 'diningSchedule',
    value: 'DINING_SCHEDULE',
    checked: true,
  },
  {
    id: 'connectWithGuests',
    value: 'CONNECT_WITH_GUESTS',
    checked: true,
  },
];
export const disabledFeaturesConstants: IDisabledFeaturesConst[] = [
  {
    feature: 'AMENITIES',
    htmlFor: 'amenities',
    id: 'amenities',
    label: 'Amenities',
  },
  {
    feature: 'ACTIVITIES',
    htmlFor: 'activities',
    id: 'activities',
    label: 'Activities',
  },
  {
    feature: 'DINING_SCHEDULE',
    htmlFor: 'diningSchedule',
    id: 'diningSchedule',
    label: 'Dining Schedule',
  },
];
