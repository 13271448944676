import * as yup from 'yup';

export const activitiesSchema = yup
  .object({
    name: yup
      .string()
      .max(200, 'Maximum 200 characters')
      .required('ACTIVITIES_FORM.NAME_REQUIRED'),
    startDate: yup.string().required('ACTIVITIES_FORM.START_DATE_REQUIRED'),
    startTime: yup.string().required('ACTIVITIES_FORM.START_TIME_REQUIRED'),
    endDate: yup.string().required('ACTIVITIES_FORM.END_DATE_REQUIRED'),
    endTime: yup.string().required('ACTIVITIES_FORM.END_TIME_REQUIRED'),
    location: yup.string().max(200, 'Maximum 200 characters').nullable(),
    description: yup.string().max(500, 'Maximum 500 characters').nullable(),
  })
  .required();

export const activitiesSchemaTwo = yup
  .object({
    name: yup
      .string()
      .max(200, 'Maximum 200 characters')
      .required('ACTIVITIES_FORM.NAME_REQUIRED'),
    startDate: yup.string(),
    startTime: yup.string().required('ACTIVITIES_FORM.START_TIME_REQUIRED'),
    endDate: yup.string(),
    endTime: yup.string().required('ACTIVITIES_FORM.END_TIME_REQUIRED'),
    location: yup.string().max(200, 'Maximum 200 characters').nullable(),
    description: yup.string().max(500, 'Maximum 500 characters').nullable(),
  })
  .required();
