export interface IBusinessParams {
  // sort: string;
  page: number;
  size: number;
  latitude: string;
  longitude: string;
  distanceInKm: string;
  status: string;
  category?: string;
  facilityId?: string;
}
export const getLocalBusinessParams = (
  latitude: string,
  longitude: string,
  distanceInKm: string,
  // sort: string,
  page: number,
  size: number,
  status: string,
  category?: string,
  facilityId?: string
) => {
  const params = {} as IBusinessParams;

  if (latitude) {
    params['latitude'] = latitude;
  }
  if (longitude) {
    params['longitude'] = longitude;
  }
  if (distanceInKm) {
    params['distanceInKm'] = distanceInKm;
  }
  if (page) {
    params['page'] = page;
  }
  if (size) {
    params['size'] = size;
  }
  if (status) {
    params['status'] = status;
  }
  if (category) {
    params['category'] = category;
  }
  if (facilityId) {
    params['facilityId'] = facilityId;
  }

  return params;
};
