import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { translateArrayList } from '../../components/cloudTranslationCommon/translation';
import ReviewImageWrap from '../../components/imageComponents/facilityReviewImage/reviewImageWrap';
import { ModalComponent } from '../../components/modal';
import { openImagesModalReducer } from '../../components/modal/modalSlice';
import NoDataComponent from '../../components/noDataComponent/noDataComponent';
import PageLoader from '../../components/pageLoading/pageLoader';
import Paginate, {
  // eslint-disable-next-line prettier/prettier
  IPaginateClickData,
} from '../../components/paginate/paginate';
import RatingStar from '../../components/ratingStar/ratingStar';
import { translateText } from '../loginPage/authSlice';
import '../ratingsReviewsPage/ratingsReviews.css';
import { IFaciltiyReviewsData } from './facilityReviewsInterface';
import {
  addFilterPage,
  addTranslatedData,
  clearFacilityReviewStats,
  facilityReviewPageFilterReset,
  fetchAllFacilityReviews,
  // eslint-disable-next-line prettier/prettier
  fetchAvgFacilityReviewsStats,
} from './facilityReviewsSlice';
import UserComponent from './userComponent';
import { facilityReviewPayloadSetter } from './utilityFunctions';

const FacilityReviews: React.FC = () => {
  const { f_id } = useParams();
  const {
    facilityReviewsFilter,
    facilityReviewStats,
    facilityReviewsData,
    isProcessingFacilityReviews,
    facilityReviewsPaginate,
    facilityReviewByIdData,
  } = useAppSelector((state) => state.facilityReviews);
  const { openImagesModal } = useAppSelector((state) => state.modal);
  const [imageIds, setImageIds] = useState<string[] | undefined>();
  const [reviewId, setReviewId] = useState<string>('');
  const [change, setChange] = useState<any>(false);
  const imageId = facilityReviewByIdData?.photoIds;
  const [translation] = useTranslation('common');
  const [t, i18n] = useTranslation('common');

  const language = i18n.language;
  // translation

  useEffect(() => {
    if (language !== 'en' && facilityReviewsData?.length > 0) {
      translateArrayList(
        facilityReviewsData,
        language,
        dispatch,
        translateText,
        addTranslatedData,
        ['review'] // Pass the properties that need to be translated
      );
    }
  }, [language, isProcessingFacilityReviews]);
  useEffect(() => {
    if (f_id) {
      language === 'en' &&
        dispatch(
          fetchAllFacilityReviews(
            facilityReviewPayloadSetter(facilityReviewsFilter, f_id)
          )
        );
    }
  }, [language, facilityReviewsFilter]);
  // --------
  useEffect(() => {
    if (language !== 'en') {
      return () => {
        dispatch(addTranslatedData([]));
      };
    }
  }, []);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(facilityReviewPageFilterReset());
    dispatch(clearFacilityReviewStats());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (f_id) {
      dispatch(
        fetchAllFacilityReviews(
          facilityReviewPayloadSetter(facilityReviewsFilter, f_id)
        )
      );
      dispatch(fetchAvgFacilityReviewsStats(f_id));
    }
  }, [facilityReviewsFilter, f_id]);

  const handlePageClick = (data: IPaginateClickData) => {
    if (data.selected !== facilityReviewsPaginate.totalPages)
      dispatch(addFilterPage(data?.selected));
  };
  const handleImageModal = (photoIds: any) => {
    setImageIds(photoIds);
    dispatch(openImagesModalReducer());
  };

  return (
    <div className="page-background amenities-page">
      <div className="amenitiesPage-head ratingsreviews-head">
        <h2>{translation('HEADER.RATING_AND_REVIEWS')}</h2>
        <div className="avg-reviews-ratings">
          {facilityReviewsData?.length >= 1
            ? facilityReviewStats && (
                <>
                  {facilityReviewStats?.reviewCount > 0 && (
                    <div>
                      {facilityReviewStats?.reviewCount}&nbsp;&nbsp;&nbsp;
                      <span>{translation('RATING.REVIEWS')}</span>
                    </div>
                  )}
                  {facilityReviewStats?.averageRating > 0 && (
                    <div className="avg-ratings">
                      <span className="blue-rating-font">
                        {facilityReviewStats?.averageRating?.toFixed(1)}
                      </span>
                      &nbsp;
                      <RatingStar
                        rating={`${facilityReviewStats?.averageRating}`}
                      />
                    </div>
                  )}
                </>
              )
            : null}
        </div>
      </div>
      {isProcessingFacilityReviews ? (
        <PageLoader />
      ) : facilityReviewsData?.length ? (
        <div className="review-card-parent facility-reviews-parent-card">
          {facilityReviewsData?.map(
            (item: IFaciltiyReviewsData, index: number) => (
              <div key={index} className="facility-reviews-card">
                <div className="review-card-flex-column">
                  <div className="review-card-flex-row-spaceBetween padding-bottom-min">
                    <UserComponent userId={item?.userId} />
                    <div className="review-card-flex-row fit-content smallScreen-rating">
                      <span className="blue-rating-font">{item?.rating}</span>
                      <RatingStar rating={`${item?.rating}`} />
                    </div>
                  </div>
                  <div className="padding-top-1rem review-description facility-review-description">
                    {item?.review ? (
                      <span>{item?.review}</span>
                    ) : (
                      <span className="no-reviews-class">
                        {' '}
                        {translation('RATING.NO_REVIEWS')}
                      </span>
                    )}
                  </div>
                  {item?.photoIds?.length >= 1 && (
                    <div className="padding-top-left-min">
                      <span
                        className="restaurant-view-more pointer"
                        onClick={() => {
                          handleImageModal(item?.photoIds),
                            setReviewId(item?.id);
                        }}
                      >
                        {translation('RATING.VIEW_GALLERY')}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      ) : (
        <NoDataComponent constant="NO_REVIEW" />
      )}
      {openImagesModal && imageIds && (
        <ModalComponent
          isOpen={openImagesModal}
          modalHeading={translation('RATING.GALLERY')}
        >
          <ReviewImageWrap reviewId={reviewId} setChange={setChange} />
        </ModalComponent>
      )}
      {facilityReviewsPaginate?.totalPages > 1 && (
        <Paginate
          handlePageClick={handlePageClick}
          totalPage={facilityReviewsPaginate?.totalPages}
        />
      )}
    </div>
  );
};

export default FacilityReviews;
