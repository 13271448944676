import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { IFacility } from '../../pages/loginPage/loginInterface';

const QrCode: FC<{
  content: IFacility;
  onCreate: null;
  isAdding: null;
}> = ({ content }) => {
  const [translation] = useTranslation('common');
  return (
    <div className="qr-code d-flex flex-column align-items-center">
      <div className="qr-code-div">
        <span>{translation('QR_CODE.SCAN_TEXT')}</span>
        <QRCode value={content?.facilityId} size={200} />
      </div>
      <div className="qr-code-underline"></div>
      <span>{translation('QR_CODE.UNIQUE_PROPERTY_IDENTIFIER')}</span>
      <p>{content?.facilityId ? content?.facilityId : 'AT12345'}</p>
    </div>
  );
};

export default QrCode;
