import React from 'react';
import { env } from '../../config/env';

const ImageComp: React.FC<{
  imageId?: string | undefined;
  className?: string;
  divClass?: string;
  noImageClass?: string;
  noImageDivClass?: string;
  alt?: string;
  src?: string;
  loadingColor?: string;
  loaderNeeded?: boolean;
}> = ({
  className,
  alt,
  src,
  imageId,
  divClass,
  noImageClass,
  noImageDivClass,
  loadingColor,
  loaderNeeded,
}) => {
  return !imageId ? (
    <div className={noImageDivClass}>
      <img className={noImageClass} src={process.env.PUBLIC_URL + src} alt="" />
    </div>
  ) : (
    <div className={divClass}>
      <img
        className={className}
        src={`${env.development.BASE_URL}/files/${imageId}`}
        alt=""
        crossOrigin=""
      />
    </div>
  );
};

export default ImageComp;
