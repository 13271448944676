export const env = {
  development: {
    BASE_URL: process.env.REACT_APP_API_BASE_URL,
  },
};

// If incase of a new Domain, make changes to the domain_env:
export const domain_env = {
  // For facility app
  FACILITY_DOMAINS: [
    'facility-dev.away2gether.com',
    'facility-uat.away2gether.com',
    'facility.away2gether.com',
    'www.facility.away2gether.com',
  ],
  // For business app
  BUSINESS_DOMAINS: [
    'support-dev.away2gether.com',
    'support-uat.away2gether.com',
    'business.away2gether.com',
    'www.business.away2gether.com',
  ],
  // For running in local machine
  LOCALHOST: ['localhost'],
};
