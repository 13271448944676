import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../../app/hook';
import {
  deleteLastRatingPhoto,
  deleteRatingPhoto,
  fetchFacilityReviewImageById,
} from '../../../pages/facilityReviewsPage/facilityReviewsSlice';
import ImageLoadingSpinner from '../../loadingSpinner/imageLoadingSpinner';

const ReviewImageComp: React.FC<{
  imageId: string | undefined;
  className?: string;
  divClass?: string;
  noImageClass?: string;
  alt?: string;
  src?: string;
  reviewId?: string;
  compare?: any;
  imageIds?: any;
  equal?: (data: string) => void;
  loadingColor?: string;
  setPics?: (data: string) => void;
  setDefaulImage?: (data: string) => void;
  setChange?: any;
}> = ({
  className,
  alt,
  src,
  imageId,
  divClass,
  noImageClass,
  reviewId,
  setChange,
  imageIds,
  loadingColor,
  compare,
  equal,
  setPics,
  setDefaulImage,
}) => {
  const {
    isProcessingFacilityReviewImageById,
    isDeleteReiviewImage,
    facilityReviewsFilter,
    facilityReviewByIdData,
  } = useAppSelector((state) => state.facilityReviews);
  const { f_id } = useParams();

  const dispatch = useAppDispatch();
  const [image, setImage] = useState<any>();
  const [translation] = useTranslation('common');

  useEffect(() => {
    const getImage = async () => {
      if (imageId) {
        const response = await dispatch(fetchFacilityReviewImageById(imageId));

        setImage(response.payload);
      }
    };
    getImage();
  }, [imageId]);
  const deletePhoto = () => {
    if (imageId && reviewId) {
      dispatch(deleteRatingPhoto({ imageId, reviewId }));

      if (
        facilityReviewByIdData?.photoIds?.length == 1 &&
        reviewId &&
        imageId &&
        f_id
      ) {
        dispatch(
          deleteLastRatingPhoto({
            imageId,
            reviewId,
            id: f_id,
            filter: facilityReviewsFilter,
          })
        );
      }
    }
  };
  return !imageId ? (
    <div className={divClass}>
      <img className={noImageClass} src={process.env.PUBLIC_URL + src} alt="" />
    </div>
  ) : isProcessingFacilityReviewImageById ? (
    <div className={divClass}>
      <ImageLoadingSpinner color={'primary'} />
    </div>
  ) : (
    <div className={divClass}>
      <img
        className={className}
        src={image}
        alt=""
        onClick={() => {
          setPics && setPics(image);
          setDefaulImage && setDefaulImage(image);
          equal && equal(imageId);
        }}
      />

      {isDeleteReiviewImage ? (
        <div className="gallery-image-wrap-2"></div>
      ) : (
        <div className="review-image-delete-icon-2">
          <img
            src={process.env.PUBLIC_URL + '/assets/reviewDelete.svg'}
            alt="delete-icon"
            onClick={() => {
              deletePhoto();
              setChange && setChange(true);
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ReviewImageComp;
