import { IBusinessProfilePayload } from '../pages/businessProfilePage/businessProfileInterface';
import { IForgotPassword } from '../pages/forgotPassword/interface';
import { ILogin } from '../pages/loginPage/loginInterface';
import { get, getImageApi, post, put } from './apiService';

export const settingsApi = async () => {
  return await get('/settings');
};
export const authenticate = async (data: ILogin) => {
  return await post('/auth/login', data);
};
export const forgotPassword = async (data: IForgotPassword) => {
  return await post('/auth/forgot-password', data);
};
export const getSupportingBusiness = async () => {
  return await get('/support-business/me');
};
export const getBusinessImage = async (id: string | number) => {
  return await getImageApi(`/files/${id}`);
};
export const getBusinessCategory = async (ids: string) => {
  return await get(`/support-business-category?categoryIds=${ids}`);
};
export const getBusinessImageId = async (data: any) => {
  return await post(`/files`, data);
};
export const updateProfile = async (data: IBusinessProfilePayload) => {
  return await put(`/support-business/${data.id}`, data);
};
