import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../components/header/header';
import PrivateRoute from '../../_helpers/privateRoute';
import './landingPage.css';

const LandingPage: React.FC<{
  hostName: string;
}> = ({ hostName }) => {
  return (
    <div>
      <Header />
      <div className="landingPage app-margin">
        <PrivateRoute hostName={hostName}>
          <Outlet />
        </PrivateRoute>
      </div>
    </div>
  );
};

export default LandingPage;
