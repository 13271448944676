/* eslint-disable prettier/prettier */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Row,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import FormTemplateWrap from '../../components/formTemplateWrap/formTemplateWrap';
import GeoLocationInput from '../../components/geoLocation/geoLoactionInput';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
import DisabledFeaturesToggler from '../../components/toggleButtonComp/disabledFeaturesToggler';
import { PathConstants } from '../../constants/pathConstants';
import './registration.css';
import { createFacility } from './registrationSlice';
import { IAddress, IFacilityRawData, ILatLong } from './registrationTypes';
import { registrationSchema } from './registrationValidation';
import { getFacilityCreationPayload } from './utilityFunctions';

const RegistrationPage: React.FC = () => {
  const [translation] = useTranslation('common');
  const { isProcessingRegistration, isSuccess } = useAppSelector(
    (state) => state.registration
  );
  const [latLong, setLatLong] = useState<ILatLong>({} as ILatLong);
  const [mapAddress, setMapAddress] = useState<IAddress>({} as IAddress);
  const [notEmpty, setNotEmpty] = useState<boolean>(false);
  const [disabledFeatures, setDisabledFeatures] = useState<string[] | void[]>(
    []
  );
  const {
    handleSubmit,
    reset,
    watch,
    control,
    formState: { errors },
  } = useForm<IFacilityRawData>({
    resolver: yupResolver(registrationSchema),
    defaultValues: {
      addressLine1: mapAddress.addressLine1,
      addressLine2: mapAddress.addressLine2,
      addressLine3: mapAddress.addressLine3,
      zip: mapAddress.zip,
    },
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    reset({
      name: watch('name'),
      email: watch('email'),
      facilityNumber: watch('facilityNumber'),
      phoneNo: watch('phoneNo'),
      addressLine1: mapAddress.addressLine1,
      addressLine2: mapAddress.addressLine2,
      addressLine3: mapAddress.addressLine3,
      zip: mapAddress?.zip,
    });
  }, [latLong, mapAddress]);
  useEffect(() => {
    if (isSuccess)
      reset({
        email: '',
        name: '',
        phoneNo: '',
        addressLine1: '',
        addressLine2: '',
        addressLine3: '',
        zip: '',
        facilityNumber: '',
      });
  }, [isSuccess]);

  const onSubmit = (formData: IFacilityRawData) => {
    dispatch(
      createFacility(
        getFacilityCreationPayload(formData, latLong, disabledFeatures)
      )
    );
  };

  return (
    <FormTemplateWrap>
      <div className="registration-form-bg">
        <h2 className="padding-bottom-1rem form-title">
          {translation(`REGISTRATION.TITLE`)}
        </h2>
        <Form
          className="form width-100 registration-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="registration-form">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <FormGroup className="me-3">
                  <Controller
                    control={control}
                    name="name"
                    render={({ field }) => (
                      <Input
                        className="registration-form-input shadow-none"
                        placeholder={translation('FACILITY.NAME_PLACEHOLDER')}
                        defaultValue=""
                        invalid={errors?.name ? true : false}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback className="validation-error">
                    {translation(`${errors.name?.message}`)}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <FormGroup className="me-3">
                  <Controller
                    control={control}
                    name="facilityNumber"
                    render={({ field }) => (
                      <Input
                        placeholder={translation(
                          'FACILITY.FACILITY_NUMBER_PLACEHOLDER'
                        )}
                        className="registration-form-input shadow-none"
                        defaultValue=""
                        invalid={errors?.facilityNumber ? true : false}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback className="validation-error">
                    {translation(`${errors.facilityNumber?.message}`)}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row className="padding-bottom-min">
              <Col sm={12} md={6} lg={6}>
                <FormGroup className="me-3">
                  <Controller
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <Input
                        type="email"
                        placeholder={translation('FACILITY.EMAIL_PLACEHOLDER')}
                        className="registration-form-input shadow-none"
                        defaultValue=""
                        invalid={errors?.email ? true : false}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback className="validation-error">
                    {translation(`${errors.email?.message}`)}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <FormGroup className="me-3">
                  <Controller
                    control={control}
                    name="phoneNo"
                    render={({ field }) => (
                      <Input
                        placeholder={translation('FACILITY.PHONE_PLACEHOLDER')}
                        className="registration-form-input shadow-none"
                        defaultValue=""
                        invalid={errors?.phoneNo ? true : false}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback className="validation-error">
                    {translation(`${errors.phoneNo?.message}`)}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <h3 className="padding-bottom-1rem location-address">
              {translation('FACILITY.LOCATION_ADDRESS')}
            </h3>
            <GeoLocationInput
              placeholderText="Please find Facility here"
              setMapAddress={setMapAddress}
              setLatLong={setLatLong}
              setNotEmpty={setNotEmpty}
            />
            <Row>
              <Col sm={12} md={6} lg={6}>
                <FormGroup className="me-3">
                  <Controller
                    control={control}
                    name="addressLine1"
                    render={({ field }) => (
                      <Input
                        placeholder={translation(
                          'FACILITY.ADDRESS_LINE1_PLACEHOLDER'
                        )}
                        className="registration-form-input shadow-none"
                        disabled={mapAddress.addressLine1 !== ''}
                        invalid={errors?.addressLine1 ? true : false}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback className="validation-error">
                    {translation(`${errors.addressLine1?.message}`)}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <FormGroup className="me-3">
                  <Controller
                    control={control}
                    name="addressLine2"
                    render={({ field }) => (
                      <Input
                        placeholder={translation(
                          'FACILITY.ADDRESS_LINE2_PLACEHOLDER'
                        )}
                        className="registration-form-input shadow-none"
                        disabled={mapAddress.addressLine2 !== ''}
                        invalid={errors?.addressLine2 ? true : false}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback className="validation-error">
                    {translation(`${errors.addressLine2?.message}`)}
                  </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6} lg={6}>
                <FormGroup className="me-3">
                  <Controller
                    control={control}
                    name="addressLine3"
                    render={({ field }) => (
                      <Input
                        className="registration-form-input shadow-none"
                        placeholder={translation(
                          'FACILITY.ADDRESS_LINE3_PLACEHOLDER'
                        )}
                        disabled={mapAddress.addressLine3 !== ''}
                        invalid={errors?.addressLine3 ? true : false}
                        {...field}
                      />
                    )}
                  />
                  <FormFeedback className="validation-error">
                    {translation(`${errors.addressLine3?.message}`)}
                  </FormFeedback>
                </FormGroup>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <FormGroup className="me-3">
                  <Controller
                    control={control}
                    name="zip"
                    render={({ field }) => (
                      <Input
                        className="registration-form-input shadow-none"
                        placeholder={translation(
                          'FACILITY.ADDRESS_LINE4_PLACEHOLDER'
                        )}
                        disabled={mapAddress.zip !== ''}
                        // invalid={errors?.zip ? true : false}
                        {...field}
                      />
                    )}
                  />
                  {/* <FormFeedback className="validation-error">{translation(`${errors.zip?.message}`)}</FormFeedback> */}
                </FormGroup>
              </Col>
            </Row>
            <DisabledFeaturesToggler
              getDisabledFeatures={setDisabledFeatures}
              parentDivClass={'disabled-feature-parent'}
              checkboxDivClass={'disabled-features-normal-checkbox'}
              checkboxClass={'normal-checkbox-label'}
              toggleDivClass={'toggle-checkbox-label'}
            />
          </div>

          <div className="registration-button-div">
            <Button
              className="form-button shadow-none"
              type="submit"
              disabled={!notEmpty}
            >
              {isProcessingRegistration ? (
                <LoadingSpinner />
              ) : (
                translation('FACILITY.SUBMIT')
              )}
            </Button>
            <span className="padding-top-min registration-form-link-text">
              {translation('FACILITY.ALREADY_HAVE_AN_ACCOUNT')}
              <Link
                className="login-padding form-guide-link"
                to={PathConstants.LOGIN}
              >
                {' '}
                &nbsp;
                {translation('LOGIN.TITLE')}
              </Link>{' '}
            </span>
          </div>
        </Form>
      </div>
    </FormTemplateWrap>
  );
};

export default RegistrationPage;
