import { get } from './apiService';

export const getAllGuestBymonth = async (id: string) => {
  return await get('/dashboard/facility/' + id + '/checked-in-users-count');
};

export const getAllTotalSupportBuisnessCount = async (id: string) => {
  return await get(
    '/dashboard/facility/' +
      id +
      '/support-business-count-around-facility?distanceInKm=20'
  );
};
