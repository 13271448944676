import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import SliderComponent from '../../components/slider/sliderComponent';
import { fetchAvgRatingsReviewsStats } from '../ratingsReviewsPage/ratingsReviewsSlice';
import { INavigationConst, navigationConst } from './bookingConstants';
import BookingsComponent from './bookingsComponent';
import { IBookingPaginate, IBookingsFinalData } from './bookingsInterface';
import './bookingsPage.css';
import {
  fetchAllApprovedBookings,
  fetchAllPendingBookings,
  // eslint-disable-next-line prettier/prettier
  fetchAllRejectedBookings
} from './bookingsSlice';
import { bookingFilterSetter } from './utilityFunctions';

const BookingsPage: React.FC = () => {
  const { supportingBusiness } = useAppSelector((state) => state.businessAuth);
  const {
    approvedBooking,
    declinedBooking,
    pendingPaginate,
    approvedPaginate,
    rejectedPaginate,
    finalPendingBookingsData,
    finalApprovedBookingsData,
    finalRejectedBookingsData,
    pendingFilter,
    rejectedFilter,
    approvedFilter,
    isProcessingPendingBookings,
    isProcessingApprovedBookings,
    isProcessingRejectedBookings,
  } = useAppSelector((state) => state.bookings);
  const { avgRatingsReviewsStats } = useAppSelector(
    (state) => state.ratingsReviews
  );
  const [switchTab, setSwitchTab] = useState<string>('pending');
  const [bookingData, setBookingData] = useState<IBookingsFinalData[]>([]);
  const [paginate, setPaginate] = useState<IBookingPaginate>(
    {} as IBookingPaginate
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    window.scrollTo(0, 0);
    if (supportingBusiness?.id) {
      dispatch(fetchAvgRatingsReviewsStats(supportingBusiness?.id));
    }
  }, [supportingBusiness?.id]);

  //use useEffect, to fetch data with the changing state.
  useEffect(() => {
    if (supportingBusiness?.id && switchTab === 'pending') {
      dispatch(
        fetchAllPendingBookings(
          bookingFilterSetter(pendingFilter, supportingBusiness?.id)
        )
      );
    } else if (supportingBusiness?.id && switchTab == 'booked') {
      dispatch(
        fetchAllApprovedBookings(
          bookingFilterSetter(approvedFilter, supportingBusiness?.id)
        )
      );
    } else if (supportingBusiness?.id && switchTab == 'cancelled') {
      dispatch(
        fetchAllRejectedBookings(
          bookingFilterSetter(rejectedFilter, supportingBusiness?.id)
        )
      );
    }
  }, [
    supportingBusiness?.id,
    approvedBooking,
    declinedBooking,
    switchTab,
    pendingFilter,
    rejectedFilter,
    approvedFilter,
  ]);

  useEffect(() => {
    if (switchTab === 'pending') {
      setBookingData(finalPendingBookingsData);
      setPaginate(pendingPaginate);
    } else if (switchTab === 'booked') {
      setBookingData(finalApprovedBookingsData);
      setPaginate(approvedPaginate);
    } else if (switchTab === 'cancelled') {
      setBookingData(finalRejectedBookingsData);
      setPaginate(rejectedPaginate);
    }
  }, [
    switchTab,
    finalPendingBookingsData,
    finalApprovedBookingsData,
    finalRejectedBookingsData,
    pendingPaginate,
    approvedPaginate,
    rejectedPaginate,
  ]);

  return (
    <div className="page-background amenities-page bookings-page">
      <SliderComponent>
        <div className="bookings-slider-div first-slide-background">
          <span>
            {avgRatingsReviewsStats?.lastMonthBookingStats?.bookingCount
              ? avgRatingsReviewsStats?.lastMonthBookingStats?.bookingCount
              : 0}
          </span>
          <span>Total Bookings last month</span>
        </div>
        <div className="bookings-slider-div second-slide-background ">
          <span>{avgRatingsReviewsStats?.ratingStats?.ratingCount}</span>
          <span>Total Ratings & Reviews</span>
        </div>
        <div className="bookings-slider-div third-slide-background">
          <span>{avgRatingsReviewsStats?.likeStats?.likeCount}</span>
          <span>Total Number of Likes</span>
        </div>
        <div className="bookings-slider-div fourth-slide-background">
          <span></span>
          <span className="coming-soon">Coming Soon</span>
          <span>% growth in supporting business</span>
        </div>
      </SliderComponent>
      <div className="bookings-sub-navbar-div">
        {navigationConst.map((item: INavigationConst, index: number) => (
          <div
            key={index}
            className={
              item.key === switchTab
                ? 'bookings-sub-navbar active pointer'
                : 'bookings-sub-navbar pointer'
            }
            onClick={() => setSwitchTab(item.key)}
          >
            {item.name}
          </div>
        ))}
      </div>
      <BookingsComponent
        tableData={bookingData}
        navState={switchTab}
        paginate={paginate}
        processing={
          switchTab === 'pending'
            ? isProcessingPendingBookings
            : switchTab === 'booked'
            ? isProcessingApprovedBookings
            : switchTab === 'cancelled'
            ? isProcessingRejectedBookings
            : false
        }
      />
    </div>
  );
};

export default BookingsPage;
