/* eslint-disable prettier/prettier */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { default as MultiSelect } from 'react-select';
import { toast } from 'react-toastify';
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { History } from '../../_helpers/history';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import ImageLoadingSpinner from '../../components/loadingSpinner/imageLoadingSpinner';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
import PageLoader from '../../components/pageLoading/pageLoader';
import ImagePreview from '../../components/profileImagePreview/imagePreview';
import { env } from '../../config/env';
// import { IMenuFileSrc } from '../diningPage/diningInterface';
import {
  fetchBusinessPicId,
  getBusinessImageById,
} from '../loginPage/businessAuthSlice';
import { fetchAllBusinessCategory } from '../registrationPage/businessRegistrationSlice';
import { IBusinessCategories } from '../registrationPage/registrationTypes';
import {
  IBusinessDocSrc,
  IBusinessProfileData,
} from './businessProfileInterface';
import { businessProfileSchema } from './businessProfileSchema';
import { businessProfilePayload } from './utilityFunction';

const BusinessProfileEdit: React.FC = () => {
  const {
    supportingBusiness,
    selectedBusinessCategory,
    isUpdatingProfile,
    isFetchingBusinessImage,
  } = useAppSelector((state) => state.businessAuth);
  const { businessCategories } = useAppSelector(
    (state) => state.businessRegistration
  );
  const [coverPic, setCoverPic] = useState<any>('');
  const [coverUrl, setCoverUrl] = useState<string>('');
  const [documentsState, setDocumentsState] = useState<IBusinessDocSrc[]>([]);

  useEffect(() => {
    dispatch(fetchAllBusinessCategory(null));
  }, []);
  const categoryValues = selectedBusinessCategory.map(
    (item: IBusinessCategories) => {
      return {
        value: item.id,
        label: item.name,
      };
    }
  );
  const {
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<IBusinessProfileData>({
    resolver: yupResolver(businessProfileSchema),
    defaultValues: {
      name: supportingBusiness?.name,
      email: supportingBusiness?.email,
      description: supportingBusiness?.description,
      capacity: supportingBusiness?.capacity,
      category: categoryValues,
      minPrice: supportingBusiness?.minPrice,
      maxPrice: supportingBusiness?.maxPrice,
    },
  });
  const dispatch = useAppDispatch();

  useEffect(() => {
    setDocumentsState(
      supportingBusiness?.documents?.map((item: IBusinessDocSrc) => ({
        ...item,
        fileSource: 'server',
      }))
    );
  }, [supportingBusiness]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const getImage = async () => {
      if (supportingBusiness?.photoId) {
        const coverResponse: any = await dispatch(
          getBusinessImageById(supportingBusiness?.photoId)
        );
        setCoverUrl(coverResponse?.payload);
      }
    };
    getImage();
  }, [supportingBusiness]);
  useEffect(() => {
    reset({
      name: supportingBusiness?.name,
      email: supportingBusiness?.email,
      description: supportingBusiness?.description,
      capacity: supportingBusiness?.capacity,
      category: categoryValues,
      minPrice: supportingBusiness?.minPrice,
      maxPrice: supportingBusiness?.maxPrice,
    });
  }, [supportingBusiness, selectedBusinessCategory]);
  const [translation] = useTranslation('common');
  const categoryOptions: any[] = businessCategories.map(
    (item: IBusinessCategories) => {
      return {
        value: item.id,
        label: item.name,
      };
    }
  );

  const uploadPicHandler = (idName: string) => {
    document.getElementById(idName)!.click();
  };

  const coverPicOnChange = (e: any) => {
    const fileObj = e.target.files;
    setCoverPic(Array.from(fileObj));
    const fileUrl = URL.createObjectURL(fileObj[0]);
    setCoverUrl(fileUrl);
    e.target.value = '';
  };
  const clearPreviewData = () => {
    setCoverPic('');
    setCoverUrl('');
  };
  const onSubmit = (data: IBusinessProfileData) => {
    const values = {
      ...data,
      documents: documentsState,
    };

    if (!supportingBusiness?.photoId && !coverPic) {
      toast.error('Business Cover pic is required');
    } else {
      if (supportingBusiness?.id) {
        dispatch(
          fetchBusinessPicId(
            businessProfilePayload(values, coverPic, supportingBusiness)
          )
        );
      }
    }
  };
  const uploadMultipleDocuments = (e: any) => {
    const fileObj = e.target.files;
    const fileType = e.target?.files[0]?.type;

    setDocumentsState([
      ...documentsState,
      { fileName: fileObj[0].name, file: fileObj, fileType: fileType },
    ]);
  };
  const uploadDocumentsImageHandler = (idName: string) => {
    document.getElementById(idName)!.click();
  };

  const handleRemoveFile = (index: number) => {
    if (documentsState && documentsState.length > index) {
      const updatedArray = [...documentsState];
      updatedArray.splice(index, 1);
      setDocumentsState(updatedArray);
    }
  };

  return (
    <div className="page-background facilityProfile">
      {selectedBusinessCategory.length > 0 ? (
        <Form className="form width-100" onSubmit={handleSubmit(onSubmit)}>
          <div className="facilityProfile-container">
            <div className="facilityProfile-image-div">
              <div className="facilityProfile-image noImage-background">
                {isFetchingBusinessImage ? (
                  <ImageLoadingSpinner color="light" />
                ) : (
                  !coverUrl && (
                    <>
                      <img
                        className="no-border-radius"
                        src={process.env.PUBLIC_URL + '/assets/noImageDark.svg'}
                        alt="no-image"
                      />
                      <span
                        className="pointer"
                        onClick={() => uploadPicHandler('cover_pic')}
                      >
                        {translation('PROFILE_EDIT.UPLOAD_COVER_PIC')}
                      </span>
                    </>
                  )
                )}
                {coverUrl && (
                  <ImagePreview
                    url={coverUrl}
                    clearPreviewData={clearPreviewData}
                    imgClass={'coverPic-preview'}
                    parentClass={'coverPic-preview-parent'}
                    closeIconClass={'previewClose-button'}
                  />
                )}
                <input
                  id="cover_pic"
                  name="cover_pic"
                  type="file"
                  style={{ display: 'none' }}
                  accept="image/jpeg, image/png"
                  onChange={(e) => coverPicOnChange(e)}
                />
              </div>
            </div>
            <div className="facilityProfile-details">
              <div className="details-heading">
                <span>{translation('Business Details')}</span>
              </div>
              <div className="details-edit-facilityData">
                <div className="details-edit-flex-column editable-input-label">
                  <span>{translation('Business Name')}</span>
                  <FormGroup className="me-3">
                    <Controller
                      control={control}
                      name="name"
                      render={({ field }) => (
                        <Input
                          className="profile-form-input shadow-none"
                          placeholder={translation('Business name')}
                          invalid={errors?.name ? true : false}
                          {...field}
                        />
                      )}
                    />
                    <FormFeedback className="validation-error">
                      {translation(`${errors.name?.message}`)}
                    </FormFeedback>
                  </FormGroup>
                </div>
                <div className="categories-edit-flex-column editable-input-label">
                  <span>{translation('Category')}</span>
                  <FormGroup className="me-3">
                    <Controller
                      control={control}
                      name="category"
                      render={({ field }) => (
                        <MultiSelect
                          options={categoryOptions}
                          isMulti
                          {...field}
                          className={
                            errors.category?.message
                              ? 'businessProfile-error-class'
                              : 'businessProfile-select-box'
                          }
                          isClearable
                          isSearchable={true}
                          menuPlacement="auto"
                          placeholder="Pick Business Category"
                        />
                      )}
                    />
                    <span className="error">
                      {errors.category?.message &&
                        translation(`${errors.category?.message}`)}
                    </span>
                  </FormGroup>
                </div>
              </div>
              <div className="details-border-line"></div>
              <div className="details-address">
                <div className="details-edit-facilityData">
                  <div className="details-edit-flex-column editable-input-label">
                    <span>{translation('Capacity')}</span>
                    <FormGroup className="me-3">
                      <Controller
                        control={control}
                        name="capacity"
                        render={({ field }) => (
                          <Input
                            type="number"
                            className="profile-form-input shadow-none"
                            placeholder={translation('Capacity')}
                            invalid={errors?.capacity ? true : false}
                            {...field}
                          />
                        )}
                      />
                      <FormFeedback className="validation-error">
                        {translation(`${errors.capacity?.message}`)}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="details-edit-flex-column editable-input-label">
                    <span>{translation('Minimum price')}</span>
                    <FormGroup className="me-3">
                      <Controller
                        control={control}
                        name="minPrice"
                        render={({ field }) => (
                          <Input
                            type="number"
                            className="profile-form-input shadow-none"
                            placeholder={translation('Minimum price')}
                            invalid={errors?.minPrice ? true : false}
                            {...field}
                          />
                        )}
                      />
                      <FormFeedback className="validation-error">
                        {translation(`${errors.minPrice?.message}`)}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="details-edit-flex-column editable-input-label">
                    <span>{translation('Maximum price')}</span>
                    <FormGroup className="me-3">
                      <Controller
                        control={control}
                        name="maxPrice"
                        render={({ field }) => (
                          <Input
                            type="number"
                            className="profile-form-input shadow-none"
                            placeholder={translation('Maximum price')}
                            invalid={errors?.maxPrice ? true : false}
                            {...field}
                          />
                        )}
                      />
                      <FormFeedback className="validation-error">
                        {translation(`${errors.maxPrice?.message}`)}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="details-edit-flex-column editable-input-label">
                    <span>{translation('Documents')}</span>
                    <div
                      style={{ width: '96%' }}
                      className="users-imageUpload-div pointer"
                      onClick={() =>
                        uploadDocumentsImageHandler('supporting-files')
                      }
                    >
                      {
                        <span className="uploadImage-text">
                          {translation(`AMENITIES_FORM.UPLOAD_PHOTO`)}
                        </span>
                      }
                      <div className="">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            '/assets/uploadIcon-dark.svg'
                          }
                          alt="upload-icon"
                        />
                      </div>
                      <input
                        id="supporting-files"
                        type="file"
                        style={{ display: 'none' }}
                        accept=".pdf, image/*"
                        onChange={(e) => uploadMultipleDocuments(e)}
                      />
                    </div>
                    <div className="document-file-name">
                      {documentsState?.map(
                        (item: IBusinessDocSrc, index: number) => (
                          <div className="multi-files-wrap" key={index}>
                            <a
                              href={
                                item?.fileSource === 'server'
                                  ? `${env.development.BASE_URL}/files/${item?.file}`
                                  : URL.createObjectURL(
                                      new Blob([item?.file[0]], {
                                        type: item?.file[0]?.type,
                                      })
                                    )
                              }
                              download
                            >
                              <span className="uploadImage-input">
                                {item?.fileName &&
                                  translation(
                                    `${
                                      item?.fileName?.length < 20
                                        ? item?.fileName
                                        : item?.fileName.substring(0, 20) + '..'
                                    }`
                                  )}
                              </span>
                            </a>
                            <span>
                              <img
                                onClick={() => handleRemoveFile(index)}
                                src="/assets/deleteicon.svg"
                              ></img>
                            </span>
                          </div>
                        )
                      )}
                    </div>
                  </div>

                  <div className="details-edit-flex-column editable-input-label">
                    <span>{translation('Description')}</span>
                    <FormGroup className="me-3">
                      <Controller
                        control={control}
                        name="description"
                        render={({ field }) => (
                          <Input
                            type="textarea"
                            row="20"
                            maxLength={250}
                            placeholder={translation('Description')}
                            className="business_profile-textarea-input shadow-none textarea-input"
                            invalid={errors?.description ? true : false}
                            {...field}
                          />
                        )}
                      />
                      <FormFeedback className="validation-error">
                        {translation(`${errors.description?.message}`)}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="profile-edit-button-div">
            <Button className="form-button shadow-none" type="submit">
              {isUpdatingProfile ? (
                <LoadingSpinner />
              ) : (
                translation('PROFILE_EDIT.UPDATE_BUTTON')
              )}
            </Button>
            <Button
              className="modal-cancel-button shadow-none"
              onClick={() => History.navigate(-1)}
            >
              {translation('PROFILE_EDIT.CANCEL_BUTTON')}
            </Button>
          </div>
        </Form>
      ) : (
        <PageLoader />
      )}
    </div>
  );
};

export default BusinessProfileEdit;
