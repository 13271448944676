export interface IBookingsParams {
  status: string;
  sort: string;
  page: number;
  size: number;
  id?: string;
}
export const getBookingsRequestParams = (
  status: string,
  sort: string,
  page: number,
  size: number,
  id?: string
) => {
  const params = {} as IBookingsParams;
  if (status) {
    params['status'] = status;
  }
  if (sort) {
    params['sort'] = sort;
  }
  if (page) {
    params['page'] = page;
  }
  if (size) {
    params['size'] = size;
  }
  if (id) {
    params['id'] = id;
  }
  return params;
};
