import { IAddUserData, IUsersFilter } from '../pages/usersPage/usersInterface';
import { deleteApi, get, post, put } from './apiService';

export const getUserById = async (id: string) => {
  return await get(`/user/${id}`);
};
export const addUser = async (data: IAddUserData) => {
  return await post(`/user`, data);
};
export const getAllUsers = async (data: IUsersFilter) => {
  return await get(
    `/user?actor=FACILITY_USER&facilityIds=${data?.facilityId}`,
    data
  );
};
export const deleteUser = async (id: string) => {
  return await deleteApi(`/user/${id}`);
};
export const updateUser = async (data: any) => {
  return await put(`/user/${data.id}`, data);
};
export const resendInvitation = async (id: string) => {
  return await post(`/user/${id}/resend-user-created-mail`, null);
};
