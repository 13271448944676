import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import 'swiper/css';
import { SwiperSlide } from 'swiper/react';
import { useAppDispatch } from '../../app/hook';
import { addFilterPage } from '../../pages/activitiesPage/activiitiesSlice';
import { addFilterMealsPage } from '../../pages/diningPage/diningSlice';
import { TBreakPoints } from './swiperBreakPoints';
import SwiperButton from './swiperButton';
import SwiperComp from './swiperComp';

export const CalenderComp: React.FC<{
  setDate: (date: Moment) => void;
  setSwiperState: (data: any) => void;
  dayForwardFn: () => void;
  dayBackFn: () => void;
  swiperBreakPoints: TBreakPoints;
}> = ({
  setDate,
  setSwiperState,
  dayForwardFn,
  dayBackFn,
  swiperBreakPoints,
}): JSX.Element => {
  const today = moment();

  const [dateArray, setDateArray] = useState<Moment[]>(
    new Array(moment().daysInMonth())
      .fill(null)
      .map((x, i) => moment().startOf('month').clone().add(i, 'days'))
  );
  const [monthAndYear, setMonthAndYear] = useState<Moment>(today);
  const [initialSlide, setInitialSlide] = useState(-1);
  const [clickedDate, setIsetClickedDate] = useState<Moment>(today);

  useEffect(() => {
    if (dateArray) {
      const todayIndex = dateArray?.findIndex((item) =>
        today.isSame(item, 'day')
      );

      setInitialSlide(todayIndex);
    }
  }, []);

  const AddMonths = (date: Moment) => {
    const month = moment(date);
    const daysInMonth = month.daysInMonth();
    let currentMonthDates;

    if (month.month() === 1) {
      const year = month.year();
      const isLeapYear = moment([year]).isLeapYear();
      currentMonthDates = new Array(isLeapYear ? 29 : 28)
        .fill(null)
        .map((x, i) => moment(date).startOf('month').add(i, 'days'));
    } else {
      currentMonthDates = new Array(daysInMonth)
        .fill(null)
        .map((x, i) => moment(date).startOf('month').add(i, 'days'));
    }

    setDateArray(currentMonthDates);
  };

  const nextMonth = () => {
    setMonthAndYear(moment(monthAndYear).add(1, 'months'));
    AddMonths(moment(monthAndYear).add(1, 'months'));
  };

  const previousMonth = () => {
    setMonthAndYear(moment(monthAndYear).subtract(1, 'months'));
    AddMonths(moment(monthAndYear).subtract(1, 'months'));
  };

  const nextButton = () => {
    setInitialSlide(1);
    nextMonth();
  };
  const prevButton = () => {
    previousMonth();
  };
  const dispatch = useAppDispatch();

  const onClickDate = (date: Moment) => {
    setIsetClickedDate(date);
    setDate(date);
    dispatch(addFilterPage(0));
    dispatch(addFilterMealsPage(0));
  };

  return (
    <div className="calender">
      <div className="calender-month-year">
        <div className="month-arrow-container" onClick={prevButton}>
          <img
            src={process.env.PUBLIC_URL + '/assets/leftArrowSm.svg'}
            alt="arrow"
          />
        </div>
        <span className="calender-month-year">
          {monthAndYear.format('MMMM')}
        </span>
        <span className="calender-month-year">
          {monthAndYear.format('YYYY')}
        </span>
        <div className="month-arrow-container" onClick={nextButton}>
          <img
            src={process.env.PUBLIC_URL + '/assets/rightArrowSm.svg'}
            alt="arrow"
          />
        </div>
        {/* <div className="month-today">
        <span>Today</span>
      </div> */}
      </div>
      <div className="calenderSwiper-container">
        <SwiperButton
          src="/assets/leftArrowBig.svg"
          customClass="calender-arrow-container"
          slideFn={dayBackFn}
        />
        {initialSlide >= 0 && (
          <SwiperComp
            setSwiperState={setSwiperState}
            initialSlide={initialSlide}
            breakPoints={swiperBreakPoints}
          >
            {dateArray?.map((item: Moment, index: number) => (
              <SwiperSlide key={index} onClick={() => onClickDate(item)}>
                <div
                  className={
                    clickedDate.isSame(item, 'day')
                      ? 'week-date-container active pointer'
                      : 'week-date-container pointer'
                  }
                  onClick={() => onClickDate(item)}
                >
                  <span
                    className={
                      clickedDate.isSame(item, 'day')
                        ? 'activeClass-week'
                        : 'week'
                    }
                  >
                    {item?.format('ddd')}
                  </span>
                  <span
                    className={
                      clickedDate.isSame(item, 'day')
                        ? 'activeClass-date'
                        : 'date'
                    }
                  >
                    {item?.date()}
                  </span>
                </div>
              </SwiperSlide>
            ))}
          </SwiperComp>
        )}
        <SwiperButton
          src="/assets/rightArrowBig.svg"
          customClass="calender-arrow-container"
          slideFn={dayForwardFn}
        />
      </div>
    </div>
  );
};
