/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  geocodeByPlaceId,
  getLatLng,
} from 'react-google-places-autocomplete';
import { useTranslation } from 'react-i18next';
import {
  IAddress,
  ILatLong,
} from '../../pages/registrationPage/registrationTypes';

const GeoLocationInput: React.FC<{
  setMapAddress: (data: IAddress) => void;
  setLatLong: (data: ILatLong) => void;
  setNotEmpty: (data: boolean) => void;
  className?: string;
  placeholderText: string;
}> = ({
  setMapAddress,
  setLatLong,
  setNotEmpty,
  placeholderText,
  className,
}) => {
  const [address, setAddress] = useState<any>('');
  const [addresObj, setAddressObj] = useState<any>();
  const [translation] = useTranslation('common');
  const getAddressObject = (address_components: any) => {
    const ShouldBeComponent: any = {
      street_number: ['street_number'],
      zipCode: ['postal_code'],
      street: ['street_address', 'route', 'neighborhood'],
      area: ['political', 'administrative_area_level_2'],
      state: [
        'administrative_area_level_1',
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
      ],
      city: [
        'locality',
        'political',
        'sublocality',
        'sublocality_level_1',
        'sublocality_level_2',
        'sublocality_level_3',
        'sublocality_level_4',
      ],
      country: ['country'],
    };

    const address: any = {
      street_number: '',
      zipCode: '',
      street: '',
      state: '',
      city: '',
      area: '',
      country: '',
    };

    address_components.forEach((component: any) => {
      for (const shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          address[shouldBe] = component.long_name;
        }
      }
    });

    // Fix the shape to match our schema
    const addressLine1 =
      address.street_number && address.street
        ? `${address.street_number}, ${address.street}`
        : !address.street_number
        ? `${address.street}`
        : `${address.street_number}`;
    const addressLine2 =
      address.city && address.area
        ? `${address.city}, ${address.area}`
        : !address.city
        ? `${address.area}`
        : `${address.city}`;
    const addressLine3 =
      address.state && address.country
        ? `${address.state}, ${address.country}`
        : !address.state
        ? `${address.country}`
        : `${address.state}`;
    const requiredAddress = {
      addressLine1,
      addressLine2,
      addressLine3,
      zip: address.zipCode,
    };
    setMapAddress(requiredAddress);
    return requiredAddress;
  };

  useEffect(() => {
    const func = async () => {
      const geocodeObj =
        address &&
        address.value &&
        (await geocodeByPlaceId(address.value.place_id));
      const addressObject =
        geocodeObj && getAddressObject(geocodeObj[0].address_components);
      const results =
        address &&
        address.value &&
        (await geocodeByAddress(address.value.description));
      const { lat, lng } = results && (await getLatLng(results[0]));
      if (lat && lng) {
        setLatLong({ lat, lng });
      }
      setAddressObj(addressObject);
    };
    func();
  }, [address]);

  return (
    <div className={`geoLocation-input-div ${className}`}>
      {/* window.google && ( */}
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_MAPS_API_KEY}
        selectProps={{
          isClearable: true,
          value: address,
          onChange: (val: string) => {
            setAddress(val);
            setNotEmpty(true);
          },
          placeholder: `${placeholderText}`,
        }}
      />
      {/* ) */}
    </div>
  );
};

export default GeoLocationInput;
