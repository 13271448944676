import moment from 'moment';
import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import DeclineBookingComp from '../../components/declineBooking/declineBookingComp';
import { ModalComponent } from '../../components/modal';
import { openDeleteModalReducer } from '../../components/modal/modalSlice';
import NoDataBooking from '../../components/noDataComponent/noDataBooking';
import PageLoader from '../../components/pageLoading/pageLoader';
import Paginate, {
  // eslint-disable-next-line prettier/prettier
  IPaginateClickData,
} from '../../components/paginate/paginate';
import Table, { ITBodyData } from '../../components/table/table';
import { tableHeader } from './bookingConstants';
import { IBookingPaginate, IBookingsFinalData } from './bookingsInterface';
import {
  addApprovedPage,
  addPendingPage,
  addRejectedPage,
  approveBookingsAction,
  // eslint-disable-next-line prettier/prettier
  declineBookingsAction,
} from './bookingsSlice';

const BookingsComponent: React.FC<{
  tableData: IBookingsFinalData[];
  navState: string;
  paginate: IBookingPaginate;
  processing: boolean;
}> = ({ tableData, navState, paginate, processing }) => {
  const { openDeleteModal } = useAppSelector((state: any) => state.modal);
  const { isDeclining } = useAppSelector((state) => state.bookings);
  const [declineId, setDeclineId] = useState<string>();
  const dispatch = useAppDispatch();
  const declineModalHandler = (reason: string) => {
    if (declineId) {
      dispatch(declineBookingsAction({ rejectReason: reason, id: declineId }));
    }
  };
  const handlePageClick = (data: IPaginateClickData) => {
    if (data.selected !== paginate.totalPages && navState == 'pending') {
      dispatch(addPendingPage(data?.selected));
    } else if (data.selected !== paginate.totalPages && navState == 'booked') {
      dispatch(addApprovedPage(data?.selected));
    } else if (
      data.selected !== paginate.totalPages &&
      navState == 'cancelled'
    ) {
      dispatch(addRejectedPage(data?.selected));
    }
  };

  const tableBody: ITBodyData[] = tableData?.map(
    (item: IBookingsFinalData) => ({
      name: {
        content: {
          component: (
            <td className="" data-label={'Name'}>
              {item.userName}
            </td>
          ),
        },
      },
      email: {
        content: {
          component: (
            <td className="" data-label={'Email'}>
              {item.email}
            </td>
          ),
        },
      },
      // phone: {
      //   content: {
      //     component: (
      //       <td className="" data-label={'Phone'}>
      //         {item.phoneNo ? item.phoneNo : 'nil'}
      //       </td>
      //     ),
      //   },
      // },
      bookingDate: {
        content: {
          component: (
            <td className="" data-label={'Booking Date'}>
              {moment(item.bookingDate.split('T')[0]).format('DD/MM/YYYY')}
              {/* {moment(`${item.bookingDate.split('T')[0]}`, 'YYYY-MM-DD')} */}
              {/* {moment(item.bookingDate.split('T')[0])} */}
            </td>
          ),
        },
      },
      time: {
        content: {
          component: (
            <td className="" data-label={'Slots'}>
              {item.slotsBooked}
            </td>
          ),
        },
      },
      action: {
        content: {
          component: (
            <td className="" data-label={'Action'}>
              <span className="table-action-buttons">
                {navState != 'cancelled' && (
                  <Button
                    className="action-approve-button shadow-none"
                    disabled={navState === 'booked'}
                    onClick={() => dispatch(approveBookingsAction(item.id))}
                  >
                    {navState === 'booked' ? 'APPROVED' : 'APPROVE'}
                  </Button>
                )}
                {navState != 'booked' && (
                  <Button
                    className="action-cancel-button shadow-none"
                    disabled={navState === 'cancelled'}
                    onClick={() => {
                      dispatch(openDeleteModalReducer());
                      setDeclineId(item.id);
                    }}
                  >
                    {navState === 'cancelled' ? 'DECLINED' : 'DECLINE'}
                  </Button>
                )}
              </span>
            </td>
          ),
        },
      },
    })
  );
  return (
    <>
      {processing ? (
        <PageLoader />
      ) : tableData.length === 0 ? (
        <NoDataBooking
          name={
            navState === 'booked'
              ? 'Approved Bookings'
              : navState === 'pending'
              ? 'Pending Bookings'
              : navState === 'cancelled'
              ? 'Cancelled Bookings'
              : ''
          }
        />
      ) : (
        <Table header={tableHeader} body={tableBody} />
      )}

      {openDeleteModal && (
        <ModalComponent isOpen={openDeleteModal} modalHeading="Decline Booking">
          <DeclineBookingComp
            declineModalHandler={declineModalHandler}
            isDeclining={isDeclining}
          />
        </ModalComponent>
      )}
      {paginate?.totalPages > 1 && (
        <Paginate
          handlePageClick={handlePageClick}
          totalPage={paginate?.totalPages}
        />
      )}
    </>
  );
};

export default BookingsComponent;
