import React from 'react';
import TableBody from './tableBody';
import TableHead from './tableHead';

export interface ITHead {
  label: string;
  key: string;
}

export enum RowType {
  STRING = 'string',
  COMPONENT = 'component',
}
export interface Row {
  // type?: RowType;
  content: { value?: string | number; component?: JSX.Element };
}

export interface ITBodyData {
  [name: string]: Row;
}

const Table: React.FC<{
  header: ITHead[];
  body: ITBodyData[];
}> = ({ header, body }) => {
  return (
    <table>
      <TableHead header={header} />
      <TableBody body={body} header={header} />
    </table>
  );
};

export default Table;
