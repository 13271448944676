import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import {
  IAddFacilityForm,
  IAddress,
  // eslint-disable-next-line prettier/prettier
  ILatLong,
} from '../../pages/registrationPage/registrationTypes';
import { addFacilitySchema } from '../../pages/registrationPage/registrationValidation';
import GeoLocationInput from '../geoLocation/geoLoactionInput';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import { closeModalReducer } from '../modal/modalSlice';
import DisabledFeaturesToggler from '../toggleButtonComp/disabledFeaturesToggler';

const AddFacilityForm: React.FC<{
  isAdding: boolean;
  setProfileDropdownToggle: (data: boolean) => void;
  onCreate: (
    data: IAddFacilityForm,
    latLong: ILatLong,
    emailId: string,
    userId: string,
    disabled: string[] | void[],
    image?: any
  ) => void;
}> = ({ isAdding, onCreate, setProfileDropdownToggle }) => {
  const { currentFacility, loggedInUser } = useAppSelector(
    (state) => state.authentication
  );
  const [latLong, setLatLong] = useState<ILatLong>({} as ILatLong);
  const [mapAddress, setMapAddress] = useState<IAddress>({} as IAddress);
  const [notEmpty, setNotEmpty] = useState<boolean>(false);
  const [imageState, setImageState] = useState<any>('');
  const [imageName, setImageName] = useState<string>('');
  const [disabledFeatures, setDisabledFeatures] = useState<string[] | void[]>(
    []
  );
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<IAddFacilityForm>({
    resolver: yupResolver(addFacilitySchema),
  });
  const dispatch = useAppDispatch();
  const [translation] = useTranslation('common');
  useEffect(() => {
    reset({
      name: watch('name'),
      facilityNumber: watch('facilityNumber'),
      phoneNo: watch('phoneNo'),
      addressLine1: mapAddress.addressLine1,
      addressLine2: mapAddress.addressLine2,
      addressLine3: mapAddress.addressLine3,
      zip: mapAddress?.zip,
    });
  }, [latLong, mapAddress]);
  const uploadNewFacilityImageHandler = (idName: string) => {
    document.getElementById(idName)!.click();
  };
  const newFacilityImageOnChange = (e: any) => {
    const fileObj = e.target.files;
    setImageState(Array.from(fileObj));
    setImageName(fileObj[0].name);
  };
  const onSubmit = (data: IAddFacilityForm) => {
    if (latLong && currentFacility && loggedInUser) {
      onCreate(
        data,
        latLong,
        currentFacility?.email,
        loggedInUser?.id,
        disabledFeatures,
        imageState
      );
    }
  };
  return (
    <div className="modal-div addFacilityForm-parent">
      <Form
        className="form width-100 addFacilityForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="scrollable-area">
          <FormGroup>
            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <Input
                  className="registration-form-input shadow-none"
                  placeholder={translation('FACILITY.NAME_PLACEHOLDER')}
                  defaultValue=""
                  invalid={errors?.name ? true : false}
                  {...field}
                />
              )}
            />
            <FormFeedback className="validation-error">
              {translation(`${errors.name?.message}`)}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Controller
              control={control}
              name="facilityNumber"
              render={({ field }) => (
                <Input
                  placeholder={translation(
                    'FACILITY.FACILITY_NUMBER_PLACEHOLDER'
                  )}
                  className="registration-form-input shadow-none"
                  defaultValue=""
                  invalid={errors?.facilityNumber ? true : false}
                  {...field}
                />
              )}
            />
            <FormFeedback className="validation-error">
              {translation(`${errors.facilityNumber?.message}`)}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Controller
              control={control}
              name="phoneNo"
              render={({ field }) => (
                <Input
                  placeholder={translation('FACILITY.PHONE_PLACEHOLDER')}
                  className="registration-form-input shadow-none"
                  defaultValue=""
                  invalid={errors?.phoneNo ? true : false}
                  {...field}
                />
              )}
            />
            <FormFeedback className="validation-error">
              {translation(`${errors.phoneNo?.message}`)}
            </FormFeedback>
          </FormGroup>
          <GeoLocationInput
            className="addNewFacility"
            placeholderText={translation('FACILITY.PLEASE_FIND_FACILITY')}
            setMapAddress={setMapAddress}
            setLatLong={setLatLong}
            setNotEmpty={setNotEmpty}
          />
          <FormGroup>
            <Controller
              control={control}
              name="addressLine1"
              render={({ field }) => (
                <Input
                  placeholder={translation(
                    'FACILITY.ADDRESS_LINE1_PLACEHOLDER'
                  )}
                  className="registration-form-input shadow-none"
                  disabled={mapAddress.addressLine1 !== ''}
                  invalid={errors?.addressLine1 ? true : false}
                  {...field}
                />
              )}
            />
            <FormFeedback className="validation-error">
              {translation(`${errors.addressLine1?.message}`)}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Controller
              control={control}
              name="addressLine2"
              render={({ field }) => (
                <Input
                  placeholder={translation(
                    'FACILITY.ADDRESS_LINE2_PLACEHOLDER'
                  )}
                  className="registration-form-input shadow-none"
                  disabled={mapAddress.addressLine2 !== ''}
                  invalid={errors?.addressLine2 ? true : false}
                  {...field}
                />
              )}
            />
            <FormFeedback className="validation-error">
              {translation(`${errors.addressLine2?.message}`)}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Controller
              control={control}
              name="addressLine3"
              render={({ field }) => (
                <Input
                  className="registration-form-input shadow-none"
                  placeholder={translation(
                    'FACILITY.ADDRESS_LINE3_PLACEHOLDER'
                  )}
                  disabled={mapAddress.addressLine3 !== ''}
                  invalid={errors?.addressLine3 ? true : false}
                  {...field}
                />
              )}
            />
            <FormFeedback className="validation-error">
              {translation(`${errors.addressLine3?.message}`)}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Controller
              control={control}
              name="zip"
              render={({ field }) => (
                <Input
                  className="registration-form-input shadow-none"
                  placeholder={translation(
                    'FACILITY.ADDRESS_LINE4_PLACEHOLDER'
                  )}
                  disabled={mapAddress.zip !== ''}
                  invalid={errors?.zip ? true : false}
                  {...field}
                />
              )}
            />
            <FormFeedback className="validation-error">
              {translation(`${errors.zip?.message}`)}
            </FormFeedback>
          </FormGroup>
          <div
            className="users-imageUpload-div pointer"
            onClick={() => uploadNewFacilityImageHandler('newFacility_image')}
          >
            {imageName ? (
              <span className="uploadImage-input">
                {translation(`${imageName}`)}
              </span>
            ) : (
              <span className="uploadImage-text">
                {translation('RESTAURANT.UPLOAD_IMAGE')}
              </span>
            )}
            <div className="">
              <img
                src={process.env.PUBLIC_URL + '/assets/uploadIcon-dark.svg'}
                alt="upload-icon"
              />
            </div>
            <input
              id="newFacility_image"
              type="file"
              style={{ display: 'none' }}
              accept="image/jpeg, image/png"
              onChange={(e) => newFacilityImageOnChange(e)}
            />
          </div>
          <DisabledFeaturesToggler
            getDisabledFeatures={setDisabledFeatures}
            parentDivClass={'disabled-feature-parent'}
            checkboxDivClass={'disabled-features-normal-checkbox'}
            checkboxClass={'normal-checkbox-label'}
            toggleDivClass={'toggle-checkbox-label'}
          />
        </div>

        <div className="modal-button-group">
          <Button
            type="submit"
            className="form-button shadow-none"
            disabled={!notEmpty}
          >
            {isAdding ? <LoadingSpinner /> : translation('BUTTONS.CREATE')}
          </Button>
          <Button
            className="shadow-none modal-cancel-button"
            onClick={() => dispatch(closeModalReducer())}
          >
            {translation('BUTTONS.CANCEL')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default AddFacilityForm;
