import * as yup from 'yup';

export const loginSchema = yup
  .object({
    email: yup
      .string()
      .email('LOGIN.EMAIL_PROPER_VALIDATION')
      .required('LOGIN.EMAIL_REQUIRED'),
    password: yup.string().required('LOGIN.PASSWORD_VALIDATION'),
  })

  .required();
