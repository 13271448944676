import { IRatingsFilter } from './ratingsReviewsInterface';

export const ratingsPayloadSetter = (
  ratingsPaginate: IRatingsFilter,
  id: string
) => {
  const payload = {
    ...ratingsPaginate,
    id,
  };
  return payload;
};
