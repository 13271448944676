import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import FormTemplateWrap from '../../components/formTemplateWrap/formTemplateWrap';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
import { PathConstants } from '../../constants/pathConstants';
import { callForgotPassword } from './forgotPasswordSlice';
import { forgotPasswordSchema } from './forgotPasswordValidation';
import { IForgotPassword } from './interface';

export const ForgotPasswordPage: React.FC = () => {
  const { isProcessingForgotPassword, passwordReset } = useAppSelector(
    (state) => state.forgotPassword
  );
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IForgotPassword>({
    resolver: yupResolver(forgotPasswordSchema),
  });
  useEffect(() => {
    if (passwordReset) {
      reset({
        email: '',
      });
    }
  }, [passwordReset]);
  const dispatch = useAppDispatch();
  const onSubmit = (data: IForgotPassword) => {
    if (window.location.pathname.split('/')[1] == 'business') {
      dispatch(callForgotPassword(forgotBusinessPasswordData(data)));
    } else {
      dispatch(callForgotPassword(forgotPasswordData(data)));
    }
  };

  const [translation] = useTranslation('common');

  return (
    <FormTemplateWrap>
      <div className="forgotPassword-form-bg">
        <img
          className="padding-bottom-2rem logo"
          src={process.env.PUBLIC_URL + '/assets/logo.svg'}
          alt="Logo"
        />
        <h2 className="padding-bottom-1rem form-title">
          {' '}
          {translation(`FORGOT_PASSWORD.TITLE`)}
        </h2>
        <span className="padding-bottom-1rem forgotPassword-helperText">
          {translation(`FORGOT_PASSWORD.HELPER_TEXT`)}
        </span>
        <Form className="form width-100" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Controller
              control={control}
              name="email"
              defaultValue=""
              render={({ field }) => (
                <Input
                  className="form-input shadow-none"
                  placeholder={translation(`FORGOT_PASSWORD.EMAIL_ADDRESS`)}
                  invalid={errors?.email ? true : false}
                  {...field}
                />
              )}
            />
            <FormFeedback className="validation-error">
              {translation(`${errors.email?.message}`)}
            </FormFeedback>
          </FormGroup>
          <div className="button-div">
            <Button
              className="form-button shadow-none forgotPassword-button"
              type="submit"
              disabled={isProcessingForgotPassword}
            >
              {isProcessingForgotPassword ? (
                <LoadingSpinner />
              ) : (
                translation('FORGOT_PASSWORD.REQUEST_PASSWORD_RESET')
              )}
            </Button>
            <Link
              className="form-guide-link"
              to={
                window.location.pathname ===
                PathConstants.BUSINESS_FORGOT_PASSWORD
                  ? PathConstants.BUSINESS_LOGIN
                  : PathConstants.LOGIN
              }
            >
              {' '}
              {translation(`FORGOT_PASSWORD.BACK_TO_LOGIN`)}
            </Link>
          </div>
        </Form>
      </div>
    </FormTemplateWrap>
  );
};

export const forgotPasswordData = (data: IForgotPassword) => {
  const userData = {
    email: data.email.toLowerCase().trim(),
    actor: 'FACILITY_ADMIN',
  };
  return userData;
};
export const forgotBusinessPasswordData = (data: IForgotPassword) => {
  const userData = {
    email: data.email.toLowerCase().trim(),
    actor: 'SUPPORT_BUSINESS_ADMIN',
  };
  return userData;
};
