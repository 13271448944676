/* eslint-disable prettier/prettier */
import {
  IAddFacilityForm,
  IAddFacilityPayload,
  IBusinessRegistrationInput,
  ICategorySelectOptions,
  IFacilityFinalData,
  IFacilityRawData,
  ILatLong,
} from './registrationTypes';

export const getFacilityCreationPayload = (
  formData: IFacilityRawData,
  latLong: ILatLong,
  disabledFeatures: string[] | any[]
): IFacilityFinalData => {
  const data = {
    name: formData.name.trim(),
    email: formData.email.toLowerCase().trim(),
    phoneNo: formData.phoneNo.trim(),
    facilityNumber: formData.facilityNumber.trim(),
    address: {
      addressLine1: formData.addressLine1?.trim() || '',
      addressLine2: formData.addressLine2?.trim() || '',
      addressLine3:
        formData.addressLine3?.trim() + ', ' + formData.zip?.trim() || '',
    },
    location: {
      longitude: latLong.lng,
      latitude: latLong.lat,
    },
    disabledFeatures: disabledFeatures?.length > 0 ? [...disabledFeatures] : [],
  };
  return data;
};

export const addNewFacilityDataManipulator = (
  formData: IAddFacilityForm,
  latLong: ILatLong,
  emailId: string,
  userId: string,
  disabledFeatures: string[] | any[],
  image?: any
): IAddFacilityPayload => {
  const payload = {
    name: formData.name.trim(),
    phoneNo: formData.phoneNo.trim(),
    address: {
      addressLine1: formData.addressLine1.trim(),
      addressLine2: formData.addressLine2.trim(),
      addressLine3: formData.addressLine3.trim() + ', ' + formData.zip.trim(),
    },
    email: emailId.trim(),
    facilityNumber: formData.facilityNumber.trim(),
    location: {
      longitude: latLong.lng,
      latitude: latLong.lat,
    },
    coverPic: image ? image : 0,
    userId: userId,
    disabledFeatures: disabledFeatures?.length > 0 ? [...disabledFeatures] : [],
  };
  return payload;
};

export const businessRegistrationDataManipulator = (
  data: IBusinessRegistrationInput,
  latLong: ILatLong
) => {
  const categoryIdArray = data?.category.map((item: ICategorySelectOptions) => {
    return Number(item.value);
  });

  const payload = {
    name: data.name.trim(),
    email: data.email.toLowerCase().trim(),
    capacity: data.capacity,
    categoryIds: categoryIdArray,
    maxPrice: data.maxPrice,
    minPrice: data.minPrice,
    description: data.description.trim(),
    location: {
      longitude: latLong.lng,
      latitude: latLong.lat,
    },
    photoId: '',
    documents: data?.documents,
  };
  return payload;
};
