export const PathConstants = {
  HOME: '/',
  LOGIN: '/login',
  REGISTRATION: '/registration',
  SUCCESS_REGISTRATION_FACILITY: '/registration-success',
  CREATE_PASSWORD: `/create-password`,
  RESET_PASSWORD: `/reset-password`,
  FACILITY_PROFILE: `facility-profile/:f_id`,
  FACILITY_PROFILE_EDIT: `facility-profile/edit/:f_id`,
  ACTIVITIES: 'administration/activities/:f_id',
  EVENT_DETAILS: 'administration/activities/:f_id/:eventId',
  AMENITIES: 'administration/amenities/:f_id',
  SUPPORTING_BUSINESS: 'supporting-business/:f_id',
  FACILITY_RATING_REVIEW: 'rating-review/:f_id',
  DINING_SCHEDULES: 'administration/dining-schedules/:f_id',
  INDIVIDUAL_RESTAURANT:
    'administration/dining-schedules/restaurant/:f_id/:restaurant_id',
  GUEST: 'guests/:f_id',
  USERS: 'users/:f_id',
  FORGOT_PASSWORD: '/forgot-password',
  BUSINESS_BOOKINGS: 'business/bookings',
  BUSINESS_RATING_REVIEW: 'business/rating-review',
  BUSINESS_LOGIN: '/business/login',
  BUSINESS_REGISTRATION: '/business/registration',
  SUCCESS_REGISTRATION_BUSINESS: '/business/registration-success',
  BUSINESS_CREATE_PASSWORD: '/business/create-password',
  BUSINESS_FORGOT_PASSWORD: '/business/forgot-password',
  BUSINESS_RESET_PASSWORD: '/business/reset-password',
  BUSINESS_PROFILE: 'business/business-profile',
  BUSINESS_PROFILE_EDIT: 'business/business-profile/edit',
};
