import React from 'react';
import { domain_env } from '../../config/env';

const FacilityLoginWrap: React.FC<{
  children: JSX.Element;
  hostName: string;
}> = ({ children, hostName }) => {
  return domain_env.FACILITY_DOMAINS.includes(hostName) ||
    domain_env.LOCALHOST.includes(hostName)
    ? children
    : null;
};

export default FacilityLoginWrap;
