import { Worker } from '@react-pdf-viewer/core';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAppDispatch, useAppSelector } from './app/hook';
import BusinessLoginWrap from './components/loginWrap/businessLoginWrap';
import FacilityLoginWrap from './components/loginWrap/facilityLoginWrap';
import PageNotFound from './components/pageNotFound/pageNotFound';
import CreatePasswordWrap from './components/passwordPageWrap/createPasswordWrap';
import ResetPasswordWrap from './components/passwordPageWrap/resetPasswordWrap';
import SuccessComponent from './components/registrationSuccess/successComponent';
import BusinessRoleWrap from './components/roleWraps/businessRoleWrap';
import FacilityRoleWrap from './components/roleWraps/facilityRoleWrap';
import HomeRouteWrap from './components/roleWraps/homeRouteWrap';
import { domain_env } from './config/env';
import { PathConstants } from './constants/pathConstants';
import './index.css';
import './mediaQueries.css';
import ActivitiesPage from './pages/activitiesPage/activitiesPage';
import AmenitiesPage from './pages/amenitiesPage/amenitiesPage';
import BookingsPage from './pages/businessBookingPage/bookingsPage';
import BusinessProfileEdit from './pages/businessProfilePage/businessProfileEdit';
import BusinessProfilePage from './pages/businessProfilePage/businessProfilePage';
import Dashboard from './pages/dashboard/dashboard';
import DiningPage from './pages/diningPage/diningPage';
import RestaurantPage from './pages/diningPage/restaurantPage';
import Event from './pages/eventsPage/event';
import FacilityProfile from './pages/facilityProfilePage/facilityProfile';
import FacilityProfileEdit from './pages/facilityProfilePage/facilityProfileEdit';
import FacilityReviews from './pages/facilityReviewsPage/facilityReviews';
import { ForgotPasswordPage } from './pages/forgotPassword';
import GuestUsersPage from './pages/guestUser/guestPage';
import LandingPage from './pages/landingPage/landingPage';
import {
  getAllFacilities,
  getAuthenticatedUser,
  // eslint-disable-next-line prettier/prettier
  resetAuthState,
} from './pages/loginPage/authSlice';
import {
  fetchBusinessUnderUser,
  // eslint-disable-next-line prettier/prettier
  resetBusinessAuthState,
} from './pages/loginPage/businessAuthSlice';
import LoginPage from './pages/loginPage/loginPage';
import RatingReviewPage from './pages/ratingsReviewsPage/ratingsReviewsPage';
import BusinessRegistration from './pages/registrationPage/businessRegistration';
import RegistrationPage from './pages/registrationPage/registrationPage';
import SupportingBusinessPage from './pages/supportingBusinessPage/supportingBusinessPage';
import UsersPage from './pages/usersPage/usersPage';
import { roleChecker } from './_helpers/checkerFunctions';
import { clearLocalStorage, isAuthenticated } from './_helpers/localStorage';
import { NavigateSetter } from './_helpers/navigateSetter';

function App() {
  const hostName = window.location.hostname;
  const { loggedInUser, roleIds, filter } = useAppSelector(
    (state) => state.authentication
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (isAuthenticated()) {
      dispatch(getAuthenticatedUser(null));
    } else {
      dispatch(resetAuthState());
      dispatch(resetBusinessAuthState());
    }
  }, [isAuthenticated()]);
  useEffect(() => {
    if (
      loggedInUser?.id &&
      (roleIds.facilityAdminRoleId ||
        roleIds.facilityUserRoleId ||
        roleIds.supportBusinessAdminRoleId)
    ) {
      if (
        // loggedInUser?.id &&
        roleChecker(
          loggedInUser?.roleId,
          roleIds.facilityAdminRoleId,
          roleIds.facilityUserRoleId
        ) &&
        (domain_env.FACILITY_DOMAINS.includes(window.location.hostname) ||
          domain_env.LOCALHOST.includes(window.location.hostname)) &&
        window.location.pathname.split('/')[1] != 'business'
      ) {
        dispatch(getAllFacilities(filter));
      } else if (
        // loggedInUser?.id &&
        roleChecker(loggedInUser?.roleId, roleIds.supportBusinessAdminRoleId) &&
        (domain_env.BUSINESS_DOMAINS.includes(window.location.hostname) ||
          domain_env.LOCALHOST.includes(window.location.hostname)) &&
        window.location.pathname.split('/')[1] == 'business'
      ) {
        dispatch(fetchBusinessUnderUser(null));
      } else {
        clearLocalStorage();
        toast.error(`User not Authenticated`);
      }
    }
  }, [roleIds]);

  return (
    <React.Fragment>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.3.122/build/pdf.worker.min.js"></Worker>

      <div className="">
        <BrowserRouter>
          <ToastContainer
            className="toastify__fontSize"
            position="top-center"
            theme="light"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <NavigateSetter />
          <Routes>
            <Route
              path={PathConstants.BUSINESS_LOGIN}
              element={
                <BusinessLoginWrap hostName={hostName}>
                  <LoginPage />
                </BusinessLoginWrap>
              }
            />
            <Route
              path={PathConstants.BUSINESS_REGISTRATION}
              element={<BusinessRegistration />}
            />
            <Route
              path={PathConstants.BUSINESS_CREATE_PASSWORD}
              element={<CreatePasswordWrap />}
            />
            <Route
              path={PathConstants.BUSINESS_RESET_PASSWORD}
              element={<ResetPasswordWrap />}
            />
            <Route
              path={PathConstants.BUSINESS_FORGOT_PASSWORD}
              element={<ForgotPasswordPage />}
            />
            <Route
              path={PathConstants.LOGIN}
              element={
                <FacilityLoginWrap hostName={hostName}>
                  <LoginPage />
                </FacilityLoginWrap>
              }
            />
            <Route
              path={PathConstants.FORGOT_PASSWORD}
              element={<ForgotPasswordPage />}
            />

            <Route
              path={PathConstants.REGISTRATION}
              element={<RegistrationPage />}
            />
            <Route
              path={PathConstants.RESET_PASSWORD}
              element={<ResetPasswordWrap />}
            />
            <Route
              path={PathConstants.CREATE_PASSWORD}
              element={<CreatePasswordWrap />}
            />
            <Route
              path={PathConstants.HOME}
              element={<LandingPage hostName={hostName} />}
            >
              <Route
                index
                element={
                  <HomeRouteWrap
                    hostName={hostName}
                    loggedInUser={loggedInUser}
                    roleIds={roleIds}
                  >
                    <Dashboard />
                  </HomeRouteWrap>
                }
              />
              <Route
                path={PathConstants.BUSINESS_BOOKINGS}
                element={
                  <BusinessRoleWrap
                    loggedInUser={loggedInUser}
                    roleIds={roleIds}
                  >
                    <BookingsPage />
                  </BusinessRoleWrap>
                }
              />
              <Route
                path={PathConstants.BUSINESS_RATING_REVIEW}
                element={
                  <BusinessRoleWrap
                    loggedInUser={loggedInUser}
                    roleIds={roleIds}
                  >
                    <RatingReviewPage />
                  </BusinessRoleWrap>
                }
              />
              <Route
                path={PathConstants.BUSINESS_PROFILE}
                element={
                  <BusinessRoleWrap
                    loggedInUser={loggedInUser}
                    roleIds={roleIds}
                  >
                    <BusinessProfilePage />
                  </BusinessRoleWrap>
                }
              />
              <Route
                path={PathConstants.BUSINESS_PROFILE_EDIT}
                element={
                  <BusinessRoleWrap
                    loggedInUser={loggedInUser}
                    roleIds={roleIds}
                  >
                    <BusinessProfileEdit />
                  </BusinessRoleWrap>
                }
              />
              <Route
                path={PathConstants.ACTIVITIES}
                element={
                  <FacilityRoleWrap
                    loggedInUser={loggedInUser}
                    roleIds={roleIds}
                  >
                    <ActivitiesPage />
                  </FacilityRoleWrap>
                }
              />
              <Route
                path={PathConstants.EVENT_DETAILS}
                element={
                  <FacilityRoleWrap
                    loggedInUser={loggedInUser}
                    roleIds={roleIds}
                  >
                    <Event />
                  </FacilityRoleWrap>
                }
              />
              <Route
                path={PathConstants.GUEST}
                element={
                  <FacilityRoleWrap
                    loggedInUser={loggedInUser}
                    roleIds={roleIds}
                  >
                    <GuestUsersPage />
                  </FacilityRoleWrap>
                }
              />

              <Route
                path={PathConstants.AMENITIES}
                element={
                  <FacilityRoleWrap
                    loggedInUser={loggedInUser}
                    roleIds={roleIds}
                  >
                    <AmenitiesPage />
                  </FacilityRoleWrap>
                }
              />
              <Route
                path={PathConstants.DINING_SCHEDULES}
                element={
                  <FacilityRoleWrap
                    loggedInUser={loggedInUser}
                    roleIds={roleIds}
                  >
                    <RestaurantPage />
                  </FacilityRoleWrap>
                }
              />
              <Route
                path={PathConstants.INDIVIDUAL_RESTAURANT}
                element={
                  <FacilityRoleWrap
                    loggedInUser={loggedInUser}
                    roleIds={roleIds}
                  >
                    <DiningPage />
                  </FacilityRoleWrap>
                }
              />
              <Route
                path={PathConstants.FACILITY_PROFILE}
                element={
                  <FacilityRoleWrap
                    loggedInUser={loggedInUser}
                    roleIds={roleIds}
                  >
                    <FacilityProfile />
                  </FacilityRoleWrap>
                }
              />
              <Route
                path={PathConstants.FACILITY_PROFILE_EDIT}
                element={
                  <FacilityRoleWrap
                    loggedInUser={loggedInUser}
                    roleIds={roleIds}
                  >
                    <FacilityProfileEdit />
                  </FacilityRoleWrap>
                }
              />
              <Route
                path={PathConstants.SUPPORTING_BUSINESS}
                element={
                  <FacilityRoleWrap
                    loggedInUser={loggedInUser}
                    roleIds={roleIds}
                  >
                    <SupportingBusinessPage />
                  </FacilityRoleWrap>
                }
              />
              <Route
                path={PathConstants.USERS}
                element={
                  <FacilityRoleWrap
                    loggedInUser={loggedInUser}
                    roleIds={roleIds}
                  >
                    <UsersPage />
                  </FacilityRoleWrap>
                }
              />
              <Route
                path={PathConstants.FACILITY_RATING_REVIEW}
                element={
                  <FacilityRoleWrap
                    loggedInUser={loggedInUser}
                    roleIds={roleIds}
                  >
                    <FacilityReviews />
                  </FacilityRoleWrap>
                }
              />
            </Route>
            <Route
              path={PathConstants.SUCCESS_REGISTRATION_FACILITY}
              element={<SuccessComponent />}
            />
            <Route
              path={PathConstants.SUCCESS_REGISTRATION_BUSINESS}
              element={<SuccessComponent />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
    </React.Fragment>
  );
}

export default App;
