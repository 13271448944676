import { IWeekdaysConstants } from '../../pages/diningPage/diningInterface';

export const WeedDays: IWeekdaysConstants[] = [
  {
    for: 'monday',
    content: 'Monday',
    value: 'MONDAY',
  },
  {
    for: 'tuesday',
    content: 'Tuesday',
    value: 'TUESDAY',
  },
  {
    for: 'wednesday',
    content: 'Wednesday',
    value: 'WEDNESDAY',
  },
  {
    for: 'thursday',
    content: 'Thursday',
    value: 'THURSDAY',
  },
  {
    for: 'friday',
    content: 'Friday',
    value: 'FRIDAY',
  },
  {
    for: 'saturday',
    content: 'Saturday',
    value: 'SATURDAY',
  },
  {
    for: 'sunday',
    content: 'Sunday',
    value: 'SUNDAY',
  },
];
