import React from 'react';
import { Row } from './table';

const TableWrapper: React.FC<{
  row: Row;
}> = ({ row }) => {
  // if (row.type === RowType.COMPONENT) {
  return <>{row?.content?.component}</>;
  // }
  // return <Label label={row?.content?.value || 'Nill'} />;
};

export default TableWrapper;
