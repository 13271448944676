import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { activeInactiveChecker } from '../../_helpers/checkerFunctions';
import { stringTruncate } from '../../_helpers/stringTruncate';
import { useAppSelector } from '../../app/hook';
import {
  IAmenitiesData,
  IAmenitiesDocSrc,
} from '../../pages/amenitiesPage/amenitiesInterface';
import ImageComp from '../imageComponents/imageComp';
import { DropDownComponent } from '../menuDropDown/dropDownComponent';

interface IAmenityCardProps {
  data: IAmenitiesData;
  index: number;
  editAmenityData: (id: string) => void;
  onSelectedFile: (data: IAmenitiesDocSrc) => void;
  deleteAmenityData: (id: string) => void;
  viewFileList: (index: number) => void;
  viewFileIndex: number;
  id: string;
}

export const AmenityCard: React.FC<IAmenityCardProps> = ({
  data,
  index,
  editAmenityData,
  onSelectedFile,
  deleteAmenityData,
  viewFileList,
  viewFileIndex,
  id,
}) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: id });

  //   const [t, i18n] = useTranslation('common');
  const [translation] = useTranslation('common');
  const { currentFacility } = useAppSelector((state) => state.authentication);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  const inlineStyles: CSSProperties = {
    opacity: isDragging ? '0.5' : '1',
    transformOrigin: '50% 50%',

    cursor: isDragging ? 'grabbing' : 'grab',

    boxShadow: isDragging
      ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px'
      : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',

    ...style,
  };

  return (
    <div>
      <div
        ref={setNodeRef}
        style={inlineStyles}
        key={data?.id}
        className="amenities-card"
      >
        <div className="amenities-image-container">
          <ImageComp
            imageId={data?.photoId}
            className="amenities-image"
            noImageClass="noImageClass"
            noImageDivClass="noImageDivClass"
            divClass="amenities-image-div"
            alt={translation(`${data?.name}-image`)}
            src="/assets/emptyImage.svg"
          />

          <div className="amenities-action-buttons">
            <div
              className="amenity-editIcon pointer"
              onClick={
                activeInactiveChecker(currentFacility?.status)
                  ? () => editAmenityData(data?.id)
                  : undefined
              }
            >
              <img
                src={process.env.PUBLIC_URL + '/assets/editWhiteIcon.svg'}
                alt="edit-icon"
              />
            </div>
            <div className="action-button-border"></div>
            <div
              className="amenity-deleteIcon pointer"
              onClick={
                activeInactiveChecker(currentFacility?.status)
                  ? () => deleteAmenityData(data?.id)
                  : undefined
              }
            >
              <img
                src={process.env.PUBLIC_URL + '/assets/deleteWhiteIcon.svg'}
                alt="delete-icon"
              />
            </div>
            <div className="action-button-border"></div>
            <div
              {...listeners}
              {...attributes}
              className="amenity-deleteIcon pointer"
            >
              <img
                src={process.env.PUBLIC_URL + '/assets/draggableHandleIcon.svg'}
                alt="delete-icon"
              />
            </div>
          </div>
        </div>
        <div className="amenities-body">
          <div className="amenities-body-name">
            <span> {data?.name && stringTruncate(data?.name, 30)}</span>
          </div>

          {
            data?.documents && data?.documents?.length > 0 ? (
              <div>
                <span
                  onClick={() => viewFileList(index)}
                  className="documents-text pointer"
                >
                  {translation('SUPPORTING_BUSINESS.VIEW_DOCUMENTS')}
                </span>{' '}
                {data?.documents.length > 0 && viewFileIndex === index && (
                  <DropDownComponent
                    onSelected={onSelectedFile}
                    menuFileList={data?.documents}
                  />
                )}
              </div>
            ) : null
            // <span className="no-documents-text ">
            //   {' '}
            //   {translation('SUPPORTING_BUSINESS.NO_DOCUMENTS')}
            // </span>
          }

          <div className="amenities-body-overflow">
            <span>{data?.description}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
