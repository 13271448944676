import React from 'react';
import { ITHead } from './table';

const TableHead: React.FC<{
  header: ITHead[];
}> = ({ header }) => {
  return (
    <thead className="table-head">
      <tr className="tr">
        {header.map((field: ITHead) => (
          <th scope="col" key={field?.key}>
            {field.label}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHead;
