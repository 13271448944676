import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Button, Form } from 'reactstrap';
import FormTemplateWrap from '../../components/formTemplateWrap/formTemplateWrap';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
import PasswordComponent from '../../components/passwordComponent/passwordComponent';
import {
  ICreatePassConstants,
  ICreatePassword,
  // eslint-disable-next-line prettier/prettier
  ICreatePasswordFinal
} from './createPasswordInterface';
import { createPasswordSchema } from './createPasswordValidation';

export const CreatePassword: FC<{
  constantValues: ICreatePassConstants;
  onClick: (data: ICreatePasswordFinal) => void;
  processing: boolean;
}> = ({ constantValues, onClick, processing }) => {
  const [translation] = useTranslation('common');
  const [token, setToken] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ICreatePassword>({
    resolver: yupResolver(createPasswordSchema),
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    const loginToken = searchParams.get('token');
    if (loginToken) {
      setToken(loginToken);
    }
  }, []);

  const onSubmit = (data: ICreatePassword) => {
    onClick(createPasswordPayload(data));
  };

  const createPasswordPayload = (
    formData: ICreatePassword
  ): ICreatePasswordFinal => {
    const data = {
      newPassword: formData.newPassword.trim(),
      confirmPassword: formData.confirmPassword.trim(),
      token: token,
    };
    return data;
  };

  return (
    <React.Fragment>
      <FormTemplateWrap>
        <div className="form-bg">
          <img
            className="padding-bottom-2rem logo"
            src={process.env.PUBLIC_URL + '/assets/logo.svg'}
            alt="Logo"
          />
          <h2 className="padding-bottom-1rem form-title">
            {translation(`${constantValues.title}`)}
          </h2>
          <Form className="form width-100" onSubmit={handleSubmit(onSubmit)}>
            <PasswordComponent
              control={control}
              errors={errors}
              constants={constantValues}
            />
            <div className="form-button-flex-center">
              <Button
                className="custom-btn form-button resetPassword-button shadow-none"
                type="submit"
              >
                {processing ? (
                  <LoadingSpinner />
                ) : (
                  translation(`${constantValues.buttonText}`)
                )}
              </Button>
            </div>
          </Form>
        </div>
      </FormTemplateWrap>
    </React.Fragment>
  );
};
