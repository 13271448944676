import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { env } from '../../config/env';
import { getImageDetails } from '../../services/amenitiesService';
import {
  getAllBusinessByLocation,
  // eslint-disable-next-line prettier/prettier
  getCategories,
} from '../../services/supportingBussinessService';
import { IUserPaginateData } from '../usersPage/usersInterface';
import { getLocalBusinessParams } from './localBusinessParams';
import {
  IBussinessData,
  // eslint-disable-next-line prettier/prettier
  ISupportBussinessPayloadFilter,
} from './SupportBusinessInterface';

export interface ILocalSupportingBussiness {
  isProcessingAllLocalBusiness: boolean;

  filter: ISupportBussinessPayloadFilter;

  localBusinessList: IBussinessData[];

  isFetchingImage: boolean;
  businessPaginate: IUserPaginateData;
}
const initialState: ILocalSupportingBussiness = {
  isProcessingAllLocalBusiness: false,

  filter: {
    name: '',
    distanceInKm: '60',
    latitude: '',
    longitude: '',

    page: 0,
    size: 10,
    sort: 'createdAt,Desc',

    category: '',
    status: 'ACTIVE',
  },
  businessPaginate: {
    pageNumber: 0,
    pageSize: 0,
    numberOfElements: 0,
    totalPages: 0,
    totalElements: 0,
    first: false,
  },

  localBusinessList: [],
  isFetchingImage: false,
};

export const supportBusinessSlice = createSlice({
  name: 'supportBusiness',
  initialState,
  reducers: {
    addFilterPage: (state, action) => {
      state.filter.page = action.payload;
    },
    applyFilterOnPage: (state, action) => {
      state.filter.category = action.payload;
    },
    addLongitudeFilter: (state, action) => {
      state.filter.longitude = action.payload;
    },
    addLatitudeFilter: (state, action) => {
      state.filter.latitude = action.payload;
    },
    clearAllFilter: (state) => {
      state.filter.category = '';
    },
    pageFilterReset: (state) => {
      state.filter.page = 0;
    },
    addTranslatedData: (state, action) => {
      state.localBusinessList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchAllLocalSupportBusiness.pending, (state) => {
        state.isProcessingAllLocalBusiness = true;
      })
      .addCase(fetchAllLocalSupportBusiness.fulfilled, (state, action) => {
        state.isProcessingAllLocalBusiness = false;
        state.localBusinessList = action.payload?.data[0]?.data;
        state.businessPaginate = action.payload.data[0];
      })
      .addCase(
        fetchAllLocalSupportBusiness.rejected,
        (state, action: PayloadAction<any>) => {
          state.isProcessingAllLocalBusiness = false;
          // toast.error(action.payload);
        }
      );
  },
});

export const fetchAllLocalSupportBusiness = createAsyncThunk(
  'supportBusiness/fetchAllBusiness',
  async (data: ISupportBussinessPayloadFilter, { rejectWithValue }) => {
    try {
      const params = getLocalBusinessParams(
        data.latitude,
        data.longitude,
        data.distanceInKm,
        data.page,
        data.size,
        data.status
        // data.sort,
        // data.category
      );
      const response = await getAllBusinessByLocation(params);
      const manipulatedArray = await Promise.all(
        response?.data[0]?.data.map(async (obj: any) => {
          const documents = await Promise.all(
            obj.documents.map(fetchDocumentDetails)
          );
          return { ...obj, documents };
        })
      );

      response.data[0].data = [...manipulatedArray];

      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchDocumentDetails = async (document: string) => {
  try {
    const response = await getImageDetails(document);

    const data = await response?.data[0];

    const fileType = data?.mimeType;
    const fileName = data?.originalName;
    const fileId = data?.id;
    const file = `${env.development.BASE_URL}/files/${data?.id}`;

    return { fileType, fileName, fileId, file };
  } catch (error) {
    console.log(`Error fetching document details for ${document}:`, error);
    return null;
  }
};

export const fetchCategoriesByIds = createAsyncThunk(
  'supportBusiness/fetchCategoriesByIds',
  async (ids: string, { rejectWithValue }) => {
    try {
      const response = await getCategories(ids);

      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
export const {
  addFilterPage,
  applyFilterOnPage,
  clearAllFilter,
  addLatitudeFilter,
  addLongitudeFilter,
  pageFilterReset,
  addTranslatedData,
} = supportBusinessSlice.actions;

export const selectbusiness = (state: RootState) => state.business;
export const businessReducer = supportBusinessSlice.reducer;
