import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import React, { FC } from 'react';
import './pdf-viewer.css';

interface IPdfComponent {
  close: () => void;
  url: string;
}

export const PdfComponent: FC<IPdfComponent> = ({ close, url }) => {
  return (
    <div style={{ width: '100%' }}>
      <div className="pdf-component-wrap" onClick={close}>
        <img
          className="pointer"
          src={process.env.PUBLIC_URL + '/assets/searchClose.svg'}
          alt=""
        />
      </div>
      <div className="pdf-container">
        <Viewer fileUrl={url} />
      </div>
    </div>
  );
};
