import { IFacilityReviewsFilter } from '../pages/facilityReviewsPage/facilityReviewsInterface';
import { IRatingsFilter } from '../pages/ratingsReviewsPage/ratingsReviewsInterface';
import { deleteApi, get } from './apiService';

export const getAllRatingsReviews = async (data: IRatingsFilter) => {
  return await get(
    `/support-business/ratings?supportBusinessId=${data?.id}&page=${data.page}&sort=${data?.sort}&size=${data?.size}`
  );
};
export const getRatedUser = async (id: string) => {
  return await get(`/user/${id}`);
};
export const getAvgRatingsReviews = async (id: string) => {
  return await get(`/support-business/${id}/stats`);
};

export const getFacilityReviews = async (data: IFacilityReviewsFilter) => {
  return await get(
    `/facility-rating?facilityId=${data?.id}&sort=${data?.sort}&page=${data?.page}&size=${data?.size}`
  );
};
export const getFacilityReviewStats = async (id: string) => {
  return await get(`/facility-rating/stats?facilityId=${id}`);
};

export const getUserDetails = async (id: string) => {
  return await get(`/user/${id}`);
};
export const deleteReviewPic = async (id: string, photoId: string) => {
  return await deleteApi(`/facility-rating/${id}/files?photoIds=${photoId}`);
};

export const getFaciltyReviewById = async (id: string) => {
  return await get(`/facility-rating/${id}`);
};
