import { IFacilityParams } from '../components/header/facilityParams';
import { IAddFacilityPayload } from '../pages/registrationPage/registrationTypes';
import { get, post, postTranslate } from './apiService';

export const getAuthUser = async () => {
  return await get('/user/me', {});
};
export const getFacilities = async (data: IFacilityParams) => {
  return await get(`/facility`, data);
};
export const getFacility = async (id: string) => {
  return await get(`/facility/${id}`, {});
};
export const addNewFacility = async (data: IAddFacilityPayload) => {
  return await post(`/facility/register`, data);
};

export const addTranslataion = async (data: any) => {
  return await postTranslate(`/translate/v2`, data, {
    params: { key: `${process.env.REACT_APP_MAPS_API_KEY}`, format: 'text' },
  });
};
export const addSingleTranslataion = async (data: string) => {
  return await postTranslate(`/translate/v2`, data, {
    params: { key: `${process.env.REACT_APP_MAPS_API_KEY}`, format: 'text' },
  });
};
