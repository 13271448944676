import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { modalReducer } from '../components/modal/modalSlice';
import { activitiesReducer } from '../pages/activitiesPage/activiitiesSlice';
import { amenitiesReducer } from '../pages/amenitiesPage/amenitiesSlice';
import { bookingsReducer } from '../pages/businessBookingPage/bookingsSlice';
import { createPasswordReducer } from '../pages/createPassword/createPasswordSlice';
import { dashboardInfoReducer } from '../pages/dashboard/dashboardSlice';
import { diningReducer } from '../pages/diningPage/diningSlice';
import { facilityProfileReducer } from '../pages/facilityProfilePage/profileSlice';
import { facilityReviewsReducer } from '../pages/facilityReviewsPage/facilityReviewsSlice';
import { forgotPasswordReducer } from '../pages/forgotPassword/forgotPasswordSlice';
import { guestReducer } from '../pages/guestUser/guestSlice';
import { authenticationReducer } from '../pages/loginPage/authSlice';
import { businessAuthReducer } from '../pages/loginPage/businessAuthSlice';
import { ratingsReviewsReducer } from '../pages/ratingsReviewsPage/ratingsReviewsSlice';
import { businessRegistrationReducer } from '../pages/registrationPage/businessRegistrationSlice';
import { registrationReducer } from '../pages/registrationPage/registrationSlice';
import { businessReducer } from '../pages/supportingBusinessPage/supportingBusinessSlice';
import { usersReducer } from '../pages/usersPage/usersSlice';

export const store = configureStore({
  reducer: {
    activities: activitiesReducer,
    amenities: amenitiesReducer,
    authentication: authenticationReducer,
    forgotPassword: forgotPasswordReducer,
    modal: modalReducer,
    createPassword: createPasswordReducer,
    registration: registrationReducer,
    users: usersReducer,
    facilityProfile: facilityProfileReducer,
    dining: diningReducer,
    guest: guestReducer,
    businessRegistration: businessRegistrationReducer,
    businessAuth: businessAuthReducer,
    bookings: bookingsReducer,
    business: businessReducer,
    ratingsReviews: ratingsReviewsReducer,
    facilityReviews: facilityReviewsReducer,
    dasboardInfo: dashboardInfoReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
