import React from 'react';
import { env } from '../../config/env';
import '../../pages/guestUser/guest.css';

const BusinessImage: React.FC<{
  imageId: string | undefined;
  className: string;
  divClass: string;
  noImageClass: string;
  alt?: string;
  src: string;
  loadingColor?: string;
}> = ({
  className,
  alt,
  src,
  imageId,
  divClass,
  noImageClass,
  loadingColor,
}) => {
  return !imageId ? (
    <div className={divClass}>
      <img className={noImageClass} src={process.env.PUBLIC_URL + src} alt="" />
    </div>
  ) : (
    <div className={divClass}>
      <img
        className={className}
        src={`${env.development.BASE_URL}/files/${imageId}`}
        alt=""
      />
    </div>
  );
};

export default BusinessImage;
