import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Moment } from 'moment';
import React, { CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import { activeInactiveChecker } from '../../_helpers/checkerFunctions';
import { useAppSelector } from '../../app/hook';
import {
  ActivityType,
  IActivity,
} from '../../pages/activitiesPage/activitiesInterface';
import DateComponent from './dateComponent';
import TimeCompWrap from './timeCompWrap';

const ActivityCard: React.FC<{
  item: IActivity;
  currentDate: Moment;
  editActivityData: (id: string) => void;
  deleteActivityData: (id: string) => void;
  eventDetailsNavigator: (item: IActivity) => void;
}> = ({
  item,
  currentDate,
  editActivityData,
  deleteActivityData,
  eventDetailsNavigator,
}) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: item.id });
  const { currentFacility } = useAppSelector((state) => state.authentication);
  const [translation] = useTranslation('common');
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  const inlineStyles: CSSProperties = {
    opacity: isDragging ? '0.5' : '1',
    transformOrigin: '50% 50%',

    cursor: isDragging ? 'grabbing' : 'grab',

    boxShadow: isDragging
      ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px'
      : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',

    ...style,
  };
  return (
    <div className="activity-card" ref={setNodeRef} style={inlineStyles}>
      <div className="activity-image activity-card-image-R">
        {item?.type == ActivityType.notification ? (
          <img
            src={process.env.PUBLIC_URL + '/assets/notificationIcon.svg'}
            alt="calender-icon"
          />
        ) : (
          <img
            src={process.env.PUBLIC_URL + '/assets/eventIcon.svg'}
            alt="calender-icon"
          />
        )}
      </div>
      <div className="activity-details activity-card-details-R">
        <span className="activity-name">{item?.name}</span>
        <div className="activity-timing-div">
          <DateComponent date={currentDate} />
        </div>
        <TimeCompWrap
          startTime={item?.startingTime}
          endTime={item?.endingTime}
        />
        {item?.type == ActivityType.event ? (
          <div className="activityCard-navigator-div">
            <span
              className="view-details pointer"
              onClick={
                activeInactiveChecker(currentFacility?.status)
                  ? () => eventDetailsNavigator(item)
                  : undefined
              }
            >
              {translation('ACTIVITIES.VIEW_DETAILS')}
            </span>
            <div className="activityCard-guest-div">
              <img
                src={process.env.PUBLIC_URL + '/assets/peopleIcon.svg'}
                alt=""
              />
              <span className="guest-numbers">
                {item?.noOfGuestsInterested ? item?.noOfGuestsInterested : '0'}
              </span>
            </div>
          </div>
        ) : null}
      </div>
      <div className="activity-action-buttons">
        <div
          className="activity-editIcon pointer"
          onClick={
            activeInactiveChecker(currentFacility?.status)
              ? () => editActivityData(item?.id)
              : undefined
          }
        >
          <img
            src={process.env.PUBLIC_URL + '/assets/editWhiteIcon.svg'}
            alt="edit-icon"
          />
        </div>
        <div className="action-button-border"></div>
        <div
          className="activity-deleteIcon pointer"
          onClick={
            activeInactiveChecker(currentFacility?.status)
              ? () => deleteActivityData(item?.id)
              : undefined
          }
        >
          <img
            src={process.env.PUBLIC_URL + '/assets/deleteWhiteIcon.svg'}
            alt="delete-icon"
          />
        </div>
        <div className="action-button-border"></div>
        <div
          {...listeners}
          {...attributes}
          className="activity-deleteIcon pointer"
        >
          <img
            className="w-h-15"
            src={process.env.PUBLIC_URL + '/assets/draggableHandleIcon.svg'}
            alt="delete-icon"
          />
        </div>
      </div>
    </div>
  );
};

export default ActivityCard;
