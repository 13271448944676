import { ICreatePassConstants } from './createPasswordInterface';

export const createPasswordConst: ICreatePassConstants = {
  title: 'CREATE_PASSWORD.TITLE',
  passwordText: 'CREATE_PASSWORD.NEW_PASSWORD',
  passwordInvalid: 'CREATE_PASSWORD.NEW_PASSWORD_REQUIRED',
  confirmPasswordText: 'CREATE_PASSWORD.CONFIRM_PASSWORD',
  confirmPasswordInvalid: 'CREATE_PASSWORD.CONFIRM_PASSWORD_REQUIRED',
  buttonText: 'CREATE_PASSWORD.CREATE_BUTTON',
};

export const resetPasswordConst: ICreatePassConstants = {
  title: 'RESET_PASSWORD.TITLE',
  passwordText: 'RESET_PASSWORD.NEW_PASSWORD',
  passwordInvalid: 'RESET_PASSWORD.NEW_PASSWORD_REQUIRED',
  confirmPasswordText: 'RESET_PASSWORD.CONFIRM_PASSWORD',
  confirmPasswordInvalid: 'RESET_PASSWORD.CONFIRM_PASSWORD_REQUIRED',
  buttonText: 'RESET_PASSWORD.RESET_BUTTON',
};
