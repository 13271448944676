import * as yup from 'yup';

export const restaurantSchema = yup
  .object({
    restaurantName: yup
      .string()
      .max(200, 'Maximum 200 characters')
      .required('DINING_SCHEDULES.RESTAURANT_NAME_REQUIRED'),
    startingTime: yup.string().required('ACTIVITIES_FORM.START_TIME_REQUIRED'),
    endTime: yup
      .string()
      .required('ACTIVITIES_FORM.END_TIME_REQUIRED')
      .test(
        'is-end-time-greater',
        'ACTIVITIES_FORM.END_TIME_MUST_BE_AFTER_START',
        function (value) {
          const { startingTime } = this.parent;
          return startingTime && value ? startingTime < value : true;
        }
      ),
  })
  .required();
