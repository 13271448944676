import { ISupportBussinessPayloadFilter } from '../pages/supportingBusinessPage/SupportBusinessInterface';
import { get } from './apiService';

export const getAllBusinessByLocation = async (
  data: ISupportBussinessPayloadFilter
) => {
  return await get(`/support-business/distance`, data);
};
export const getCategories = async (ids: string) => {
  return await get(`/support-business-category?categoryIds=${ids}`);
};
