import { IFacility } from '../loginPage/loginInterface';
import { IProfileValidation } from './profileInterface';

export const facilityProfilePayload = (
  coverPic: any,
  coloredLogo: any,
  whiteLogo: any,
  data: IProfileValidation,
  f_id: string,
  currentFacility: IFacility
) => {
  const payload = {
    coverPic: coverPic,
    coloredLogo: coloredLogo,
    whiteLogo: whiteLogo,
    data: data,
    facilityId: f_id,
    coverPicId: currentFacility.coverPicId,
    whiteLogoId: currentFacility.whiteLogoId,
    coloredLogoId: currentFacility.coloredLogoId,
  };
  return payload;
};

export const faciltyPayloadSetter = (
  data: IProfileValidation,
  coloredLogoId: string,
  whiteLogoId: string,
  coverPicId: string,
  facilityId: string
) => {
  const payload = {
    name: data.name,
    phoneNo: data.phoneNo,
    whiteLogoId: whiteLogoId,
    coloredLogoId: coloredLogoId,
    coverPicId: coverPicId,
    facilityId: facilityId,
  };
  return payload;
};
