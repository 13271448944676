import React from 'react';
import './pageNotFound.css';

const PageNotFound: React.FC = () => {
  return (
    <div className="page-background pageNotFound">
      <div>Page Not Found</div>
    </div>
  );
};

export default PageNotFound;
