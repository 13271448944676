import {
  IBookingsFilter,
  // eslint-disable-next-line prettier/prettier
  IRejectBookingPayload
} from '../pages/businessBookingPage/bookingsInterface';
import { get, post } from './apiService';

export const getAllBookings = async (data: IBookingsFilter) => {
  return await get(
    `/support-business/bookings?supportBusinessId=${data?.id}&status=${data?.status}&sort=${data?.sort}&page=${data?.page}&size=${data?.size}`
  );
};

export const getBookedUser = async (id: string) => {
  return await get(`/user/${id}`);
};
export const approveBookings = async (id: string) => {
  return await post(`/support-business/bookings/${id}/approve`, null);
};
export const declineBookings = async (data: IRejectBookingPayload) => {
  return await post(
    `/support-business/bookings/${data.id}/reject`,
    data.rejectReason
  );
};
