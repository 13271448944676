import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';
import { RootState } from '../../app/store';
import { getImage } from '../../services/facilityService';
import { getAllGuestList } from '../../services/guestSummary';
import { IUserPaginateData } from '../usersPage/usersInterface';
import { IGuestData, IGuestPayloadFilter } from './guestInterface';
import { getGuestParams } from './guestParams';

export interface IGuestSummary {
  isProcessingAllguest: boolean;

  filter: IGuestPayloadFilter;

  guestList: IGuestData[];
  guestPaginate: IUserPaginateData;

  isFetchingImage: boolean;
}
const initialState: IGuestSummary = {
  isProcessingAllguest: false,

  filter: {
    facilityId: '',
    page: 0,
    size: 10,
    sort: 'createdAt,Desc',
  },
  guestPaginate: {
    pageNumber: 0,
    pageSize: 0,
    numberOfElements: 0,
    totalPages: 0,
    totalElements: 0,
    first: false,
  },

  guestList: [],
  isFetchingImage: false,
};

export const guestSummarySlice = createSlice({
  name: 'guestSummary',
  initialState,
  reducers: {
    addFilterPage: (state, action) => {
      state.filter.page = action.payload;
    },
    guestFilterPageReset: (state) => {
      state.filter.page = 0;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchAllGuest.pending, (state) => {
        state.isProcessingAllguest = true;
      })
      .addCase(fetchAllGuest.fulfilled, (state, action) => {
        state.isProcessingAllguest = false;
        state.guestList = action.payload?.data[0]?.data;
        state.guestPaginate = action.payload?.data[0];
      })
      .addCase(fetchAllGuest.rejected, (state) => {
        state.isProcessingAllguest = false;
        state.guestList = [];
      })
      .addCase(getGuestImageById.pending, (state) => {
        state.isFetchingImage = true;
      })
      .addCase(getGuestImageById.fulfilled, (state) => {
        state.isFetchingImage = false;
      })
      .addCase(
        getGuestImageById.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingImage = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      );
  },
});

export const fetchAllGuest = createAsyncThunk(
  'guest/guestList',
  async (data: IGuestPayloadFilter, { rejectWithValue }) => {
    try {
      const params = getGuestParams(
        data.sort,
        data.page,
        data.size,
        data?.facilityId
      );
      const response = await getAllGuestList(params);
      return response;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getGuestImageById = createAsyncThunk(
  'facilityProfile/getImageById',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getImage(id);
      const fileType = response.headers['content-type'];
      const buffer = Buffer.from(response.data).toString('base64');

      const data = `data:${fileType};base64, ${buffer}`;

      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const { addFilterPage, guestFilterPageReset } =
  guestSummarySlice.actions;
export const selectguestUsers = (state: RootState) => state.guest;
export const guestReducer = guestSummarySlice.reducer;
