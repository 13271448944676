import React from 'react';
import { IRoleIds, IUser } from '../../pages/loginPage/loginInterface';
import { roleChecker } from '../../_helpers/checkerFunctions';
import RefreshLoader from '../refreshLoader/refreshLoader';

const BusinessRoleWrap: React.FC<{
  children: JSX.Element;
  loggedInUser: IUser;
  roleIds: IRoleIds;
}> = ({ children, loggedInUser, roleIds }) => {
  return roleChecker(
    loggedInUser?.roleId,
    roleIds.supportBusinessAdminRoleId
  ) ? (
    children
  ) : (
    <RefreshLoader />
  );
};

export default BusinessRoleWrap;
