import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import GuestImage from '../../components/guestDownloadImage/guestImage';
import NoDataComponent from '../../components/noDataComponent/noDataComponent';
import PageLoader from '../../components/pageLoading/pageLoader';
import Paginate, {
  // eslint-disable-next-line prettier/prettier
  IPaginateClickData,
} from '../../components/paginate/paginate';
import '../usersPage/usersPage.css';
import './guest.css';
import { IGuestData } from './guestInterface';
import {
  addFilterPage,
  fetchAllGuest,
  // eslint-disable-next-line prettier/prettier
  guestFilterPageReset,
} from './guestSlice';
import { guestFilterChange } from './utilityFunctions';

const GuestUsersPage: React.FC = () => {
  const { f_id } = useParams();
  const [translation] = useTranslation('common');
  const dispatch = useAppDispatch();

  const handlePageClick = (data: IPaginateClickData) => {
    if (data.selected !== guestPaginate.totalPages)
      dispatch(addFilterPage(data?.selected));
  };
  const { filter, guestList, isProcessingAllguest, guestPaginate } =
    useAppSelector((state) => state.guest);
  useEffect(() => {
    dispatch(guestFilterPageReset());
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (f_id) {
      dispatch(fetchAllGuest(guestFilterChange(filter, f_id)));
    }
  }, [filter, f_id]);

  return (
    <React.Fragment>
      <div className="page-background amenities-page">
        <div className="guest-head">
          <h2>{translation('GUEST_PAGE.TITLE')}</h2>
        </div>
        {isProcessingAllguest ? (
          <PageLoader />
        ) : guestList.length > 0 ? (
          <div className="guest-card-div guest-parent-card-R">
            {guestList.map((item: IGuestData) => (
              <div key={item.id} className="guest-card">
                <GuestImage
                  imageId={item?.imageId}
                  className="user-image"
                  noImageClass="noImageClass"
                  divClass="user-image-div"
                  alt={''}
                  src="/assets/userImage.svg"
                  loadingColor="light"
                />
                <div className="guest-card-content">
                  <div className="user-details-wrap">
                    <span className="user-name">
                      {translation(`${item?.userName}`)}
                    </span>
                  </div>
                  <div className="user-details-wrap">
                    <span className="user-email">
                      {translation(`${item?.email}`)}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="no-data">
            <NoDataComponent constant="NO_USER" />
          </div>
        )}
        {guestPaginate?.totalPages > 1 && (
          <Paginate
            handlePageClick={handlePageClick}
            totalPage={guestPaginate?.totalPages}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default GuestUsersPage;
