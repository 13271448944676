/* eslint-disable prettier/prettier */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '../../app/store';
import { forgotPassword } from '../../services/authenticationService';
import { IForgotPassword } from './interface';

interface IForgotPasswordState {
  isProcessingForgotPassword: boolean;
  passwordReset: boolean;
  accessToken?: string;
}

const initialState: IForgotPasswordState = {
  isProcessingForgotPassword: false,
  passwordReset: false,
};

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(callForgotPassword.pending, (state) => {
        state.isProcessingForgotPassword = true;
        state.passwordReset = false;
      })
      .addCase(
        callForgotPassword.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isProcessingForgotPassword = false;

          toast.success(
            `${action.payload?.message
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload?.message.slice(1)}`
          );
          state.passwordReset = true;
        }
      )
      .addCase(
        callForgotPassword.rejected,
        (state, action: PayloadAction<any>) => {
          state.isProcessingForgotPassword = false;
          state.passwordReset = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      );
  },
});

export const callForgotPassword = createAsyncThunk(
  'forgotPassword/callForgotPassword',
  async (userData: IForgotPassword, thunkAPI) => {
    try {
      const response = await forgotPassword(userData);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err?.message);
    }
  }
);

export const selectForgotPassword = (state: RootState) => state.forgotPassword;
export const forgotPasswordReducer = forgotPasswordSlice.reducer;
