import * as yup from 'yup';

export const createPasswordSchema = yup
  .object({
    newPassword: yup.string().required('CREATE_PASSWORD.NEW_PASSWORD_REQUIRED'),
    confirmPassword: yup
      .string()
      .required('CREATE_PASSWORD.CONFIRM_PASSWORD_REQUIRED')
      .oneOf(
        [yup.ref('newPassword'), null],
        'CREATE_PASSWORD.PASSWORD_DONOT_MATCH'
      ),
  })

  .required();
