export interface IAmenityParams {
  sort: string;
  page: number;
  size: number;
  facilityIds?: string;
}
export const getAmenityRequestParams = (
  sort: string,
  page: number,
  size: number,
  facilityIds?: string
) => {
  const params = {} as IAmenityParams;

  if (sort) {
    params['sort'] = sort;
  }
  if (page) {
    params['page'] = page;
  }
  if (size) {
    params['size'] = size;
  }
  if (facilityIds) {
    params['facilityIds'] = facilityIds;
  }
  return params;
};
