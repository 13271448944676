import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';
import { RootState } from '../../app/store';
import { getImageDetails } from '../../services/amenitiesService';
import {
  getBusinessCategory,
  getBusinessImage,
  getBusinessImageId,
  getSupportingBusiness,
  // eslint-disable-next-line prettier/prettier
  updateProfile,
} from '../../services/authenticationService';
import { getImageId } from '../../services/facilityService';
import { formDataConverter } from '../../_helpers/formDataConverter';
import { History } from '../../_helpers/history';
import {
  IBusinessDocSrc,
  IBusinessProfilePayload,
  // eslint-disable-next-line prettier/prettier
  IBusinessProfileUpdatePayload,
} from '../businessProfilePage/businessProfileInterface';
import { IBusinessCategories } from '../registrationPage/registrationTypes';
import { ISupportingBusinessList } from './loginInterface';

export interface IBusinessAuth {
  isFetchingBusinessUnderUser: boolean;
  supportingBusinessList: ISupportingBusinessList[];
  supportingBusiness: ISupportingBusinessList;
  isRedirectingBusiness: boolean;
  isFetchingBusinessImage: boolean;
  isFetchingCategoryById: boolean;
  selectedBusinessCategory: IBusinessCategories[];
  isUpdatingProfile: boolean;
  updatedProfile: boolean;
}

const initialState: IBusinessAuth = {
  isFetchingBusinessUnderUser: false,
  supportingBusinessList: [],
  supportingBusiness: {} as ISupportingBusinessList,
  isRedirectingBusiness: false,
  isFetchingBusinessImage: false,
  isFetchingCategoryById: false,
  selectedBusinessCategory: [],
  isUpdatingProfile: false,
  updatedProfile: false,
};

export const businessAuthSlice = createSlice({
  name: 'businessAuth',
  initialState,
  reducers: {
    resetBusinessAuthState: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchBusinessUnderUser.pending, (state) => {
        state.isFetchingBusinessUnderUser = true;
        state.isRedirectingBusiness = true;
      })
      .addCase(fetchBusinessUnderUser.fulfilled, (state, action) => {
        state.isFetchingBusinessUnderUser = false;
        state.isRedirectingBusiness = true;
        state.supportingBusinessList = action.payload?.data[0]?.data;
        state.supportingBusiness = action.payload?.data[0]?.data[0];
      })
      .addCase(fetchBusinessUnderUser.rejected, (state) => {
        state.isFetchingBusinessUnderUser = false;
        state.isRedirectingBusiness = false;
      })
      .addCase(getBusinessImageById.pending, (state) => {
        state.isFetchingBusinessImage = true;
      })
      .addCase(getBusinessImageById.fulfilled, (state) => {
        state.isFetchingBusinessImage = false;
      })
      .addCase(
        getBusinessImageById.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingBusinessImage = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(fetchBusinessCategoryById.pending, (state) => {
        state.isFetchingCategoryById = true;
      })
      .addCase(fetchBusinessCategoryById.fulfilled, (state, action) => {
        state.isFetchingCategoryById = false;
        state.selectedBusinessCategory = action.payload.data[0]?.data;
      })
      .addCase(fetchBusinessCategoryById.rejected, (state) => {
        state.isFetchingCategoryById = false;
      })
      .addCase(fetchBusinessPicId.pending, (state) => {
        state.isUpdatingProfile = true;
        state.updatedProfile = false;
      })
      .addCase(
        fetchBusinessPicId.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isUpdatingProfile = false;
          state.updatedProfile = true;

          // toast.success(
          //   `${action.payload?.message
          //     .toLowerCase()
          //     .charAt(0)
          //     .toUpperCase()}${action.payload?.message.slice(1)}`
          // );
          // History.navigate(-1);
        }
      )
      .addCase(
        fetchBusinessPicId.rejected,
        (state, action: PayloadAction<any>) => {
          state.isUpdatingProfile = false;
          state.updatedProfile = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )

      .addCase(updateBusinessProfile.fulfilled, (state, action) => {
        state.isUpdatingProfile = false;
        state.updatedProfile = true;
        toast.success(
          `${action.payload?.message
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload?.message.slice(1)}`
        );
        History.navigate(-1);
      })
      .addCase(updateBusinessProfile.rejected, (state) => {
        state.isUpdatingProfile = false;
      });
  },
});

export const fetchBusinessUnderUser = createAsyncThunk(
  'businessAuth/fetchBusinessUnderUser',
  async (data: null, { rejectWithValue, dispatch }) => {
    try {
      let categoryIds: number[] = [];
      const response = await getSupportingBusiness();
      categoryIds = await response?.data[0]?.data[0]?.categoryIds;
      if (categoryIds.length) {
        dispatch(fetchBusinessCategoryById(categoryIds.toString()));
      }

      // -------------

      if (response?.data[0]?.data[0]?.documents?.length > 0) {
        const imageDetailsArray = await Promise.all(
          response?.data[0]?.data[0]?.documents?.map(async (id: string) => {
            const data = await getImageDetails(id);
            const array1 = [];

            array1.push({
              fileType: data?.data[0].mimeType,
              fileName: data?.data[0].originalName,
              file: `${id}`,
            });
            return array1;
          })
        );

        const result = imageDetailsArray.flat().reduce((acc, item) => {
          acc.push(item);
          return acc;
        }, []);

        response.data[0].data[0] = {
          ...response?.data[0]?.data[0],
          documents: result,
        };
      }
      // -------------------------
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const getBusinessImageById = createAsyncThunk(
  'businessAuth/getBusinessImageById',
  async (id: string | number, { rejectWithValue }) => {
    try {
      const response = await getBusinessImage(id);
      const fileType = response.headers['content-type'];
      const buffer = Buffer.from(response.data).toString('base64');

      const data = `data:${fileType};base64, ${buffer}`;

      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchBusinessCategoryById = createAsyncThunk(
  'businessAuth/fetchBusinessCategoryById',
  async (ids: string, { rejectWithValue }) => {
    try {
      const response = await getBusinessCategory(ids);
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const updateBusinessProfile = createAsyncThunk(
  'businessAuth/updateBusinessProfile',
  async (data: IBusinessProfilePayload, { rejectWithValue }) => {
    try {
      const response = await updateProfile(data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const fetchBusinessPicId = createAsyncThunk(
  'businessAuth/fetchBusinessPicId',
  async (
    data: IBusinessProfileUpdatePayload,
    { rejectWithValue, dispatch }
  ) => {
    try {
      let imageResponse: any = {};
      if (data.coverPic != '') {
        imageResponse = await getBusinessImageId(
          formDataConverter(data.coverPic)
        );
      }

      const businessDocumentsResponse: string[] = [];

      if (data?.profileData?.documents?.length > 0) {
        await Promise.all(
          data?.profileData?.documents?.map(async (obj: IBusinessDocSrc) => {
            const documentsResponse =
              obj?.fileSource !== 'server' &&
              (await getImageId(formDataConverter(obj?.file)));
            obj?.fileSource !== 'server'
              ? businessDocumentsResponse?.push(
                  documentsResponse?.data?.[0]?.id
                )
              : businessDocumentsResponse?.push(obj?.file);
          })
        );
      }

      const response = dispatch(
        updateBusinessProfile({
          ...data.profileData,
          documents: businessDocumentsResponse,

          photoId: data.coverPic
            ? imageResponse?.data[0]?.id
            : data.profileData.photoId,
        })
      );
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.message);
    }
  }
);

export const { resetBusinessAuthState } = businessAuthSlice.actions;
export const selectBusinessAuth = (state: RootState) => state.businessAuth;
export const businessAuthReducer = businessAuthSlice.reducer;
