import { ITHead } from '../../components/table/table';
import '../../components/table/table.css';

export const tableHeader: ITHead[] = [
  {
    label: 'Name',
    key: 'name',
  },
  {
    label: 'Email',
    key: 'email',
  },
  // {
  //   label: 'Phone',
  //   key: 'phone',
  // },
  {
    label: 'Booking Date',
    key: 'bookingDate',
  },
  {
    label: 'Slots',
    key: 'time',
  },
  {
    label: 'Action',
    key: 'action',
  },
];

export interface INavigationConst {
  name: string;
  key: string;
}
export const navigationConst: INavigationConst[] = [
  {
    name: 'Pending',
    key: 'pending',
  },
  {
    name: 'Booked',
    key: 'booked',
  },
  {
    name: 'Cancelled',
    key: 'cancelled',
  },
];
