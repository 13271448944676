import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { CSSProperties } from 'react';
import { useAppDispatch } from '../../app/hook';
import { IMealsPerDay } from '../../pages/diningPage/diningInterface';
import { addSelectedDay } from '../../pages/diningPage/diningSlice';
import DropdownComp, { IDropdownList } from '../diningDropdown/dropdownComp';
import RestaurantImgComp from '../imageComponents/restaurantImgComp';
import TimeCompWrap from '../restaurants/timeCompWrap';

const MealCard: React.FC<{
  dragabble?: boolean;
  className: string;
  dropdown: string;
  compKey: string;
  showOptions: (key: string) => void;
  perDayData: IMealsPerDay;
  onCardClick: (data: IMealsPerDay) => void;
  addEditModalHandler: (data: IMealsPerDay, keys: IDropdownList) => void;
  removeRepeatHandler: (
    data: IMealsPerDay,
    keys: IDropdownList,
    day: string
  ) => void;
  restaurantImg: string;
  day?: string;
  getSelectedDay?: (data: string) => void;
}> = ({
  dragabble,
  className,
  showOptions,
  dropdown,
  compKey,
  perDayData,
  onCardClick,
  addEditModalHandler,
  day,
  removeRepeatHandler,
  restaurantImg,
}) => {
  const {
    isDragging,
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: perDayData?.id });
  const dispatch = useAppDispatch();
  const optionsDropdownHandler = (key: string) => {
    showOptions(key);
  };

  const optionClickParent = (item: IDropdownList) => {
    dispatch(addSelectedDay(day));
    addEditModalHandler(perDayData, item);
    if (day) {
      removeRepeatHandler(perDayData, item, day);
    }
    showOptions('');
  };
  const onMealCardClick = (data: IMealsPerDay, e: any) => {
    if (e.target.id == 'showOptions' || e.target.id == 'dropdown-comp') {
      return;
    }
    onCardClick(data);
  };
  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined,
  };

  const inlineStyles: CSSProperties = {
    opacity: isDragging ? '0.5' : '1',
    transformOrigin: '50% 50%',

    cursor: isDragging ? 'grabbing' : 'grab',

    boxShadow: isDragging
      ? 'rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px'
      : 'rgb(63 63 68 / 5%) 0px 0px 0px 1px, rgb(34 33 81 / 15%) 0px 1px 3px 0px',

    ...style,
  };
  return (
    <div
      ref={setNodeRef}
      style={inlineStyles}
      className={className}
      onClick={(e) => onMealCardClick(perDayData, e)}
    >
      <RestaurantImgComp
        imageId={restaurantImg}
        className="restImage-diningPage"
        divClass="restImage-div"
        noImageClass="restaurant-noImage"
        noImageDivClass="restImage-noImage-div"
        src="/assets/emptyImage.svg"
      />
      <div className="mealCard-content">
        <span className="mealName">{perDayData?.mealType}</span>
        <div className="flex">
          <TimeCompWrap
            startTime={perDayData?.startTime}
            endTime={perDayData?.endTime}
          />
          <img
            className="pointer mr-10"
            id="showOptions"
            src={process.env.PUBLIC_URL + '/assets/showOptionsIcon2.svg'}
            alt="show-options"
            onClick={() => optionsDropdownHandler(compKey)}
          />

          {dragabble ? (
            <img
              {...listeners}
              {...attributes}
              src={
                process.env.PUBLIC_URL + '/assets/draggableHandleIcon-ash.svg'
              }
              alt="delete-icon"
            />
          ) : null}
          {dropdown === compKey && (
            <DropdownComp
              compKey={compKey}
              optionClickParent={optionClickParent}
            />
          )}
        </div>
        <div className="mealItemNumb">
          <img
            src={process.env.PUBLIC_URL + '/assets/itemsImage.svg'}
            alt="items-icon"
          />
          <span>{`${perDayData?.noOfItems} ${
            Number(perDayData?.noOfItems) > 1 ? 'Items' : 'Item'
          }`}</span>
        </div>
      </div>
    </div>
  );
};

export default MealCard;
