import { IDateWeek, IRestaurantFilter } from './diningInterface';
import { IRestaurantParams } from './diningParams';

export const filterChange = (
  data: IRestaurantFilter,
  f_id: string
): IRestaurantParams => {
  const payload = {
    facilityIds: f_id,
    ...data,
  };
  return payload;
};

export const convertDiningTime = (time: string) => {
  const timePartArray: any[] = time?.split(':');
  let time_24 = Number(timePartArray[0]);
  let amPm = 'AM';
  if (time_24 >= 12) {
    amPm = 'PM';
  }
  if (time_24 > 12) {
    time_24 = Number(timePartArray[0]) - 12;
  }
  const formattedTime = time_24 + ':' + timePartArray[1] + ' ' + amPm;
  return formattedTime;
};

export const diningTypePayload = (
  mealTypeId: string,
  restId: string,
  f_id: string
) => {
  const payload = {
    mealTypeId: mealTypeId,
    restaurantId: restId,
    facilityIds: f_id,
  };
  return payload;
};
export const diningItemsPayload = (
  diningTypeId: string,
  f_id: string,
  restaurantId: string
) => {
  const payload = {
    diningTypeId: diningTypeId,
    facilityIds: f_id,
    restaurantId,
  };
  return payload;
};

export const updateDiningItemsManipulator = (
  diningItems: any,
  facilityId: string
) => {
  const payload = {
    diningItemsList: [...diningItems],
    facilityId,
  };
  return payload;
};

export const updateMealTypeManipulator = (
  mealTypeId: string,
  mealType: string,
  startTime: string,
  endTime: string,
  facilityId: string
) => {
  const payload = {
    id: mealTypeId,
    mealType,
    startTime,
    endTime,
    facilityId,
  };
  return payload;
};

export const editRestaurant = (id: string) => {
  const payload = {
    id,
    for: 'edit',
  };
  return payload;
};
export const deleteRestaurant = (id: string) => {
  const payload = {
    id,
    for: 'delete',
  };
  return payload;
};
export const restaurantPayload = (id: string) => {
  const payload = {
    id,
    for: 'none',
  };
  return payload;
};

export const getDaysArray = function (year: number, month: number) {
  const monthIndex = month - 1;
  const weeks = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const date = new Date(year, monthIndex, 1);
  const result: IDateWeek[] = [];
  while (date.getMonth() == monthIndex) {
    result.push({ date: date.getDate(), week: weeks[date.getDay()] });
    date.setDate(date.getDate() + 1);
  }
  return result;
};
