/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Row } from 'reactstrap';
import { useAppSelector } from '../../app/hook';
import { GuestChart } from '../../components/dashboardComponents/guest';
import { SupportBuisness } from '../../components/dashboardComponents/supportingBusiness';
import './dashboard.css';

const Dashboard: React.FC = () => {
  const { currentFacility, activeFacility } = useAppSelector(
    (state) => state.authentication
  );
  const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    window.scrollTo(0, 0);
    if(!currentFacility?.id){
      if(!searchParams.get('f_id')){
        setSearchParams({ f_id: activeFacility?.id });
      }
    }else{
      setSearchParams({ f_id: currentFacility?.id });
    }
  }, [currentFacility?.id, activeFacility?.id, window.location.href]);

  const data = [
    {
      country: 'AD',
      'hot dog': 92,
      'hot dogColor': 'hsl(91, 70%, 50%)',
      burger: 189,
      burgerColor: 'hsl(234, 70%, 50%)',
      sandwich: 190,
      sandwichColor: 'hsl(210, 70%, 50%)',
      kebab: 154,
      kebabColor: 'hsl(192, 70%, 50%)',
      fries: 59,
      friesColor: 'hsl(3, 70%, 50%)',
      donut: 156,
      donutColor: 'hsl(206, 70%, 50%)',
    },
    {
      country: 'AE',
      'hot dog': 88,
      'hot dogColor': 'hsl(168, 70%, 50%)',
      burger: 109,
      burgerColor: 'hsl(17, 70%, 50%)',
      sandwich: 144,
      sandwichColor: 'hsl(5, 70%, 50%)',
      kebab: 200,
      kebabColor: 'hsl(288, 70%, 50%)',
      fries: 166,
      friesColor: 'hsl(9, 70%, 50%)',
      donut: 18,
      donutColor: 'hsl(73, 70%, 50%)',
    },
    {
      country: 'AF',
      'hot dog': 50,
      'hot dogColor': 'hsl(314, 70%, 50%)',
      burger: 134,
      burgerColor: 'hsl(318, 70%, 50%)',
      sandwich: 155,
      sandwichColor: 'hsl(71, 70%, 50%)',
      kebab: 95,
      kebabColor: 'hsl(70, 70%, 50%)',
      fries: 7,
      friesColor: 'hsl(123, 70%, 50%)',
      donut: 105,
      donutColor: 'hsl(44, 70%, 50%)',
    },
    {
      country: 'AG',
      'hot dog': 23,
      'hot dogColor': 'hsl(108, 70%, 50%)',
      burger: 108,
      burgerColor: 'hsl(206, 70%, 50%)',
      sandwich: 133,
      sandwichColor: 'hsl(236, 70%, 50%)',
      kebab: 99,
      kebabColor: 'hsl(122, 70%, 50%)',
      fries: 73,
      friesColor: 'hsl(276, 70%, 50%)',
      donut: 141,
      donutColor: 'hsl(354, 70%, 50%)',
    },
    {
      country: 'AI',
      'hot dog': 176,
      'hot dogColor': 'hsl(266, 70%, 50%)',
      burger: 122,
      burgerColor: 'hsl(75, 70%, 50%)',
      sandwich: 32,
      sandwichColor: 'hsl(322, 70%, 50%)',
      kebab: 161,
      kebabColor: 'hsl(259, 70%, 50%)',
      fries: 3,
      friesColor: 'hsl(113, 70%, 50%)',
      donut: 163,
      donutColor: 'hsl(295, 70%, 50%)',
    },
    {
      country: 'AL',
      'hot dog': 164,
      'hot dogColor': 'hsl(332, 70%, 50%)',
      burger: 155,
      burgerColor: 'hsl(259, 70%, 50%)',
      sandwich: 81,
      sandwichColor: 'hsl(303, 70%, 50%)',
      kebab: 111,
      kebabColor: 'hsl(340, 70%, 50%)',
      fries: 7,
      friesColor: 'hsl(34, 70%, 50%)',
      donut: 171,
      donutColor: 'hsl(345, 70%, 50%)',
    },
    {
      country: 'AM',
      'hot dog': 122,
      'hot dogColor': 'hsl(285, 70%, 50%)',
      burger: 56,
      burgerColor: 'hsl(315, 70%, 50%)',
      sandwich: 181,
      sandwichColor: 'hsl(157, 70%, 50%)',
      kebab: 156,
      kebabColor: 'hsl(326, 70%, 50%)',
      fries: 196,
      friesColor: 'hsl(327, 70%, 50%)',
      donut: 100,
      donutColor: 'hsl(292, 70%, 50%)',
    },
  ];
  const data1 = [
    {
      id: 'japan',
      color: 'hsl(351, 70%, 50%)',
      data: [
        {
          x: 'plane',
          y: 135,
        },
        {
          x: 'helicopter',
          y: 109,
        },
        {
          x: 'boat',
          y: 185,
        },
        {
          x: 'train',
          y: 49,
        },
        {
          x: 'subway',
          y: 144,
        },
        {
          x: 'bus',
          y: 165,
        },
        {
          x: 'car',
          y: 194,
        },
        {
          x: 'moto',
          y: 170,
        },
        {
          x: 'bicycle',
          y: 251,
        },
        {
          x: 'horse',
          y: 143,
        },
        {
          x: 'skateboard',
          y: 104,
        },
        {
          x: 'others',
          y: 148,
        },
      ],
    },
    {
      id: 'france',
      color: 'hsl(29, 70%, 50%)',
      data: [
        {
          x: 'plane',
          y: 238,
        },
        {
          x: 'helicopter',
          y: 57,
        },
        {
          x: 'boat',
          y: 157,
        },
        {
          x: 'train',
          y: 147,
        },
        {
          x: 'subway',
          y: 8,
        },
        {
          x: 'bus',
          y: 38,
        },
        {
          x: 'car',
          y: 28,
        },
        {
          x: 'moto',
          y: 10,
        },
        {
          x: 'bicycle',
          y: 65,
        },
        {
          x: 'horse',
          y: 15,
        },
        {
          x: 'skateboard',
          y: 286,
        },
        {
          x: 'others',
          y: 236,
        },
      ],
    },
    {
      id: 'us',
      color: 'hsl(177, 70%, 50%)',
      data: [
        {
          x: 'plane',
          y: 100,
        },
        {
          x: 'helicopter',
          y: 237,
        },
        {
          x: 'boat',
          y: 28,
        },
        {
          x: 'train',
          y: 223,
        },
        {
          x: 'subway',
          y: 275,
        },
        {
          x: 'bus',
          y: 300,
        },
        {
          x: 'car',
          y: 78,
        },
        {
          x: 'moto',
          y: 24,
        },
        {
          x: 'bicycle',
          y: 260,
        },
        {
          x: 'horse',
          y: 176,
        },
        {
          x: 'skateboard',
          y: 89,
        },
        {
          x: 'others',
          y: 172,
        },
      ],
    },
    {
      id: 'germany',
      color: 'hsl(171, 70%, 50%)',
      data: [
        {
          x: 'plane',
          y: 149,
        },
        {
          x: 'helicopter',
          y: 213,
        },
        {
          x: 'boat',
          y: 27,
        },
        {
          x: 'train',
          y: 197,
        },
        {
          x: 'subway',
          y: 142,
        },
        {
          x: 'bus',
          y: 103,
        },
        {
          x: 'car',
          y: 212,
        },
        {
          x: 'moto',
          y: 207,
        },
        {
          x: 'bicycle',
          y: 5,
        },
        {
          x: 'horse',
          y: 91,
        },
        {
          x: 'skateboard',
          y: 177,
        },
        {
          x: 'others',
          y: 210,
        },
      ],
    },
    {
      id: 'norway',
      color: 'hsl(190, 70%, 50%)',
      data: [
        {
          x: 'plane',
          y: 262,
        },
        {
          x: 'helicopter',
          y: 102,
        },
        {
          x: 'boat',
          y: 150,
        },
        {
          x: 'train',
          y: 155,
        },
        {
          x: 'subway',
          y: 23,
        },
        {
          x: 'bus',
          y: 103,
        },
        {
          x: 'car',
          y: 158,
        },
        {
          x: 'moto',
          y: 136,
        },
        {
          x: 'bicycle',
          y: 37,
        },
        {
          x: 'horse',
          y: 161,
        },
        {
          x: 'skateboard',
          y: 123,
        },
        {
          x: 'others',
          y: 33,
        },
      ],
    },
  ];
  const theme = {
    axis: {
      fontSize: '14px',
      tickColor: '',
      ticks: {
        line: {
          stroke: '#555555',
        },
        text: {
          fill: '#777777',
        },
      },
      legend: {
        text: {
          fill: '#F0F0F5',
          legendPosition: 'middle',
        },
      },
    },
    grid: {
      line: {
        stroke: '#F0F0F5',
      },
    },
  };
  const datas = [
    {
      id: 'japan',
      color: 'hsl(247, 70%, 50%)',
      data: [
        {
          x: 'plane',
          y: 120,
        },
        {
          x: 'helicopter',
          y: 141,
        },
        {
          x: 'boat',
          y: 259,
        },
        {
          x: 'train',
          y: 280,
        },
        {
          x: 'subway',
          y: 184,
        },
        {
          x: 'bus',
          y: 33,
        },
        {
          x: 'car',
          y: 107,
        },
        {
          x: 'moto',
          y: 80,
        },
        {
          x: 'bicycle',
          y: 18,
        },
        {
          x: 'horse',
          y: 121,
        },
        {
          x: 'skateboard',
          y: 4,
        },
        {
          x: 'others',
          y: 250,
        },
      ],
    },
  ];
  return (
    <div className="page-background user-page">
      <Row className="pt-4">
      </Row>
      <Row className="gx-4 gy-4">
        {
          searchParams.get('f_id') && 
        <SupportBuisness/>
        }
        {
          searchParams.get('f_id') &&
        <GuestChart />
        }
      </Row>
    </div>
  );
};

export default Dashboard;
