import * as yup from 'yup';

export const forgotPasswordSchema = yup
  .object({
    email: yup
      .string()
      .email('LOGIN.EMAIL_PROPER_VALIDATION')
      .required('LOGIN.EMAIL_REQUIRED'),
  })

  .required();
