/* eslint-disable prettier/prettier */
import { yupResolver } from '@hookform/resolvers/yup';
import React, { FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { useAppDispatch } from '../../app/hook';
import { IImageDetails } from '../../pages/amenitiesPage/amenitiesInterface';
import {
  IUsersData,
  IUsersFetchAllData,
} from '../../pages/usersPage/usersInterface';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import { usersSchema } from './usersPageValidation';

const UsersPageForm: FC<{
  content?: IUsersFetchAllData;
  imageDetails?: IImageDetails;
  isAdding: boolean;
  buttonTitle: string;
  onCreate: (data: any) => void;
  setImage?: any;
}> = ({ content, onCreate, imageDetails, isAdding, setImage, buttonTitle }) => {
  const [imageState, setImageState] = useState<any>('');
  const [imageName, setImageName] = useState<string>('');
  useEffect(() => {
    setImageState('');
  }, []);
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IUsersData>({
    resolver: yupResolver(usersSchema),
    defaultValues: {
      userName: content?.userName,
      emailId: content?.email,
      designation: content?.designation,
      image: '',
    },
  });
  const [translation] = useTranslation('common');
  const onSubmit = (data: IUsersData) => {
    const response = {
      ...data,
      image: imageState,
    };

    onCreate(response);
  };
  useEffect(() => {
    reset({
      userName: content?.userName,
      emailId: content?.email,
      designation: content?.designation,
      image: '',
    });
  }, [content]);
  useEffect(() => {
    content?.imageId && imageDetails
      ? setImageName(imageDetails?.originalName)
      : setImageName('');
  }, [content, imageDetails]);
  const uploadUserImageHandler = (idName: string) => {
    document.getElementById(idName)!.click();
  };
  const userImageOnChange = (e: any) => {
    const fileObj = e.target.files;
    setImageState(Array.from(fileObj));
    setImageName(fileObj[0].name);
  };
  return (
    <React.Fragment>
      <div className="modal-div">
        <Form className="form width-100" onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Controller
              control={control}
              name="userName"
              render={({ field }) => (
                <Input
                  id="name"
                  className="form-input shadow-none"
                  placeholder={translation('USERS_PAGE_DATA.NAME_LABEL')}
                  invalid={errors?.userName ? true : false}
                  {...field}
                />
              )}
            />
            <FormFeedback>
              {translation(`${errors?.userName?.message}`)}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Controller
              control={control}
              name="designation"
              render={({ field }) => (
                <Input
                  id="designation"
                  placeholder={translation('USERS_PAGE_DATA.DESIGNATION_LABEL')}
                  className="form-input shadow-none"
                  invalid={errors?.designation ? true : false}
                  {...field}
                />
              )}
            />
            <FormFeedback>
              {translation(`${errors?.designation?.message}`)}
            </FormFeedback>
          </FormGroup>
          <FormGroup>
            <Controller
              control={control}
              name="emailId"
              render={({ field }) => (
                <Input
                  id="email"
                  placeholder={translation('USERS_PAGE_DATA.EMAIL_LABEL')}
                  className="form-input shadow-none"
                  disabled={content?.email ? true : false}
                  invalid={errors?.emailId ? true : false}
                  {...field}
                />
              )}
            />
            <FormFeedback>
              {translation(`${errors?.emailId?.message}`)}
            </FormFeedback>
          </FormGroup>
          <div
            className="users-imageUpload-div pointer"
            onClick={() => uploadUserImageHandler('user_image')}
          >
            {imageName ? (
              <span className="uploadImage-input">
                {translation(`${imageName}`)}
              </span>
            ) : (
              <span className="uploadImage-text">
                {translation('AMENITIES_FORM.UPLOAD_PHOTO')}
              </span>
            )}
            <div className="">
              <img
                src={process.env.PUBLIC_URL + '/assets/uploadIcon-dark.svg'}
                alt="upload-icon"
              />
            </div>
            <input
              id="user_image"
              type="file"
              style={{ display: 'none' }}
              accept="image/jpeg, image/png"
              onChange={(e) => userImageOnChange(e)}
            />
          </div>
          <div className="activityModal-separator"></div>
          <div className="modal-submit-button-div">
            <Button type="submit" className="form-button shadow-none">
              {isAdding ? (
                <LoadingSpinner />
              ) : (
                translation(`AMENITIES_FORM.SAVE_UPDATE`)
              )}
            </Button>
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default UsersPageForm;
