import { yupResolver } from '@hookform/resolvers/yup';
import { Moment } from 'moment';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import {
  IDiningDynamicInput,
  IDiningItemsPayload,
  IMealItems,
  IMealsPerDay,
  IRepeatFormSchema,
  IRestaurants,
  IUpdateRepeatDiningMealsPayload,
  IWeekdaysCheckbox,
  IWeekdaysConstants,
  ItemTypeConstants,
} from '../../pages/diningPage/diningInterface';
import {
  deleteDiningItemById,
  setDeleteArrayReducer,
} from '../../pages/diningPage/diningSlice';
import '../diningForm/diningForm.css';
import ItemsParentCardForm from '../diningForm/itemParentCardForm';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import { WeedDays } from './modalConstants';
import { repeatFormSchema } from './repeatFormValidation';

const AddEditRepeatMeal: React.FC<{
  mealObject: IMealsPerDay;
  diningItemsList: IMealItems[];
  currentDate: Moment;
  restaurantId: string;
  isAdding: boolean;
  onCreate: (data: IUpdateRepeatDiningMealsPayload, date: string) => void;
  restaurant: IRestaurants;
}> = ({
  mealObject,
  currentDate,
  restaurantId,
  isAdding,
  onCreate,
  diningItemsList,
  restaurant,
}) => {
  const [diningItems, setDiningItems] =
    useState<IDiningItemsPayload[]>(diningItemsList);
  useEffect(() => {
    setDiningItems(diningItemsList);
  }, [diningItemsList]);
  const { deleteArray } = useAppSelector((state) => state.dining);
  const [translation] = useTranslation('common');
  const [categoryId, setCategoryId] = useState<number | null>(
    mealObject?.mealCategory?.id as number
  );
  const { categories } = useAppSelector((state) => state.dining);
  const elementRef: any = useRef();
  const [weekdayCheckbox, setWeekdayCheckbox] = useState<IWeekdaysCheckbox[]>(
    []
  );
  const [weekdaysList, setWeekdaysList] = useState<string[]>(
    mealObject?.weekdays
  );
  const [presentDate, setPresentDate] = useState<string>(
    currentDate.format('YYYY-MM-DD')
  );

  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IRepeatFormSchema>({
    resolver: yupResolver(repeatFormSchema),
    defaultValues: {
      name: mealObject?.mealType,
      startTime: mealObject?.startTime,
      categoryId: mealObject?.mealCategory?.id,
      endTime: mealObject?.endTime,
    },
  });

  const addMoreInputHandler = (e: MouseEvent<HTMLButtonElement>) => {
    const newField = {
      id: Math.random().toString(),
      title: '',
      price: '',
      description: '',
      type: ItemTypeConstants.exclusive,
    };
    setDiningItems([...diningItems, newField]);
  };
  useEffect(() => {
    reset({
      name: mealObject?.mealType,
      startTime: mealObject?.startTime,
      endTime: mealObject?.endTime,
    });
  }, [mealObject]);
  const AlwaysScrollToBottom = () => {
    elementRef?.current?.scrollIntoView();
  };

  useEffect(() => {
    AlwaysScrollToBottom();
  }, [diningItems]);

  const activeItemTypeButton = (type: string, id?: string) => {
    const itemsArray = [...diningItems];
    const newItemsArray = itemsArray.map((item: IDiningDynamicInput) => {
      if (item.id == id) {
        return {
          ...item,
          type,
        };
      }
      return item;
    });
    setDiningItems([...newItemsArray]);
  };

  const handleCheckboxChange = (e: any) => {
    const { checked, id } = e.target;
    const newValue: IWeekdaysCheckbox = { id, value: id, checked: checked };
    const newWeeksArray: IWeekdaysCheckbox[] = [...weekdayCheckbox];
    const finalArray: IWeekdaysCheckbox[] = newWeeksArray.filter(
      (item: IWeekdaysCheckbox) => item.id != newValue.id
    );
    finalArray.push(newValue);
    const onlyTrueValueArray: IWeekdaysCheckbox[] = finalArray.filter(
      (item: IWeekdaysCheckbox) => item.checked == true
    );
    setWeekdayCheckbox([...onlyTrueValueArray]);
    const weekDaysArray: string[] = onlyTrueValueArray.map(
      (item: IWeekdaysCheckbox) => {
        return item.value;
      }
    );
    setWeekdaysList([...weekDaysArray]);
  };
  const getData = (
    name: string,
    value: string,
    index: number,
    itemId?: string
  ) => {
    const valueArray: IDiningDynamicInput[] = [...diningItems];
    const valueIndex: number = valueArray.findIndex(
      (item: IDiningDynamicInput) => item.id == itemId
    );
    valueArray[valueIndex] = {
      ...valueArray[valueIndex],
      [name]: value,
    };
    setDiningItems([...valueArray]);
  };

  const removeItemClick = (
    itemIndex: number,
    selectedItem: IDiningDynamicInput,
    id?: string
  ) => {
    if (Number(id) >= 1) {
      const array = [...deleteArray, id];
      dispatch(setDeleteArrayReducer(Array.from(new Set(array))));
    }

    const clone = [...diningItems];
    clone.splice(itemIndex, 1);
    setDiningItems(clone);
  };

  const onSubmit = (data: IRepeatFormSchema) => {
    if (deleteArray.length) {
      deleteArray.map((id: string) => {
        dispatch(deleteDiningItemById(id));
      });
    }
    let payload = {} as IUpdateRepeatDiningMealsPayload;

    payload = {
      id: mealObject?.id,
      title: data?.name,
      startTime: data?.startTime,
      endTime: data?.endTime,
      categoryId: categoryId as number,

      // date: presentDate,
      restaurantId,
      repeatable: true,
      weekdaysList: [...weekdaysList],
      diningItems: [
        ...diningItems.map((item: IDiningDynamicInput) => {
          if (Number(item.id) < 1) {
            item.id = '0';
          }
          return item;
        }),
      ],
    };
    onCreate(payload, presentDate);
  };

  return (
    <Form className="form width-100" onSubmit={handleSubmit(onSubmit)}>
      <div className="dining-modal-div">
        <div className="modal-left-div">
          <h2 className="add-items">Add Items</h2>
          <div className="diningItems-scroll-container">
            {diningItems.length ? (
              <ItemsParentCardForm
                getData={getData}
                removeItemClick={removeItemClick}
                diningItems={diningItems}
                activeItemTypeButton={activeItemTypeButton}
              />
            ) : (
              <div className="no-dining-meals-div">
                <img
                  src={process.env.PUBLIC_URL + '/assets/noDiningSchedule.svg'}
                  alt=""
                />
                <span className="no-dining-meals-text">No Dining Items!</span>
              </div>
            )}
            <div ref={elementRef} />
          </div>
          <span className="addMore pointer" onClick={addMoreInputHandler}>
            Add More
          </span>
        </div>
        <div className="modal-right-div">
          <h2 className="add-items">Title and Timing</h2>
          <div className="modal-right-wrap">
            <FormGroup>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <Input
                    placeholder="Meal Name"
                    className="form-input shadow-none"
                    invalid={errors?.name ? true : false}
                    {...field}
                  />
                )}
              />
              <FormFeedback>
                {translation(`${errors?.name?.message}`)}
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Input
                className="form-input shadow-none repeatType-dropdown"
                name="categoryId"
                type="select"
                value={categoryId as number}
                onChange={(e) => setCategoryId(e.target.value as any)}
              >
                <option key={0} value={0}>
                  {translation(`AMENITIES_FORM.SELECT_NONE`)}
                </option>
                {categories.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item?.categoryName}
                  </option>
                ))}
              </Input>
            </FormGroup>
            <div className="date-time-parent-div">
              <FormGroup className="date-time-div">
                <Label className="input-label">Start Time</Label>
                <Controller
                  control={control}
                  name="startTime"
                  render={({ field }) => (
                    <Input
                      type="time"
                      min={restaurant?.startTime}
                      placeholder="--:--"
                      className="dining-date-input shadow-none pointer"
                      invalid={errors?.startTime ? true : false}
                      {...field}
                    />
                  )}
                />
                <FormFeedback>
                  {translation(`${errors?.startTime?.message}`)}
                </FormFeedback>
              </FormGroup>
              <FormGroup className="date-time-div">
                <Label className="input-label">End Time</Label>
                <Controller
                  control={control}
                  name="endTime"
                  render={({ field }) => (
                    <Input
                      type="time"
                      max={restaurant?.endTime}
                      placeholder="--:--"
                      className="dining-date-input shadow-none pointer"
                      invalid={errors?.endTime ? true : false}
                      {...field}
                    />
                  )}
                />
                <FormFeedback>
                  {translation(`${errors?.endTime?.message}`)}
                </FormFeedback>
              </FormGroup>
            </div>
          </div>
          <h2 className="add-items">Select Days</h2>
          <div className="weekday-checkbox-div">
            {WeedDays.map((item: IWeekdaysConstants, index: number) => (
              <label
                key={index}
                htmlFor={item.value}
                className="weekday-checkbox"
              >
                <input
                  type="checkbox"
                  id={item.value}
                  checked={
                    item.value ==
                    weekdaysList
                      .filter((day: string) => item.value == day)
                      .toString()
                  }
                  className="pointer"
                  onChange={(e) => handleCheckboxChange(e)}
                />
                {item.content}
              </label>
            ))}
          </div>

          <div className="modal-submit-button-div">
            <Button type="submit" className="form-button shadow-none">
              {isAdding ? <LoadingSpinner /> : 'Save Updates'}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  );
};

export default AddEditRepeatMeal;
