import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { RootState } from '../../app/store';
import { PathConstants } from '../../constants/pathConstants';
import { getImageId } from '../../services/facilityService';
import {
  getAllBusinessCategory,
  registerSupportingBusiness,
} from '../../services/registration';
import { formDataConverter } from '../../_helpers/formDataConverter';
import { History } from '../../_helpers/history';
import {
  IBusinessCategories,
  IBusinessRegistrationPayloadData,
  IDocumentsType,
} from './registrationTypes';

export interface IBusinessRegistration {
  isProcessingBusinessRegistration: boolean;
  isSuccess: boolean;
  isFetchingBusinessCategories: boolean;
  businessCategories: IBusinessCategories[];
}
const initialState: IBusinessRegistration = {
  isProcessingBusinessRegistration: false,
  isSuccess: true,
  isFetchingBusinessCategories: false,
  businessCategories: [],
};

export const businessRegistrationSlice = createSlice({
  name: 'businessRegistration',
  initialState,
  reducers: {
    resetBusinessRegistrationState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSupportingBusiness.pending, (state) => {
        state.isProcessingBusinessRegistration = true;
        state.isSuccess = false;
      })
      .addCase(
        createSupportingBusiness.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isProcessingBusinessRegistration = false;

          toast.success(
            `${action.payload?.message
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload?.message.slice(1)}`
          );
          state.isSuccess = true;
          History.push(PathConstants.SUCCESS_REGISTRATION_BUSINESS);
        }
      )
      .addCase(
        createSupportingBusiness.rejected,
        (state, action: PayloadAction<any>) => {
          state.isProcessingBusinessRegistration = false;
          state.isSuccess = true;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(fetchAllBusinessCategory.pending, (state) => {
        state.isFetchingBusinessCategories = true;
      })
      .addCase(
        fetchAllBusinessCategory.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isFetchingBusinessCategories = false;
          state.businessCategories = action.payload?.data[0]?.data;
        }
      )
      .addCase(
        fetchAllBusinessCategory.rejected,
        (state, action: PayloadAction<any>) => {
          state.isFetchingBusinessCategories = false;
        }
      );
  },
});

export const createSupportingBusiness = createAsyncThunk(
  'businessRegistration/createBusiness',
  async (userData: IBusinessRegistrationPayloadData, { rejectWithValue }) => {
    try {
      const businessDocumentsResponse: string[] = [];

      if (userData?.documents?.length) {
        await Promise.all(
          userData?.documents?.map(async (obj: IDocumentsType) => {
            const documentsResponse = await getImageId(
              formDataConverter(obj.fileData)
            );
            businessDocumentsResponse.push(documentsResponse?.data[0]?.id);
          })
        );
      }

      const businessFinalData = {
        ...userData,
        documents: businessDocumentsResponse,
      };

      const response = await registerSupportingBusiness(businessFinalData);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchAllBusinessCategory = createAsyncThunk(
  'businessRegistration/fetchAllBusinessCategory',
  async (data: null, { rejectWithValue }) => {
    try {
      const response = await getAllBusinessCategory();
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const { resetBusinessRegistrationState } =
  businessRegistrationSlice.actions;
export const selectBusinessRegistration = (state: RootState) =>
  state.businessRegistration;
export const businessRegistrationReducer = businessRegistrationSlice.reducer;
