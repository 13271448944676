/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';
import { getLanguageCode } from '../../_helpers/checkerFunctions';
import { useAppDispatch } from '../../app/hook';
import {
  IMealItems,
  IMealsPerDay,
  IRestaurants,
} from '../../pages/diningPage/diningInterface';
import { translateText } from '../../pages/loginPage/authSlice';
import RestaurantImgComp from '../imageComponents/restaurantImgComp';
import TimeCompWrap from '../restaurants/timeCompWrap';
import { closeModalReducer } from './modalSlice';

export const DiningMealModal: React.FC<{
  isOpen: boolean;
  modalHeadingData: IMealsPerDay;
  mealItems: IMealItems[];
  restaurant: IRestaurants;
}> = ({ isOpen, modalHeadingData, mealItems, restaurant }) => {
  const [regularItems, setRegularItems] = useState<IMealItems[]>(
    mealItems?.filter((item: IMealItems) => item.type === 'REGULAR')
  );
  const [exclusiveItems, setExclusiveItems] = useState<IMealItems[]>(
    mealItems?.filter((item: IMealItems) => item.type === 'EXCLUSIVE')
  );
  const [noExclusive, setNoExclusive] = useState<boolean>(false);
  const [noRegular, setNoRegular] = useState<boolean>(false);

  const [toggle, setToggle] = useState<boolean>(false);
  const [translation] = useTranslation('common');
  const dispatch = useAppDispatch();
  const [t, i18n] = useTranslation('common');
  const language = i18n.language;
  useEffect(() => {
    if (!exclusiveItems?.length) {
      setNoExclusive(true);
    } else {
      setNoExclusive(false);
    }
    if (!regularItems?.length) {
      setNoRegular(true);
    } else {
      setNoRegular(false);
    }
  }, [exclusiveItems, regularItems]);

  const resizeScreen = () => {
    if (window.innerWidth <= 740) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };
  useEffect(() => {
    window.addEventListener('resize', resizeScreen);
    if (window.innerWidth <= 740) {
      setToggle(true);
    } else {
      setToggle(false);
    }
    return () => {
      window.removeEventListener('resize', resizeScreen);
    };
  });
  const [category, setCategory] = useState<string>('');

  useEffect(() => {
    const translatefunction = async () => {
      const resp = await dispatch(
        translateText({
          q: [modalHeadingData?.mealCategory?.categoryName],
          target: getLanguageCode(language),
        })
      );

      const responseName = resp?.payload?.data?.translations[0]?.translatedText;

      setCategory(responseName);
    };
    translatefunction();
  }, [language]);

  return ReactDOM.createPortal(
    <div className="container-fluid">
      {!noExclusive && !noRegular && (
        <Modal
          isOpen={mealItems && isOpen}
          fade={true}
          centered={true}
          size="md"
          className={toggle ? '' : 'dining-modal'}
        >
          <div>
            <div className="mealdata-modal-header">
              {modalHeadingData && (
                <div className="mealdata-modal-heading-category">
                  <div className="mealdata-modal-heading">
                    <RestaurantImgComp
                      imageId={restaurant?.imageId}
                      className="restImage-diningPage"
                      divClass="restImage-div"
                      noImageClass="restaurant-noImage"
                      noImageDivClass="restImage-noImage-div"
                      src="/assets/emptyImage.svg"
                    />
                    <div className="mealCard-content">
                      <div className="mealCard-content-heading">
                        <span className="mealName">
                          {modalHeadingData?.mealType}
                        </span>
                        {toggle && (
                          <div onClick={() => dispatch(closeModalReducer())}>
                            <img
                              className="pointer"
                              src={
                                process.env.PUBLIC_URL +
                                '/assets/closeModalIcon.svg'
                              }
                              alt="close-icon"
                            />
                          </div>
                        )}
                      </div>
                      <TimeCompWrap
                        startTime={modalHeadingData?.startTime}
                        endTime={modalHeadingData?.endTime}
                      />
                      <div className="mealItemNumb">
                        <img
                          src={
                            process.env.PUBLIC_URL + '/assets/itemsImage.svg'
                          }
                          alt="items-icon"
                        />
                        <span>{`${modalHeadingData?.noOfItems} ${
                          Number(modalHeadingData?.noOfItems) > 1
                            ? 'Items'
                            : 'Item'
                        }`}</span>
                      </div>
                    </div>
                  </div>
                  {modalHeadingData?.mealCategory?.categoryName ? (
                    <div className="category-name">
                      {translation(`SUPPORTING_BUSINESS.CATEGORY`)}:{' '}
                      <span className="meal-category">{category}</span>
                    </div>
                  ) : null}
                </div>
              )}
              <div
                className="modalHeadingRight"
                onClick={() => dispatch(closeModalReducer())}
              >
                <img
                  className="pointer"
                  src={process.env.PUBLIC_URL + '/assets/closeModalWhite.svg'}
                  alt="close-icon"
                />
              </div>
            </div>
          </div>
          <div className="mealItem-parent-div">
            {regularItems && (
              <div className="mealdate-regular">
                <div className="mealItem-regular-border"></div>
                <span className="regular-heading">
                  {translation(`RESTAURANT.REGULAR_ITEMS`)}
                </span>
                {regularItems?.map((item: IMealItems, index: number) => (
                  <div key={index} className="items-parent-wrap">
                    <div className="item-progress-wrap">
                      <div className="progress-dot"></div>
                      <div
                        className={
                          regularItems?.length == index + 1
                            ? 'hide-progressbar'
                            : 'item-progressbar'
                        }
                      ></div>
                    </div>

                    <div className="item-details-parent">
                      <div className="item-name-description">
                        <span className="title">{item?.title}</span>
                        <span className="description">{item?.description}</span>
                      </div>
                      <div className="item-price">
                        <span className="price">&#36;&nbsp;{item?.price}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {exclusiveItems && (
              <div className="mealdate-exclusive">
                <div className="mealItem-exclusive-border"></div>
                <span className="exclusive-heading">
                  {translation(`RESTAURANT.EXCLUSIVE_ITEMS`)}
                </span>
                {exclusiveItems?.map((item: IMealItems, index: number) => (
                  <div key={index} className="items-parent-wrap">
                    <div className="item-progress-wrap">
                      <div className="progress-dot"></div>
                      <div
                        className={
                          regularItems?.length == index + 1
                            ? 'hide-progressbar'
                            : 'item-progressbar'
                        }
                      ></div>
                    </div>
                    <div className="item-details-parent">
                      <div className="item-name-description">
                        <span className="title">{item?.title}</span>
                        <span className="description">{item?.description}</span>
                      </div>
                      <div className="item-price">
                        <span className="price">&#36;&nbsp;{item?.price}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Modal>
      )}

      {noExclusive && !noRegular && (
        <Modal
          isOpen={mealItems && isOpen}
          fade={true}
          centered={true}
          size="md"
          className=""
        >
          <div>
            <div className="mealdata-modal-header">
              {modalHeadingData && (
                <div className="mealdata-modal-heading-category-single">
                  <div className="mealdata-modal-heading single">
                    <RestaurantImgComp
                      imageId={restaurant?.imageId}
                      className="restImage-diningPage"
                      divClass="restImage-div"
                      noImageClass="restaurant-noImage"
                      noImageDivClass="restImage-noImage-div"
                      src="/assets/emptyImage.svg"
                    />
                    <div className="mealCard-content">
                      <div className="mealCard-content-heading single">
                        <span className="mealName">
                          {modalHeadingData?.mealType}
                        </span>
                        <div onClick={() => dispatch(closeModalReducer())}>
                          <img
                            className="pointer"
                            src={
                              process.env.PUBLIC_URL +
                              '/assets/closeModalIcon.svg'
                            }
                            alt="close-icon"
                          />
                        </div>
                      </div>
                      <TimeCompWrap
                        startTime={modalHeadingData?.startTime}
                        endTime={modalHeadingData?.endTime}
                      />
                      <div className="mealItemNumb">
                        <img
                          src={
                            process.env.PUBLIC_URL + '/assets/itemsImage.svg'
                          }
                          alt="items-icon"
                        />
                        <span>{`${modalHeadingData?.noOfItems} ${
                          Number(modalHeadingData?.noOfItems) > 1
                            ? 'Items'
                            : 'Item'
                        }`}</span>
                      </div>
                    </div>
                  </div>
                  {modalHeadingData?.mealCategory?.categoryName ? (
                    <div className="category-name">
                      {translation(`SUPPORTING_BUSINESS.CATEGORY`)}:{' '}
                      <span className="meal-category">{category}</span>
                    </div>
                  ) : null}
                </div>
              )}
              <div
                className="modalHeadingRight single"
                onClick={() => dispatch(closeModalReducer())}
              >
                <img
                  className="pointer"
                  src={process.env.PUBLIC_URL + '/assets/closeModalWhite.svg'}
                  alt="close-icon"
                />
              </div>
            </div>
          </div>
          <div className="mealItem-parent-div">
            {regularItems && (
              <div className="mealdate-regular single">
                <div className="mealItem-regular-border"></div>
                <span className="regular-heading">
                  {translation(`RESTAURANT.REGULAR_ITEMS`)}
                </span>
                {regularItems?.map((item: IMealItems, index: number) => (
                  <div key={index} className="items-parent-wrap">
                    <div className="item-progress-wrap">
                      <div className="progress-dot"></div>
                      <div
                        className={
                          regularItems?.length == index + 1
                            ? 'hide-progressbar'
                            : 'item-progressbar'
                        }
                      ></div>
                    </div>

                    <div className="item-details-parent">
                      <div className="item-name-description">
                        <span className="title">{item?.title}</span>
                        <span className="description">{item?.description}</span>
                      </div>
                      <div className="item-price">
                        <span className="price">&#36;&nbsp;{item?.price}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Modal>
      )}

      {!noExclusive && noRegular && (
        <Modal
          isOpen={mealItems && isOpen}
          fade={true}
          centered={true}
          size="md"
          className=""
        >
          <div>
            <div className="mealdata-modal-header">
              {modalHeadingData && (
                <div className="mealdata-modal-heading-category-single">
                  <div className="mealdata-modal-heading single">
                    <RestaurantImgComp
                      imageId={restaurant?.imageId}
                      className="restImage-diningPage"
                      divClass="restImage-div"
                      noImageClass="restaurant-noImage"
                      noImageDivClass="restImage-noImage-div"
                      src="/assets/emptyImage.svg"
                    />
                    <div className="mealCard-content">
                      <div className="mealCard-content-heading single">
                        <span className="mealName">
                          {modalHeadingData?.mealType}
                        </span>
                        <div onClick={() => dispatch(closeModalReducer())}>
                          <img
                            className="pointer"
                            src={
                              process.env.PUBLIC_URL +
                              '/assets/closeModalIcon.svg'
                            }
                            alt="close-icon"
                          />
                        </div>
                      </div>
                      <TimeCompWrap
                        startTime={modalHeadingData?.startTime}
                        endTime={modalHeadingData?.endTime}
                      />
                      <div className="mealItemNumb">
                        <img
                          src={
                            process.env.PUBLIC_URL + '/assets/itemsImage.svg'
                          }
                          alt="items-icon"
                        />
                        <span>{`${modalHeadingData?.noOfItems} ${
                          Number(modalHeadingData?.noOfItems) > 1
                            ? 'Items'
                            : 'Item'
                        }`}</span>
                      </div>
                    </div>
                  </div>
                  {modalHeadingData?.mealCategory?.categoryName ? (
                    <div className="category-name">
                      {translation(`SUPPORTING_BUSINESS.CATEGORY`)}:{' '}
                      <span className="meal-category">{category}</span>
                    </div>
                  ) : null}
                </div>
              )}
              <div
                className="modalHeadingRight single"
                onClick={() => dispatch(closeModalReducer())}
              >
                <img
                  className="pointer"
                  src={process.env.PUBLIC_URL + '/assets/closeModalWhite.svg'}
                  alt="close-icon"
                />
              </div>
            </div>
          </div>
          <div className="mealItem-parent-div">
            {exclusiveItems && (
              <div className="mealdate-exclusive single">
                <div className="mealItem-exclusive-border single"></div>
                <span className="exclusive-heading">Exclusive Items</span>
                {exclusiveItems?.map((item: IMealItems, index: number) => (
                  <div key={index} className="items-parent-wrap">
                    <div className="item-progress-wrap">
                      <div className="progress-dot"></div>
                      <div
                        className={
                          regularItems?.length == index + 1
                            ? 'hide-progressbar'
                            : 'item-progressbar'
                        }
                      ></div>
                    </div>
                    <div className="item-details-parent">
                      <div className="item-name-description">
                        <span className="title">{item?.title}</span>
                        <span className="description">{item?.description}</span>
                      </div>
                      <div className="item-price">
                        <span className="price">&#36;&nbsp;{item?.price}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </Modal>
      )}
    </div>,
    document.getElementById('modal-root')!
  );
};
