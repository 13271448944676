/* eslint-disable prettier/prettier */
import {
  IAddUserPayload,
  IUsersData,
  IUsersFilter,
  IUsersPayloadFilter
} from './usersInterface';

export const deleteUser = (id: string) => {
  const deleteUserPayload = {
    id: id,
    for: 'delete',
  };
  return deleteUserPayload;
};
export const editUser = (id: string) => {
  const editUserPayload = {
    id: id,
    for: 'edit',
  };
  return editUserPayload;
};
export const updateUserData = (id: string, data: IUsersData, f_id: string) => {
  const updateUserPayload = {
    ...data,
    id: id,
    facilityId: f_id,
    actor: 'FACILITY_USER',
  };
  return updateUserPayload;
};

export const filterChange = (
  data: IUsersFilter,
  f_id: string
): IUsersPayloadFilter => {
  const filterData = {
    ...data,
    facilityId: f_id,
  };
  return filterData;
};

export const deleteUserPayload = (id: string, f_id: string) => {
    const payload = {
        id: id,
        facilityId: f_id
    }
    return payload;
}
export const addImageData = (data: IUsersData, f_id: string) => {
  const payload = {
    ...data,
    facilityId: f_id,
    imageData: data.image
  }
  return payload;
}
export const updateImageData = (data: IUsersData, f_id: string, userId: string) => {
  const payload = {
    ...data,
    facilityId: f_id,
    id: userId,
    imageData: data.image
  }
  return payload;
}
export const addUserManupulator = (data: IAddUserPayload, profileImageId: string) => {
  const payload = {
    ...data,
    actor: 'FACILITY_USER',
    profileImageId: profileImageId
  }
  return payload;
}