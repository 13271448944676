import { IGuestPayloadFilter } from './guestInterface';

export const guestFilterChange = (
  data: IGuestPayloadFilter,
  f_id: string
): IGuestPayloadFilter => {
  const filterData = {
    ...data,
    facilityId: f_id,
  };
  return filterData;
};
