import React from 'react';
import '../../pages/registrationPage/registration.css';
import FormTemplateWrap from '../formTemplateWrap/formTemplateWrap';

const SuccessComponent: React.FC = () => {
  return (
    <FormTemplateWrap>
      <div className="form-bg successRegistration-page">
        <img
          className="padding-bottom-min logo"
          src={process.env.PUBLIC_URL + '/assets/logo.svg'}
          alt="Logo"
        />
        <span className="success-message">Successfully Registered</span>
        <span className="adminApproval-message">
          Please wait for Admin approval
        </span>
        <span className="adminApproval-message">
          A Mail will be sent to your registered Email id once approved
        </span>
      </div>
    </FormTemplateWrap>
  );
};

export default SuccessComponent;
