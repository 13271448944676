import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Slider from 'react-slick';
import { useAppDispatch, useAppSelector } from '../../../app/hook';
import {
  fetchfacilityRatingById,
  fetchFacilityReviewImageById,
} from '../../../pages/facilityReviewsPage/facilityReviewsSlice';
import { GalleryLoader } from './galleryImage';
import ReviewImageComp from './reviewImageComp';

const ReviewImageWrap: React.FC<{
  reviewId: string;
  setChange?: any;
}> = ({ reviewId, setChange }) => {
  const {
    facilityReviewsFilter,
    facilityReviewStats,
    facilityReviewsData,
    isProcessingFacilityReviews,
    facilityReviewsPaginate,
    facilityReviewByIdData,
    isDeleteReiviewImage,
  } = useAppSelector((state) => state.facilityReviews);
  const [galleryImageIds, setGalleryImageIds] = useState<string[]>();
  const { f_id } = useParams();

  useEffect(() => {
    const getImage = async () => {
      if (reviewId) {
        const response = await dispatch(fetchfacilityRatingById(reviewId));
        setGalleryImageIds(response.payload.data[0].photoIds);
        setIsEqual(response.payload.data[0].photoIds?.slice(0, 1).toString());
      }
    };
    getImage();
  }, [isDeleteReiviewImage]);

  const dispatch = useAppDispatch();
  const [defaultImage, setDefaulImage] = useState<boolean>(false);
  const [defaultImageId, setDefaultImageId] = useState<any>('');

  useEffect(() => {
    const getDefaultImage = async () => {
      setDefaulImage(true);
      try {
        if (galleryImageIds) {
          const response = await dispatch(
            fetchFacilityReviewImageById(
              galleryImageIds?.slice(0, 1).toString()
            )
          );

          setPics(response.payload);

          setDefaulImage(false);
        }
      } catch (err: any) {
        setDefaulImage(false);
      }
    };
    getDefaultImage();
  }, [galleryImageIds]);

  const [pics, setPics] = useState<any>('');
  const [isEqual, setIsEqual] = useState<any>('');

  function Arrowss(props: any) {
    let className = props.type === 'next' ? 'nextArrow' : 'prevArrow';
    className += ' arrow';
    const char =
      props.type === 'next' ? (
        <img
          src={process.env.PUBLIC_URL + '/assets/nextArrow.svg'}
          className="next-button"
        ></img>
      ) : (
        <img
          src={process.env.PUBLIC_URL + '/assets/previousIcon.svg'}
          className="prev-button"
        ></img>
      );
    return (
      <span className={className} onClick={props.onClick}>
        {char}
      </span>
    );
  }
  const settings = {
    dots: false,
    infinite: false,
    arrows: true,
    centerMode: false,

    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,

    nextArrow: <Arrowss type="next" />,
    prevArrow: <Arrowss type="prev" />,
    responsive: [
      {
        breakpoint: 380,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <>
      {defaultImage ? (
        <GalleryLoader />
      ) : (
        <div className="review-image-style">
          <img alt="name" src={pics} className="review-image-card-default" />
        </div>
      )}
      {defaultImage ? (
        <div className="gallery-image-wrap-1"></div>
      ) : (
        <div className="slider-width">
          <Slider {...settings}>
            {galleryImageIds?.map((id: string, index) => (
              <div key={index} className="carosal-div">
                <ReviewImageComp
                  key={index}
                  imageId={id}
                  className={
                    isEqual == id
                      ? 'review-image-imageClass-1'
                      : 'review-image-imageClass'
                  }
                  divClass="gallery-image-wrap"
                  loadingColor="light"
                  setPics={setPics}
                  compare={pics}
                  equal={setIsEqual}
                  reviewId={reviewId}
                  setChange={setChange}
                  imageIds={galleryImageIds}
                />
              </div>
            ))}
          </Slider>
        </div>
      )}
    </>
  );
};

export default ReviewImageWrap;
