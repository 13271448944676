import React from 'react';
import './imageLoader.css';

type _react = typeof React;

export const GalleryLoader = () => {
  return (
    <>
      <div className="delete-icon-style">
        <img
          src={process.env.PUBLIC_URL + '/assets/galleryImage.svg'}
          className="gallery-image"
        ></img>
      </div>

      <span className="image-loader"></span>
    </>
  );
};
