/* eslint-disable react/react-in-jsx-scope */
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './translator.css';

interface ILanguageSelector {
  isLanguageSelector: boolean;
  setIsLanguageselector: (data: boolean) => void;
}

export const LanguageSelector: FC<ILanguageSelector> = ({
  isLanguageSelector,
  setIsLanguageselector,
}) => {
  const [t, i18n] = useTranslation('common');

  interface ILanguageOptons {
    label: string;
    value: string;
  }

  const langaugesOtions: ILanguageOptons[] = [
    {
      label: 'ENG',
      value: 'en',
    },
    {
      label: 'SPA',
      value: 'sp',
    },
    {
      label: 'FRE',
      value: 'fr',
    },
    {
      label: 'GER',
      value: 'de',
    },
  ];

  const [selectedLanguage, setSelectedLanguage] = useState({
    label: 'ENG',
    value: 'en',
  } as ILanguageOptons);

  const changeLanguage = (item: ILanguageOptons) => {
    if (i18n.language !== item.value) {
      i18n.changeLanguage(item.value);
    }

    setIsLanguageselector(false);
    setSelectedLanguage(item);
  };

  return (
    <>
      <div
        onClick={() => setIsLanguageselector(!isLanguageSelector)}
        className="selcetor-parent"
      >
        <div className="selector-wrap">
          <img src="/assets/globeIcon.svg" alt="" />
          <span className="show-lang-selected">{selectedLanguage.label}</span>
          <img
            src="/assets/carot.svg"
            style={{ objectFit: 'none' }}
            alt=""
          ></img>
        </div>
        {isLanguageSelector && (
          <div className="select-dropdown">
            <div className="drop-down-container">
              {langaugesOtions.map((item: ILanguageOptons) =>
                selectedLanguage.label !== item.label ? (
                  <span
                    key={item.value}
                    onClick={() => changeLanguage(item)}
                    className="language-text"
                  >
                    {item.label}
                  </span>
                ) : null
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
