import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import ImageComp from '../../components/imageComponents/imageComp';
import { ModalComponent } from '../../components/modal';
import { openQRModalReducer } from '../../components/modal/modalSlice';
import PageLoader from '../../components/pageLoading/pageLoader';
import QrCode from '../../components/qrCode/qrCode';
import { History } from '../../_helpers/history';
import { getSelectedFacility } from '../loginPage/authSlice';
import { IFacility } from '../loginPage/loginInterface';
import './facilityProfile.css';

const FacilityProfile: React.FC = () => {
  const { f_id } = useParams();
  const { isProcessingFacility } = useAppSelector(
    (state) => state.authentication
  );
  const { openQRModal } = useAppSelector((state) => state.modal);
  const [facility, setFacility] = useState<IFacility>();
  const dispatch = useAppDispatch();
  const [translation] = useTranslation('common');

  useEffect(() => {
    window.scrollTo(0, 0);
    const getFacilityFunc = async () => {
      if (f_id) {
        const response = await dispatch(getSelectedFacility(f_id));
        setFacility(response.payload?.data[0]);
      }
    };
    getFacilityFunc();
  }, [f_id]);
  const handleQrClick = () => {
    dispatch(openQRModalReducer());
  };
  const handleEditPage = () => {
    if (f_id) {
      History.navigate(`/facility-profile/edit/${f_id}`);
    }
  };

  return (
    <div className="page-background facilityProfile">
      {isProcessingFacility ? (
        <PageLoader />
      ) : (
        <div className="facilityProfile-container">
          <div className="facilityProfile-image-div">
            <ImageComp
              imageId={facility?.coverPicId}
              noImageClass="noImageClass"
              className="profile-image"
              divClass="noColor-image-background"
              noImageDivClass="noImage-background"
              alt="facility-profile-image"
              src="/assets/emptyImage.svg"
            />
            <ImageComp
              imageId={facility?.whiteLogoId}
              className="profile-whiteLogo"
              noImageClass="noImageClass"
              divClass="facilityProfile-whiteLogo"
              alt="facility-white-logo"
              src="/assets/defaultLogoWhite.svg"
            />
            <div
              className="facilityProfile-QrCode pointer"
              onClick={handleQrClick}
            >
              <div>
                <img
                  src={process.env.PUBLIC_URL + '/assets/qrIcon-white.svg'}
                  alt="qr-code-image"
                />
              </div>
              <span>
                {translation('PROFILE_EDIT.UNIQUE_PROPERTY_IDENTIFIER')}
              </span>
            </div>
          </div>
          <div className="facilityProfile-details">
            <div className="details-header">
              <ImageComp
                imageId={facility?.coloredLogoId}
                className="profile-coloredLogo"
                noImageClass="noImageClass"
                divClass="facilityProfile-coloredLogo"
                alt="facility-logo"
                src="/assets/defaultLogoBlack.svg"
              />
              <Button
                className="editProfile-button shadow-none"
                onClick={handleEditPage}
              >
                <img
                  src={process.env.PUBLIC_URL + '/assets/editIcon-white.svg'}
                  alt="edit-icon"
                />
                {translation('PROFILE_EDIT.EDIT_PROFILE_BUTTON')}
              </Button>
            </div>
            <div className="details-facilityData">
              <div className="details-flex-column">
                <span>{translation('PROFILE_EDIT.EMAIL_ADDRESS')}</span>
                <span>{translation(`${facility?.email}`)}</span>
              </div>
              <div className="details-flex-column">
                <span>{translation('PROFILE_EDIT.PHONE_NUMBER')}</span>
                <span>{translation(`${facility?.phoneNo}`)}</span>
              </div>
              <div className="details-flex-column">
                <span>{translation('PROFILE_EDIT.FACILITY_NUMBER')}</span>
                <span>{translation(`${facility?.facilityNumber}`)}</span>
              </div>
            </div>
            <div className="details-border-line"></div>
            <div className="details-address">
              <span className="detailsLocation-heading">
                {translation('PROFILE_EDIT.LOCATION_ADDRESS')}
              </span>
              <div className="details-facilityData">
                <div className="details-flex-column">
                  <span>{translation('PROFILE_EDIT.APARTMENT_FLOOR')}</span>
                  <span>
                    {translation(`${facility?.facilityAddress?.addressLine1}`)}
                  </span>
                </div>
                <div className="details-flex-column">
                  <span>{translation('PROFILE_EDIT.STREET_NAME')}</span>
                  <span>
                    {translation(`${facility?.facilityAddress?.addressLine2}`)}
                  </span>
                </div>
                <div className="details-flex-column">
                  <span>State / Country / Zip</span>
                  <span>
                    {translation(`${facility?.facilityAddress?.addressLine3}`)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {facility
        ? openQRModal && (
            <ModalComponent
              isOpen={openQRModal}
              modalHeading={facility?.facilityName}
            >
              <QrCode content={facility} onCreate={null} isAdding={null} />
            </ModalComponent>
          )
        : null}
    </div>
  );
};

export default FacilityProfile;
