import * as yup from 'yup';

export const businessRegistrationSchema = yup
  .object({
    name: yup.string().required('Business name is required'),
    email: yup
      .string()
      .email('FACILITY.EMAIL_VALID')
      .required('Email id is required'),
    category: yup
      .array()
      .min(1, 'Minimum one category required')
      .of(
        yup.object().shape({
          label: yup.string().required('Category is required'),
          value: yup.string().required('Category is required'),
        })
      )
      .required('Category is required'),
    description: yup
      .string()
      .max(500, 'Maximum 500 characters')
      .required('Description is required'),
    minPrice: yup.string().required('Minimum price is required'),
    maxPrice: yup.string().required('Maximum price is required'),
    capacity: yup.string().required('Capacity is required'),
  })
  .required();
