import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import '../diningForm/diningForm.css';
import LoadingSpinner from '../loadingSpinner/loadingSpinner';
import { closeModalReducer } from '../modal/modalSlice';
import { categoryFormSchema } from './repeatFormValidation';

const AddEditCategory: React.FC<{
  onCreate: (data: string) => void;
}> = ({ onCreate }) => {
  const [translation] = useTranslation('common');
  const { isCreatingNewCategory, categoryRefreshTrigger } = useAppSelector(
    (state) => state.dining
  );
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<{ category: string }>({
    resolver: yupResolver(categoryFormSchema),
    defaultValues: {
      category: '',
    },
  });

  useEffect(() => {
    reset({
      category: '',
    });
  }, []);

  const onSubmit = (data: { category: string }) => {
    onCreate(data.category);
  };

  return (
    <>
      <div className="dining-modal-header">
        <h2 className="modal-heading">
          {translation('DINING_SCHEDULES.ADD_CATEGORY')}
        </h2>
        <div onClick={() => dispatch(closeModalReducer())}>
          <img
            className="pointer"
            src={process.env.PUBLIC_URL + '/assets/closeModalIcon.svg'}
            alt="close-icon"
          />
        </div>
      </div>
      <Form className="form width-100" onSubmit={handleSubmit(onSubmit)}>
        <div className="category-parent-div">
          <div className="modal-right-wrap">
            <FormGroup>
              <FormGroup>
                <Controller
                  control={control}
                  name="category"
                  render={({ field }) => (
                    <Input
                      placeholder="Category"
                      className="form-input shadow-none"
                      invalid={errors?.category ? true : false}
                      {...field}
                    />
                  )}
                />
                <FormFeedback>
                  {translation(`${errors?.category?.message}`)}
                </FormFeedback>
              </FormGroup>
            </FormGroup>
          </div>

          <div className="modal-submit-button-div">
            <Button type="submit" className="form-button shadow-none">
              {isCreatingNewCategory ? (
                <LoadingSpinner />
              ) : (
                translation(`BUTTONS.ADD`)
              )}
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
};

export default AddEditCategory;
