/* eslint-disable prettier/prettier */
import {
  IAddNewAmenityPayload,
  IAmenitiesFilter,
  IAmenitiesPayloadFilter,
  IAmenityData,
  IUpdateAmenityPayload,
} from './amenitiesInterface';

export const filterChange = (
  f_id: string,
  data: IAmenitiesFilter
): IAmenitiesPayloadFilter => {
  const payload = {
    ...data,
    facilityIds: f_id,
  };
  return payload;
};

export const addAmenityDataChange = (
  f_id: string,
  data: IAddNewAmenityPayload
): IAddNewAmenityPayload => {
  const payload = {
    ...data,
    facilityId: f_id,
    status: 'ACTIVE',
  };
  return payload;
};

export const addAmenityManupulator = (data: any, photoId: string) => {
  const payload = {
    ...data,
    photoId: photoId,
    status: 'ACTIVE',
  };
  return payload;
};
export const updateAmenityManupulator = (data: any, photoId: string) => {
  const payload = {
    ...data,
    photoId: photoId,
    status: 'ACTIVE',
  };
  return payload;
};

export const addAmenityImage = (data: IAmenityData, f_id: string) => {
  const payload = {
    ...data,
    facilityId: f_id,
    imageData: data.image,
  };
  return payload;
};
export const updateAmenityImage = (
  data: IAmenityData,
  f_id: string,
  id: string
) => {
  const payload = {
    ...data,
    id: id,
    facilityId: f_id,
    imageData: data.image,
    status: 'ACTIVE',
  };
  return payload;
};

export const deleteAmenity = (id: string) => {
  const payload = {
    id: id,
    for: 'delete',
  };
  return payload;
};

export const editAmenity = (id: string) => {
  const payload = {
    id: id,
    for: 'edit',
  };
  return payload;
};

export const editAmenityPayload = (
  id: string,
  f_id: string,
  data: IUpdateAmenityPayload
) => {
  const payload = {
    ...data,
    id: id,
    facilityId: f_id,
    status: 'ACTIVE',
  };
  return payload;
};

export const deleteAmenityPayload = (id: string, f_id: string) => {
  const payload = {
    id: id,
    facilityId: f_id,
  };
  return payload;
};
