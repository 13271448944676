export const roleChecker = (userRoleId: string, ...roleIds: string[]) => {
  const value = roleIds.includes(userRoleId);
  return value;
};
export const activeInactiveChecker = (status: string) => {
  if (status == 'ACTIVE') {
    return true;
  } else if (status == 'INACTIVE') {
    return false;
  }
};

export const getLanguageCode = (language: string) => {
  switch (language) {
    case 'en':
      return 'en';
    case 'sp':
      return 'es';
    case 'fr':
      return 'fr';
    case 'de':
      return 'de';
    default:
      return 'unknown';
  }
};
