import {
  IAddNewAmenity,
  IAmenitiesPayloadFilter,
  IUpdateAmenityFinal,
} from '../pages/amenitiesPage/amenitiesInterface';
import { deleteApi, get, post, put } from './apiService';

export const getAmenityById = async (id: string) => {
  return await get(`/facility-amenities/${id}`);
};
export const getAllAmenities = async (data: IAmenitiesPayloadFilter) => {
  return await get('/facility-amenities', data);
};
export const addAmenity = async (data: IAddNewAmenity) => {
  return await post('/facility-amenities', data);
};
export const updateAmenity = async (data: IUpdateAmenityFinal) => {
  return await put(`/facility-amenities/${data?.id}`, data);
};
export const deleteAmenity = async (id: string) => {
  return await deleteApi(`/facility-amenities/${id}`);
};
export const getImageDetails = async (id: string) => {
  return await get(`/files/details/${id}`);
};
