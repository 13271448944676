import React from 'react';
import { useTranslation } from 'react-i18next';
import './noDataComponent.css';

const NoDataBooking: React.FC<{
  name: string;
}> = ({ name }) => {
  const [translation] = useTranslation('common');
  return (
    <div>
      <div className="noData">
        <img src={process.env.PUBLIC_URL + '/assets/noData.svg'} alt="" />
        <span>{translation(`No ${name}`)}</span>
      </div>
    </div>
  );
};

export default NoDataBooking;
