import React from 'react';
import { domain_env } from '../../config/env';
import { PathConstants } from '../../constants/pathConstants';
import { History } from '../../_helpers/history';
import '../pageLoading/pageLoader.css';

const RefreshLoader = () => {
  const hostName = window.location.hostname;
  const pathName = window.location.pathname;
  const handleClick = () => {
    if (
      (domain_env.BUSINESS_DOMAINS.includes(hostName) ||
        domain_env.LOCALHOST.includes(hostName)) &&
      pathName.split('/')[1] == 'business'
    ) {
      History.push(PathConstants.BUSINESS_LOGIN);
    } else if (
      (domain_env.FACILITY_DOMAINS.includes(hostName) ||
        domain_env.LOCALHOST.includes(hostName)) &&
      pathName.split('/')[1] != 'business'
    ) {
      History.push(PathConstants.LOGIN);
    }
  };
  return (
    <React.Fragment>
      <div className="refreshLoader">
        <img
          src={process.env.PUBLIC_URL + '/assets/logo.png'}
          alt=""
          onClick={handleClick}
        />
      </div>
    </React.Fragment>
  );
};

export default RefreshLoader;
