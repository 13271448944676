import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';
import { RootState } from '../../app/store';
import {
  getImage,
  getImageId,
  // eslint-disable-next-line prettier/prettier
  updateFacility,
} from '../../services/facilityService';
import { formDataConverter } from '../../_helpers/formDataConverter';
import { History } from '../../_helpers/history';
import { IFacilityPayload, IProfileUpdatePayload } from './profileInterface';
import { faciltyPayloadSetter } from './utilityFunction';

export interface IFacilitySlice {
  isUpdatingProfile: boolean;
  updatedProfile: boolean;
  isFetchingImage: boolean;
}
const initialState: IFacilitySlice = {
  isUpdatingProfile: false,
  updatedProfile: false,
  isFetchingImage: false,
};

export const facilityProfileSlice = createSlice({
  name: 'facilityProfile',
  initialState,
  reducers: {
    resetProfileState: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProfileImageId.pending, (state) => {
        state.isUpdatingProfile = true;
        state.updatedProfile = false;
      })
      .addCase(
        fetchProfileImageId.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isUpdatingProfile = false;
          state.updatedProfile = true;

          toast.success(
            `${action.payload?.payload?.message
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload?.payload?.message.slice(1)}`
          );
          History.navigate(-1);
        }
      )
      .addCase(
        fetchProfileImageId.rejected,
        (state, action: PayloadAction<any>) => {
          state.isUpdatingProfile = false;
          state.updatedProfile = false;

          toast.error(
            `${action.payload
              .toLowerCase()
              .charAt(0)
              .toUpperCase()}${action.payload.slice(1)}`
          );
        }
      )
      .addCase(getImageById.pending, (state) => {
        state.isFetchingImage = true;
      })
      .addCase(getImageById.fulfilled, (state) => {
        state.isFetchingImage = false;
      })
      .addCase(getImageById.rejected, (state, action: PayloadAction<any>) => {
        state.isFetchingImage = false;

        toast.error(
          `${action.payload
            .toLowerCase()
            .charAt(0)
            .toUpperCase()}${action.payload.slice(1)}`
        );
      });
  },
});

export const getImageById = createAsyncThunk(
  'facilityProfile/getImageById',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await getImage(id);
      const fileType = response.headers['content-type'];
      const buffer = Buffer.from(response.data).toString('base64');

      const data = `data:${fileType};base64, ${buffer}`;

      return data;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const updateFacilityDetails = createAsyncThunk(
  'facilityProfile/updateFacilityDetails',
  async (data: IProfileUpdatePayload, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateFacility(data);
      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const fetchProfileImageId = createAsyncThunk(
  'facilityProfile/fetchProfileImageId',
  async (data: IFacilityPayload, { rejectWithValue, dispatch }) => {
    try {
      let coloredLogoresponse: any = {};
      let whiteLogoresponse: any = {};
      let coverPicresponse: any = {};
      if (data.coloredLogo != '') {
        coloredLogoresponse = await getImageId(
          formDataConverter(data.coloredLogo)
        );
      }
      if (data.whiteLogo != '') {
        whiteLogoresponse = await getImageId(formDataConverter(data.whiteLogo));
      }
      if (data.coverPic != '') {
        coverPicresponse = await getImageId(formDataConverter(data.coverPic));
      }
      const response: any = dispatch(
        updateFacilityDetails(
          faciltyPayloadSetter(
            data.data,
            data.coloredLogo
              ? coloredLogoresponse?.data[0]?.id
              : data.coloredLogoId,
            data.whiteLogo ? whiteLogoresponse?.data[0]?.id : data.whiteLogoId,
            data.coverPic ? coverPicresponse?.data[0]?.id : data.coverPicId,
            data.facilityId
          )
        )
      );

      return response;
    } catch (err: any) {
      return rejectWithValue(err.message);
    }
  }
);

export const { resetProfileState } = facilityProfileSlice.actions;
export const selectfacilityProfile = (state: RootState) =>
  state.facilityProfile;
export const facilityProfileReducer = facilityProfileSlice.reducer;
